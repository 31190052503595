import colors from 'styles/colors';
import styled from 'styled-components';
import React from 'react';
import ArrowRight from 'images/arrow_right.svg';
import ArrowLeft from 'images/arrow_left.svg';
import ArrowLast from 'images/arrow_last.svg';
import ArrowFirst from 'images/arrow_first.svg';
import { FormattedMessage, useIntl } from 'react-intl';

const IconDiv = styled.img`
  display: flex;
  justify-content: center;
  height: 14px;
`;

const Icon = (img) => {
  return <IconDiv src={img} alt={'icon'} />;
};

export const CustomStyle = {
  headerStyle: {
    backgroundColor: colors.blue8,
    color: colors.gray1,
    fontSize: '12px',
    fontWeight: '600',
  },
  // paginationType: 'stepped',
  pageSize: 11,
  pageSizeOptions: [],
  display: 'flex',
  showFirstLastPageButtons: true,
  draggable: false,
  maxBodyHeight: '65vh',
};

export const CustomStyleBody = {
  width: '100%',
  boxShadow: 'none',
  fontSize: '14px',
  fontWeight: 400,
  color: colors.gray1,
  borderTopLeftRadius: '8px',
  borderTopRightRadius: '8px',
  display: 'flex',
  flexDirection: 'column',
  height: 'auto',
};

export const borderRadius = (columns) => {
  for (let c = 0; c < columns.length; c++) {
    columns[c]['headerStyle'] = {};
    columns[c]['cellStyle'] = {};
    columns[c]['headerStyle']['fontFamily'] = 'Poppins';
    columns[c]['cellStyle']['textOverflow'] = 'ellipsis';
    columns[c]['cellStyle']['whiteSpace'] = 'nowrap';
    columns[c]['cellStyle']['overflow'] = 'hidden';
    columns[c]['cellStyle']['maxWidth'] = 100;
  }

  columns[0]['headerStyle']['borderTopLeftRadius'] = '8px';
  columns[columns.length - 1]['headerStyle']['borderTopRightRadius'] = '8px';

  return columns;
};

export const Components = {
  Toolbar: () => null,
};

export const Icons = {
  SortArrow: () => null,
  NextPage: () => Icon(ArrowRight),
  PreviousPage: () => Icon(ArrowLeft),
  LastPage: () => Icon(ArrowLast),
  FirstPage: () => Icon(ArrowFirst),
};

export const Localization = () => {
  const intl = useIntl();

  return {
    pagination: {
      nextTooltip: <FormattedMessage id={'table.NextPage'} />,
      previousTooltip: <FormattedMessage id={'table.PreviousPage'} />,
      firstTooltip: <FormattedMessage id={'table.FirstPage'} />,
      lastTooltip: <FormattedMessage id={'table.LastPage'} />,
      labelDisplayedRows:
        `{from} - {to} ` +
        intl.formatMessage({
          id: 'table.localization.of',
        }) +
        ` {count}`,
    },
  };
};
