import React from 'react';
import PropTypes from 'prop-types';
import TableIconsRow from 'components/commons/Icons/TableIconsRow/index.js';
import {
  BigContainer,
  Container,
  Wrapper,
  Left,
  Title,
  Subtitle,
} from './styled';

const Accordion = (props) => {
  return (
    <BigContainer>
      <Container>
        <Wrapper>
          <Left>
            <Title>{props.name}</Title>
            <Subtitle>{props.subtitle}</Subtitle>
          </Left>
          <TableIconsRow
            onEdit={props.onEditParent}
            onNewTab={props.onNewTabParent}
            onAddPdf={props.onAddPdfParent}
            onInfo={props.onInfo}
            onAuxClick={props.onAuxClick}
          />
        </Wrapper>
      </Container>
      {props.children}
    </BigContainer>
  );
};

Accordion.propTypes = {
  name: PropTypes.any,
  subtitle: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onEditParent: PropTypes.func,
  onNewTabParent: PropTypes.func,
  onAddPdfParent: PropTypes.func,
  onInfo: PropTypes.func,
  onAuxClick: PropTypes.func,
  children: PropTypes.any,
};

export default Accordion;
