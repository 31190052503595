import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Label from 'components/commons/Texts/Label';
import BasicInput from 'components/commons/Inputs/BasicInput';
import { FirstRowDiv, InputContainer, customStyles } from './styled';
import Select from 'react-select';
import { removeFirstWhiteSpace } from 'utils/functions/general';

const FirstRow = (props) => {
  return (
    <FirstRowDiv>
      <InputContainer>
        <Label value={<FormattedMessage id={'orders.input1'} />} />
        <Select
          options={props.allSuppliers.map((item) => {
            return { value: item.id, label: item.name };
          })}
          placeholder={<FormattedMessage id={'selectBoxDefault'} />}
          styles={customStyles('select')}
          menuPortalTarget={document.body}
          menuPosition={'fixed'}
          onChange={(opt) => props.handleChange('supplier', opt.value)}
          hideSelectedOptions
          isSearchable={false}
          defaultValue={props.defaultSupplier}
          isDisabled={props.isEditing}
        />
      </InputContainer>
      <InputContainer>
        <Label value={<FormattedMessage id={'orders.input2'} />} />
        <BasicInput
          onChange={(e) =>
            props.handleChange(
              'orderNumber',
              removeFirstWhiteSpace(e.target.value),
            )
          }
          value={props.orderNumber}
          maxLength={50}
          disabled={props.isEditing}
        />
      </InputContainer>
      <InputContainer>
        <Label value={<FormattedMessage id={'orders.input3'} />} />
        <BasicInput
          onChange={(e) => props.handleChange('startDate', e.target.value)}
          value={props.startDate}
          type="date"
        />
      </InputContainer>
      <InputContainer>
        <Label value={<FormattedMessage id={'orders.input4'} />} />
        <BasicInput
          onChange={(e) => props.handleChange('endingDate', e.target.value)}
          value={props.endingDate}
          type="date"
          disabled={props.isEditing}
        />
      </InputContainer>
    </FirstRowDiv>
  );
};

FirstRow.propTypes = {
  handleChange: PropTypes.func,
  supplier: PropTypes.number,
  orderNumber: PropTypes.string,
  startDate: PropTypes.string,
  endingDate: PropTypes.string,
  allSuppliers: PropTypes.array,
  isEditing: PropTypes.bool,
  defaultSupplier: PropTypes.array,
};

export default FirstRow;
