import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import GenericButton from 'components/commons/Buttons/GenericButton';
import Check from 'images/check_no_background.png';
import Inputs from './Inputs';
import { createSupplier } from 'store/Suppliers/actions';
import { Split } from 'utils/functions/general';
import {
  Container,
  Centered,
  Wrapper,
  Title,
  Body,
  LastRow,
  ConfirmButton,
  CancelButton,
  InsertedContainer,
  IconContainer,
  Icon,
} from './styled';

const Modal = (props) => {
  const [inserted, setInserted] = useState(false);
  const [error, setError] = useState('suppliers.status1');
  const [name, setName] = useState(
    props.show === 2 ? props.supplierEdit.name : '',
  );
  const [mail, setMail] = useState(
    props.show === 2 ? Split(props.supplierEdit.mail) : [],
  );
  const [phone, setPhone] = useState(
    props.show === 2 ? Split(props.supplierEdit.phone_number) : [],
  );
  const [discount, setDiscount] = useState(
    props.show === 2 ? props.supplierEdit.discount : '',
  );
  const dispatch = useDispatch();

  const handleChange = (name, value) => {
    switch (name) {
      case 'inserted':
        setInserted(value);
        break;
      case 'error':
        setError(value);
        break;
      case 'name':
        setName(value);
        break;
      case 'mail':
        setMail(value);
        break;
      case 'phone':
        setPhone(value);
        break;
      case 'discount':
        setDiscount(value);
        break;
    }
  };

  const submit = () => {
    if (validateFields()) {
      dispatch(
        createSupplier(
          setError,
          name,
          mail.join(),
          phone.join(),
          discount,
          props.closeModal,
          !props.show === 2,
          setInserted,
        ),
      );
    }
  };

  const validateFields = () => {
    if (!name) {
      handleChange('error', 'suppliers.status2');
      return false;
    }

    return true;
  };

  useEffect(() => {
    function handleEscapeKey(event) {
      if (event.code === 'Escape') {
        props.closeModal();
      }
    }

    document.addEventListener('keydown', handleEscapeKey);
    return () => document.removeEventListener('keydown', handleEscapeKey);
  }, []);

  return (
    <>
      <Container />
      <Centered inserted={inserted}>
        {inserted ? (
          <InsertedContainer>
            <IconContainer>
              <Icon src={Check} alt={'icon'} />
            </IconContainer>
            <Title>
              <FormattedMessage
                id={
                  props.show === 2 ? 'suppliers.edited' : 'suppliers.inserted'
                }
              />
            </Title>
          </InsertedContainer>
        ) : (
          <>
            <Wrapper>
              <Title>
                <FormattedMessage
                  id={props.show === 2 ? 'suppliers.title2' : 'suppliers.title'}
                />
              </Title>
              <Body>
                <Inputs
                  error={error}
                  handleChange={handleChange}
                  name={name}
                  phone={phone}
                  mail={mail}
                  discount={discount}
                  setMail={setMail}
                  setPhone={setPhone}
                  isEditing={props.show === 2}
                />
                <LastRow>
                  <CancelButton>
                    <GenericButton
                      theme="light"
                      title={<FormattedMessage id={'suppliers.cancel'} />}
                      onClick={props.closeModal}
                    />
                  </CancelButton>
                  <ConfirmButton>
                    <GenericButton
                      theme="dark"
                      title={
                        <FormattedMessage
                          id={
                            props.show === 2
                              ? 'suppliers.edit'
                              : 'suppliers.create'
                          }
                        />
                      }
                      onClick={submit}
                      disabled={!props.show === 2 && !name}
                    />
                  </ConfirmButton>
                </LastRow>
              </Body>
            </Wrapper>
          </>
        )}
      </Centered>
    </>
  );
};

Modal.propTypes = {
  closeModal: PropTypes.func,
  show: PropTypes.number,
  supplierEdit: PropTypes.any,
};

export default Modal;
