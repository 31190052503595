import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Wrapper, Container, Error, Body } from './styled';
import FirstRow from './FirstRow';
import SecondRow from './SecondRow';

const Inputs = (props) => {
  return (
    <>
      <Wrapper>
        <Body show={props.show}>
          <Container>
            <FirstRow
              handleChange={props.handleChange}
              supplier={props.supplier}
              orderNumber={props.orderNumber}
              startDate={props.startDate}
              endingDate={props.endingDate}
              allSuppliers={props.allSuppliers}
              isEditing={props.show === 2}
              defaultSupplier={props.defaultSupplier}
            />
            <SecondRow
              handleChange={props.handleChange}
              amountDevices={props.amountDevices}
            />
          </Container>
          <Error id={props.error}>
            <FormattedMessage id={props.error} />
          </Error>
        </Body>
      </Wrapper>
    </>
  );
};

Inputs.propTypes = {
  error: PropTypes.string,
  handleChange: PropTypes.func,
  supplier: PropTypes.number,
  orderNumber: PropTypes.string,
  startDate: PropTypes.string,
  endingDate: PropTypes.string,
  allSuppliers: PropTypes.array,
  amountDevices: PropTypes.any,
  show: PropTypes.number,
  defaultSupplier: PropTypes.array,
};

export default Inputs;
