import React from 'react';
import PropTypes from 'prop-types';
import FirstRow from './FirstRow';
import SecondRow from './SecondRow';

const FirstStage = (props) => {
  if (props.show !== 1 && props.show !== 2) return null;

  return (
    <>
      <FirstRow handleChange={props.handleChange} />
      <SecondRow
        handleChange={props.handleChange}
        contractSignatureDate={props.contractSignatureDate}
        description={props.description}
        contractEndDate={props.contractEndDate}
        show={props.show}
      />
    </>
  );
};

FirstStage.propTypes = {
  handleChange: PropTypes.func,
  show: PropTypes.number,
  contractSignatureDate: PropTypes.string,
  contractEndDate: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
};

export default FirstStage;
