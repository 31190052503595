import React, { useState, useEffect } from 'react';
import Modal from './Modal';
import Landing from 'components/commons/Landing';
import * as C from 'components/pages/App/constants';
import { PERMISSIONS } from 'utils/constants';
import Body from './Body';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { getClients } from 'store/Clients/actions';
import { FormattedMessage } from 'react-intl';

const ServicesInfo = (props) => {
  const dispatch = useDispatch();
  const nif = props.match.params.id;

  // 0: Close, 1: New Contract, 2: Edit Contract, 3: New Annex, 4: Edit Annex
  const [openModal, setOpenModal] = useState(0);
  const [itemEdit, setItemEdit] = useState({});
  const [filter, setFilter] = useState('');
  const [name, setName] = useState('');

  useEffect(() => {
    dispatch(getClients(setName, nif));
  }, []);

  return (
    <>
      {openModal !== 0 && (
        <Modal
          show={openModal}
          closeModal={() => setOpenModal(0)}
          itemEdit={itemEdit}
          nif={nif}
        />
      )}
      <Landing
        redirectArray={[
          C.services_info_es + nif,
          C.services_info_en + nif,
          C.services_info_pt + nif,
        ]}
        permission={PERMISSIONS.services}
        permissionAbm={PERMISSIONS.services_abm}
        moduleName="Servicios"
        titleId="lateral_menu.services"
        setOpenModal={setOpenModal}
        buttonTextId="services.add_service"
        filter={filter}
        setFilter={setFilter}
        historic={[
          {
            subtitle: <FormattedMessage id={'lateral_menu.services'} />,

            click: C.services_es,
          },
          {
            subtitle: name.length > 0 ? name[0].name : nif,
          },
        ]}
      >
        <Body
          openModal={openModal}
          setItemEdit={setItemEdit}
          setOpenModal={setOpenModal}
          filter={filter}
          nif={nif}
        />
      </Landing>
    </>
  );
};

ServicesInfo.propTypes = {
  match: PropTypes.any,
};

export default ServicesInfo;
