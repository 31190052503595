import styled from 'styled-components';
import colors from 'styles/colors';

export const Wrapper = styled.div`
  display: flex;
  height: 75%;
  margin: 0 40px;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-width: 730px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  margin-bottom: 15px;
`;

export const Error = styled.div`
  display: flex;
  color: ${(props) =>
    ['suppliers.status1'].includes(props.id) ? colors.gray1 : colors.red2};
  width: 100%;
  font-size: 0.813rem;
  font-weight: 500;
`;
