import React, { useState } from 'react';
import Modal from './Modal';
import Insert from './Insert';
import Landing from 'components/commons/Landing';
import * as C from 'components/pages/App/constants';
import { PERMISSIONS } from 'utils/constants';
import OrdersTable from '../Orders/Table';
import { TableContainer } from './styled';

const Orders = () => {
  // 0: Close, 1: New, 2: Edit, 3: Delete, 4: Insert
  const [openModal, setOpenModal] = useState(0);
  const [orderEdit, setOrderEdit] = useState({});
  const [filter, setFilter] = useState('');

  return (
    <>
      {![0, 1, 2, 3].includes(openModal) && (
        <Insert
          show={openModal}
          closeModal={() => setOpenModal(0)}
          orderEdit={orderEdit}
        />
      )}

      {[1, 2, 3].includes(openModal) && (
        <Modal
          show={openModal}
          closeModal={() => setOpenModal(0)}
          orderEdit={orderEdit}
        />
      )}
      <Landing
        redirectArray={[C.orders_es, C.orders_en, C.orders_pt]}
        permission={PERMISSIONS.orders}
        permissionAbm={PERMISSIONS.orders_abm}
        moduleName="Ordenes"
        titleId="lateral_menu.orders"
        setOpenModal={setOpenModal}
        buttonTextId="orders.add_order"
        filter={filter}
        setFilter={setFilter}
      >
        <TableContainer>
          <OrdersTable
            openModal={openModal}
            setOpenModal={setOpenModal}
            setOrderEdit={setOrderEdit}
            filter={filter}
            setFilter={setFilter}
          />
        </TableContainer>
      </Landing>
    </>
  );
};

export default Orders;
