import React from 'react';
import PropTypes from 'prop-types';
import { CustomBox, Paragraph, CustomTooltip } from './styled';

const Tooltips = (props) => {
  return (
    <CustomTooltip
      title={
        <>
          <b
            style={{
              fontWeight: props.dots ? '400' : '600',
            }}
          >
            {props.title}
          </b>
          {props.desc && <Paragraph>{props.desc}</Paragraph>}
        </>
      }
      placement="right"
    >
      <CustomBox>{props.title}</CustomBox>
    </CustomTooltip>
  );
};

Tooltips.propTypes = {
  title: PropTypes.object,
  desc: PropTypes.object,
  dots: PropTypes.bool,
};

export default Tooltips;
