import styled from 'styled-components';
import colors from 'styles/colors';
import { ReactComponent as stats } from 'images/statistics.svg';
import { ReactComponent as clients } from 'images/clients.svg';
import { ReactComponent as logout } from 'images/logout.svg';
import { ReactComponent as suppliers } from 'images/suppliers.svg';
import { ReactComponent as orders } from 'images/orders.svg';
import { ReactComponent as logo } from 'images/Emendu_logo.svg';
import { ReactComponent as services } from 'images/services.svg';
import { ReactComponent as shipments } from 'images/shipments.svg';
import { ReactComponent as fulfillments } from 'images/fulfillments.svg';
import { ReactComponent as devices } from 'images/devices.svg';
import { ReactComponent as dashboard } from 'images/dashboard.svg';
import { ReactComponent as back } from 'images/back_arrow.svg';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const TopContainer = styled.div`
  width: 100%;
  height: 75px;
  min-height: 75px;
  display: flex;
  background-color: ${colors.white};
  box-shadow: 0px 2px 8px 3px rgba(0, 0, 0, 0.101678);
  justify-content: space-between;
  z-index: 2;
`;

export const UserContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 45px;
`;

export const UserInfo = styled.div`
  display: flex;
  align-items: center;
  padding: 0 10px;

  button {
    padding: 12px;
  }
`;

export const Picture = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 100px;
`;

export const Name = styled.div`
  padding: 0 10px;
  font-size: 1rem;
  font-weight: 500;
`;

export const Arrow = styled.img`
  width: 12px;
  height: 7.5px;
  cursor: pointer;
`;

export const Body = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
`;

export const LeftContainer = styled.div`
  width: 210px;
  min-width: 160px;
  height: 100%;
  display: flex;
  background-color: ${colors.white};
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 1024px) {
    width: 180px;
    min-width: 160px;
  }
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
`;

export const Stats = styled(stats)`
  width: 15px;
  fill: ${(props) => (props.active === 'true' ? colors.blue2 : colors.gray8)};
`;

export const Suppliers = styled(suppliers)`
  width: 15px;
  fill: ${(props) => (props.active === 'true' ? colors.blue2 : colors.gray8)};
`;

export const Orders = styled(orders)`
  width: 15px;
  fill: ${(props) => (props.active === 'true' ? colors.blue2 : colors.gray8)};
`;

export const Services = styled(services)`
  width: 15px;
  fill: ${(props) => (props.active === 'true' ? colors.blue2 : colors.gray8)};
`;

export const Shipments = styled(shipments)`
  width: 15px;
  fill: ${(props) => (props.active === 'true' ? colors.blue2 : colors.gray8)};
`;

export const Fulfillments = styled(fulfillments)`
  width: 15px;
  fill: ${(props) => (props.active === 'true' ? colors.blue2 : colors.gray8)};
`;

export const Devices = styled(devices)`
  width: 15px;
  fill: ${(props) => (props.active === 'true' ? colors.blue2 : colors.gray8)};
`;

export const Dashboard = styled(dashboard)`
  width: 15px;
  fill: ${(props) => (props.active === 'true' ? colors.blue2 : colors.gray8)};
`;

export const Clients = styled(clients)`
  width: 18px;
  g {
    fill: ${(props) => (props.active === 'true' ? colors.blue2 : colors.gray8)};
  }
`;

export const Logout = styled(logout)`
  width: 16px;
  height: 16px;
  fill: rgba(0, 0, 0, 0.54);
`;

export const EmenduContainer = styled.div`
  margin-bottom: 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const EmenduText = styled.div`
  color: ${colors.gray3};
  font-size: 0.75rem;
  font-weight: 500;
  width: 70%;
  display: flex;
  justify-content: flex-end;
`;

export const EmenduLogo = styled(logo)`
  width: 75%;
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 45px;
`;

export const BackArrow = styled(back)`
  width: 40px;
  height: 40px;
  cursor: pointer;
  path:nth-child(1) {
    fill: ${colors.blue2} !important;
  }
  path:nth-child(2) {
    fill: ${colors.blue2} !important;
  }
  path:nth-child(3) {
    fill: ${colors.white} !important;
  }
`;
