import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Creatable from 'react-select/creatable';
import Select from 'react-select';
import { FormattedMessage } from 'react-intl';
import Label from 'components/commons/Texts/Label';
import { SixthRowDiv, InputContainer, customStyles } from './styled';
import BasicInput from 'components/commons/Inputs/BasicInput';
import { Input } from '..';
import { removeFirstWhiteSpace } from 'utils/functions/general';
import { LANG_OPTIONS } from './constants';

const SixthRow = (props) => {
  const selectRef = useRef();

  return (
    <SixthRowDiv>
      <InputContainer>
        <Label value={<FormattedMessage id={'inventory.input24'} />} />
        <Select
          options={LANG_OPTIONS}
          placeholder={''}
          styles={customStyles}
          components={{
            Input,
          }}
          menuPortalTarget={document.body}
          menuPosition={'fixed'}
          isDisabled={
            !props.searched ||
            !['Desktop', 'Laptop', 'Peripheral'].includes(props.deviceType)
          }
          onChange={(opt) => props.handleChange('keyLang', opt.value)}
          value={
            !['Desktop', 'Laptop', 'Peripheral'].includes(props.deviceType)
              ? {
                  label: '',
                  name: '',
                }
              : {
                  label: props.keyLang,
                  name: props.keyLang,
                }
          }
          ref={selectRef}
        />
      </InputContainer>
      <InputContainer>
        <Label value={<FormattedMessage id={'inventory.input25'} />} />
        <Creatable
          options={props.handleDuplicated(props.deviceInfo, 'benchmark_name')}
          placeholder={''}
          styles={customStyles}
          components={{
            DropdownIndicator: () => null,
            Input,
          }}
          menuPortalTarget={document.body}
          menuPosition={'fixed'}
          onInputChange={(inputValue, { action }) =>
            props.onInputChange(
              'benchmark',
              40,
              'string',
              removeFirstWhiteSpace(inputValue),
              {
                action,
              },
            )
          }
          isDisabled={
            !props.searched ||
            ['Monitor', 'Peripheral'].includes(props.deviceType)
          }
          onChange={(opt) => props.handleChange('benchmark', opt.value)}
          value={
            ['Monitor', 'Peripheral'].includes(props.deviceType)
              ? {
                  label: '',
                  name: '',
                }
              : {
                  label: props.benchmark,
                  name: props.benchmark,
                }
          }
          ref={selectRef}
          inputValue={props.benchmark}
        />
      </InputContainer>
      <InputContainer>
        <Label value={<FormattedMessage id={'inventory.input26'} />} />
        <BasicInput
          disabled={
            !props.searched ||
            ['Monitor', 'Peripheral'].includes(props.deviceType)
          }
          onChange={(event) =>
            props.handleChange('result', event.target.value.replace(/\D/g, ''))
          }
          value={props.result}
        />
      </InputContainer>
      <InputContainer>
        <Label value={<FormattedMessage id={'inventory.input27'} />} />
        <BasicInput
          disabled={!props.searched}
          onChange={(event) =>
            props.handleChange(
              'description',
              removeFirstWhiteSpace(event.target.value),
            )
          }
          value={props.description}
          maxLength={300}
        />
      </InputContainer>
    </SixthRowDiv>
  );
};

SixthRow.propTypes = {
  handleChange: PropTypes.func,
  deviceType: PropTypes.string,
  searched: PropTypes.bool,
  keyLang: PropTypes.string,
  benchmark: PropTypes.string,
  result: PropTypes.any,
  description: PropTypes.string,
  deviceInfo: PropTypes.any,
  handleDuplicated: PropTypes.func,
  onInputChange: PropTypes.func,
};

export default SixthRow;
