export const Style = {
  borderRadius: '8px',
  borderColor: 'hsl(0,0%,80%)',
  padding: '2px 8px',
  overflow: 'auto',
  borderWidth: '1px',
  cursor: 'default',
  maxHeight: '42px',
  background: 'none',
  width: 'auto',
  fontSize: '14px',
  fontWeigth: '200',
};
