import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Wrapper, Container, Error, Body } from './styled';
import FirstRow from './FirstRow';
import SecondRow from './SecondRow';
import ThirdRow from './ThirdRow';
import FourthRow from './FourthRow';

const Inputs = (props) => {
  return (
    <>
      <Wrapper>
        <Body>
          <Container>
            <FirstRow
              handleChange={props.handleChange}
              guarantee={props.guarantee}
              price={props.price}
              discount={props.discount}
            />
            <SecondRow
              handleChange={props.handleChange}
              shipDate={props.shipDate}
              tracking={props.tracking}
            />
            <ThirdRow
              handleChange={props.handleChange}
              startRentingDate={props.startRentingDate}
              period={props.period}
              endRentingDate={props.endRentingDate}
            />
            <FourthRow
              handleChange={props.handleChange}
              description={props.description}
            />
          </Container>
          <Error id={props.error}>
            <FormattedMessage id={props.error} />
          </Error>
        </Body>
      </Wrapper>
    </>
  );
};

Inputs.propTypes = {
  error: PropTypes.string,
  handleChange: PropTypes.func,
  guarantee: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  discount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  shipDate: PropTypes.string,
  startRentingDate: PropTypes.string,
  period: PropTypes.string,
  endRentingDate: PropTypes.string,
  tracking: PropTypes.string,
  description: PropTypes.string,
};

export default Inputs;
