import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Wrapper, Container, Error, Body } from './styled';
import FirstRow from './FirstRow';

const Inputs = (props) => {
  return (
    <>
      <Wrapper>
        <Body>
          <Container>
            <FirstRow
              handleChange={props.handleChange}
              name={props.name}
              phone={props.phone}
              mail={props.mail}
              discount={props.discount}
              setMail={props.setMail}
              setPhone={props.setPhone}
              isEditing={props.isEditing}
            />
          </Container>
          <Error id={props.error}>
            <FormattedMessage id={props.error} />
          </Error>
        </Body>
      </Wrapper>
    </>
  );
};

Inputs.propTypes = {
  error: PropTypes.string,
  handleChange: PropTypes.func,
  name: PropTypes.string,
  phone: PropTypes.any,
  mail: PropTypes.any,
  discount: PropTypes.any,
  setMail: PropTypes.func,
  setPhone: PropTypes.func,
  isEditing: PropTypes.bool,
};

export default Inputs;
