import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Creatable from 'react-select/creatable';
import Select from 'react-select';
import { FormattedMessage } from 'react-intl';
import { DISK_SPACE_TYPE } from './constants';
import Label from 'components/commons/Texts/Label';
import BasicInput from 'components/commons/Inputs/BasicInput';
import {
  FourthRowDiv,
  InputContainer,
  customStyles,
  MiddleInputContainer,
} from './styled';
import { Input } from '..';

const FourthRow = (props) => {
  const selectRef = useRef();
  return (
    <FourthRowDiv>
      <InputContainer>
        <Label value={<FormattedMessage id={'inventory.input11'} />} />
        <BasicInput
          disabled={
            !props.searched || !['Phone', 'Tablet'].includes(props.deviceType)
          }
          maxLength={20}
          onChange={(event) =>
            props.handleChange('imeiNumber', event.target.value)
          }
          value={props.imeiNumber}
        />
      </InputContainer>
      <MiddleInputContainer>
        <Label value={<FormattedMessage id={'inventory.input12'} />} />
        <Creatable
          options={props.handleDuplicated(props.deviceInfo, 'screen_size')}
          placeholder={''}
          styles={customStyles}
          components={{
            DropdownIndicator: () => null,
            Input,
          }}
          menuPortalTarget={document.body}
          menuPosition={'fixed'}
          onInputChange={(inputValue, { action }) =>
            props.onInputChange('screenSize', null, 'decimal', inputValue, {
              action,
            })
          }
          isDisabled={
            !props.searched || ['Peripheral'].includes(props.deviceType)
          }
          onChange={(opt) => props.handleChange('screenSize', opt.value)}
          value={
            ['Peripheral'].includes(props.deviceType)
              ? {
                  label: '',
                  name: '',
                }
              : {
                  label: props.screenSize,
                  name: props.screenSize,
                }
          }
          ref={selectRef}
          inputValue={props.screenSize}
          defaultValue={[
            {
              value: props.screenSize,
              label: props.screenSize,
            },
          ]}
        />
      </MiddleInputContainer>
      <MiddleInputContainer>
        <Label value={<FormattedMessage id={'inventory.input13'} />} />
        <Creatable
          options={props.handleDuplicated(props.deviceInfo, 'ram')}
          placeholder={''}
          styles={customStyles}
          components={{
            DropdownIndicator: () => null,
            Input,
          }}
          menuPortalTarget={document.body}
          menuPosition={'fixed'}
          onInputChange={(inputValue, { action }) =>
            props.onInputChange('ram', null, 'number', inputValue, {
              action,
            })
          }
          is
          isDisabled={
            !props.searched ||
            ['Monitor', 'Peripheral'].includes(props.deviceType)
          }
          onChange={(opt) => props.handleChange('ram', opt.value)}
          value={
            ['Monitor', 'Peripheral'].includes(props.deviceType)
              ? {
                  label: '',
                  name: '',
                }
              : {
                  label: props.ram,
                  name: props.ram,
                }
          }
          ref={selectRef}
          inputValue={props.ram}
        />
      </MiddleInputContainer>
      <MiddleInputContainer>
        <Label value={<FormattedMessage id={'inventory.input14'} />} />
        <Select
          options={DISK_SPACE_TYPE}
          placeholder={''}
          styles={customStyles}
          components={{
            Input,
          }}
          menuPortalTarget={document.body}
          menuPosition={'fixed'}
          isDisabled={
            !props.searched || !['Desktop', 'Laptop'].includes(props.deviceType)
          }
          onChange={(opt) => props.handleChange('diskType', opt.value)}
          ref={selectRef}
          value={[
            {
              value: props.diskType,
              label: props.diskType,
            },
          ]}
        />
      </MiddleInputContainer>
      <MiddleInputContainer>
        <Label value={<FormattedMessage id={'inventory.input15'} />} />
        <Creatable
          options={props.handleDuplicated(props.deviceInfo, 'disk_space')}
          placeholder={''}
          styles={customStyles}
          components={{
            DropdownIndicator: () => null,
            Input,
          }}
          menuPortalTarget={document.body}
          menuPosition={'fixed'}
          onInputChange={(inputValue, { action }) =>
            props.onInputChange('diskSpace', null, 'number', inputValue, {
              action,
            })
          }
          isDisabled={
            !props.searched ||
            ['Monitor', 'Peripheral'].includes(props.deviceType)
          }
          onChange={(opt) => props.handleChange('diskSpace', opt.value)}
          value={
            ['Monitor', 'Peripheral'].includes(props.deviceType)
              ? {
                  label: '',
                  name: '',
                }
              : {
                  label: props.diskSpace,
                  name: props.diskSpace,
                }
          }
          ref={selectRef}
          inputValue={props.diskSpace}
        />
      </MiddleInputContainer>
      <MiddleInputContainer>
        <Label value={<FormattedMessage id={'inventory.input16'} />} />
        <Select
          options={DISK_SPACE_TYPE}
          placeholder={''}
          styles={customStyles}
          components={{
            Input,
          }}
          menuPortalTarget={document.body}
          menuPosition={'fixed'}
          isDisabled={
            !props.searched || !['Desktop', 'Laptop'].includes(props.deviceType)
          }
          onChange={(opt) => props.handleChange('secondDiskType', opt.value)}
          value={
            !['Desktop', 'Laptop'].includes(props.deviceType)
              ? {
                  label: '',
                  name: '',
                }
              : {
                  label: props.secondDiskType,
                  name: props.secondDiskType,
                }
          }
          ref={selectRef}
        />
      </MiddleInputContainer>
      <MiddleInputContainer>
        <Label value={<FormattedMessage id={'inventory.input17'} />} />
        <Creatable
          options={props.handleDuplicated(
            props.deviceInfo,
            'secondary_disk_space',
          )}
          placeholder={''}
          styles={customStyles}
          components={{
            DropdownIndicator: () => null,
            Input,
          }}
          menuPortalTarget={document.body}
          menuPosition={'fixed'}
          onInputChange={(inputValue, { action }) =>
            props.onInputChange('secondDiskSpace', null, 'number', inputValue, {
              action,
            })
          }
          isDisabled={
            !props.searched || !['Desktop', 'Laptop'].includes(props.deviceType)
          }
          onChange={(opt) => props.handleChange('secondDiskSpace', opt.value)}
          value={
            !['Desktop', 'Laptop'].includes(props.deviceType)
              ? {
                  label: '',
                  name: '',
                }
              : {
                  label: props.secondDiskSpace,
                  name: props.secondDiskSpace,
                }
          }
          ref={selectRef}
          inputValue={props.secondDiskSpace}
        />
      </MiddleInputContainer>
    </FourthRowDiv>
  );
};

FourthRow.propTypes = {
  handleChange: PropTypes.func,
  deviceType: PropTypes.string,
  searched: PropTypes.bool,
  deviceInfo: PropTypes.any,
  handleDuplicated: PropTypes.func,
  imeiNumber: PropTypes.any,
  screenSize: PropTypes.any,
  ram: PropTypes.any,
  diskType: PropTypes.string,
  diskSpace: PropTypes.any,
  secondDiskType: PropTypes.string,
  secondDiskSpace: PropTypes.any,
  onInputChange: PropTypes.func,
};

export default FourthRow;
