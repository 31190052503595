import React, { useState, useEffect } from 'react';
import Info from 'components/commons/Info';
import * as D from 'components/pages/App/constants';
import PropTypes from 'prop-types';
import { PERMISSIONS } from 'utils/constants';
import Body from './Body';
import { useDispatch } from 'react-redux';
import { getOrders } from 'store/Orders/actions';

const OrdersInfo = (props) => {
  const dispatch = useDispatch();

  const orderId = props.match.params.id;
  const [order, setOrder] = useState([{}]);

  useEffect(() => {
    dispatch(getOrders(setOrder, orderId));
  }, []);

  return (
    <Info
      redirectArray={[
        D.orders_info_es + orderId,
        D.orders_info_en + orderId,
        D.orders_info_pt + orderId,
      ]}
      permission={PERMISSIONS.orders}
      moduleName="Ordenes"
      subtitle="lateral_menu.orders"
      title={order[0].supplier_order}
      setModuleOnClick={D.orders_es}
    >
      <Body order={order[0]} />
    </Info>
  );
};

OrdersInfo.propTypes = {
  match: PropTypes.any,
};

export default OrdersInfo;
