import React from 'react';
import { FormattedMessage } from 'react-intl';
import DotsTag from 'components/commons/Tags/DotsTag';
import history from 'utils/history';
import { device_es } from 'components/pages/App/constants';
import { stringToDate } from 'utils/functions/DateUtils';
import * as styled from './styled';

export const COLUMNS = [
  {
    name: 'asset_type',
    label: <FormattedMessage id={'table.header1'} />,
    options: {
      customBodyRender: (row) => {
        switch (row) {
          case 'Peripheral':
            return (
              <styled.IconContainer>
                <styled.Peripheral />
              </styled.IconContainer>
            );
          case 'Desktop':
            return (
              <styled.IconContainer>
                <styled.Desktop />
              </styled.IconContainer>
            );
          case 'Laptop':
            return (
              <styled.IconContainer>
                <styled.Laptop />
              </styled.IconContainer>
            );
          case 'Phone':
            return (
              <styled.IconContainer>
                <styled.Phone />
              </styled.IconContainer>
            );
          case 'Tablet':
            return (
              <styled.IconContainer>
                <styled.Tablet />
              </styled.IconContainer>
            );
          case 'Monitor':
            return (
              <styled.IconContainer>
                <styled.Monitor />
              </styled.IconContainer>
            );
        }
      },
      setCellHeaderProps: () => {
        return { style: { width: '64px', textAlign: 'center' } };
      },
      setCellProps: () => {},
    },
  },
  {
    name: 'display_name',
    label: <FormattedMessage id={'table.header2'} />,
    options: {
      setCellHeaderProps: () => {},
      setCellProps: () => {},
    },
  },
  {
    name: 'serial_number',
    label: <FormattedMessage id={'table.header8'} />,
    options: {
      setCellHeaderProps: () => {},
      setCellProps: () => {},
    },
  },
  {
    name: 'owner',
    label: <FormattedMessage id={'table.header6'} />,
    options: {
      setCellHeaderProps: () => {
        return { style: { textAlign: 'left' } };
      },
      setCellProps: () => {},
    },
  },
  {
    name: 'device_start_renting_date',
    label: <FormattedMessage id={'table.header3'} />,
    options: {
      sortCompare: (order) => {
        return (a, b) => {
          const firstDate = stringToDate(a.data);
          const secondDate = stringToDate(b.data);
          return (firstDate - secondDate) * (order === 'asc' ? 1 : -1);
        };
      },
      setCellHeaderProps: () => {
        return { style: { width: '80px' } };
      },
      setCellProps: () => {},
    },
  },
  {
    name: 'device_period',
    label: <FormattedMessage id={'table.header4'} />,
    options: {
      sortCompare: (order) => {
        return (a, b) => {
          const firstDate = stringToDate(a.data);
          const secondDate = stringToDate(b.data);
          return (firstDate - secondDate) * (order === 'asc' ? 1 : -1);
        };
      },
      setCellHeaderProps: () => {
        return { style: { width: '80px' } };
      },
      setCellProps: () => {},
    },
  },
  {
    name: 'device_price',
    label: <FormattedMessage id={'table.header5'} />,
    options: {
      sortCompare: (order) => {
        return (a, b) => {
          const first = a.data ? parseFloat(a.data) : 0;
          const second = b.data ? parseFloat(b.data) : 0;
          return (first - second) * (order === 'asc' ? 1 : -1);
        };
      },
      customBodyRender: (val) => val && <>{val} €</>,
      setCellHeaderProps: () => {
        return { style: { width: '85px', textAlign: 'right' } };
      },
      setCellProps: () => {
        return { style: { textAlign: 'right' } };
      },
    },
  },
  {
    name: 'device_status',
    label: <FormattedMessage id={'table.header7'} />,
    options: {
      customBodyRender: (row) => {
        return <DotsTag status={row} />;
      },
      setCellHeaderProps: () => {
        return { style: { width: '70px', textAlign: 'center' } };
      },
      setCellProps: () => {},
    },
  },
  {
    name: 'annex_x_product_id',
    label: ' ',
    options: {
      customBodyRender: (row) => {
        return (
          <styled.IconContainer
            onClick={() => history.push(device_es + row)}
            onAuxClick={() => window.open(device_es + row, '_blank')}
          >
            <styled.Info />
          </styled.IconContainer>
        );
      },
      sort: false,
      setCellHeaderProps: () => {
        return { style: { width: '20px', textAlign: 'center' } };
      },
      setCellProps: () => {},
    },
  },
  {
    name: 'serial_number',
    label: ' ',
    options: {
      setCellHeaderProps: () => {
        return { style: { width: '0' } };
      },
      setCellProps: () => {},
      customBodyRender: () => <styled.Hidden />,
      sort: false,
      draggable: false,
    },
  },
];
