import React from 'react';
import { FormattedMessage } from 'react-intl';
import ShipmentsTag from 'components/commons/Tags/ShipmentsTag';
import { stringToDate } from 'utils/functions/DateUtils';
import TableIconsRow from 'components/commons/Icons/TableIconsRow';
import history from 'utils/history';
import * as D from 'components/pages/App/constants';

import { PERMISSIONS } from 'utils/constants';

export const columns = (handleEdit, user, handleAdd, handleState) => {
  return [
    {
      title: <FormattedMessage id={'shipments.header1'} />,
      field: 'shipment_id',
      // align: 'center',
    },
    {
      title: <FormattedMessage id={'shipments.header2'} />,
      field: 'company_name',
      // align: 'center',
    },
    {
      title: <FormattedMessage id={'shipments.header7'} />,
      field: 'receiver_name',
      // align: 'center',
    },
    {
      title: <FormattedMessage id={'shipments.header4'} />,
      field: 'estimated_date',
      // align: 'center',
      customSort: (a, b) => {
        const firstDate = stringToDate(a.start_date);
        const secondDate = stringToDate(b.start_date);
        return firstDate - secondDate;
      },
    },
    {
      title: <FormattedMessage id={'shipments.header5'} />,
      field: 'actual_date',
      // align: 'center',
      customSort: (a, b) => {
        const firstDate = stringToDate(a.ending_date);
        const secondDate = stringToDate(b.ending_date);
        return firstDate - secondDate;
      },
    },
    {
      title: <FormattedMessage id={'shipments.header3'} />,
      field: 'amount_devices',
      align: 'center',
      render: (row) =>
        row.amount_devices && (
          <>
            {row.amount_ready_devices}/{row.amount_devices}
          </>
        ),
    },
    {
      title: <FormattedMessage id={'shipments.header6'} />,
      field: 'status',
      align: 'center',
      render: (row) =>
        row.status && (
          <ShipmentsTag
            onClick={
              user.permissions[PERMISSIONS.shipments_abm] &&
              row.status === '003'
                ? () => handleState(row)
                : null
            }
            status={row.status}
          />
        ),
    },
    {
      // align: 'center',
      render: (row) =>
        row.status && (
          <TableIconsRow
            onCheck={
              !['001', '002'].includes(row.status) ||
              !user.permissions[PERMISSIONS.shipments_abm]
                ? undefined
                : () => handleAdd(row)
            }
            onEdit={
              !['001', '002', '003'].includes(row.status) ||
              !user.permissions[PERMISSIONS.shipments_abm]
                ? undefined
                : () => handleEdit(row)
            }
            onInfo={() => history.push(D.shipments_info_es + row.shipment_id)}
            onAuxClick={() =>
              window.open(D.shipments_info_es + row.shipment_id, '_blank')
            }
          />
        ),
    },
  ];
};
