import styled from 'styled-components';
import colors from 'styles/colors';
import { ReactComponent as laptop } from 'images/Laptop.svg';
import { ReactComponent as desktop } from 'images/Desktop.svg';
import { ReactComponent as phone } from 'images/Phone.svg';
import { ReactComponent as tablet } from 'images/Tablet.svg';
import { ReactComponent as monitor } from 'images/Monitor.svg';
import { ReactComponent as peripheral } from 'images/Peripheral.svg';

export const Wrapper = styled.div`
  background-color: ${colors.white};
  border-radius: 8px;
  display: flex;
  width: 24%;
  height: 100%;
  overflow: hidden;

  @media (max-width: 1024px) {
    display: block;
    margin-bottom: 25px;
    width: 100%;
    height: auto;
    overflow: initial;
  }
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 12px 16px;

  @media (max-width: 1024px) {
    display: block;
    box-sizing: border-box;
    margin: 0;
    padding: 12px 16px;
  }
`;

export const Title = styled.div`
  display: flex;
  color: ${colors.blue2};
  font-weight: 600;
  font-size: 1rem;
  margin: 0 4px 8px 4px;
  white-space: nowrap;
`;

export const Row = styled.div`
  display: flex;
  border-top: 1px solid ${colors.gray5};
  width: 100%;
  height: 15%;

  @media (max-width: 1024px) {
    height: 45px;
  }
`;

export const RowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  margin: 0 12px;
`;

export const RowLeftSide = styled.div`
  display: flex;
  font-weight: 500;
  color: ${colors.gray3};
  font-size: 0.875rem;
`;

export const RowRightSide = styled.div`
  display: flex;
  color: ${colors.blue2};
  font-weight: 500;
  font-weight: 0.875rem;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
`;

export const Laptop = styled(laptop)`
  display: flex;
  width: 25px;
  height: 20px;
  fill: ${colors.gray3};
`;

export const Desktop = styled(desktop)`
  display: flex;
  width: 25px;
  height: 20px;
  fill: ${colors.gray3};
`;

export const Phone = styled(phone)`
  display: flex;
  width: 25px;
  height: 20px;
  fill: ${colors.gray3};
`;

export const Tablet = styled(tablet)`
  display: flex;
  width: 25px;
  height: 20px;
  fill: ${colors.gray3};
`;

export const Monitor = styled(monitor)`
  display: flex;
  width: 25px;
  height: 20px;
  fill: ${colors.gray3};
`;

export const Peripheral = styled(peripheral)`
  display: flex;
  width: 25px;
  height: 20px;
  fill: ${colors.gray3};
`;
