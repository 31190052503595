import React, { useState, useEffect } from 'react';
import Landing from 'components/commons/Landing';
import * as C from 'components/pages/App/constants';
import { PERMISSIONS } from 'utils/constants';
import { useSelector, useDispatch } from 'react-redux';
import { getClientDevices, setTableOpen } from 'store/Dashboard/actions';
import { Wrapper } from './styled';
import Body from './Body';
import { selectTableOpen } from 'store/Dashboard/selectors';

const Dashboard = () => {
  const dispatch = useDispatch();
  const tableOpen = useSelector(selectTableOpen);
  const [clientDevices, setClientDevices] = useState([]);

  useEffect(() => {
    dispatch(getClientDevices(setClientDevices));
  }, []);

  const handleAmount = () => {
    let amount = {
      Desktop: 0,
      Laptop: 0,
      Tablet: 0,
      Phone: 0,
      Monitor: 0,
      Peripheral: 0,
      999: 0,
      998: 0,
      997: 0,
    };
    clientDevices.forEach((row) => {
      if (row.device_status === '999') {
        amount[row.asset_type] = amount[row.asset_type] + 1;
      }
      amount[row.device_status] = amount[row.device_status] + 1;
    });
    return amount;
  };

  return (
    <>
      <Landing
        empty
        redirectArray={[C.dashboard, C.dashboard, C.dashboard]}
        permission={PERMISSIONS.dashboard}
        moduleName="Dashboard"
      >
        <Wrapper>
          <Body
            clientDevices={clientDevices}
            amount={handleAmount()}
            isOpen={tableOpen}
            handleChange={() => dispatch(setTableOpen())}
          />
        </Wrapper>
      </Landing>
    </>
  );
};

export default Dashboard;
