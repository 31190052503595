import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Label from 'components/commons/Texts/Label';
import BasicInput from 'components/commons/Inputs/BasicInput';
import { ThirdRowDiv, InputContainer } from './styled';
import { removeFirstWhiteSpace } from 'utils/functions/general';

const ThirdRow = (props) => {
  return (
    <ThirdRowDiv>
      <InputContainer>
        <Label value={<FormattedMessage id={'clients.input10'} />} />
        <BasicInput
          onChange={(e) =>
            props.handleChange(
              'description',
              removeFirstWhiteSpace(e.target.value),
            )
          }
          maxLength={500}
          value={props.description}
        />
      </InputContainer>
    </ThirdRowDiv>
  );
};

ThirdRow.propTypes = {
  handleChange: PropTypes.func,
  description: PropTypes.string,
};

export default ThirdRow;
