import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import FirstRow from './FirstRow';
import SecondRow from './SecondRow';
import { useDispatch } from 'react-redux';
import { getEmenduEmployees } from 'store/Login/actions';

const SecondStage = (props) => {
  if (props.show !== 3 && props.show !== 4) return null;
  const [emenduEmployees, setEmenduEmployees] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    (props.show === 3 || props.show === 4) &&
      dispatch(getEmenduEmployees(setEmenduEmployees));
  }, [props.show]);

  return (
    <>
      <FirstRow handleChange={props.handleChange} />
      <SecondRow
        handleChange={props.handleChange}
        annexSignatureDate={props.annexSignatureDate}
        amountDevices={props.amountDevices}
        managedBy={props.managedBy}
        employeeId={props.employeeId}
        show={props.show}
        emenduEmployees={emenduEmployees}
      />
    </>
  );
};

SecondStage.propTypes = {
  handleChange: PropTypes.func,
  show: PropTypes.number,
  annexSignatureDate: PropTypes.string,
  amountDevices: PropTypes.any,
  managedBy: PropTypes.string,
  employeeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default SecondStage;
