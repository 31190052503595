import styled from 'styled-components';
import colors from 'styles/colors';

export const Wrapper = styled.div`
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  padding: 40px 40px 20px 40px;
  box-sizing: border-box;

  &::-webkit-scrollbar {
    height: 1px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${colors.gray4};
    border-right: none;
    border-left: none;
    border-radius: 8px;
    border: 3px solid #f4f8fb;
  }
  &::-webkit-scrollbar-track-piece {
    background: transparent;
    margin-top: 150px;
  }

  @media (max-width: 1024px) {
    display: block;
  }
`;

export const Title = styled.div`
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: ${(props) => (props.historic ? '5px' : '20px')};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: ${(props) =>
    props.historic ? 'calc(100% - 95px)' : 'calc(100% - 80px)'};
`;

export const FirstRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 48px;
  margin-bottom: 32px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  height: 48px;
  width: 100%;
  max-width: ${(props) => (props.blur ? '380px' : '190px')};
`;

export const BlurContainer = styled.div`
  display: flex;
  width: 100%;
  margin-right: 15px;
`;

export const TableContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-grow: 1;
  flex: 1;
  box-sizing: border-box;
`;

export const Filters = styled.div`
  display: flex;
  width: 40%;
  height: 100%;
`;

export const Input = styled.input`
  display: flex;
  height: 100%;
  width: 100%;
  border-radius: 0 8px 8px 0;
  border: none;
  font-family: Poppins;
  font-size: 1rem;
  font-weight: 500;
  padding: 0 20px 0 10px;
  margin-right: 16px;
  &:focus {
    outline: none;
  }
`;

export const IconContainer = styled.div`
  width: 70px;
  background-color: ${colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px 0 0 8px;
  cursor: ${(props) => (props.right ? null : 'pointer')};
  display: ${(props) => (props.right ? 'none' : 'relative')};
`;

export const IconContainerFilter = styled(IconContainer)`
  border-radius: 8px;
`;

export const Icon = styled.img`
  width: 16px;
  height: 16px;
`;

export const SubtitleContainer = styled.div`
  display: flex;
  letter-spacing: -0.003em;
  font-weight: 500;
  font-size: 1rem;
  margin-bottom: 16px;
`;

export const PreviousSubtitle = styled.div`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  margin-left: 5px;
`;

export const ActualSubtitle = styled.div`
  color: ${colors.gray2};
  margin-left: 5px;
  cursor: default;
`;

export const FilterContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 11;
`;

export const FilterCentered = styled.div`
  display: flex;
  position: absolute;
  top: max(390px);
  left: 45%;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  width: 15%;
  height: 250px;
  background-color: ${colors.white};
  padding: 16px 32px;
  overflow: auto;
  z-index: 11;
  border: 2px solid ${colors.blue3};
`;
