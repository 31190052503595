import styled from 'styled-components';
import colors from 'styles/colors';
import { ReactComponent as feedback } from 'images/feedback.svg';
import { ReactComponent as incident } from 'images/incident.svg';

export const Wrapper = styled.div`
  display: flex;
  width: 24%;
  height: 100%;
  overflow: hidden;

  @media (max-width: 1024px) {
    display: block;
    margin-bottom: 25px;
    width: 100%;
    height: auto;
    overflow: initial;

    :last-child {
      margin-bottom: 0;
    }
  }
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;
  box-sizing: border-box;

  @media (max-width: 1024px) {
    display: block;
    box-sizing: border-box;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 30%;

  @media (max-width: 1024px) {
    display: block;
    height: auto;
    height: 80px;
    margin-bottom: 10px;

    :last-child {
      margin-bottom: 0;
    }
  }
`;

export const IconDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => (props.dark ? colors.green1 : null)};
  color: ${(props) => (props.dark ? colors.white : colors.green1)};
  width: 45px;
  height: 45px;
  border-radius: 8px;
  font-size: 3.25rem;
`;

export const Incident = styled(incident)`
  display: flex;
  height: 35px;
  width: 35px;
  fill: ${colors.green1};
`;

export const Feedback = styled(feedback)`
  display: flex;
  height: 35px;
  width: 35px;
  path {
    fill: ${colors.green1};
  }
`;
