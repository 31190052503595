import styled from 'styled-components';
import colors from 'styles/colors';
import { ReactComponent as chevron } from 'images/chevron.svg';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background-color: ${(props) => (props.active ? colors.green1 : null)};
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 1rem;
  cursor: ${(props) => (props.active ? 'default' : 'pointer')};
  color: ${(props) => (props.active ? colors.white : colors.blue2)};
  &:hover {
    background-color: ${(props) =>
      props.active ? null : 'rgba(102, 102, 102, 0.1)'};
    transition: all 0.4s;
  }
`;

export const Button = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 24px;
  white-space: nowrap;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
`;

export const Chevron = styled(chevron)`
  height: 25px;
  width: 25px;
  fill: ${colors.white};
`;
