import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import InfoRow from 'components/commons/Texts/InfoRow';

const Info = (props) => {
  if (props.module != 0) return null;
  return (
    <>
      <InfoRow
        title={<FormattedMessage id={'info.clients.key1'} />}
        value={props.client.nif}
      />
      <InfoRow
        title={<FormattedMessage id={'info.clients.key2'} />}
        value={props.client.name}
      />
      <InfoRow
        title={<FormattedMessage id={'info.clients.key3'} />}
        value={props.client.street}
      />
      <InfoRow
        title={<FormattedMessage id={'info.clients.key4'} />}
        value={props.client.floor}
      />
      <InfoRow
        title={<FormattedMessage id={'info.clients.key5'} />}
        value={props.client.door}
      />
      <InfoRow
        title={<FormattedMessage id={'info.clients.key6'} />}
        value={props.client.city}
      />
      <InfoRow
        title={<FormattedMessage id={'info.clients.key7'} />}
        value={props.client.country}
      />
      <InfoRow
        title={<FormattedMessage id={'info.clients.key8'} />}
        value={props.client.zip_code}
      />
      <InfoRow
        title={<FormattedMessage id={'info.clients.key9'} />}
        value={props.client.phone_number}
      />
      <InfoRow
        title={<FormattedMessage id={'info.clients.key10'} />}
        value={props.client.description}
      />
    </>
  );
};

Info.propTypes = {
  module: PropTypes.number,
  client: PropTypes.any,
};

export default Info;
