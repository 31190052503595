import styled from 'styled-components';
import colors from 'styles/colors';
import { ReactComponent as laptop } from 'images/Laptop.svg';
import { ReactComponent as desktop } from 'images/Desktop.svg';
import { ReactComponent as phone } from 'images/Phone.svg';
import { ReactComponent as tablet } from 'images/Tablet.svg';
import { ReactComponent as monitor } from 'images/Monitor.svg';
import { ReactComponent as peripheral } from 'images/Peripheral.svg';
import { ReactComponent as InfoIcon } from 'images/info.svg';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background-color: ${colors.white};
  border-radius: 8px;
  overflow: hidden;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 12px 16px;
  box-sizing: border-box;
`;

export const Title = styled.div`
  display: flex;
  color: ${colors.blue2};
  font-weight: 600;
  font-size: 1rem;
  padding: 0 4px 8px 4px;
  margin-bottom: 18px;
  white-space: nowrap;
  // justify-content: space-between;
  border-bottom: 1px solid ${colors.gray5};
  align-items: baseline;

  div:last-child {
    margin-left: auto;

    div {
      padding: 3px 25px;
      border-color: ${colors.green9};
      color: ${colors.green9};
      box-sizing: border-box;
      font-size: 12px;
      font-weight: 500;
    }
  }
`;

export const DevicesInfo = styled.div`
  font-size: 0.75rem;
  font-weight: 400;
  margin-left: 3px;
  color: ${colors.gray3};

  span {
    font-weight: 500;
  }
`;

export const Body = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

export const BodyWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 16px;
  box-sizing: border-box;

  [class$='MuiButtonBase-root-MuiIconButton-root']:hover {
    background: transparent;
  }

  [class$='MuiSvgIcon-root'].MuiSvgIcon-fontSizeMedium {
    color: ${colors.blue5};
  }

  [class$='MuiButtonBase-root-MuiIconButton-root']:hover {
    color: ${colors.gray3};
  }

  [class$='MuiInputBase-root-MuiInput-root']:before {
    border-bottom: 1px solid ${colors.gray3};
  }

  [class$='MuiInputBase-root-MuiInput-root']:after,
  [class$='MuiInputBase-root-MuiInput-root'].Mui-focused:after,
  [class$='MuiInputBase-root-MuiInput-root']:hover:not(.Mui-disabled):before {
    border-bottom: 2px solid ${colors.blue5};
  }

  .MuiTableCell-root.MuiTableCell-head {
    padding: 0px !important;
  }

  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded
    [class$='MUIDataTableHeadCell-contentWrapper'],
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded
    [class$='MUIDataTableHeadCell-sortAction'] {
    text-transform: initial;
  }
`;

export const Label = styled.span`
  font-family: 'Poppins';
  font-size: 14px;
`;

export const Laptop = styled(laptop)`
  display: flex;
  width: 25px;
  height: 20px;
  fill: ${colors.gray3};
`;

export const Desktop = styled(desktop)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 25px;
  height: 20px;
  fill: ${colors.gray3};
`;

export const Phone = styled(phone)`
  display: flex;
  width: 25px;
  height: 20px;
  fill: ${colors.gray3};
`;

export const Tablet = styled(tablet)`
  display: flex;
  width: 25px;
  height: 20px;
  fill: ${colors.gray3};
`;

export const Monitor = styled(monitor)`
  display: flex;
  width: 25px;
  height: 20px;
  fill: ${colors.gray3};
`;

export const Peripheral = styled(peripheral)`
  display: flex;
  width: 25px;
  height: 20px;
  fill: ${colors.gray3};
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const Info = styled(InfoIcon)`
  display: flex;
  height: 20px;
  width: 25px;
  fill: ${colors.gray2};
  cursor: pointer;
`;

export const Hidden = styled.div`
  display: none;
`;
