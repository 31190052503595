import { checkStatus, parseJSON } from 'utils/request';
import config from 'config.js';
import { selectToken } from 'store/Login/selectors';
import { setLoading } from 'store/General/actions';

export const getFulfillments = (setFulfillments) => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    const token = selectToken(getState());
    const requestURL = `${config.baseURL}/v1/shipment?token=${token}`;
    try {
      const response = await fetch(requestURL, {
        method: 'GET',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
        },
      });
      const response_1 = await checkStatus(response);
      const response2 = await parseJSON(response_1);
      if (response2.status === '1') {
        setFulfillments(response2.result);
      }
      // eslint-disable-next-line no-empty
    } catch (error) {}
    dispatch(setLoading(false));
  };
};

export const getFulfillment = (setFulfillment, fulfillmentId) => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    const token = selectToken(getState());
    const requestURL = `${
      config.baseURL
    }/v1/shipment?token=${token}&shipment_id=${fulfillmentId || ''}`;
    try {
      const response = await fetch(requestURL, {
        method: 'GET',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
        },
      });
      const response_1 = await checkStatus(response);
      const response2 = await parseJSON(response_1);
      if (response2.status === '1') {
        setFulfillment(response2.result);
      }
      // eslint-disable-next-line no-empty
    } catch (error) {}
    dispatch(setLoading(false));
  };
};

export const getFulfillmentDevices = (setDevices, fulfillmentId) => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    const token = selectToken(getState());
    const requestURL = `${config.baseURL}/v1/shipment/devices/info?token=${token}&shipment_id=${fulfillmentId}`;
    try {
      const response = await fetch(requestURL, {
        method: 'GET',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
        },
      });
      const response_1 = await checkStatus(response);
      const response2 = await parseJSON(response_1);
      if (response2.status === '1') {
        setDevices(response2.result);
      }
      // eslint-disable-next-line no-empty
    } catch {}
    dispatch(setLoading(false));
  };
};

export const updateFulfillmentState = (
  closeModal,
  shipmentId,
  shipmentStatus,
  amountPackages,
  overallWeight,
  setFlag,
  flag,
) => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    const token = selectToken(getState());
    const requestURL = `${config.baseURL}/v1/shipment/state?token=${token}`;
    try {
      const response = await fetch(requestURL, {
        method: 'POST',
        body: JSON.stringify({
          shipment_id: shipmentId,
          status: shipmentStatus,
          amount_packages: amountPackages,
          overall_weight: overallWeight,
        }),
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'content-type': 'application/json',
        },
      });
      const response_1 = await checkStatus(response);
      const response2 = await parseJSON(response_1);
      if (response2.status === '1') {
        setFlag && setFlag(!flag);
        closeModal();
      }
      // eslint-disable-next-line no-empty
    } catch {}
    dispatch(setLoading(false));
  };
};

export const checkFulfillment = (shipmentId, serialNumber, brand) => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    const token = selectToken(getState());
    const requestURL = `${config.baseURL}/v1/shipment/check?token=${token}`;
    try {
      const response = await fetch(requestURL, {
        method: 'PUT',
        body: JSON.stringify({
          shipment_id: shipmentId,
          serial_number: serialNumber,
          brand: brand,
          it_check: true,
        }),
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'content-type': 'application/json',
        },
      });
      const response_1 = await checkStatus(response);
      const response2 = await parseJSON(response_1);
      if (response2.status === '1') {
        console.log(
          `checkFulfillment: ${shipmentId}, ${serialNumber}, ${brand}`,
        );
      } else {
        console.log(`error!!!`);
      }
      // eslint-disable-next-line no-empty
    } catch {}
    dispatch(setLoading(false));
  };
};
