export const Split = (str) => {
  return !str ? [] : str.split(',');
};

export const removeFirstWhiteSpace = (val) => val.replace(/^\s/g, '');

export const avoidZeroFirstAmountDevices = (val) => val.replace(/^0|\D/g, '');

export const removeSerialNumberDuplicated = (val) => {
  let newArray = val.split(',');
  let newArrayTrim = newArray.map((el) => el.trim());
  let removeDuplicated = [...new Set(newArrayTrim)];
  let removeDuplicatedToString = removeDuplicated.toString();
  return removeDuplicatedToString;
};
