import React, { useState } from 'react';
import PropTypes from 'prop-types';
import LoginModal from 'components/commons/Modal/LoginModal';
import {
  Subtitle,
  InputAndError,
  Error,
  InputContainer,
  SubText,
} from './styled';
import { FormattedMessage } from 'react-intl';
import InputWithLabel from 'components/commons/Inputs/InputWithLabel';
import { resetPass } from 'store/Login/actions';
import { useDispatch } from 'react-redux';
import * as C from '../../constants';

const ForgetPassword = (props) => {
  const [sent, setSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState('login.status1');
  const [user, setUser] = useState('');
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const handleChange = (event) => {
    setUser(event.target.value);
  };

  const validateFieldAndDispatch = () => {
    setErrorMessage('login.status1');

    if (!user || !C.EMAIL_PATTERN.test(user)) {
      setErrorMessage('login.status2');
      return;
    }

    setLoading(true);
    dispatch(resetPass(user, setSent, setErrorMessage, setLoading));
  };

  const handleEnter = (event) => {
    if (event.key === 'Enter' && !sent) validateFieldAndDispatch();
  };

  return (
    <LoginModal
      closeModal={() => props.setForgetPassword(false)}
      title={sent ? 'login.reset6' : 'login.forget'}
      buttonText={sent ? 'login.reset5' : 'login.forget3'}
      loading={loading}
      buttonFunc={
        sent ? () => props.setForgetPassword(false) : validateFieldAndDispatch
      }
      check={sent}
    >
      <Subtitle>
        <FormattedMessage id={sent ? 'login.reset7' : 'login.forget2'} />
      </Subtitle>

      <InputAndError sent={sent}>
        <InputContainer>
          <InputWithLabel
            title={<FormattedMessage id="login.input1" />}
            value={user}
            onChange={handleChange}
            error={errorMessage !== 'login.status1'}
            onKeyDown={handleEnter}
          />
        </InputContainer>
        <Error>
          <SubText error={errorMessage !== 'login.status1'}>
            {<FormattedMessage id={errorMessage} />}
          </SubText>
        </Error>
      </InputAndError>
    </LoginModal>
  );
};

ForgetPassword.propTypes = {
  setForgetPassword: PropTypes.func,
};

export default ForgetPassword;
