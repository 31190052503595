import React, { useState } from 'react';
import PropTypes from 'prop-types';
import LoginModal from 'components/commons/Modal/LoginModal';
import {
  Subtitle,
  InputAndError,
  Error,
  InputContainer,
  SubText,
  InputsContainer,
} from './styled';
import { FormattedMessage } from 'react-intl';
import InputWithLabel from 'components/commons/Inputs/InputWithLabel';
import { newPass, logout } from 'store/Login/actions';
import { useDispatch } from 'react-redux';
import history from 'utils/history';
import * as D from 'components/pages/App/constants';

const ResetPassword = (props) => {
  const [errorMessage, setErrorMessage] = useState('login.status1');
  const [password, setPassword] = useState('');
  const [sPassword, setSPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorType, setErrorType] = useState(0);

  const dispatch = useDispatch();

  const handleChange = (event) => {
    switch (event.target.name) {
      case 'password':
        setPassword(event.target.value);
        break;
      case 'spassword':
        setSPassword(event.target.value);
        break;
    }
  };

  const validateFieldAndDispatch = () => {
    setErrorMessage('login.status1');

    if (!password || password.length < 8) {
      setErrorMessage('login.status97');
      setErrorType(1);
      return;
    }

    if (password !== sPassword) {
      setErrorMessage('login.status98');
      setErrorType(2);
      return;
    }

    setLoading(true);
    dispatch(
      newPass(password, props.setPassUpdated, setErrorMessage, setLoading),
    );
  };

  const login = () => {
    props.setPassUpdated(false);
    history.push(D.defaultRedirect);
  };

  const handleEnter = (event) => {
    if (event.key === 'Enter' && !props.passUpdated) validateFieldAndDispatch();
  };

  return (
    <LoginModal
      closeModal={() => dispatch(logout())}
      title={props.passUpdated ? 'login.reset8' : 'login.reset1'}
      buttonText={'login.reset5'}
      loading={loading}
      buttonFunc={props.passUpdated ? login : validateFieldAndDispatch}
      check={props.passUpdated}
      size="medium"
    >
      <Subtitle updated={props.passUpdated}>
        <FormattedMessage
          id={props.passUpdated ? 'login.status1' : 'login.reset2'}
        />
      </Subtitle>
      <InputsContainer>
        <InputAndError updated={props.passUpdated}>
          <InputContainer>
            <InputWithLabel
              title={<FormattedMessage id="login.reset3" />}
              value={password}
              onChange={handleChange}
              error={errorMessage !== 'login.status1' && errorType === 1}
              name={'password'}
              type="password"
            />
          </InputContainer>
          <Error>
            <SubText
              error={errorMessage !== 'login.status1' && errorType === 1}
            >
              {
                <FormattedMessage
                  id={errorType === 1 ? errorMessage : 'login.status1'}
                />
              }
            </SubText>
          </Error>
        </InputAndError>
        <InputAndError updated={props.passUpdated}>
          <InputContainer>
            <InputWithLabel
              title={<FormattedMessage id="login.reset4" />}
              value={sPassword}
              onChange={handleChange}
              error={errorMessage !== 'login.status1' && errorType === 2}
              name={'spassword'}
              type="password"
              onKeyDown={handleEnter}
            />
          </InputContainer>
          <Error>
            <SubText
              error={errorMessage !== 'login.status1' && errorType === 2}
            >
              {
                <FormattedMessage
                  id={errorType === 2 ? errorMessage : 'login.status1'}
                />
              }
            </SubText>
          </Error>
        </InputAndError>
      </InputsContainer>
    </LoginModal>
  );
};

ResetPassword.propTypes = {
  setPassUpdated: PropTypes.func,
  passUpdated: PropTypes.bool,
};

export default ResetPassword;
