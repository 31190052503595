import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import BasicInput from 'components/commons/Inputs/BasicInput';
import { FormattedMessage } from 'react-intl';
import {
  Wrapper,
  Row,
  BigItemContainer,
  Title,
  InputContainer,
  SmallItemContainer,
  Serial,
} from './styled';

const SecondStage = (props) => {
  useEffect(() => {
    props.setInfoDevicesToInsert(
      props.devicesToInsert.map((value) => {
        let temp = props.allDevices[value];
        return {
          asset_type: temp.asset_type,
          serial_number: temp.serial_number,
          brand: temp.brand,
          price: '',
          discount: '',
          ship_date: '',
          //guarantee: '',
        };
      }),
    );
  }, [props.stage]);

  const handleChange = (index, access, value) => {
    let temp = props.infoDevicesToInsert;
    temp[index][access] = value;
    props.setInfoDevicesToInsert(temp);
  };

  return (
    <Wrapper>
      {props.devicesToInsert.map((value, index) => {
        return (
          <Row key={index}>
            <BigItemContainer>
              <Title>
                <FormattedMessage id={'services.device.input0'} />
                <Serial>[SN: {props.allDevices[value].serial_number}]</Serial>
              </Title>
              <InputContainer>
                <BasicInput
                  disabled
                  value={props.allDevices[value].comercial_description}
                />
              </InputContainer>
            </BigItemContainer>
            <SmallItemContainer>
              <Title>
                <FormattedMessage id={'services.device.input1'} />
              </Title>
              <InputContainer>
                <BasicInput
                  min={0}
                  type="number"
                  onChange={(value) =>
                    handleChange(index, 'price', value.target.value)
                  }
                />
              </InputContainer>
            </SmallItemContainer>
            {/*
            <SmallItemContainer>
              <Title>
                <FormattedMessage id={'services.device.input2'} />
              </Title>
              <InputContainer>
                <BasicInput
                  min={0}
                  type="number"
                  onChange={(value) =>
                    handleChange(index, 'guarantee', value.target.value)
                  }
                />
              </InputContainer>
            </SmallItemContainer>
                */}
            <SmallItemContainer>
              <Title>
                <FormattedMessage id={'services.device.input3'} />
              </Title>
              <InputContainer>
                <BasicInput
                  min={0}
                  type="number"
                  onChange={(value) =>
                    handleChange(index, 'discount', value.target.value)
                  }
                />
              </InputContainer>
            </SmallItemContainer>
            <SmallItemContainer>
              <Title>
                <FormattedMessage id={'services.device.input4'} />
              </Title>
              <InputContainer>
                <BasicInput
                  type="date"
                  onChange={(value) =>
                    handleChange(index, 'ship_date', value.target.value)
                  }
                />
              </InputContainer>
            </SmallItemContainer>
          </Row>
        );
      })}
    </Wrapper>
  );
};

SecondStage.propTypes = {
  stage: PropTypes.number,
  allDevices: PropTypes.any,
  setInfoDevicesToInsert: PropTypes.func,
  devicesToInsert: PropTypes.any,
  infoDevicesToInsert: PropTypes.any,
};

export default SecondStage;
