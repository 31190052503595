import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Label from 'components/commons/Texts/Label';
import BasicInput from 'components/commons/Inputs/BasicInput';
import { FirstRowDiv, InputContainer } from './styled';

const SecondRow = (props) => {
  return (
    <FirstRowDiv>
      <InputContainer>
        <Label value={<FormattedMessage id={'services.device.input4'} />} />
        <BasicInput
          min={0}
          type="date"
          value={props.shipDate}
          onChange={(value) =>
            props.handleChange('shipDate', value.target.value)
          }
        />
      </InputContainer>
      <InputContainer>
        <Label value={<FormattedMessage id={'services.device.input5'} />} />
        <BasicInput
          min={0}
          maxLength={50}
          value={props.tracking}
          onChange={(value) =>
            props.handleChange('tracking', value.target.value)
          }
        />
      </InputContainer>
    </FirstRowDiv>
  );
};

SecondRow.propTypes = {
  handleChange: PropTypes.func,
  shipDate: PropTypes.string,
  tracking: PropTypes.string,
};

export default SecondRow;
