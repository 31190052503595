import React from 'react';
import PropTypes from 'prop-types';
import { Container, Key, Value, Right } from './styled';

const InfoRow = (props) => {
  return (
    <Container>
      <Key>{props.title}</Key>
      <Right>
        <Value>{props.value}</Value>
      </Right>
    </Container>
  );
};

InfoRow.propTypes = {
  title: PropTypes.any,
  value: PropTypes.any,
};

export default InfoRow;
