import styled from 'styled-components';

export const FirstRowDiv = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  max-height: 75px;
  min-height: 75px;
`;

export const InputContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  width: ${(props) => (props.middle ? '48%' : '100%')};
`;
