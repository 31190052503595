import React from 'react';
import { FormattedMessage } from 'react-intl';

export const PERMISSIONS = {
  inventory: 'inventory',
  inventory_abm: 'inventory_abm',
  clients: 'clients',
  clients_abm: 'clients_abm',
  suppliers: 'supplier',
  suppliers_abm: 'supplier_abm',
  orders: 'orders',
  orders_abm: 'orders_abm',
  services: 'services',
  services_abm: 'services_abm',
  shipments: 'shipments',
  shipments_abm: 'shipments_abm',
  contracts: 'contracts',
  fulfillments: 'fulfillments',
  fulfillments_abm: 'fulfillments_abm',
  dashboard: 'dashboard',
  dashboard_abm: 'dashboard_abm',
  client_view: 'client_view',
};

export const EMENDU_NIF = 'B40633893';

export const CONTRACT_TYPES = [
  { id: '001', name: <FormattedMessage id={'contract_type001'} /> },
  { id: '002', name: <FormattedMessage id={'contract_type002'} /> },
  { id: '003', name: <FormattedMessage id={'contract_type003'} /> },
];

export const aT1 = 'Desktop';
export const aT2 = 'Laptop';
export const aT3 = 'Phone';
export const aT4 = 'Tablet';
export const aT5 = 'Monitor';
export const aT6 = 'Peripheral';

export const DEMO_USER = 'demo.emendu@gmail.com';
