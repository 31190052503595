import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Content,
  Centered,
  FirstRow,
  CrossIcon,
  Title,
  Wrapper,
  ButtonContainer,
  LastRow,
  Spinner,
  Check,
} from './styled';
import { FormattedMessage } from 'react-intl';
import Cross from 'images/cross.png';
import CheckIcon from 'images/check.png';
import GenericButton from 'components/commons/Buttons/GenericButton';

const LoginModal = (props) => {
  return (
    <>
      <Container onClick={() => props.closeModal()} />
      <Centered size={props.size}>
        <Content>
          <Wrapper>
            <FirstRow>
              <Title>
                <FormattedMessage id={props.title} />
                {props.check && <Check src={CheckIcon} alt={'icon'} />}
              </Title>
              {!props.check && (
                <CrossIcon
                  src={Cross}
                  onClick={() => props.closeModal(false)}
                  alt={'icon'}
                />
              )}
            </FirstRow>
            {props.children}
          </Wrapper>
          <LastRow>
            <ButtonContainer>
              <GenericButton
                theme="dark"
                onClick={props.buttonFunc}
                title={
                  props.loading ? (
                    <Spinner />
                  ) : (
                    <FormattedMessage id={props.buttonText} />
                  )
                }
              />
            </ButtonContainer>
          </LastRow>
        </Content>
      </Centered>
    </>
  );
};

LoginModal.propTypes = {
  children: PropTypes.node,
  closeModal: PropTypes.func,
  title: PropTypes.string,
  buttonText: PropTypes.node,
  buttonFunc: PropTypes.func,
  loading: PropTypes.bool,
  check: PropTypes.bool,
  size: PropTypes.string,
};

export default LoginModal;
