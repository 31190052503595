import styled from 'styled-components';
import colors from 'styles/colors';

export const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: ${(props) => (props.rent ? colors.blue5 : colors.white)};
  border-radius: 8px;
  cursor: pointer;
  white-space: nowrap;
`;

export const Left = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25%;
  height: 100%;
`;

export const Right = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 75%;
  height: 100%;
`;

export const Title = styled.div`
  display: flex;
  font-weight: 600;
  font-size: 1rem;
  color: ${(props) => (props.rent ? colors.white : colors.blue2)};
`;

export const Subtitle = styled.div`
  display: flex;
  font-weight: 500;
  font-size: 0.875rem;
  color: ${(props) => (props.rent ? colors.white : colors.blue2)};
`;
