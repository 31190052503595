import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import GenericButton from 'components/commons/Buttons/GenericButton';
import { updateShipmentState } from 'store/Shipments/actions';
import {
  Container,
  Centered,
  Wrapper,
  Title,
  Body,
  LastRow,
  ConfirmButton,
  CancelButton,
} from './styled';

const States = (props) => {
  const status = '004';
  const dispatch = useDispatch();

  const submit = () => {
    dispatch(
      updateShipmentState(
        props.closeModal,
        props.shipmentEdit.shipment_id,
        status,
      ),
    );
    // props.setFlag(!props.flag);
  };

  useEffect(() => {
    function handleEscapeKey(event) {
      if (event.code === 'Escape') {
        props.closeModal();
      }
    }

    document.addEventListener('keydown', handleEscapeKey);
    return () => document.removeEventListener('keydown', handleEscapeKey);
  }, []);

  return (
    <>
      <Container />
      <Centered>
        <Wrapper>
          <Title>
            <FormattedMessage id={'shipments.state.title'} />
          </Title>
          <Body>
            <LastRow>
              <CancelButton>
                <GenericButton
                  theme="light"
                  title={<FormattedMessage id={'shipments.state.cancel'} />}
                  onClick={props.closeModal}
                />
              </CancelButton>
              <ConfirmButton>
                <GenericButton
                  theme="dark"
                  title={<FormattedMessage id={'shipments.state.confirm'} />}
                  onClick={submit}
                  // disabled={status === '002' && !nif}
                />
              </ConfirmButton>
            </LastRow>
          </Body>
        </Wrapper>
      </Centered>
    </>
  );
};

States.propTypes = {
  closeModal: PropTypes.func,
  show: PropTypes.number,
  shipmentEdit: PropTypes.any,
  flag: PropTypes.bool,
  setFlag: PropTypes.func,
};

export default States;
