import styled, { keyframes } from 'styled-components';
import colors from 'styles/colors';

const dark = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(7, 7, 7, 0.8);
  text-align: center;
  animation: 0.3s ${dark};
  z-index: 11;
`;

export const Centered = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 6px;
  width: 70%;
  height: ${(props) => (props.inserted ? '50%' : '75%')};
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  z-index: 12;
  overflow: auto;
  &::-webkit-scrollbar {
    height: 10px;
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${colors.gray4};
    border-right: none;
    border-left: none;
    border-radius: 8px;
    border: 3px solid #f4f8fb;
  }
  &::-webkit-scrollbar-track-piece {
    background: transparent;
    margin-top: 110px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 54px);
  margin: 24px 32px;
  min-width: 750px;
  min-height: 600px;
`;

export const Title = styled.div`
  display: flex;
  font-size: 2.5rem;
  margin-bottom: 32px;
  font-weight: 600;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 90px);
  width: 100%;
`;

export const Subtitle = styled.div`
  display: flex;
  font-weight: 500;
  font-size: 0.813rem;
  color: ${colors.gray1};
  display: ${(props) => (props.stage === 1 ? 'none' : 'default')};
  margin-bottom: 24px;
`;

export const LastRow = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ConfirmButton = styled.div`
  display: flex;
  width: 17%;
  padding-left: 15px;
  height: 45px;
  min-width: 150px;
`;

export const CancelButton = styled.div`
  display: flex;
  width: 14%;
  height: 45px;
  min-width: 115px;
`;

export const InsertedContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 70px;
  height: 100%;
  align-items: center;
`;

export const IconContainer = styled.div`
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.green9};
  min-height: 158px;
  min-width: 158px;
  margin-bottom: 42px;
`;

export const Icon = styled.img`
  width: 75px;
`;

export const StageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-bottom: 5px;
  min-width: 500px;
`;

export const Progress = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: sticky;
  top: 0;
`;

export const ProgressBar = styled.div`
  width: ${(props) => (props.stage === 1 ? '50%' : '100%')};
  background-color: ${colors.green9};
  transition: width 0.6s ease;
  min-height: 6px;
  position: sticky;
  top: 0;
`;

export const Error = styled.div`
  display: ${(props) => (props.stage === 1 ? 'none' : 'default')};
  color: ${(props) =>
    ['orders.status1'].includes(props.id) ? colors.gray1 : colors.red2};
  font-size: 0.813rem;
  font-weight: 500;
`;
