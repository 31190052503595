import React from 'react';
import { FormattedMessage } from 'react-intl';
export const STATUS = [
  {
    value: '001',
    label: <FormattedMessage id={'device.state001'} />,
  },
  {
    value: '002',
    label: <FormattedMessage id={'device.state002'} />,
  },
  {
    value: '003',
    label: <FormattedMessage id={'device.state003'} />,
  },
  {
    value: '004',
    label: <FormattedMessage id={'device.state004'} />,
  },
  {
    value: '005',
    label: <FormattedMessage id={'device.state005'} />,
  },
  {
    value: '006',
    label: <FormattedMessage id={'device.state006'} />,
  },
  {
    value: '007',
    label: <FormattedMessage id={'device.state007'} />,
  },
  {
    value: '008',
    label: <FormattedMessage id={'device.state008'} />,
  },
  {
    value: '009',
    label: <FormattedMessage id={'device.state009'} />,
  },
  {
    value: '010',
    label: <FormattedMessage id={'device.state010'} />,
  },
];
