import React, { useState, useEffect } from 'react';
import Info from 'components/commons/Info';
import * as D from 'components/pages/App/constants';
import PropTypes from 'prop-types';
import { PERMISSIONS } from 'utils/constants';
import Body from './Body';
import { useDispatch } from 'react-redux';
import { getShipments } from 'store/Shipments/actions';

const ShipmentsInfo = (props) => {
  const dispatch = useDispatch();

  const shipmentId = props.match.params.id;
  const [shipment, setShipment] = useState([{}]);

  useEffect(() => {
    dispatch(getShipments(setShipment, shipmentId));
  }, []);

  return (
    <Info
      redirectArray={[
        D.shipments_info_es + shipmentId,
        D.shipments_info_en + shipmentId,
        D.shipments_info_pt + shipmentId,
      ]}
      permission={PERMISSIONS.shipments}
      moduleName="Envíos"
      subtitle="lateral_menu.shipments"
      title={shipment[0].shipment_id}
      setModuleOnClick={D.shipments_es}
    >
      <Body shipment={shipment[0]} />
    </Info>
  );
};

ShipmentsInfo.propTypes = {
  match: PropTypes.any,
};

export default ShipmentsInfo;
