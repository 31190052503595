import styled from 'styled-components';
import colors from 'styles/colors';

export const BigContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 4px);
  background-color: ${colors.white};
  margin-bottom: 16px;
  border-radius: 6px;
  border: 2px solid ${colors.gray5};
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  min-height: 70px;
  max-height: 70px;
  background-color: ${colors.white};
  border-radius: 6px;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0 26px;
  overflow: hidden;
`;

export const Left = styled.div`
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Title = styled.div`
  color: ${colors.black};
  font-weight: 600;
  font-size: 0.875rem;
  text-transform: uppercase;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Subtitle = styled.div`
  color: ${colors.gray3};
  letter-spacing: 0.5px;
  font-weight: 400;
  font-size: 0.813rem;
  text-transform: uppercase;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
