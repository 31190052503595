import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper, Container, Body } from './styled';
import FirstRow from './FirstRow';

const Inputs = (props) => {
  return (
    <>
      <Wrapper>
        <Body>
          <Container>
            <FirstRow
              status={props.status}
              setStatus={props.setStatus}
              nif={props.nif}
              setNif={props.setNif}
              itemEdit={props.itemEdit}
              clients={props.clients}
            />
          </Container>
        </Body>
      </Wrapper>
    </>
  );
};

Inputs.propTypes = {
  status: PropTypes.string,
  setStatus: PropTypes.func,
  nif: PropTypes.string,
  setNif: PropTypes.func,
  itemEdit: PropTypes.any,
  clients: PropTypes.any,
};

export default Inputs;
