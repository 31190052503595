import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { BodyDiv } from './styled';
import { selectUser } from 'store/Login/selectors';
import { PERMISSIONS as P } from 'utils/constants';
import Accordion from 'components/commons/Accordion';
import { FormattedMessage } from 'react-intl';
import * as C from 'components/pages/App/constants';
import history from 'utils/history';
import AccordionChild from '../AccordionChild';
import { stringToInputDate } from 'utils/functions/DateUtils';

const Body = (props) => {
  const user = useSelector(selectUser);

  const handleArrayChild = (device, isFilter) => {
    return {
      device_id: device.device_id,
      guarantee: device.guarantee,
      price: device.price,
      discount: device.discount,
      ship_date: isFilter
        ? device.ship_date
        : stringToInputDate(device.ship_date),
      start_renting_date: isFilter
        ? device.start_renting_date
        : stringToInputDate(device.start_renting_date),
      end_annex_date: isFilter
        ? device.end_annex_date
        : stringToInputDate(device.end_annex_date),
      end_renting_date: isFilter
        ? device.end_renting_date
        : stringToInputDate(device.end_renting_date),
      tracking_number: device.tracking_number,
      description: device.description,
      comercial_description: device.comercial_description,
      serial_number: device.serial_number,
      status: device.status,
    };
  };

  const handleAddParent = (annex) => {
    let amountInUse = 0;

    annex.devices.forEach((device) => {
      !device.end_renting_date && amountInUse++;
    });

    props.setItemEdit({
      annex_id: annex.annex_id,
      amount_devices: annex.amount_devices,
      amount_in_use: amountInUse,
    });
    props.setOpenModal(1);
  };

  const handleEditChild = (device) => {
    props.setItemEdit(handleArrayChild(device, false));
    props.setOpenModal(3);
  };

  const handleInfoParent = (annex_id, aux) => {
    if (aux) {
      window.open(
        (props.clientView ? C.contracts_devices_es : C.services_devices_es) +
          annex_id +
          '/' +
          props.number,
        '_blank',
      );
    } else {
      history.push(
        (props.clientView ? C.contracts_devices_es : C.services_devices_es) +
          annex_id +
          '/' +
          props.number,
      );
    }
  };

  const handleFilter = () => {
    if (props.filter) {
      let array = [];

      props.annexDevices[0].devices.forEach((row) => {
        if (
          row.comercial_description
            .toUpperCase()
            .indexOf(props.filter.toUpperCase()) > -1
        ) {
          array.push(handleArrayChild(row, true));
        }
      });
      return array;
    }
  };

  return (
    <BodyDiv>
      {props.annexDevices && (
        <Accordion
          name={
            <FormattedMessage
              id={'services.annex_type' + props.annexDevices[0].description}
              values={{ id: props.number }}
            />
          }
          subtitle={
            <FormattedMessage
              id={
                'services.annex.subtitle_' +
                (props.annexDevices[0].amount_devices === 1
                  ? 'singular'
                  : 'plural')
              }
              values={{
                date: props.annexDevices[0].signature_date,
                amount: props.annexDevices[0].amount_devices,
              }}
            />
          }
          onInfo={() => handleInfoParent(props.annexDevices[0].annex_id)}
          onAuxClick={() =>
            handleInfoParent(props.annexDevices[0].annex_id, true)
          }
          onAddPdfParent={
            user.permissions[P.services_abm] &&
            (() => handleAddParent(props.annexDevices[0]))
          }
          onNewTabParent={() =>
            window.open(props.annexDevices[0].url, '_blank')
          }
        >
          <AccordionChild
            onEditChild={
              user.permissions[P.services_abm] ? handleEditChild : null
            }
            childs={
              props.filter ? handleFilter() : props.annexDevices[0].devices
            }
            clientView={props.clientView}
          />
        </Accordion>
      )}
    </BodyDiv>
  );
};

Body.propTypes = {
  openModal: PropTypes.number,
  setItemEdit: PropTypes.func,
  setOpenModal: PropTypes.func,
  filter: PropTypes.string,
  annexDevices: PropTypes.any,
  clientView: PropTypes.bool,
  number: PropTypes.number,
};

export default Body;
