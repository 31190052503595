import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Creatable from 'react-select/creatable';
import { FormattedMessage } from 'react-intl';
import Label from 'components/commons/Texts/Label';
import { removeFirstWhiteSpace } from 'utils/functions/general';
import {
  SecondRowDiv,
  InputContainer,
  customStyles,
  Cantity,
  Space,
} from './styled';
import BasicInput from 'components/commons/Inputs/BasicInput';
import { Input } from '..';

const SecondRow = (props) => {
  const selectRef = useRef();
  const [counter, setCounter] = useState(0);

  const onChangeSerial = (event) => {
    props.handleChange(
      'serialNumber',
      removeFirstWhiteSpace(event.target.value),
    );
    const array = event.target.value
      .split(',')
      .filter((val) => val.trim() !== '');
    setCounter(array.length);
  };

  return (
    <SecondRowDiv>
      <InputContainer>
        <Label value={<FormattedMessage id={'inventory.input5'} />} />
        <Creatable
          options={props.handleDuplicated(props.deviceInfo, 'display_name')}
          placeholder={''}
          styles={customStyles}
          components={{
            DropdownIndicator: () => null,
            Input,
          }}
          menuPortalTarget={document.body}
          menuPosition={'fixed'}
          onInputChange={(inputValue, { action }) =>
            props.onInputChange(
              'displayName',
              30,
              'string',
              removeFirstWhiteSpace(inputValue),
              {
                action,
              },
            )
          }
          isDisabled={!props.searched}
          onChange={(opt) => props.handleChange('displayName', opt.value)}
          value={{
            label: props.displayName,
            name: props.displayName,
          }}
          ref={selectRef}
          inputValue={props.displayName}
        />
      </InputContainer>
      <InputContainer>
        <Cantity>
          <Label value={<FormattedMessage id={'inventory.input6'} />} />
          <Space />
          <Label value={'(' + counter + ')'} />
        </Cantity>
        <BasicInput
          disabled={!props.searched || props.show === 2}
          onChange={onChangeSerial}
          value={props.serialNumber}
        />
      </InputContainer>
      <InputContainer>
        <Label value={<FormattedMessage id={'inventory.input7'} />} />
        <Creatable
          options={props.handleDuplicated(props.deviceInfo, 'os')}
          placeholder={''}
          styles={customStyles}
          components={{
            DropdownIndicator: () => null,
            Input,
          }}
          menuPortalTarget={document.body}
          menuPosition={'fixed'}
          onInputChange={(inputValue, { action }) =>
            props.onInputChange(
              'os',
              30,
              'string',
              removeFirstWhiteSpace(inputValue),
              {
                action,
              },
            )
          }
          isDisabled={
            !props.searched ||
            ['Monitor', 'Peripheral'].includes(props.deviceType)
          }
          onChange={(opt) => props.handleChange('os', opt.value)}
          value={
            ['Monitor', 'Peripheral'].includes(props.deviceType)
              ? {
                  label: '',
                  name: '',
                }
              : {
                  label: props.os,
                  name: props.os,
                }
          }
          ref={selectRef}
          inputValue={props.os}
        />
      </InputContainer>
      <InputContainer>
        <Label value={<FormattedMessage id={'inventory.input8'} />} />
        <Creatable
          options={props.handleDuplicated(props.deviceInfo, 'os_version')}
          placeholder={''}
          styles={customStyles}
          components={{
            DropdownIndicator: () => null,
            Input,
          }}
          menuPortalTarget={document.body}
          menuPosition={'fixed'}
          onInputChange={(inputValue, { action }) =>
            props.onInputChange(
              'osVersion',
              30,
              'string',
              removeFirstWhiteSpace(inputValue),
              {
                action,
              },
            )
          }
          isDisabled={
            !props.searched ||
            ['Monitor', 'Peripheral'].includes(props.deviceType)
          }
          onChange={(opt) => props.handleChange('osVersion', opt.value)}
          value={
            ['Monitor', 'Peripheral'].includes(props.deviceType)
              ? {
                  label: '',
                  name: '',
                }
              : {
                  label: props.osVersion,
                  name: props.osVersion,
                }
          }
          ref={selectRef}
          inputValue={props.osVersion}
        />
      </InputContainer>
    </SecondRowDiv>
  );
};

SecondRow.propTypes = {
  handleChange: PropTypes.func,
  deviceType: PropTypes.string,
  searched: PropTypes.bool,
  deviceInfo: PropTypes.any,
  handleDuplicated: PropTypes.func,
  displayName: PropTypes.string,
  os: PropTypes.string,
  osVersion: PropTypes.string,
  serialNumber: PropTypes.any,
  onInputChange: PropTypes.func,
  show: PropTypes.number,
};

export default SecondRow;
