import React, { useState, useEffect } from 'react';
import Info from 'components/commons/Info';
import * as C from 'components/pages/App/constants';
import { PERMISSIONS } from 'utils/constants';
import PropTypes from 'prop-types';
import Modal from '../FulfillmentsInfo/Modal';
import { useDispatch } from 'react-redux';
import {
  getFulfillment,
  getFulfillmentDevices,
} from 'store/Fulfillments/actions';

import Body from './Body';

const FulfillmentsInfo = (props) => {
  // 0: Close, 1: Ready, 2: Confirm
  const [openModal, setOpenModal] = useState(0);
  const [itemEdit, setItemEdit] = useState({});

  const dispatch = useDispatch();

  const fulfillmentsId = props.match.params.id;
  const [fulfillment, setFulfillment] = useState([{}]);
  const [devices, setDevices] = useState([{}]);

  useEffect(() => {
    dispatch(getFulfillment(setFulfillment, fulfillmentsId));
    dispatch(getFulfillmentDevices(setDevices, fulfillmentsId));
  }, []);

  return (
    <>
      {![0].includes(openModal) && (
        <Modal
          show={openModal}
          closeModal={() => setOpenModal(0)}
          itemEdit={itemEdit}
          redirectArray={[
            C.fulfillments_es,
            C.fulfillments_en,
            C.fulfillments_pt,
          ]}
        />
      )}
      <Info
        redirectArray={[
          C.fulfillments_info_es + fulfillmentsId,
          C.fulfillments_info_en + fulfillmentsId,
          C.fulfillments_info_pt + fulfillmentsId,
        ]}
        permission={PERMISSIONS.fulfillments}
        permissionAbm={PERMISSIONS.fulfillments_abm}
        moduleName="Salidas"
        subtitle="lateral_menu.fulfillments"
        title={`SH-0${fulfillment[0].shipment_id}`}
        setModuleOnClick={C.fulfillments_es}
        empty
      >
        <Body
          fulfillment={fulfillment[0]}
          devices={devices}
          openModal={openModal}
          setOpenModal={setOpenModal}
          setItemEdit={setItemEdit}
          redirectArray={[
            C.fulfillments_es,
            C.fulfillments_en,
            C.fulfillments_pt,
          ]}
        />
      </Info>
    </>
  );
};

FulfillmentsInfo.propTypes = {
  match: PropTypes.any,
};

export default FulfillmentsInfo;
