import React, { useState, useEffect } from 'react';
import Landing from 'components/commons/Landing';
import * as C from 'components/pages/App/constants';
import { PERMISSIONS } from 'utils/constants';
import Body from 'components/pages/ServicesAnnex/Body';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { getAnnexDevices } from 'store/Services/actions';
import { FormattedMessage } from 'react-intl';

const ContractsInfo = (props) => {
  const dispatch = useDispatch();
  const annex_id = props.match.params.id;
  const number = props.match.params.num;

  const [filter, setFilter] = useState('');
  const [annexDevices, setAnnexDevices] = useState('');

  useEffect(() => {
    dispatch(getAnnexDevices(setAnnexDevices, annex_id));
  }, []);

  return (
    <>
      <Landing
        redirectArray={[
          C.contracts_annex_es + annex_id + '/' + number,
          C.contracts_annex_en + annex_id + '/' + number,
          C.contracts_annex_pt + annex_id + '/' + number,
        ]}
        permission={PERMISSIONS.contracts}
        moduleName="Contratos"
        titleId="lateral_menu.contracts"
        filter={filter}
        setFilter={setFilter}
        historic={
          annexDevices && [
            {
              subtitle: <FormattedMessage id={'lateral_menu.contracts'} />,
              click: C.contracts_es,
            },
            {
              subtitle: (
                <FormattedMessage
                  id={'services.annex_type' + annexDevices[0].description}
                  values={{ id: number }}
                />
              ),
            },
          ]
        }
      >
        <Body
          filter={filter}
          annexDevices={annexDevices}
          clientView
          number={number}
        />
      </Landing>
    </>
  );
};

ContractsInfo.propTypes = {
  match: PropTypes.any,
};

export default ContractsInfo;
