import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import GenericButton from 'components/commons/Buttons/GenericButton';
import Check from 'images/check_no_background.png';
import Inputs from './Inputs';
import { editAnnexDevice } from 'store/Services/actions';
import {
  Container,
  Centered,
  Wrapper,
  Title,
  Body,
  LastRow,
  ConfirmButton,
  CancelButton,
  InsertedContainer,
  IconContainer,
  Icon,
} from './styled';

const Modal = (props) => {
  const [inserted, setInserted] = useState(false);
  const [error, setError] = useState('services.device.status1');
  const [guarantee, setGuarantee] = useState(props.itemEdit.guarantee);
  const [price, setPrice] = useState(props.itemEdit.price);
  const [discount, setDiscount] = useState(props.itemEdit.discount);
  const [shipDate, setShipDate] = useState(props.itemEdit.ship_date);
  const [startRentingDate, setStartRentingDate] = useState(
    props.itemEdit.start_renting_date,
  );
  const [period, setPeriod] = useState(props.itemEdit.end_annex_date);
  const [endRentingDate, setEndRentingDate] = useState(
    props.itemEdit.end_renting_date,
  );
  const [tracking, setTracking] = useState(props.itemEdit.tracking_number);
  const [description, setDescription] = useState(props.itemEdit.description);

  const dispatch = useDispatch();

  const handleChange = (name, value) => {
    switch (name) {
      case 'inserted':
        setInserted(value);
        break;
      case 'error':
        setError(value);
        break;
      case 'guarantee':
        setGuarantee(value);
        break;
      case 'price':
        setPrice(value);
        break;
      case 'discount':
        setDiscount(value);
        break;
      case 'shipDate':
        setShipDate(value);
        break;
      case 'startRentingDate':
        setStartRentingDate(value);
        break;
      case 'period':
        setPeriod(value);
        break;
      case 'endRentingDate':
        setEndRentingDate(value);
        break;
      case 'tracking':
        setTracking(value);
        break;
      case 'description':
        setDescription(value);
        break;
    }
  };

  const submit = () => {
    dispatch(
      editAnnexDevice(
        setInserted,
        setError,
        props.closeModal,
        props.itemEdit.device_id,
        guarantee,
        price,
        discount,
        shipDate,
        startRentingDate,
        period,
        endRentingDate,
        tracking,
        description,
      ),
    );
  };

  useEffect(() => {
    function handleEscapeKey(event) {
      if (event.code === 'Escape') {
        props.closeModal();
      }
    }

    document.addEventListener('keydown', handleEscapeKey);
    return () => document.removeEventListener('keydown', handleEscapeKey);
  }, []);

  return (
    <>
      <Container />
      <Centered inserted={inserted}>
        {inserted ? (
          <InsertedContainer>
            <IconContainer>
              <Icon src={Check} alt={'icon'} />
            </IconContainer>
            <Title>
              <FormattedMessage id={'services.device.inserted3'} />
            </Title>
          </InsertedContainer>
        ) : (
          <>
            <Wrapper>
              <Title>
                <FormattedMessage id={'services.device.title2'} />
              </Title>
              <Body>
                <Inputs
                  error={error}
                  handleChange={handleChange}
                  guarantee={guarantee}
                  price={price}
                  discount={discount}
                  shipDate={shipDate}
                  startRentingDate={startRentingDate}
                  period={period}
                  endRentingDate={endRentingDate}
                  tracking={tracking}
                  description={description}
                />
                <LastRow>
                  <CancelButton>
                    <GenericButton
                      theme="light"
                      title={<FormattedMessage id={'services.device.left'} />}
                      onClick={props.closeModal}
                    />
                  </CancelButton>
                  <ConfirmButton>
                    <GenericButton
                      theme="dark"
                      title={<FormattedMessage id={'services.device.right'} />}
                      onClick={submit}
                    />
                  </ConfirmButton>
                </LastRow>
              </Body>
            </Wrapper>
          </>
        )}
      </Centered>
    </>
  );
};

Modal.propTypes = {
  closeModal: PropTypes.func,
  show: PropTypes.number,
  itemEdit: PropTypes.any,
};

export default Modal;
