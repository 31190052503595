import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Laptop, Phone, Tablet, Monitor, Peripheral } from './styled';

export const ITEMS = [
  {
    title: <FormattedMessage id={'stockList.pc'} />,
    icon: <Laptop />,
    accesor: 'Laptop',
  },
  {
    title: <FormattedMessage id={'stockList.phone'} />,
    icon: <Phone />,
    accesor: 'Phone',
  },
  {
    title: <FormattedMessage id={'stockList.tablet'} />,
    icon: <Tablet />,
    accesor: 'Tablet',
  },
  {
    title: <FormattedMessage id={'stockList.monitor'} />,
    icon: <Monitor />,
    accesor: 'Monitor',
  },
  {
    title: <FormattedMessage id={'stockList.peripheral'} />,
    icon: <Peripheral />,
    accesor: 'Peripheral',
  },
];
