import React from 'react';
import PropTypes from 'prop-types';
import { ReactMultiEmail } from 'react-multi-email';
import { Style } from './styled';
import 'react-multi-email/style.css';
import './custom-multiple-input.css'; // important: this line must be placed after react-table css import

const MultipleInput = (props) => {
  return (
    <ReactMultiEmail
      emails={props.items}
      style={Style}
      validateEmail={
        props.isNotMail
          ? (item) => {
              let flag;
              props.type === 'text'
                ? (flag = true)
                : /^\d+$/.test(item)
                ? (flag = true)
                : (flag = false);
              return flag;
            }
          : undefined
      }
      onChange={(item) => {
        props.handleChange(item);
      }}
      getLabel={(email, index, removeEmail) => {
        return (
          <div data-tag key={index}>
            {email}
            <span data-tag-handle onClick={() => removeEmail(index)}>
              x
            </span>
          </div>
        );
      }}
    />
  );
};

MultipleInput.propTypes = {
  items: PropTypes.array,
  handleChange: PropTypes.func,
  isNotMail: PropTypes.bool,
  type: PropTypes.string,
};

export default MultipleInput;
