import styled from 'styled-components';

export const Top = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  background-color: transparent;
  height: ${(props) => (props.isOpen ? '38%' : '0')};
  margin-bottom: ${(props) => (props.isOpen ? '2%' : '0')};
  //padding: ${(props) => (props.isOpen ? '16px' : '0')};
  border-radius: 8px;
  box-sizing: border-box;
  overflow: hidden;

  @media (max-width: 1024px) {
    display: block;
    height: ${(props) => (props.isOpen ? 'auto' : '0')};
  }
`;

export const Bottom = styled.div`
  width: 100%;
  display: flex;
  height: ${(props) => (props.isOpen ? '60%' : '100%')};
`;
