import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Label from 'components/commons/Texts/Label';
import { FirstRowDiv, InputContainer } from './styled';
import InputFile from 'components/commons/Inputs/InputFile';

const FirstRow = (props) => {
  return (
    <FirstRowDiv>
      <InputContainer>
        <Label value={<FormattedMessage id={'services.input1'} />} />
        <InputFile
          onChange={(e) =>
            props.handleChange('contractFile', e ? e.target.files[0] : '')
          }
          handleError={(error) => props.handleChange('error', error)}
        />
      </InputContainer>
    </FirstRowDiv>
  );
};

FirstRow.propTypes = {
  handleChange: PropTypes.func,
};

export default FirstRow;
