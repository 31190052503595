import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { BodyDiv } from './styled';
import { selectUser } from 'store/Login/selectors';
import { PERMISSIONS as P } from 'utils/constants';
import { getContracts } from 'store/Services/actions';
import Accordion from 'components/commons/Accordion';
import { FormattedMessage, useIntl } from 'react-intl';
import * as C from 'components/pages/App/constants';
import history from 'utils/history';
import AccordionChild from '../AccordionChild';
import { stringToInputDate } from 'utils/functions/DateUtils';

const Body = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const [contracts, setContracts] = useState([{}]);

  useEffect(() => {
    !props.openModal && dispatch(getContracts(setContracts, props.nif));
  }, [props.openModal]);

  const handleArrayParent = (row, isFilter) => {
    return {
      contract_id: row.contract_id,
      signature_date: isFilter
        ? row.signature_date
        : stringToInputDate(row.signature_date),
      url: row.url,
      end_date: row.end_date
        ? isFilter
          ? row.end_date
          : stringToInputDate(row.end_date)
        : '',
      description: row.description,
      annexes: row.annexes,
    };
  };

  const handleEditParent = (contract) => {
    props.setItemEdit(handleArrayParent(contract, false));
    props.setOpenModal(2);
  };

  const handleAddChild = (id) => {
    props.setItemEdit({ contract_id: id });
    props.setOpenModal(3);
  };

  const handleEditChild = (annex) => {
    props.setItemEdit({
      annex_id: annex.annex_id,
      signature_date: stringToInputDate(annex.signature_date),
      amount_devices: annex.amount_devices,
      managed_by: annex.managed_by,
      url: annex.url,
      employee_id: annex.employee_id,
    });
    props.setOpenModal(4);
  };

  const handleFilter = () => {
    let array = [];
    contracts.forEach((row) => {
      if (
        intl
          .formatMessage({
            id: 'services.contract_type' + row.description,
            values: { id: row.contract_id },
          })
          .toUpperCase()
          .indexOf(props.filter.toUpperCase()) > -1
      ) {
        array.push(handleArrayParent(row, true));
      }
    });
    return array;
  };

  const handleInfoChild = (annex_id, aux, number) => {
    if (aux) {
      window.open(
        (props.nif ? C.services_annex_es : C.contracts_annex_es) +
          annex_id +
          '/' +
          number,
        '_blank',
      );
    } else {
      history.push(
        (props.nif ? C.services_annex_es : C.contracts_annex_es) +
          annex_id +
          '/' +
          number,
      );
    }
  };

  return (
    <BodyDiv>
      {(props.filter ? handleFilter() : contracts).map((contract, index) => {
        return (
          <Accordion
            key={contract.contract_id || index}
            name={
              <FormattedMessage
                id={'services.contract_type' + contract.description}
                values={{ id: index + 1 }}
              />
            }
            subtitle={contract.signature_date + ' - ' + contract.end_date}
            onNewTabParent={() => window.open(contract.url, '_blank')}
            onAddPdfParent={
              user.permissions[P.services_abm] &&
              (() => handleAddChild(contract.contract_id))
            }
            onEditParent={
              user.permissions[P.services_abm] &&
              (() => handleEditParent(contract))
            }
          >
            <AccordionChild
              onEditChild={user.permissions[P.services_abm] && handleEditChild}
              onInfoChild={handleInfoChild}
              childs={contract.annexes}
              status={contract.description}
              nif={props.nif}
            />
          </Accordion>
        );
      })}
    </BodyDiv>
  );
};

Body.propTypes = {
  openModal: PropTypes.number,
  setItemEdit: PropTypes.func,
  setOpenModal: PropTypes.func,
  filter: PropTypes.string,
  nif: PropTypes.string,
};

export default Body;
