import produce from 'immer/dist/immer';
import { initialState } from './initialState';
import * as T from './types';

const general = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case T.SET_LOADING:
        draft.loading = action.state;
        break;
      case T.SET_MODULE:
        draft.module = action.module;
        if (action.module != 'Dashboard') {
          draft.dashboardFilter = initialState.dashboardFilter;
        }
        break;
      case T.SET_DASHBOARD_FILTER:
        draft.dashboardFilter = action.dashboardFilter;
        break;
    }
  });

export default general;
