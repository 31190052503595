import styled from 'styled-components';
import colors from 'styles/colors';

const handleBackground = (status) => {
  switch (status) {
    case '001':
      return colors.orange4;
    case '002':
      return colors.green8;
    case '003':
      return colors.gray5;
    case '004':
      return colors.red5;
  }
};

const handleColor = (status) => {
  switch (status) {
    case '001':
      return colors.orange2;
    case '002':
      return colors.green5;
    case '003':
      return colors.gray2;
    case '004':
      return colors.red6;
  }
};

export const Container = styled.div`
  width: 100%;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Tag = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-width: 100px;
  padding: 2px 10px;
  border-radius: 6px;
  font-weight: 500;
  font-size: 0.75rem;
  letter-spacing: -0.4px;
  color: ${({ status }) => handleColor(status)};
  background-color: ${({ status }) => handleBackground(status)};
`;
