import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import DataTable from 'components/commons/Table/DataTable';
import { COLUMNS } from './constants';
import {
  CountContainer,
  YCheck,
  GCheck,
  CountRight,
  Title,
  Subtitle,
} from './styled';

const FirsStage = (props) => {
  useEffect(() => {
    props.setInfoDevicesToInsert(
      props.devicesToInsert.map((value) => {
        let temp = props.allDevices[value];
        return {
          serial_number: temp.serial_number,
          brand: temp.brand,
        };
      }),
    );
  }, [props.devicesToInsert]);

  return (
    <>
      <DataTable
        title={<FormattedMessage id={'shipments.subtitle4'} />}
        data={props.allDevices}
        columns={COLUMNS}
        onSelect={props.setDevicesToInsert}
        selectable={
          !(
            props.shipmentEdit.devices ===
            props.devicesToInsert.length + props.shipmentEdit.amount_devices
          )
        }
        itemsSelected={props.devicesToInsert}
      />
      <CountContainer>
        {props.shipmentEdit.devices === props.devicesToInsert.length ? (
          <GCheck />
        ) : (
          <YCheck />
        )}
        <CountRight>
          <Title>
            <FormattedMessage
              id={'shipments.subtitle5'}
              values={{ amount: props.shipmentEdit.devices }}
            />
          </Title>
          <Subtitle>
            <FormattedMessage
              id={'shipments.subtitle6'}
              values={{
                amount:
                  props.shipmentEdit.devices -
                  (props.devicesToInsert.length +
                    props.shipmentEdit.amount_devices),
              }}
            />
          </Subtitle>
        </CountRight>
      </CountContainer>
    </>
  );
};

FirsStage.propTypes = {
  allDevices: PropTypes.any,
  devicesToInsert: PropTypes.any,
  infoDevicesToInsert: PropTypes.any,
  setDevicesToInsert: PropTypes.func,
  setInfoDevicesToInsert: PropTypes.func,
  shipmentEdit: PropTypes.any,
};

export default FirsStage;
