import produce from 'immer/dist/immer';
import { initialState } from './initialState';
import * as T from './types';

const login = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case T.LOGIN_SUCCESS:
        localStorage.setItem('user', JSON.stringify(action.user.result));
        localStorage.setItem('username', JSON.stringify(action.userMail));
        draft.currentUser = action.user.result;
        draft.userMail = action.userMail;
        break;
      case T.CLIENT_VIEW_SUCCESS:
        localStorage.setItem('userCopy', JSON.stringify(action.currentUser));
        localStorage.setItem(
          'usernameCopy',
          JSON.stringify(action.currentMail),
        );
        localStorage.setItem('user', JSON.stringify(action.user));
        localStorage.setItem('username', JSON.stringify(action.demoUser));
        draft.currentUser = action.user;
        draft.userMail = action.demoUser;
        draft.currentUserCopy = action.currentUser;
        draft.userMailCopy = action.currentMail;
        break;
      case T.CLIENT_VIEW_END:
        localStorage.setItem('user', JSON.stringify(draft.currentUserCopy));
        localStorage.setItem('username', JSON.stringify(draft.userMailCopy));
        draft.currentUser = draft.currentUserCopy;
        draft.userMail = draft.userMailCopy;
        localStorage.removeItem('userCopy');
        localStorage.removeItem('usernameCopy');
        draft.currentUserCopy = null;
        draft.userMailCopy = '';
        break;
      case T.NEW_PASSWORD:
        draft.currentUser.reset_password = false;
        action.currentUser.reset_password = false;
        localStorage.setItem('user', JSON.stringify(action.currentUser));
        break;
      case T.LOGOUT:
        localStorage.removeItem('user');
        localStorage.removeItem('username');
        localStorage.removeItem('userCopy');
        localStorage.removeItem('usernameCopy');
        draft.currentUser = null;
        draft.userMail = '';
        draft.currentUserCopy = null;
        draft.userMailCopy = '';
        break;
    }
  });

export default login;
