import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Wrapper, Container, Error, Body } from './styled';
import FirstStage from './Stages/FirstStage';
import SecondStage from './Stages/SecondStage';

const Inputs = (props) => {
  return (
    <>
      <Wrapper stage={props.stage}>
        <Body>
          <Container>
            <FirstStage
              handleChange={props.handleChange}
              stage={props.stage}
              clientName={props.clientName}
              nif={props.nif}
              address={props.address}
              door={props.door}
              floor={props.floor}
              phone={props.phone}
              postalCode={props.postalCode}
              city={props.city}
              country={props.country}
              description={props.description}
              show={props.show}
            />
            <SecondStage
              handleChange={props.handleChange}
              stage={props.stage}
              email={props.email}
              password={props.password}
              name={props.name}
              lastName={props.lastName}
              role={props.role}
              nif={props.nif}
            />
          </Container>
          <Error id={props.error}>
            <FormattedMessage id={props.error} />
          </Error>
        </Body>
      </Wrapper>
    </>
  );
};

Inputs.propTypes = {
  error: PropTypes.string,
  handleChange: PropTypes.func,
  stage: PropTypes.any,
  clientName: PropTypes.string,
  nif: PropTypes.string,
  address: PropTypes.string,
  door: PropTypes.any,
  floor: PropTypes.any,
  phone: PropTypes.any,
  postalCode: PropTypes.any,
  city: PropTypes.string,
  country: PropTypes.string,
  description: PropTypes.string,
  email: PropTypes.string,
  password: PropTypes.string,
  name: PropTypes.string,
  lastName: PropTypes.string,
  role: PropTypes.string,
  show: PropTypes.number,
};

export default Inputs;
