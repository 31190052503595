export const DEVICES_TYPE = [
  {
    value: 'Desktop',
    label: 'Desktop',
  },
  {
    value: 'Laptop',
    label: 'Laptop',
  },
  {
    value: 'Phone',
    label: 'Phone',
  },
  {
    value: 'Tablet',
    label: 'Tablet',
  },
  {
    value: 'Monitor',
    label: 'Monitor',
  },
  {
    value: 'Peripheral',
    label: 'Peripheral',
  },
];
