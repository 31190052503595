import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Label from 'components/commons/Texts/Label';
import BasicInput from 'components/commons/Inputs/BasicInput';
import { SecondRowDiv, InputContainer } from './styled';
import Select from 'react-select';
import { avoidZeroFirstAmountDevices } from 'utils/functions/general';
import { customStyles } from 'components/pages/Orders/Modal/Inputs/FirstRow/styled';

const SecondRow = (props) => {
  return (
    <SecondRowDiv>
      <InputContainer>
        <Label value={<FormattedMessage id={'services.input2'} />} />
        <BasicInput
          onChange={(e) =>
            props.handleChange('annexSignatureDate', e.target.value)
          }
          value={props.annexSignatureDate}
          type="date"
        />
      </InputContainer>
      <InputContainer>
        <Label value={<FormattedMessage id={'services.input6'} />} />
        <Select
          options={
            props.emenduEmployees &&
            props.emenduEmployees.map((item) => {
              return {
                value: item.id,
                label:
                  item.first_name + ' ' + item.last_name + ' - ' + item.role,
              };
            })
          }
          placeholder={<FormattedMessage id={'selectBoxDefault'} />}
          styles={customStyles('select')}
          menuPortalTarget={document.body}
          menuPosition={'fixed'}
          onChange={(opt) => props.handleChange('employeeId', opt.value)}
          hideSelectedOptions
          isSearchable={false}
          defaultValue={[
            {
              value: props.employeeId,
              label: props.managedBy,
            },
          ]}
        />
      </InputContainer>
      <InputContainer>
        <Label value={<FormattedMessage id={'services.input7'} />} />
        <BasicInput
          onChange={(e) =>
            props.handleChange(
              'amountDevices',
              avoidZeroFirstAmountDevices(e.target.value),
            )
          }
          value={props.amountDevices}
        />
      </InputContainer>
    </SecondRowDiv>
  );
};

SecondRow.propTypes = {
  handleChange: PropTypes.func,
  show: PropTypes.number,
  annexSignatureDate: PropTypes.string,
  amountDevices: PropTypes.any,
  managedBy: PropTypes.string,
  employeeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  emenduEmployees: PropTypes.any,
};

export default SecondRow;
