import React, { useState } from 'react';
import InfoButton from 'components/commons/Buttons/InfoButton';
import { FormattedMessage } from 'react-intl';
import Info from './Info';
import Users from './Users';
import PropTypes from 'prop-types';
import {
  OptionsContainer,
  OptionsWrapper,
  Option,
  Information,
} from './styled';

const Body = (props) => {
  const [module, setModule] = useState(0);

  return (
    <>
      <OptionsContainer>
        <OptionsWrapper>
          <Option>
            <InfoButton active={module === 0} onClick={() => setModule(0)}>
              <FormattedMessage id={'info.option1'} />
            </InfoButton>
          </Option>
          <Option>
            <InfoButton active={module === 1} onClick={() => setModule(1)}>
              <FormattedMessage id={'info.option3'} />
            </InfoButton>
          </Option>
        </OptionsWrapper>
      </OptionsContainer>
      <Information>
        <Info module={module} client={props.client} />
        <Users module={module} client={props.client.nif} />
      </Information>
    </>
  );
};

Body.propTypes = {
  client: PropTypes.any,
};

export default Body;
