import React from 'react';
import PropTypes from 'prop-types';
import history from 'utils/history';
import * as D from 'components/pages/App/constants';
import * as styled from './styled';

const Item = ({ item, setOpenModal, setItemEdit }) => {
  const {
    shipment_id,
    company_name,
    amount_devices,
    amount_ready_it_devices,
    estimated_date,
    status,
    mail_label_url,
  } = item;

  const handleClick = () => history.push(D.fulfillments_info_es + shipment_id);
  const handleClickQr = () => window.open(mail_label_url, '_blank');

  return (
    <styled.ItemWrap
      onClick={() => (
        status !== '006' ? handleClick() : setOpenModal(2),
        setItemEdit && setItemEdit(item)
      )}
    >
      <styled.Heading>SH-0{shipment_id}</styled.Heading>
      <styled.Company>{company_name}</styled.Company>
      <styled.Data>
        <styled.Details>
          <styled.Devices />
          {amount_ready_it_devices}/{amount_devices}
        </styled.Details>
        {estimated_date && (
          <styled.Details>
            <styled.Calendar />
            {estimated_date}
          </styled.Details>
        )}
      </styled.Data>

      {status === '004' && <styled.NewShipment />}
      {status === '005' && (
        <styled.InProgress
          full={amount_ready_it_devices === amount_devices ? 'true' : 'false'}
        />
      )}

      {status === '006' && (
        <>
          <styled.Info
            onClick={(e) => {
              e.stopPropagation();
              handleClick();
            }}
          />
          <styled.QR
            status={status}
            mail_label={mail_label_url}
            onClick={(e) => {
              if (mail_label_url) {
                e.stopPropagation();
                handleClickQr();
              } else {
                null;
              }
            }}
          />
        </>
      )}
    </styled.ItemWrap>
  );
};

Item.propTypes = {
  item: PropTypes.object,
  setOpenModal: PropTypes.func,
  setItemEdit: PropTypes.func,
};

export default Item;
