import React, { useState } from 'react';
import Modal from './Modal';
import Landing from 'components/commons/Landing';
import * as C from 'components/pages/App/constants';
import { PERMISSIONS } from 'utils/constants';
import Body from './Body';

const Suppliers = () => {
  const [openModal, setOpenModal] = useState(0);
  const [supplierEdit, setSupplierEdit] = useState({});
  const [filter, setFilter] = useState('');
  const [blurred, setBlurred] = useState(false);

  return (
    <>
      {openModal !== 0 && (
        <Modal
          show={openModal}
          closeModal={() => setOpenModal(0)}
          supplierEdit={supplierEdit}
        />
      )}
      <Landing
        redirectArray={[C.suppliers_es, C.suppliers_en, C.suppliers_pt]}
        permission={PERMISSIONS.suppliers}
        permissionAbm={PERMISSIONS.suppliers_abm}
        moduleName="Proveedores"
        titleId="lateral_menu.suppliers"
        setOpenModal={setOpenModal}
        buttonTextId="suppliers.add_supplier"
        filter={filter}
        setFilter={setFilter}
        buttonBlur={
          blurred ? 'suppliers.hide_suppliers' : 'suppliers.show_suppliers'
        }
        setBlurred={setBlurred}
        blurred={blurred}
      >
        <Body
          openModal={openModal}
          setSupplierEdit={setSupplierEdit}
          setOpenModal={setOpenModal}
          filter={filter}
          blurred={blurred}
        />
      </Landing>
    </>
  );
};

export default Suppliers;
