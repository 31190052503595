import React, { useState, useEffect } from 'react';
import Info from 'components/commons/Info';
import * as D from 'components/pages/App/constants';
import PropTypes from 'prop-types';
import { PERMISSIONS } from 'utils/constants';
import Body from './Body';
import { useDispatch } from 'react-redux';
import { getSuppliers } from 'store/Suppliers/actions';

const SuppliersInfo = (props) => {
  const dispatch = useDispatch();

  const supplierId = props.match.params.id;
  const [supplier, setSupplier] = useState([{}]);

  useEffect(() => {
    dispatch(getSuppliers(setSupplier, setSupplier, supplierId));
  }, []);

  return (
    <Info
      redirectArray={[
        D.suppliers_info_es + supplierId,
        D.suppliers_info_en + supplierId,
        D.suppliers_info_pt + supplierId,
      ]}
      permission={PERMISSIONS.orders}
      moduleName="Proveedores"
      subtitle="lateral_menu.suppliers"
      title={supplier[0].name}
      setModuleOnClick={D.suppliers_es}
    >
      <Body supplier={supplier[0]} />
    </Info>
  );
};

SuppliersInfo.propTypes = {
  match: PropTypes.any,
};

export default SuppliersInfo;
