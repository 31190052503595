import React from 'react';
import PropTypes from 'prop-types';
import { ButtonContainer, IconContainer, Title } from './styled';

const LateralmenuButton = (props) => {
  if (!props.show) return null;
  return (
    <ButtonContainer active={props.active} onClick={props.onClick}>
      <IconContainer>{props.children}</IconContainer>
      <Title>{props.title}</Title>
    </ButtonContainer>
  );
};

LateralmenuButton.propTypes = {
  title: PropTypes.node,
  onClick: PropTypes.func,
  active: PropTypes.any,
  children: PropTypes.node,
  show: PropTypes.bool,
};

export default LateralmenuButton;
