import styled from 'styled-components';

export const FirstRowDiv = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  max-height: 100px;
  min-height: 100px;
`;

export const InputContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
`;
