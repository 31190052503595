import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import GenericButton from 'components/commons/Buttons/GenericButton';
import Inputs from './Inputs';
import { updateDeviceState } from 'store/Inventory/actions';
import { getClients } from 'store/Clients/actions';
import {
  Container,
  Centered,
  Wrapper,
  Title,
  Body,
  LastRow,
  ConfirmButton,
  CancelButton,
} from './styled';

const States = (props) => {
  const [status, setStatus] = useState(props.itemEdit.status);
  const [nif, setNif] = useState(props.itemEdit.client_reserved_nif);
  const [clients, setClients] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    status === '002' && dispatch(getClients(setClients));
  }, [status]);

  const submit = () => {
    dispatch(
      updateDeviceState(
        status,
        props.itemEdit.it_check_pending,
        props.itemEdit.brand,
        props.itemEdit.serial_number,
        props.itemEdit.asset_type,
        props.closeModal,
        null,
        null,
        nif,
        props.itemEdit.status,
      ),
    );
    props.setFlag(!props.flag);
  };

  useEffect(() => {
    function handleEscapeKey(event) {
      if (event.code === 'Escape') {
        props.closeModal();
      }
    }

    document.addEventListener('keydown', handleEscapeKey);
    return () => document.removeEventListener('keydown', handleEscapeKey);
  }, []);

  return (
    <>
      <Container />
      <Centered>
        <Wrapper>
          <Title>
            <FormattedMessage id={'inventory.state.title'} />
          </Title>
          <Body>
            <Inputs
              status={status}
              setStatus={setStatus}
              nif={nif}
              setNif={setNif}
              itemEdit={props.itemEdit}
              clients={clients}
            />
            <LastRow>
              <CancelButton>
                <GenericButton
                  theme="light"
                  title={<FormattedMessage id={'inventory.state.left'} />}
                  onClick={props.closeModal}
                />
              </CancelButton>
              <ConfirmButton>
                <GenericButton
                  theme="dark"
                  title={<FormattedMessage id={'inventory.state.right'} />}
                  onClick={submit}
                  disabled={status === '002' && !nif}
                />
              </ConfirmButton>
            </LastRow>
          </Body>
        </Wrapper>
      </Centered>
    </>
  );
};

States.propTypes = {
  closeModal: PropTypes.func,
  show: PropTypes.number,
  itemEdit: PropTypes.any,
  flag: PropTypes.bool,
  setFlag: PropTypes.func,
};

export default States;
