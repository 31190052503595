import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Label from 'components/commons/Texts/Label';
import BasicInput from 'components/commons/Inputs/BasicInput';
import { FirstRowDiv, InputContainer } from './styled';
import { EMENDU_NIF } from 'utils/constants';
import { removeFirstWhiteSpace } from 'utils/functions/general';

const FirstRow = (props) => {
  return (
    <FirstRowDiv>
      <InputContainer>
        <Label value={<FormattedMessage id={'clients.input11'} />} />
        <BasicInput
          onChange={(e) =>
            props.handleChange('email', removeFirstWhiteSpace(e.target.value))
          }
          maxLength={40}
          value={props.email}
        />
      </InputContainer>
      <InputContainer>
        <Label value={<FormattedMessage id={'clients.input12'} />} />
        <BasicInput
          onChange={(e) =>
            props.handleChange(
              'password',
              removeFirstWhiteSpace(e.target.value),
            )
          }
          value={props.password}
        />
      </InputContainer>
      <InputContainer>
        <Label value={<FormattedMessage id={'clients.input13'} />} />
        <BasicInput
          onChange={(e) =>
            props.handleChange('name', removeFirstWhiteSpace(e.target.value))
          }
          maxLength={40}
          value={props.name}
          disabled={props.nif != EMENDU_NIF}
        />
      </InputContainer>
      <InputContainer>
        <Label value={<FormattedMessage id={'clients.input14'} />} />
        <BasicInput
          onChange={(e) =>
            props.handleChange(
              'lastName',
              removeFirstWhiteSpace(e.target.value),
            )
          }
          maxLength={40}
          value={props.lastName}
          disabled={props.nif != EMENDU_NIF}
        />
      </InputContainer>
    </FirstRowDiv>
  );
};

FirstRow.propTypes = {
  handleChange: PropTypes.func,
  email: PropTypes.string,
  password: PropTypes.string,
  name: PropTypes.string,
  lastName: PropTypes.string,
  nif: PropTypes.string,
};

export default FirstRow;
