export const LANG_OPTIONS = [
  {
    value: 'DE',
    label: 'DE',
  },
  {
    value: 'ES',
    label: 'ES',
  },
  {
    value: 'FR',
    label: 'FR',
  },
  {
    value: 'NL',
    label: 'NL',
  },
  {
    value: 'PT',
    label: 'PT',
  },
  {
    value: 'UK',
    label: 'UK',
  },
  {
    value: 'US',
    label: 'US',
  },
];
