import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import GenericButton from 'components/commons/Buttons/GenericButton';
import { redirect } from 'utils/functions/redirect_lang';
import { FormattedMessage } from 'react-intl';
import * as styled from './styled';
import Input from './Input';

const Devices = ({
  devices,
  setOpenModal,
  shipmentID,
  shipmentState,
  redirectArray,
}) => {
  const [totalChecked, setTotalChecked] = useState(0);
  const backToList = () => redirect(redirectArray);

  const submit = () => setOpenModal(1);

  useEffect(() => {
    const checked = devices.reduce((acc, obj) => acc + obj.it_check, 0) || 0;
    setTotalChecked(checked);
  }, [devices]);

  return (
    <>
      {devices &&
        devices.map((device, i) => (
          <Input
            key={i}
            device={device}
            shipmentID={shipmentID}
            totalChecked={totalChecked}
            setTotalChecked={setTotalChecked}
          />
        ))}

      <styled.ConfirmButton>
        <GenericButton
          theme="dark"
          title={
            shipmentState === '006' && devices.length === totalChecked ? (
              <FormattedMessage id={'fulfillments.btn.back'} />
            ) : (
              <FormattedMessage id={'fulfillments.btn.readyToShip'} />
            )
          }
          onClick={
            shipmentState === '006' && devices.length === totalChecked
              ? backToList
              : submit
          }
          disabled={devices.length !== totalChecked && true}
        />
      </styled.ConfirmButton>
    </>
  );
};

Devices.propTypes = {
  devices: PropTypes.array,
  setOpenModal: PropTypes.func,
  shipmentState: PropTypes.any,
  shipmentID: PropTypes.number,
  redirectArray: PropTypes.array,
};

export default Devices;
