import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { selectUser, selectResetPassword } from 'store/Login/selectors.js';
import PropTypes from 'prop-types';

const PrivateRoute = ({ component: Component, children, ...rest }) => {
  const user = useSelector(selectUser);
  const reset = useSelector(selectResetPassword);
  return (
    <Route
      {...rest}
      render={(props) => {
        if (user && !reset) {
          return Component ? <Component {...props} /> : children;
        } else {
          return <Redirect to={{ pathname: '/' }} />;
        }
      }}
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.any,
  children: PropTypes.node,
};

export default PrivateRoute;
