import styled from 'styled-components';
import colors from 'styles/colors';

export const Label = styled.p`
  display: flex;
  color: ${colors.gray2};
  font-weight: 500;
  font-size: ${(props) => (props.size === 'small' ? '0.813rem' : '0.825rem')};
  letter-spacing: ${(props) => (props.size === 'small' ? '0.5px' : '1px')};
  margin: 0 0 5px 0;
`;
