import React from 'react';
import PropTypes from 'prop-types';
import BasicInput from 'components/commons/Inputs/BasicInput';
import { Container, Key, Right } from './styled';

const InputRow = (props) => {
  return (
    <Container>
      <Key>{props.title}</Key>
      <Right>
        <BasicInput
          value={props.value}
          disabled={props.disabled}
          maxLength={props.maxLength}
          onChange={props.onChange}
        />
      </Right>
    </Container>
  );
};

InputRow.propTypes = {
  title: PropTypes.any,
  value: PropTypes.any,
  disabled: PropTypes.bool,
  maxLength: PropTypes.number,
  onChange: PropTypes.func,
};

export default InputRow;
