export const todayDate = () => {
  var today = new Date();
  var dd = today.getDate();
  var mm = today.getMonth() + 1;
  var yyyy = today.getFullYear();

  if (dd < 10) {
    dd = '0' + dd;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }
  return yyyy + '-' + mm + '-' + dd;
};

export const substractMonthsToTodayDate = (value) => {
  var today = new Date();
  today.setMonth(today.getMonth() - value);
  var dd = today.getDate();
  var mm = today.getMonth() + 1;
  var yyyy = today.getFullYear();

  if (dd < 10) {
    dd = '0' + dd;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }
  return yyyy + '-' + mm + '-' + dd;
};

export const stringToDate = (value) => {
  if (value) {
    // DD/MM/YY
    const dateParts = value.split('/');

    return new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
  } else {
    return '';
  }
};

export const stringToInputDate = (value) => {
  if (value) {
    // DD/MM/YY
    const dateParts = value.split('/');

    return '20' + dateParts[2] + '-' + dateParts[1] + '-' + dateParts[0];
  } else {
    return '';
  }
};
