import React from 'react';
import PropTypes from 'prop-types';
import * as styled from './styled';
import { FormattedMessage } from 'react-intl';
import Tooltips from 'components/commons/Tooltips';

const DotsTag = (props) => {
  return (
    <styled.Container>
      <styled.Tag status={props.status}>
        <Tooltips
          title={<FormattedMessage id={'table.device.state' + props.status} />}
          dots
        />
      </styled.Tag>
    </styled.Container>
  );
};

DotsTag.propTypes = {
  status: PropTypes.string,
};

export default DotsTag;
