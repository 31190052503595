import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Label from 'components/commons/Texts/Label';
import BasicInput from 'components/commons/Inputs/BasicInput';
import { FirstRowDiv, InputContainer } from './styled';

const ThirdRow = (props) => {
  return (
    <FirstRowDiv>
      <InputContainer>
        <Label value={<FormattedMessage id={'services.device.input6'} />} />
        <BasicInput
          type="date"
          value={props.startRentingDate}
          onChange={(value) =>
            props.handleChange('startRentingDate', value.target.value)
          }
        />
      </InputContainer>
      <InputContainer>
        <Label value={<FormattedMessage id={'services.device.input7'} />} />
        <BasicInput
          type="date"
          value={props.period}
          onChange={(value) => props.handleChange('period', value.target.value)}
        />
      </InputContainer>
      <InputContainer>
        <Label value={<FormattedMessage id={'services.device.input8'} />} />
        <BasicInput
          onChange={(e) => props.handleChange('endRentingDate', e.target.value)}
          value={props.endRentingDate}
          type="date"
        />
      </InputContainer>
    </FirstRowDiv>
  );
};

ThirdRow.propTypes = {
  handleChange: PropTypes.func,
  startRentingDate: PropTypes.string,
  period: PropTypes.string,
  endRentingDate: PropTypes.string,
};

export default ThirdRow;
