import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import InfoRow from 'components/commons/Texts/InfoRow';

const Info = (props) => {
  if (props.module != 0) return null;
  return (
    <>
      <InfoRow
        title={<FormattedMessage id={'info.orders.key1'} />}
        value={props.order.supplier}
      />
      <InfoRow
        title={<FormattedMessage id={'info.orders.key2'} />}
        value={props.order.supplier_order}
      />
      <InfoRow
        title={<FormattedMessage id={'info.orders.key3'} />}
        value={props.order.inserted_on}
      />
      <InfoRow
        title={<FormattedMessage id={'info.orders.key4'} />}
        value={props.order.start_date}
      />
      <InfoRow
        title={<FormattedMessage id={'info.orders.key5'} />}
        value={props.order.ending_date}
      />
      <InfoRow
        title={<FormattedMessage id={'info.orders.key6'} />}
        value={props.order.devices}
      />
      <InfoRow
        title={<FormattedMessage id={'info.orders.key7'} />}
        value={<FormattedMessage id={'orders.status' + props.order.state} />}
      />
    </>
  );
};

Info.propTypes = {
  module: PropTypes.number,
  order: PropTypes.any,
};

export default Info;
