import React from 'react';
import PropTypes from 'prop-types';
import { Button } from './styled';

const GenericButton = (props) => {
  if (!props.title) return null;
  return (
    <Button
      onClick={props.disabled ? null : props.onClick}
      theme={props.theme}
      size={props.size}
      disabled={props.disabled}
    >
      {props.title}
    </Button>
  );
};

GenericButton.propTypes = {
  title: PropTypes.any,
  onClick: PropTypes.func,
  theme: PropTypes.string,
  size: PropTypes.string,
  disabled: PropTypes.bool,
};

export default GenericButton;
