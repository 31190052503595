import React from 'react';
import PropTypes from 'prop-types';
import FirsStage from './FirstStage';
import SecondStage from './SecondStage';

const Stages = (props) => {
  return (
    <>
      <FirsStage
        stage={props.stage}
        allDevices={props.allDevices}
        setDevicesToInsert={props.setDevicesToInsert}
        devicesToInsert={props.devicesToInsert}
        orderEdit={props.orderEdit}
      />
      {props.stage === 2 && (
        <SecondStage
          stage={props.stage}
          allDevices={props.allDevices}
          setInfoDevicesToInsert={props.setInfoDevicesToInsert}
          devicesToInsert={props.devicesToInsert}
          infoDevicesToInsert={props.infoDevicesToInsert}
        />
      )}
    </>
  );
};

Stages.propTypes = {
  stage: PropTypes.number,
  allDevices: PropTypes.any,
  setDevicesToInsert: PropTypes.func,
  devicesToInsert: PropTypes.any,
  orderEdit: PropTypes.any,
  setInfoDevicesToInsert: PropTypes.func,
  infoDevicesToInsert: PropTypes.any,
};

export default Stages;
