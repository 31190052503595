import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: ${(props) => (props.dashboard ? '100%' : '80%')};
`;

export const TableContainer = styled.div`
  width: 100%;
  height: 100%;
  min-width: 460px;

  @media (max-width: 1024px) {
    min-width: 304px;
  }
`;
