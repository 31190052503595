import React from 'react';
import PropTypes from 'prop-types';
import { Container, Tag } from './styled';
import { FormattedMessage } from 'react-intl';

const OrderTag = (props) => {
  return (
    <Container>
      <Tag status={props.status}>
        <FormattedMessage id={'orders.status' + props.status} />
      </Tag>
    </Container>
  );
};

OrderTag.propTypes = {
  status: PropTypes.string,
};

export default OrderTag;
