import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import * as styled from './styled';
import FirstRow from './FirstRow';

const Inputs = (props) => {
  return (
    <styled.Wrapper>
      <styled.Body show={props.show}>
        <styled.Container>
          <FirstRow
            handleChange={props.handleChange}
            amountPackages={props.amountPackages}
            overallWeight={props.overallWeight}
          />
        </styled.Container>
        <styled.Error id={props.error}>
          <FormattedMessage id={props.error} />
        </styled.Error>
      </styled.Body>
    </styled.Wrapper>
  );
};

Inputs.propTypes = {
  error: PropTypes.string,
  handleChange: PropTypes.func,
  amountPackages: PropTypes.string,
  overallWeight: PropTypes.string,
  show: PropTypes.number,
};

export default Inputs;
