import React, { useEffect, useState } from 'react';
import Modal from './Modal';
import States from './States';
import Landing from 'components/commons/Landing';
import * as C from 'components/pages/App/constants';
import { PERMISSIONS } from 'utils/constants';
import InventoryTable from './Table';
import { TableContainer } from './styled';
import Filters from './Filters';
import { getClients } from 'store/Clients/actions';
import { useDispatch } from 'react-redux';

const Inventory = () => {
  const [openModal, setOpenModal] = useState(0);
  const [itemEdit, setItemEdit] = useState({});
  const [filter, setFilter] = useState('');
  const [flag, setFlag] = useState(false);
  const [clients, setClients] = useState('');
  const [client, setClient] = useState([]);
  const [state, setState] = useState([]);
  const [assetType, setAssetType] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getClients(setClients, '', true));
  }, []);

  const handleChange = (name, value) => {
    switch (name) {
      case 'client':
        setClient(value);
        break;
      case 'state':
        setState(value);
        break;
      case 'assetType':
        setAssetType(value);
        break;
    }
  };

  return (
    <>
      {![0, 1, 2].includes(openModal) && (
        <States
          show={openModal}
          closeModal={() => setOpenModal(0)}
          itemEdit={itemEdit}
          flag={flag}
          setFlag={setFlag}
        />
      )}
      {![0, 3].includes(openModal) && (
        <Modal
          show={openModal}
          closeModal={() => setOpenModal(0)}
          itemEdit={itemEdit}
        />
      )}
      <Landing
        redirectArray={[C.inventory_es, C.inventory_en, C.inventory_pt]}
        permission={PERMISSIONS.inventory}
        permissionAbm={PERMISSIONS.inventory_abm}
        moduleName="Inventario"
        titleId="lateral_menu.inventory"
        setOpenModal={setOpenModal}
        buttonTextId="inventory.add_device"
        filter={filter}
        setFilter={setFilter}
        hideFilter={false}
        filters={
          <Filters
            clients={clients}
            client={client}
            state={state}
            assetType={assetType}
            handleChange={handleChange}
          />
        }
      >
        <TableContainer>
          <InventoryTable
            openModal={openModal}
            setOpenModal={setOpenModal}
            filter={filter}
            setFilter={setFilter}
            setItemEdit={setItemEdit}
            flag={flag}
            setFlag={setFlag}
            client={client}
            state={state}
            assetType={assetType}
            clients={clients}
          />
        </TableContainer>
      </Landing>
    </>
  );
};

export default Inventory;
