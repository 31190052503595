import React, { useEffect } from 'react';
import history from 'utils/history';
import { useDispatch } from 'react-redux';
import { logout } from 'store/Login/actions';

const Logout = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logout());
    history.push('/');
  }, []);

  return <></>;
};

export default Logout;
