import { checkStatus, parseJSON } from 'utils/request';
import config from 'config.js';
import { selectToken } from 'store/Login/selectors';
import { setLoading } from 'store/General/actions';

export const getAssets = (deviceType, setAssets, setError) => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    setError('inventory.get_device.status1');
    const token = selectToken(getState());
    const requestURL = `${config.baseURL}/v1/product/assets?token=${token}&asset_type=${deviceType}`;
    try {
      const response = await fetch(requestURL, {
        method: 'GET',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
        },
      });
      const response_1 = await checkStatus(response);
      const response2 = await parseJSON(response_1);
      if (response2.status === '1') {
        setAssets(response2.result);
      } else {
        setError('inventory.get_device.status' + response2.status);
      }
    } catch (error) {
      setError('inventory.get_device.status99');
    }
    dispatch(setLoading(false));
  };
};

export const getDevice = (
  setSearched,
  setDeviceInfo,
  setError,
  deviceType,
  brand,
  model,
  version,
  handleChange,
) => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    setError('inventory.get_device.status1');
    const token = selectToken(getState());
    const requestURL = `${config.baseURL}/v1/product/device?token=${token}&asset_type=${deviceType}&brand=${brand}&model=${model}&version=${version}`;
    try {
      const response = await fetch(requestURL, {
        method: 'GET',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
        },
      });
      const response_1 = await checkStatus(response);
      const response2 = await parseJSON(response_1);
      if (response2.status === '1') {
        const result = response2.result;
        setDeviceInfo(result);
        setSearched(true);
        handleChange('displayName', result[0].display_name);
        handleChange('os', result[0].os);
        handleChange('osVersion', result[0].os_version);
        handleChange('screenSize', result[0].screen_size);
        handleChange('ram', result[0].ram);
        ['Desktop', 'Laptop'].includes(deviceType) &&
          handleChange('diskType', 'SDD');
        handleChange('diskSpace', result[0].disk_space);
        handleChange('secondDiskSpace', result[0].secondary_disk_space);
        handleChange('cpuModel', result[0].cpu_model);
        handleChange('cpuSpeed', result[0].cpu_speed);
        handleChange('cpuCoreCount', result[0].cpu_core_count);
        handleChange('graphicCard', result[0].graphic_card);
        handleChange('keyLang', result[0].keyboard_language);
        handleChange('benchmark', result[0].benchmark_name);
      } else {
        setError('inventory.get_device.status' + response2.status);
      }
    } catch (error) {
      setError('inventory.get_device.status99');
    }
    dispatch(setLoading(false));
  };
};

export const createDevice = (
  deviceType,
  brand,
  model,
  version,
  displayName,
  serialNumber,
  os,
  osVersion,
  comercialDescription,
  otherSpecs,
  imeiNumber,
  screenSize,
  ram,
  diskType,
  diskSpace,
  seconDiskType,
  secondDiskSpace,
  cpuModel,
  cpuSpeed,
  cpuCoreCount,
  colour,
  mdm,
  graphicCard,
  keyLang,
  benchmark,
  result,
  description,
  setInserted,
  setError,
  closeModal,
  inserting,
) => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    setError('inventory.status1');
    const token = selectToken(getState());
    const requestURL = `${config.baseURL}/v1/product/device?token=${token}`;
    try {
      const response = await fetch(requestURL, {
        method: 'POST',
        body: JSON.stringify({
          asset_type: deviceType,
          brand: brand,
          model: model,
          version: version,
          display_name: displayName,
          description: description,
          serial_number: serialNumber,
          comercial_description: comercialDescription,
          os: os,
          os_version: osVersion,
          screen_size: screenSize,
          cpu_model: cpuModel,
          cpu_speed: cpuSpeed,
          cpu_core_count: cpuCoreCount,
          ram: ram,
          disk_space: diskSpace,
          disk_type: diskType,
          secondary_disk_space: secondDiskSpace,
          secondary_disk_type: seconDiskType,
          graphic_card: graphicCard,
          keyboard_language: keyLang,
          mdm: mdm,
          colour: colour,
          benchmark_name: benchmark,
          benchmark_result: result,
          other_specs: otherSpecs,
          imei_number: imeiNumber,
          inserting: inserting,
        }),
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'content-type': 'application/json',
        },
      });
      const response_1 = await checkStatus(response);
      const response2 = await parseJSON(response_1);
      if (response2.status === '1') {
        setInserted(true);
        setTimeout(closeModal, 3500);
      } else {
        setError('inventory.status' + response2.status);
      }
    } catch (error) {
      setError('inventory.status99');
    }
    dispatch(setLoading(false));
  };
};

export const getTableDevices = (setDevices, asset, brand, serial) => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    const token = selectToken(getState());
    const requestURL = `${
      config.baseURL
    }/v1/product/inventory?token=${token}&asset_type=${asset || ''}&brand=${
      brand || ''
    }&serial_number=${serial || ''}`;
    try {
      const response = await fetch(requestURL, {
        method: 'GET',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
        },
      });
      const response_1 = await checkStatus(response);
      const response2 = await parseJSON(response_1);
      if (response2.status === '1') {
        setDevices(response2.result);
      }
      // eslint-disable-next-line no-empty
    } catch (error) {}
    dispatch(setLoading(false));
  };
};

export const updateDeviceState = (
  status,
  ItCheckPending,
  brand,
  serialNumber,
  assetType,
  closeModal,
  setFlag,
  flag,
  nif,
  oldState,
) => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    const token = selectToken(getState());
    const requestURL = `${config.baseURL}/v1/product/state?token=${token}`;
    try {
      const response = await fetch(requestURL, {
        method: 'POST',
        body: JSON.stringify({
          status: status,
          it_check_pending: ItCheckPending,
          brand: brand,
          serial_number: serialNumber,
          asset_type: assetType,
          company_nif: nif,
          old_state: oldState,
        }),
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'content-type': 'application/json',
        },
      });
      const response_1 = await checkStatus(response);
      const response2 = await parseJSON(response_1);
      if (response2.status === '1') {
        setFlag && setFlag(!flag);
        closeModal();
      }
      // eslint-disable-next-line no-empty
    } catch {}
    dispatch(setLoading(false));
  };
};
