import styled from 'styled-components';
import colors from 'styles/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  background-color: ${colors.blue4};
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  width: 300px;
`;

export const ButtonContainer = styled.div`
  margin-top: 40px;
  width: 200px;
  height: 40px;
`;

export const Image = styled.img`
  width: 100%;
`;
