import React from 'react';
import PropTypes from 'prop-types';
import FirstRow from './FirstRow';
import SecondRow from './SecondRow';

const SecondStage = (props) => {
  if (props.stage !== 2) return null;

  return (
    <>
      <FirstRow
        handleChange={props.handleChange}
        email={props.email}
        password={props.password}
        name={props.name}
        lastName={props.lastName}
        nif={props.nif}
      />
      <SecondRow
        handleChange={props.handleChange}
        role={props.role}
        nif={props.nif}
      />
    </>
  );
};

SecondStage.propTypes = {
  handleChange: PropTypes.func,
  stage: PropTypes.any,
  email: PropTypes.string,
  password: PropTypes.string,
  name: PropTypes.string,
  lastName: PropTypes.string,
  role: PropTypes.string,
  nif: PropTypes.string,
};

export default SecondStage;
