import * as T from './types';

export const setLoading = (state) => {
  return {
    type: T.SET_LOADING,
    state,
  };
};

export const setModule = (module) => {
  return {
    type: T.SET_MODULE,
    module,
  };
};

export const setDashboardFilter = (dashboardFilter) => {
  return {
    type: T.SET_DASHBOARD_FILTER,
    dashboardFilter,
  };
};
