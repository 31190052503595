import React from 'react';
import styled from 'styled-components';
import { Route, Switch, Router } from 'react-router-dom';
import * as D from './constants';
import history from 'utils/history.js';
import Login from 'components/pages/Login';
import Inventory from 'components/pages/Inventory';
import Clients from 'components/pages/Clients';
import Logout from 'components/pages/Logout';
import NotFound from 'components/pages/NotFound';
import Private from 'components/commons/PrivateRoute';
import Suppliers from 'components/pages/Suppliers';
import Orders from 'components/pages/Orders';
import Info from 'components/pages/OrdersInfo';
import Services from 'components/pages/Services';
import Shipments from 'components/pages/Shipments';
import Fulfillments from 'components/pages/Fulfillments';
import Contracts from 'components/pages/Contracts';
import { default as SInfo } from 'components/pages/SuppliersInfo';
import { default as CInfo } from 'components/pages/ClientsInfo';
import { default as SeInfo } from 'components/pages/ServicesInfo';
import { default as Annex } from 'components/pages/ServicesAnnex';
import { default as ShInfo } from 'components/pages/ShipmentsInfo';
import { default as SD } from 'components/pages/ServicesDevices';
import { default as FuInfo } from 'components/pages/FulfillmentsInfo';
import { default as IInfo } from 'components/pages/InventoryInfo';
import { default as CI } from 'components/pages/ContractsInfo';
import { default as CD } from 'components/pages/ContractsDevices';
import Devices from 'components/pages/Devices';
import Dashboard from 'components/pages/Dashboard';
import { default as DI } from 'components/pages/DashboardInfo';
import Iframe from 'components/pages/Iframe';

export const AppContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
`;

const App = () => {
  return (
    <AppContainer>
      <Router history={history}>
        <Switch>
          <Route exact path={D.login} component={Login} />
          <Private exact path={D.inventory_es} component={Inventory} />
          <Private exact path={D.inventory_en} component={Inventory} />
          <Private exact path={D.clients_es} component={Clients} />
          <Private exact path={D.clients_en} component={Clients} />
          <Private exact path={D.suppliers_es} component={Suppliers} />
          <Private exact path={D.suppliers_en} component={Suppliers} />
          <Private exact path={D.suppliers_pt} component={Suppliers} />
          <Private exact path={D.orders_es} component={Orders} />
          <Private exact path={D.orders_en} component={Orders} />
          <Private exact path={D.orders_pt} component={Orders} />
          <Private exact path={D.orders_info_es + ':id'} component={Info} />
          <Private exact path={D.orders_info_en + ':id'} component={Info} />
          <Private exact path={D.orders_info_pt + ':id'} component={Info} />
          <Private exact path={D.suppliers_info_es + ':id'} component={SInfo} />
          <Private exact path={D.suppliers_info_en + ':id'} component={SInfo} />
          <Private exact path={D.suppliers_info_pt + ':id'} component={SInfo} />
          <Private exact path={D.clients_info_es + ':id'} component={CInfo} />
          <Private exact path={D.clients_info_en + ':id'} component={CInfo} />
          <Private exact path={D.services_es} component={Services} />
          <Private exact path={D.services_en} component={Services} />
          <Private exact path={D.services_pt} component={Services} />
          <Private exact path={D.services_info_es + ':id'} component={SeInfo} />
          <Private exact path={D.services_info_en + ':id'} component={SeInfo} />
          <Private exact path={D.services_info_pt + ':id'} component={SeInfo} />
          <Private exact path={D.shipments_es} component={Shipments} />
          <Private exact path={D.shipments_en} component={Shipments} />
          <Private
            exact
            path={D.shipments_info_es + ':id'}
            component={ShInfo}
          />
          <Private
            exact
            path={D.shipments_info_en + ':id'}
            component={ShInfo}
          />
          <Private exact path={D.contracts_es} component={Contracts} />
          <Private exact path={D.contracts_en} component={Contracts} />
          <Private
            exact
            path={D.services_annex_es + ':id' + '/:num'}
            component={Annex}
          />
          <Private
            exact
            path={D.services_annex_en + ':id' + '/:num'}
            component={Annex}
          />
          <Private
            exact
            path={D.services_annex_pt + ':id' + '/:num'}
            component={Annex}
          />
          <Private
            exact
            path={D.services_devices_es + ':id' + '/:num'}
            component={SD}
          />
          <Private
            exact
            path={D.services_devices_en + ':id' + '/:num'}
            component={SD}
          />
          <Private
            exact
            path={D.services_devices_pt + ':id' + '/:num'}
            component={SD}
          />
          <Private
            exact
            path={D.contracts_annex_es + ':id' + '/:num'}
            component={CI}
          />
          <Private
            exact
            path={D.contracts_annex_en + ':id' + '/:num'}
            component={CI}
          />
          <Private
            exact
            path={D.contracts_devices_es + ':id' + '/:num'}
            component={CD}
          />
          <Private
            exact
            path={D.contracts_devices_en + ':id' + '/:num'}
            component={CD}
          />
          <Private exact path={D.device_es + ':id'} component={DI} />
          <Private exact path={D.device_en + ':id'} component={DI} />
          <Private exact path={D.form_es + ':id'} component={Iframe} />
          <Private exact path={D.form_en + ':id'} component={Iframe} />
          <Private exact path={D.form_pt + ':id'} component={Iframe} />
          <Private
            exact
            path={D.inventory_es + '/:asset/:brand/:serial'}
            component={IInfo}
          />
          <Private
            exact
            path={D.inventory_en + '/:asset/:brand/:serial'}
            component={IInfo}
          />
          <Private exact path={D.fulfillments_es} component={Fulfillments} />
          <Private exact path={D.fulfillments_en} component={Fulfillments} />
          <Private exact path={D.fulfillments_pt} component={Fulfillments} />
          <Private
            exact
            path={D.fulfillments_info_es + ':id'}
            component={FuInfo}
          />
          <Private
            exact
            path={D.fulfillments_info_en + ':id'}
            component={FuInfo}
          />
          <Private
            exact
            path={D.fulfillments_info_pt + ':id'}
            component={FuInfo}
          />
          <Private exact path={D.devices_es} component={Devices} />
          <Private exact path={D.devices_en} component={Devices} />
          <Private exact path={D.devices_pt} component={Devices} />
          <Route exact path={D.logout} component={Logout} />
          <Route exact path={D.dashboard} component={Dashboard} />
          <Route component={NotFound} />
        </Switch>
      </Router>
    </AppContainer>
  );
};
export default App;
