import styled from 'styled-components';
import colors from 'styles/colors';
import { ReactComponent as logo } from 'images/Emendu_logo.svg';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
`;

export const Wrapper = styled.div`
  padding: 50px 25px;
  width: 100%;
  max-width: 600px;
  border-radius: 8px;
  text-align: center;
  box-sizing: border-box;
  background-color: ${colors.white};

  @media (min-width: 768px) {
    padding: 50px 100px;
  }
`;

export const Image = styled(logo)`
  width: 100%;
  max-width: 150px;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    max-width: 180px;
  }
`;

export const InputContainer = styled.div`
  input {
    width: 100%;
    padding: 15px 25px;
    box-sizing: border-box;
  }
`;

export const Title = styled.h1`
  margin: 0 0 10px;
  color: #444444;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.55rem;
  letter-spacing: 0.2px;

  @media (min-width: 768px) {
    font-size: 1.5rem;
    line-height: 1.85rem;
  }
`;

export const SubTitle = styled.div`
  font-size: 12px;
  line-height: 1.25rem;
  color: #888888;

  @media (min-width: 768px) {
    font-size: 14px;
    line-height: 1.25rem;
  }

  a {
    color: #888888;

    :hover {
      text-decoration: none;
    }
  }
`;

export const Subtitle = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  height: 50px;
  margin-top: 30px;
  display: inline-block;

  @media (min-width: 768px) {
    max-width: 235px;
  }
`;

export const SubText = styled.div`
  opacity: ${(props) => (props.error || props.forget ? 100 : 0)};
  color: ${(props) => (props.error ? colors.red2 : colors.gray3)};
  margin-top: 5px;
  font-weight: 500;
  font-size: 0.8rem;
  text-align: left;
`;

export const ResetPassword = styled.div`
  cursor: pointer;
  color: ${colors.gray3};
  margin-top: 5px;
  font-weight: 500;
  font-size: 0.8rem;
  text-align: right;

  &:hover {
    color: ${colors.gray2};
  }
`;

export const InputAndSubtitle = styled.div`
  padding-top: 20px;
`;

export const Spinner = styled.div`
  display: inline-block;
  width: 40px;
  height: 40px;
  &:after {
    content: ' ';
    display: block;
    width: 16px;
    height: 16px;
    margin: 8px;
    border-radius: 50%;
    border: 4px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
