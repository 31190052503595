import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import colors from 'styles/colors';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { data, options } from './constants';
import {
  Wrapper,
  Container,
  Title,
  Body,
  BodyWrapper,
  LeftContainer,
  LeftWrapper,
  RightContainer,
  RightWrapper,
  Row,
  RowLeft,
  Icon,
  RowRight,
} from './styled';

ChartJS.register(ArcElement, Tooltip, Legend);

const DonutChart = (props) => {
  return (
    <Wrapper>
      <Container>
        <Title>
          <FormattedMessage id={'state.title'} />
        </Title>
        <Body>
          <BodyWrapper>
            <LeftContainer>
              <LeftWrapper>
                <Doughnut data={data(props.amount)} options={options()} />
              </LeftWrapper>
            </LeftContainer>
            <RightContainer>
              <RightWrapper>
                <Row>
                  <RowLeft>
                    <Icon color={colors.green10} />
                    <FormattedMessage id={'state.active'} />
                  </RowLeft>
                  <RowRight>{props.amount['999']}</RowRight>
                </Row>
                <Row>
                  <RowLeft>
                    <Icon color={colors.yellow5} />
                    <FormattedMessage id={'state.pending'} />
                  </RowLeft>
                  <RowRight>{props.amount['998']}</RowRight>
                </Row>
                <Row>
                  <RowLeft>
                    <Icon color={colors.gray13} />
                    <FormattedMessage id={'state.inactive'} />
                  </RowLeft>
                  <RowRight>{props.amount['997']}</RowRight>
                </Row>
              </RightWrapper>
            </RightContainer>
          </BodyWrapper>
        </Body>
      </Container>
    </Wrapper>
  );
};

DonutChart.propTypes = {
  amount: PropTypes.object,
};

export default DonutChart;
