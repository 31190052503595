import React, { useState, useEffect } from 'react';
import Table from 'components/commons/Table';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { getShipments } from 'store/Shipments/actions';
import * as C from './constants';
import { stringToInputDate } from 'utils/functions/DateUtils';
import { selectUser } from 'store/Login/selectors';
import { useSelector } from 'react-redux';

const ShipmentsTable = (props) => {
  const dispatch = useDispatch();
  const [shipments, setShipments] = useState(null);
  const user = useSelector(selectUser);

  useEffect(() => {
    if (!props.openModal) {
      setShipments(null);
      dispatch(getShipments(setShipments));
      props.setFilter('');
    }
  }, [props.openModal]);

  const handleFilter = () => {
    if (props.filter) {
      let array = [];

      shipments.forEach((row) => {
        if (
          row.shipment_id
            .toString()
            .toUpperCase()
            .indexOf(props.filter.toUpperCase()) > -1 ||
          row.company_name.toUpperCase().indexOf(props.filter.toUpperCase()) >
            -1
        ) {
          array.push({
            shipment_id: row.shipment_id,
            company_name: row.company_name,
            receiver_name: row.receiver_name,
            estimated_date: row.estimated_date,
            actual_date: row.actual_date,
            amount_devices: row.amount_devices,
            amount_ready_devices: row.amount_ready_devices,
            status: row.status,
          });
        }
      });
      return array;
    }
  };

  const handleArray = (shipment) => {
    return {
      amount_devices: shipment.amount_devices,
      amount_ready_devices: shipment.amount_ready_devices,
      city: shipment.city,
      company_name: shipment.company_name,
      company_nif: shipment.company_nif,
      country: shipment.country,
      devices: shipment.devices,
      door: shipment.door,
      estimated_date: stringToInputDate(shipment.estimated_date),
      extra_requests: shipment.extra_requests,
      floor: shipment.floor,
      mail_label_url: shipment.mail_label_url,
      number: shipment.number,
      phone_number: shipment.phone_number,
      actual_date: stringToInputDate(shipment.actual_date),
      receiver_name: shipment.receiver_name,
      receiver_mail: shipment.receiver_mail,
      shipment_id: shipment.shipment_id,
      status: shipment.status,
      stair: shipment.stair,
      street: shipment.street,
      tracking_link: shipment.tracking_link,
      zip_code: shipment.zip_code,
    };
  };

  // Add Devices
  const handleAdd = (shipment) => {
    props.setShipmentEdit({
      amount_devices: shipment.amount_ready_devices,
      devices: shipment.amount_devices,
      shipment_id: shipment.shipment_id,
      company_nif: shipment.company_nif,
    });
    props.setOpenModal(4);
  };

  const handleEdit = (row) => {
    props.setShipmentEdit(handleArray(row));
    props.setOpenModal(2);
  };

  // Change Status
  const handleState = (row) => {
    props.setShipmentEdit(handleArray(row));
    props.setOpenModal(3);
  };

  if (!shipments) return null;
  return (
    <Table
      columns={C.columns(handleEdit, user, handleAdd, handleState)}
      data={props.filter ? handleFilter() : shipments}
    />
  );
};

ShipmentsTable.propTypes = {
  openModal: PropTypes.number,
  setShipmentEdit: PropTypes.func,
  setOpenModal: PropTypes.func,
  filter: PropTypes.string,
  setFilter: PropTypes.func,
};

export default ShipmentsTable;
