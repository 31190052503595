import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import InfoRow from 'components/commons/Texts/InfoRow';

const Info = (props) => {
  return (
    <>
      <InfoRow
        title={
          <FormattedMessage
            id={
              props.clientView
                ? 'services.devices.key21'
                : 'services.devices.key1'
            }
          />
        }
        value={
          props.clientView
            ? props.info.display_name
            : props.info.comercial_description
        }
      />
      <InfoRow
        title={<FormattedMessage id={'services.devices.key2'} />}
        value={props.info.serial_number}
      />
      <InfoRow
        title={<FormattedMessage id={'services.devices.key3'} />}
        value={props.info.price}
      />
      <InfoRow
        title={<FormattedMessage id={'services.devices.key4'} />}
        value={props.info.guarantee}
      />
      <InfoRow
        title={<FormattedMessage id={'services.devices.key5'} />}
        value={props.info.discount}
      />
      <InfoRow
        title={<FormattedMessage id={'services.devices.key6'} />}
        value={props.info.ship_date}
      />
      <InfoRow
        title={<FormattedMessage id={'services.devices.key7'} />}
        value={props.info.tracking_number}
      />
      <InfoRow
        title={<FormattedMessage id={'services.devices.key8'} />}
        value={props.info.start_renting_date}
      />
      <InfoRow
        title={<FormattedMessage id={'services.devices.key9'} />}
        value={props.info.end_renting_date}
      />
      <InfoRow
        title={<FormattedMessage id={'services.devices.key10'} />}
        value={props.info.end_annex_date}
      />
      <InfoRow
        title={<FormattedMessage id={'services.devices.key11'} />}
        value={props.info.description}
      />
      <InfoRow
        title={<FormattedMessage id={'services.devices.key12'} />}
        value={
          props.info.status ? (
            <FormattedMessage
              id={
                'device.state' +
                (props.info.end_renting_date ? '999' : props.info.status)
              }
            />
          ) : (
            ''
          )
        }
      />
    </>
  );
};

Info.propTypes = {
  info: PropTypes.any,
  clientView: PropTypes.bool,
};

export default Info;
