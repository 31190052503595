import styled from 'styled-components';
import colors from 'styles/colors';

export const Input = styled.input`
  display: flex;
  height: 100%;
  width: 100%;
  border-radius: 8px;
  border: 1px solid ${colors.gray6};
  border: ${(props) =>
    props.error ? '1px solid' + colors.red2 : '1px solid' + colors.gray6};
  font-family: Poppins;
  font-size: 1.1rem;
  font-weight: 500;
  padding: 0;
  box-sizing: border-box;
  padding: 0 10px;
  &:focus {
    outline: ${(props) =>
      props.error ? '1px solid' + colors.red2 : '2px solid' + colors.blue5};
  }
`;
