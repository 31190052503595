import React from 'react';
import PropTypes from 'prop-types';
import { LabelDiv } from './styled';

const Label = (props) => {
  return <LabelDiv> {props.value} </LabelDiv>;
};

Label.propTypes = {
  value: PropTypes.any,
};

export default Label;
