import React from 'react';
import PropTypes from 'prop-types';
import TableIconsRow from 'components/commons/Icons/TableIconsRow/index.js';
import { FormattedMessage } from 'react-intl';
import {
  Row,
  RowWrapper,
  RowContainer,
  LeftRow,
  RightRow,
  Text,
} from 'components/commons/Accordion/Generic/styled.js';

const AccordionChild = (props) => {
  return (
    <>
      {props.childs && props.childs.length > 0 && (
        <Row header>
          <RowContainer>
            <RowWrapper>
              <LeftRow>
                <Text bold width={'4%'}>
                  <FormattedMessage id={'accordion2.header1'} />
                </Text>
                <Text bold width={'50%'}>
                  <FormattedMessage
                    id={
                      props.clientView
                        ? 'accordion2.header2'
                        : 'accordion2.header6'
                    }
                  />
                </Text>
                <Text bold width={'19%'}>
                  <FormattedMessage id={'accordion2.header3'} />
                </Text>
                <Text bold width={'14%'}>
                  <FormattedMessage id={'accordion2.header4'} />
                </Text>
                <Text bold width={'14%'}>
                  <FormattedMessage id={'accordion2.header5'} />
                </Text>
              </LeftRow>
            </RowWrapper>
          </RowContainer>
        </Row>
      )}
      {props.childs &&
        props.childs.map((index, i) => {
          return (
            <Row key={i} i={i}>
              <RowContainer>
                <RowWrapper>
                  <LeftRow>
                    <Text width={'4%'}>{i + 1}</Text>
                    <Text width={'50%'}>
                      {props.clientView
                        ? index.display_name
                        : index.comercial_description}
                    </Text>
                    <Text width={'19%'}>{index.serial_number}</Text>
                    <Text width={'14%'}>{index.start_renting_date}</Text>
                    <Text width={'14%'}>
                      {index.status && (
                        <FormattedMessage
                          id={
                            'device.state' +
                            (index.end_renting_date ? '999' : index.status)
                          }
                        />
                      )}
                    </Text>
                  </LeftRow>
                  <RightRow>
                    <TableIconsRow
                      onEdit={
                        props.onEditChild
                          ? () => props.onEditChild(index)
                          : null
                      }
                      onAuxClick={() => props.onInfoChild(index.annex_id, true)}
                    />
                  </RightRow>
                </RowWrapper>
              </RowContainer>
            </Row>
          );
        })}
    </>
  );
};

AccordionChild.propTypes = {
  childs: PropTypes.any,
  onEditChild: PropTypes.func,
  onInfoChild: PropTypes.func,
  status: PropTypes.string,
  clientView: PropTypes.bool,
};

export default AccordionChild;
