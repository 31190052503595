import React from 'react';
import DevicesTag from 'components/commons/Tags/DevicesTag';

export const COLUMNS = [
  {
    name: 'display_name',
    label: 'Display Name',
  },
  {
    name: 'serial_number',
    label: 'Serial Number',
  },
  {
    name: 'comercial_description',
    label: 'Comercial Description',
  },
  {
    name: 'status',
    label: 'State',
    options: {
      customBodyRender: (row) => {
        return row && <DevicesTag status={row} />;
      },
    },
  },
];
