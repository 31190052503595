import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Label from 'components/commons/Texts/Label';
import { ThirdRowDiv, InputContainer } from './styled';
import BasicInput from 'components/commons/Inputs/BasicInput';
import { removeFirstWhiteSpace } from 'utils/functions/general';

const ThirdRow = (props) => {
  return (
    <ThirdRowDiv>
      <InputContainer>
        <Label value={<FormattedMessage id={'inventory.input9'} />} />
        <BasicInput
          disabled
          maxLength={300}
          value={props.comercialDescription}
        />
      </InputContainer>
      <InputContainer>
        <Label value={<FormattedMessage id={'inventory.input10'} />} />
        <BasicInput
          disabled={!props.searched}
          maxLength={300}
          onChange={(event) =>
            props.handleChange(
              'otherSpecs',
              removeFirstWhiteSpace(event.target.value),
            )
          }
          value={props.otherSpecs}
        />
      </InputContainer>
    </ThirdRowDiv>
  );
};

ThirdRow.propTypes = {
  handleChange: PropTypes.func,
  deviceType: PropTypes.string,
  searched: PropTypes.bool,
  deviceInfo: PropTypes.any,
  handleDuplicated: PropTypes.func,
  comercialDescription: PropTypes.string,
  otherSpecs: PropTypes.string,
  onInputChange: PropTypes.func,
};

export default ThirdRow;
