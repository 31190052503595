import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import GenericButton from 'components/commons/Buttons/GenericButton';
import Check from 'images/check_no_background.png';
import Inputs from './Inputs';
import { createShipment } from 'store/Shipments/actions';
import { getCompanies } from 'store/Shipments/actions';
import {
  Body,
  CancelButton,
  Centered,
  ConfirmButton,
  Container,
  Icon,
  IconContainer,
  InsertedContainer,
  LastRow,
  Title,
  Wrapper,
} from './styled';

const Modal = (props) => {
  const [inserted, setInserted] = useState(false);
  const [error, setError] = useState('shipments.status1');
  const [company, setCompany] = useState(
    props.show === 2 ? props.shipmentEdit.company_nif : null,
  );
  const defaultCompany =
    props.show === 2
      ? [
          {
            value: props.shipmentEdit.company_nif,
            label: props.shipmentEdit.company_name,
          },
        ]
      : [];
  const [allCompanies, setAllCompanies] = useState(
    props.show === 2
      ? [
          {
            id: props.shipmentEdit.company_nif,
            name: props.shipmentEdit.company_name,
          },
        ]
      : [],
  );
  const [shipmentId, setShipmentId] = useState(
    props.show === 2 ? props.shipmentEdit.shipment_id : '',
  );
  const [shippingDate, setShippingDate] = useState(
    props.show === 2 ? props.shipmentEdit.estimated_date : '',
  );
  const [actualDate, setActualDate] = useState(
    props.show === 2 ? props.shipmentEdit.actual_date : '',
  );
  const [amountDevices, setAmounDevices] = useState(
    props.show === 2 ? props.shipmentEdit.amount_devices : '',
  );
  const [receiverMail, setReceiverMail] = useState(
    props.show === 2 ? props.shipmentEdit.receiver_mail : '',
  );
  const [receiverName, setReceiverName] = useState(
    props.show === 2 ? props.shipmentEdit.receiver_name : '',
  );
  const [street, setStreet] = useState(
    props.show === 2 ? props.shipmentEdit.street : '',
  );
  const [number, setNumber] = useState(
    props.show === 2 ? props.shipmentEdit.number : '',
  );
  const [stair, setStair] = useState(
    props.show === 2 ? props.shipmentEdit.stair : '',
  );
  const [door, setDoor] = useState(
    props.show === 2 ? props.shipmentEdit.door : '',
  );
  const [floor, setFloor] = useState(
    props.show === 2 ? props.shipmentEdit.floor : '',
  );
  const [zipCode, setZipCode] = useState(
    props.show === 2 ? props.shipmentEdit.zip_code : '',
  );
  const [city, setCity] = useState(
    props.show === 2 ? props.shipmentEdit.city : '',
  );
  const [country, setCountry] = useState(
    props.show === 2 ? props.shipmentEdit.country : '',
  );
  const [phoneNumber, setPhoneNumber] = useState(
    props.show === 2 ? props.shipmentEdit.phone_number : '',
  );
  const [extraRequests, setExtraRequests] = useState(
    props.show === 2 ? props.shipmentEdit.extra_requests : '',
  );
  const [trackingLink, setTrackingLink] = useState(
    props.show === 2 ? props.shipmentEdit.tracking_link : '',
  );
  const amountDevicesCharged =
    props.show === 2 ? props.shipmentEdit.amount_ready_devices : '';
  const [mailLabelUrl, setMailLabelUrl] = useState(
    props.show === 2 ? props.shipmentEdit.mail_label_url : '',
  );

  const dispatch = useDispatch();

  const handleChange = (name, value) => {
    switch (name) {
      case 'company':
        setCompany(value);
        break;
      case 'shipmentId':
        setShipmentId(value);
        break;
      case 'shippingDate':
        setShippingDate(value);
        break;
      case 'actualDate':
        setActualDate(value);
        break;
      case 'amountDevices':
        setAmounDevices(value);
        break;
      case 'receiverMail':
        setReceiverMail(value);
        break;
      case 'receiverName':
        setReceiverName(value);
        break;
      case 'street':
        setStreet(value);
        break;
      case 'number':
        setNumber(value);
        break;
      case 'stair':
        setStair(value);
        break;
      case 'door':
        setDoor(value);
        break;
      case 'floor':
        setFloor(value);
        break;
      case 'zipCode':
        setZipCode(value);
        break;
      case 'city':
        setCity(value);
        break;
      case 'country':
        setCountry(value);
        break;
      case 'phoneNumber':
        setPhoneNumber(value);
        break;
      case 'extraRequests':
        setExtraRequests(value);
        break;
      case 'trackingLink':
        setTrackingLink(value);
        break;
      case 'mailLabelUrl':
        setMailLabelUrl(value);
        break;
    }
  };

  const submit = () => {
    if (props.show != 3) {
      if (validateFields()) {
        dispatch(
          createShipment(
            setError,
            company,
            street,
            number,
            floor,
            door,
            stair,
            city,
            zipCode,
            country,
            receiverName,
            receiverMail,
            phoneNumber,
            extraRequests,
            amountDevices,
            shippingDate,
            trackingLink,
            shipmentId,
            actualDate,
            mailLabelUrl,
            setInserted,
            props.show === 1,
            props.closeModal,
          ),
        );
      }
    }
  };

  const validateFields = () => {
    if (!company) {
      setError('shipments.status2');
      return false;
    }
    if (!street) {
      setError('shipments.status3');
      return false;
    }
    if (!number) {
      setError('shipments.status4');
      return false;
    }
    if (!city) {
      setError('shipments.status8');
      return false;
    }
    if (!zipCode) {
      setError('shipments.status9');
      return false;
    }
    if (!country) {
      setError('shipments.status10');
      return false;
    }
    if (!receiverName) {
      setError('shipments.status11');
      return false;
    }
    if (!receiverMail) {
      setError('shipments.status12');
      return false;
    }
    if (!phoneNumber) {
      setError('shipments.status13');
      return false;
    }
    if (!amountDevices || amountDevices < 1) {
      setError('shipments.status15');
      return false;
    }
    if (!shippingDate) {
      setError('shipments.status16');
      return false;
    }

    return true;
  };

  useEffect(() => {
    function handleEscapeKey(event) {
      if (event.code === 'Escape') {
        props.closeModal();
      }
    }

    props.show === 1 && dispatch(getCompanies(setAllCompanies));

    document.addEventListener('keydown', handleEscapeKey);
    return () => document.removeEventListener('keydown', handleEscapeKey);
  }, []);

  return (
    <>
      <Container />
      <Centered inserted={inserted}>
        {inserted ? (
          <InsertedContainer>
            <IconContainer>
              <Icon src={Check} alt={'icon'} />
            </IconContainer>
            <Title>
              <FormattedMessage id={'shipments.confirm' + props.show} />
            </Title>
          </InsertedContainer>
        ) : (
          <>
            <Wrapper>
              <Title isDeleting={props.show === 3}>
                <FormattedMessage id={'shipments.title' + props.show} />
              </Title>
              <Body>
                <Inputs
                  allCompanies={allCompanies}
                  amountDevices={amountDevices}
                  city={city}
                  company={company}
                  country={country}
                  defaultCompany={defaultCompany}
                  door={door}
                  error={error}
                  extraRequests={extraRequests}
                  floor={floor}
                  handleChange={handleChange}
                  // mailLabelUrl={mailLabelUrl}
                  number={number}
                  phoneNumber={phoneNumber}
                  actualDate={actualDate}
                  receiverMail={receiverMail}
                  receiverName={receiverName}
                  shippingDate={shippingDate}
                  show={props.show}
                  stair={stair}
                  street={street}
                  trackingLink={trackingLink}
                  zipCode={zipCode}
                  setError={setError}
                  /* shipmentId={shipmentId} */
                />
                <LastRow>
                  <CancelButton>
                    <GenericButton
                      theme="light"
                      title={<FormattedMessage id={'shipments.cancel'} />}
                      onClick={props.closeModal}
                    />
                  </CancelButton>
                  <ConfirmButton>
                    <GenericButton
                      theme="dark"
                      title={
                        <FormattedMessage
                          id={'shipments.button' + props.show}
                        />
                      }
                      onClick={submit}
                      disabled={
                        props.show != 3 &&
                        (!amountDevices ||
                          !city ||
                          !company ||
                          !country ||
                          !number ||
                          !phoneNumber ||
                          !receiverMail ||
                          !receiverName ||
                          !shippingDate ||
                          !street ||
                          !zipCode ||
                          amountDevices < amountDevicesCharged)
                      }
                    />
                  </ConfirmButton>
                </LastRow>
              </Body>
            </Wrapper>
          </>
        )}
      </Centered>
    </>
  );
};

Modal.propTypes = {
  closeModal: PropTypes.func,
  shipmentEdit: PropTypes.any,
  show: PropTypes.number,
};

export default Modal;
