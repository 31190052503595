import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { ITEMS } from './constants';
import {
  Wrapper,
  Container,
  Title,
  Row,
  RowLeftSide,
  RowRightSide,
  RowContainer,
  IconContainer,
} from './styled';

const StockList = (props) => {
  return (
    <Wrapper>
      <Container>
        <Title>
          <FormattedMessage id={'stockList.title'} />
        </Title>
        {ITEMS.map((item, i) => {
          return (
            <Row key={i}>
              <RowContainer>
                <RowLeftSide>
                  <IconContainer>{item.icon}</IconContainer>
                  {item.title}
                </RowLeftSide>
                <RowRightSide>
                  {item.accesor === 'Laptop'
                    ? props.amount.Desktop + props.amount.Laptop
                    : props.amount[item.accesor]}
                </RowRightSide>
              </RowContainer>
            </Row>
          );
        })}
      </Container>
    </Wrapper>
  );
};

StockList.propTypes = {
  amount: PropTypes.object,
};

export default StockList;
