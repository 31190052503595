/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import MainLayout from 'components/commons/MainLayout';
import { redirect } from 'utils/functions/redirect_lang';
import * as D from 'components/pages/App/constants';
import { selectUser } from 'store/Login/selectors';
import GenericButton from 'components/commons/Buttons/GenericButton';
import Search from 'images/search.png';
import Filter from 'images/filter.png';
import history from 'utils/history';
import { setModule } from 'store/General/actions';
import * as styled from './styled';
import PropTypes from 'prop-types';

const Landing = (props) => {
  const user = useSelector(selectUser);
  const intl = useIntl();
  const dispatch = useDispatch();
  const [filtersOpen, setFiltersOpen] = useState(false);

  const handleClick = () => props.setBlurred((prev) => !prev);

  useEffect(() => {
    if (user.permissions[props.permission]) {
      dispatch(setModule(props.moduleName));
      redirect(props.redirectArray);
    } else {
      history.replace(
        props.permission === 'dashboard'
          ? D.contracts_es
          : D.defaultRedirectNoP,
      );
    }
  }, []);

  return (
    <>
      <MainLayout>
        <styled.Wrapper>
          {!props.empty && (
            <styled.Title historic={props.historic}>
              <FormattedMessage id={props.titleId} />
            </styled.Title>
          )}
          {props.historic && (
            <styled.SubtitleContainer>
              {props.historic.map((index, position) => {
                return position === props.historic.length - 1 ? (
                  <styled.ActualSubtitle key={position}>
                    {'>'} {index.subtitle}
                  </styled.ActualSubtitle>
                ) : (
                  <styled.PreviousSubtitle
                    key={position}
                    onClick={() => history.push(index.click)}
                  >
                    {![0, props.historic.length - 1].includes(position) && '> '}
                    {index.subtitle}
                  </styled.PreviousSubtitle>
                );
              })}
            </styled.SubtitleContainer>
          )}
          {!props.empty ? (
            <styled.Container historic={props.historic}>
              <styled.FirstRow>
                <styled.Filters>
                  <styled.IconContainer>
                    <styled.Icon src={Search} alt={'icon'} />
                  </styled.IconContainer>
                  <styled.Input
                    value={props.filter}
                    onChange={(e) => props.setFilter(e.target.value)}
                    placeholder={intl.formatMessage({
                      id: 'defaultPlaceholder',
                    })}
                  />
                  <styled.IconContainerFilter
                    right={props.hideFilter}
                    onClick={() => setFiltersOpen(!filtersOpen)}
                  >
                    <styled.Icon src={Filter} alt={'icon'} />
                  </styled.IconContainerFilter>
                  {filtersOpen && (
                    <>
                      <styled.FilterContainer
                        onClick={() => setFiltersOpen(false)}
                      />
                      <styled.FilterCentered>
                        {props.filters}
                      </styled.FilterCentered>
                    </>
                  )}
                </styled.Filters>
                {user.permissions[props.permissionAbm] && props.buttonTextId && (
                  <styled.ButtonContainer blur={props.buttonBlur}>
                    {props.buttonBlur && (
                      <styled.BlurContainer>
                        <GenericButton
                          theme="light"
                          title={<FormattedMessage id={props.buttonBlur} />}
                          onClick={handleClick}
                          size="small"
                        />
                      </styled.BlurContainer>
                    )}
                    <GenericButton
                      theme="dark"
                      title={<FormattedMessage id={props.buttonTextId} />}
                      onClick={() => props.setOpenModal(1)}
                      size="small"
                    />
                  </styled.ButtonContainer>
                )}
              </styled.FirstRow>
              {props.children}
            </styled.Container>
          ) : (
            <>{props.children}</>
          )}
        </styled.Wrapper>
      </MainLayout>
    </>
  );
};

Landing.propTypes = {
  redirectArray: PropTypes.array,
  permission: PropTypes.string,
  permissionAbm: PropTypes.string,
  moduleName: PropTypes.string,
  titleId: PropTypes.string,
  setOpenModal: PropTypes.func,
  children: PropTypes.node,
  buttonTextId: PropTypes.string,
  buttonBlur: PropTypes.string,
  filter: PropTypes.string,
  setFilter: PropTypes.func,
  historic: PropTypes.any,
  empty: PropTypes.any,
  setBlurred: PropTypes.func,
  blurred: PropTypes.bool,
  hideFilter: PropTypes.bool,
  filters: PropTypes.node,
};

Landing.defaultProps = {
  hideFilter: true,
};

export default Landing;
