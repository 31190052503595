import styled from 'styled-components';
import colors from 'styles/colors';

export const LabelDiv = styled.div`
  display: flex;
  color: ${colors.gray1};
  font-weight: 500;
  font-size: 0.813rem;
  letter-spacing: 0.5px;
  margin-bottom: 7px;
`;
