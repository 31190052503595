import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import GenericButton from 'components/commons/Buttons/GenericButton';
import Check from 'images/check_no_background.png';
import Inputs from './Inputs';
import { createOrder, deleteOrder } from 'store/Orders/actions';
import { getSuppliers } from 'store/Suppliers/actions';
import {
  Container,
  Centered,
  Wrapper,
  Title,
  Body,
  LastRow,
  ConfirmButton,
  CancelButton,
  InsertedContainer,
  IconContainer,
  Icon,
} from './styled';

const Modal = (props) => {
  const [inserted, setInserted] = useState(false);
  const [error, setError] = useState('orders.status1');
  const [supplier, setSupplier] = useState(
    props.show === 2 ? props.orderEdit.supplier_id : null,
  );
  const defaultSupplier =
    props.show === 2
      ? [
          {
            value: props.orderEdit.supplier_id,
            label: props.orderEdit.supplier,
          },
        ]
      : [];
  const [allSuppliers, setAllSuppliers] = useState(
    props.show === 2
      ? [{ id: props.orderEdit.supplier_id, name: props.orderEdit.supplier }]
      : [],
  );
  const [orderNumber, setOrderNumber] = useState(
    props.show === 2 ? props.orderEdit.supplier_order : '',
  );
  const [startDate, setStartDate] = useState(
    props.show === 2 ? props.orderEdit.start_date : '',
  );
  const [endingDate, setEndingDate] = useState(
    props.show === 2 ? props.orderEdit.ending_date : '',
  );
  const [amountDevices, setAmounDevices] = useState(
    props.show === 2 ? props.orderEdit.devices : '',
  );
  const amountDevicesCharged =
    props.show === 2 ? props.orderEdit.amount_devices : '';

  const dispatch = useDispatch();

  const handleChange = (name, value) => {
    switch (name) {
      case 'supplier':
        setSupplier(value);
        break;
      case 'orderNumber':
        setOrderNumber(value);
        break;
      case 'startDate':
        setStartDate(value);
        break;
      case 'endingDate':
        setEndingDate(value);
        break;
      case 'amountDevices':
        setAmounDevices(value);
        break;
    }
  };

  const submit = () => {
    if (props.show != 3) {
      if (validateFields()) {
        dispatch(
          createOrder(
            setError,
            supplier,
            orderNumber,
            startDate,
            endingDate,
            setInserted,
            props.show === 1,
            props.closeModal,
            amountDevices,
          ),
        );
      }
    } else {
      dispatch(deleteOrder(props.orderEdit, setInserted, props.closeModal));
    }
  };

  const validateFields = () => {
    if (!supplier) {
      setError('orders.status2');
      return false;
    }
    if (!startDate) {
      setError('orders.status3');
      return false;
    }
    if (!orderNumber) {
      setError('orders.status4');
      return false;
    }
    if (!endingDate) {
      setError('orders.status5');
      return false;
    }
    if (!amountDevices || amountDevices < 1) {
      setError('orders.status10');
      return false;
    }

    return true;
  };

  useEffect(() => {
    function handleEscapeKey(event) {
      if (event.code === 'Escape') {
        props.closeModal();
      }
    }

    props.show === 1 && dispatch(getSuppliers(setAllSuppliers));

    document.addEventListener('keydown', handleEscapeKey);
    return () => document.removeEventListener('keydown', handleEscapeKey);
  }, []);

  return (
    <>
      <Container />
      <Centered inserted={inserted}>
        {inserted ? (
          <InsertedContainer>
            <IconContainer>
              <Icon src={Check} alt={'icon'} />
            </IconContainer>
            <Title>
              <FormattedMessage id={'orders.confirm' + props.show} />
            </Title>
          </InsertedContainer>
        ) : (
          <>
            <Wrapper>
              <Title isDeleting={props.show === 3}>
                <FormattedMessage id={'orders.title' + props.show} />
              </Title>
              <Body>
                <Inputs
                  error={error}
                  handleChange={handleChange}
                  supplier={supplier}
                  orderNumber={orderNumber}
                  startDate={startDate}
                  endingDate={endingDate}
                  allSuppliers={allSuppliers}
                  amountDevices={amountDevices}
                  show={props.show}
                  defaultSupplier={defaultSupplier}
                />
                <LastRow>
                  <CancelButton>
                    <GenericButton
                      theme="light"
                      title={<FormattedMessage id={'orders.cancel'} />}
                      onClick={props.closeModal}
                    />
                  </CancelButton>
                  <ConfirmButton>
                    <GenericButton
                      theme="dark"
                      title={
                        <FormattedMessage id={'orders.button' + props.show} />
                      }
                      onClick={submit}
                      disabled={
                        props.show != 3 &&
                        (!supplier ||
                          !orderNumber ||
                          !startDate ||
                          !endingDate ||
                          !amountDevices ||
                          amountDevices < amountDevicesCharged)
                      }
                    />
                  </ConfirmButton>
                </LastRow>
              </Body>
            </Wrapper>
          </>
        )}
      </Centered>
    </>
  );
};

Modal.propTypes = {
  closeModal: PropTypes.func,
  show: PropTypes.number,
  orderEdit: PropTypes.any,
};

export default Modal;
