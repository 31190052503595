import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import InfoRow from 'components/commons/Texts/InfoRow';

const Info = (props) => {
  if (props.module != 0) return null;
  return (
    <>
      <InfoRow
        title={<FormattedMessage id={'info.suppliers.key1'} />}
        value={props.supplier.name}
      />
      <InfoRow
        title={<FormattedMessage id={'info.suppliers.key2'} />}
        value={props.supplier.discount + ' %'}
      />
      <InfoRow
        title={<FormattedMessage id={'info.suppliers.key3'} />}
        value={props.supplier.mail}
      />
      <InfoRow
        title={<FormattedMessage id={'info.suppliers.key4'} />}
        value={props.supplier.phone_number}
      />
    </>
  );
};

Info.propTypes = {
  module: PropTypes.number,
  supplier: PropTypes.any,
};

export default Info;
