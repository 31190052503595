import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import InfoRow from 'components/commons/Texts/InfoRow';
import { aT1, aT2, aT3, aT4, aT5, aT6 } from 'utils/constants';

const Info = (props) => {
  if (props.module != 0 || !props.device) return null;
  const asset = props.device.asset_type;

  return (
    <>
      <InfoRow
        title={<FormattedMessage id={'inventory.input1_min'} />}
        value={asset}
      />
      <InfoRow
        title={<FormattedMessage id={'inventory.input2_min'} />}
        value={props.device.brand}
      />
      <InfoRow
        title={<FormattedMessage id={'inventory.input3_min'} />}
        value={props.device.model}
      />
      <InfoRow
        title={<FormattedMessage id={'inventory.input4_min'} />}
        value={props.device.version}
      />
      <InfoRow
        title={<FormattedMessage id={'inventory.input5_min'} />}
        value={props.device.display_name}
      />
      <InfoRow
        title={<FormattedMessage id={'inventory.input6_min'} />}
        value={props.device.serial_number}
      />
      {![aT5, aT6].includes(asset) && (
        <>
          <InfoRow
            title={<FormattedMessage id={'inventory.input7_min'} />}
            value={props.device.os}
          />
          <InfoRow
            title={<FormattedMessage id={'inventory.input8_min'} />}
            value={props.device.os_version}
          />
        </>
      )}
      <InfoRow
        title={<FormattedMessage id={'inventory.input9_min'} />}
        value={props.device.comercial_description}
      />
      <InfoRow
        title={<FormattedMessage id={'inventory.input10_min'} />}
        value={props.device.other_specs}
      />
      {[aT3, aT4].includes(asset) && (
        <>
          <InfoRow
            title={<FormattedMessage id={'inventory.input11_min'} />}
            value={props.device.imei_number}
          />
        </>
      )}
      {![aT6].includes(asset) && (
        <>
          <InfoRow
            title={<FormattedMessage id={'inventory.input12_min'} />}
            value={props.device.screen_size}
          />
        </>
      )}
      {![aT5, aT6].includes(asset) && (
        <>
          <InfoRow
            title={<FormattedMessage id={'inventory.input13_min'} />}
            value={props.device.ram}
          />
          <InfoRow
            title={<FormattedMessage id={'inventory.input15_min'} />}
            value={props.device.disk_space}
          />
        </>
      )}
      {[aT1, aT2].includes(asset) && (
        <>
          <InfoRow
            title={<FormattedMessage id={'inventory.input14_min'} />}
            value={props.device.disk_type}
          />
          <InfoRow
            title={<FormattedMessage id={'inventory.input17_min'} />}
            value={props.device.secondary_disk_space}
          />
          <InfoRow
            title={<FormattedMessage id={'inventory.input16_min'} />}
            value={props.device.secondary_disk_type}
          />
          <InfoRow
            title={<FormattedMessage id={'inventory.input18_min'} />}
            value={props.device.cpu_model}
          />
          <InfoRow
            title={<FormattedMessage id={'inventory.input19_min'} />}
            value={props.device.cpu_speed}
          />
          <InfoRow
            title={<FormattedMessage id={'inventory.input20_min'} />}
            value={props.device.cpu_core_count}
          />
        </>
      )}
      {![aT5, aT6].includes(asset) && (
        <>
          <InfoRow
            title={<FormattedMessage id={'inventory.input21_min'} />}
            value={props.device.colour}
          />
          <InfoRow
            title={<FormattedMessage id={'inventory.input22_min'} />}
            value={props.device.mdm}
          />
        </>
      )}
      {[aT1, aT2].includes(asset) && (
        <>
          <InfoRow
            title={<FormattedMessage id={'inventory.input23_min'} />}
            value={props.device.graphic_card}
          />
          <InfoRow
            title={<FormattedMessage id={'inventory.input24_min'} />}
            value={props.device.keyboard_language}
          />
        </>
      )}
      {![aT5, aT6].includes(asset) && (
        <>
          <InfoRow
            title={<FormattedMessage id={'inventory.input25_min'} />}
            value={props.device.benchmark_name}
          />
          <InfoRow
            title={<FormattedMessage id={'inventory.input26_min'} />}
            value={props.device.benchmark_result}
          />
        </>
      )}
    </>
  );
};

Info.propTypes = {
  module: PropTypes.number,
  device: PropTypes.any,
};

export default Info;
