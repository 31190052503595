import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import GenericButton from 'components/commons/Buttons/GenericButton';
import Check from 'images/check_no_background.png';
import Inputs from './Inputs';
import { createCompany, createUser } from 'store/Clients/actions';
import { EMENDU_NIF } from 'utils/constants';
import {
  Container,
  Centered,
  Wrapper,
  Title,
  Body,
  LastRow,
  ConfirmButton,
  CancelButton,
  InsertedContainer,
  IconContainer,
  Icon,
  Progress,
  ProgressBar,
  Deg,
} from './styled';

const Modal = (props) => {
  const [inserted, setInserted] = useState(false);
  const [error, setError] = useState('clients.status1');
  const [stage, setStage] = useState(
    props.show === 1 ? props.show : props.show - 1,
  );
  const [clientName, setClientName] = useState(
    props.show != 1 ? props.clientEdit.name : '',
  );
  const [nif, setNif] = useState(props.show != 1 ? props.clientEdit.nif : '');
  const [address, setAddress] = useState(
    props.show != 1 ? props.clientEdit.street : '',
  );
  const [door, setDoor] = useState(
    props.show != 1 ? props.clientEdit.door : '',
  );
  const [floor, setFloor] = useState(
    props.show != 1 ? props.clientEdit.floor : '',
  );
  const [phone, setPhone] = useState(
    props.show != 1 ? props.clientEdit.phone_number : '',
  );
  const [postalCode, setPostalCode] = useState(
    props.show != 1 ? props.clientEdit.zip_code : '',
  );
  const [city, setCity] = useState(
    props.show != 1 ? props.clientEdit.city : '',
  );
  const [country, setCountry] = useState(
    props.show != 1 ? props.clientEdit.country : '',
  );
  const [description, setDescription] = useState(
    props.show != 1 ? props.clientEdit.description : '',
  );
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [role, setRole] = useState('');
  const dispatch = useDispatch();

  const handleChange = (name, value) => {
    switch (name) {
      case 'inserted':
        setInserted(value);
        break;
      case 'error':
        setError(value);
        break;
      case 'stage':
        setStage(value);
        break;
      case 'clientName':
        setClientName(value);
        break;
      case 'nif':
        setNif(value);
        break;
      case 'address':
        setAddress(value);
        break;
      case 'door':
        setDoor(value);
        break;
      case 'floor':
        setFloor(value);
        break;
      case 'phone':
        setPhone(value);
        break;
      case 'postalCode':
        setPostalCode(value);
        break;
      case 'city':
        setCity(value);
        break;
      case 'country':
        setCountry(value);
        break;
      case 'description':
        setDescription(value);
        break;
      case 'email':
        setEmail(value);
        break;
      case 'password':
        setPassword(value);
        break;
      case 'name':
        setName(value);
        break;
      case 'lastName':
        setLastName(value);
        break;
      case 'role':
        setRole(value);
        break;
    }
  };

  const submit = (isCompany, inserting) => {
    if (isCompany) {
      dispatch(
        createCompany(
          handleChange,
          nif,
          description,
          clientName,
          address,
          floor,
          door,
          city,
          postalCode,
          country,
          phone,
          email,
          password,
          name,
          lastName,
          role,
          props.closeModal,
          inserting,
        ),
      );
    } else {
      dispatch(
        createUser(
          handleChange,
          email,
          password,
          nif,
          name,
          lastName,
          role,
          inserting,
          props.closeModal,
        ),
      );
    }
  };

  const handleClick = () => {
    if (stage === 1 && props.show === 1) {
      setStage(2);
    } else if (stage === 2 && props.show === 1) {
      submit(true, true);
    } else if (props.show === 2) {
      submit(true, false);
    } else {
      submit(false, false);
    }
  };

  useEffect(() => {
    function handleEscapeKey(event) {
      if (event.code === 'Escape') {
        props.closeModal();
      }
    }

    document.addEventListener('keydown', handleEscapeKey);
    return () => document.removeEventListener('keydown', handleEscapeKey);
  }, []);

  return (
    <>
      <Container />
      <Centered inserted={inserted}>
        {inserted ? (
          <InsertedContainer>
            <IconContainer>
              <Icon src={Check} alt={'icon'} />
            </IconContainer>
            <Title>
              <FormattedMessage id={'clients.inserted' + props.show} />
            </Title>
          </InsertedContainer>
        ) : (
          <>
            {props.show === 1 && (
              <Progress>
                <ProgressBar stage={stage} />
                <Deg />
              </Progress>
            )}
            <Wrapper>
              <Title>
                <FormattedMessage
                  id={
                    props.show === 1 && stage === 2
                      ? 'clients.stage3'
                      : 'clients.stage' + props.show
                  }
                />
              </Title>
              <Body>
                <Inputs
                  error={error}
                  handleChange={handleChange}
                  stage={stage}
                  clientName={clientName}
                  nif={nif}
                  address={address}
                  door={door}
                  floor={floor}
                  phone={phone}
                  postalCode={postalCode}
                  city={city}
                  country={country}
                  description={description}
                  email={email}
                  password={password}
                  name={name}
                  lastName={lastName}
                  role={role}
                  show={props.show}
                />
                <LastRow>
                  <CancelButton>
                    <GenericButton
                      theme="light"
                      title={
                        <FormattedMessage
                          id={
                            props.show === 1 && stage === 2
                              ? 'clients.button_left.stage3'
                              : 'clients.button_left.stage' + props.show
                          }
                        />
                      }
                      onClick={
                        stage === 2 && props.show === 1
                          ? () => setStage(1)
                          : props.closeModal
                      }
                    />
                  </CancelButton>
                  <ConfirmButton>
                    <GenericButton
                      theme="dark"
                      title={
                        <FormattedMessage
                          id={
                            props.show === 1 && stage === 2
                              ? 'clients.button_right.stage3'
                              : 'clients.button_right.stage' + props.show
                          }
                        />
                      }
                      onClick={() => handleClick()}
                      disabled={
                        !clientName ||
                        !nif ||
                        !address ||
                        !phone ||
                        !postalCode ||
                        !city ||
                        !country ||
                        (stage === 2 && (!email || !password)) ||
                        (stage === 2 &&
                          nif === EMENDU_NIF &&
                          (!email || !password || !name || !lastName || !role))
                      }
                    />
                  </ConfirmButton>
                </LastRow>
              </Body>
            </Wrapper>
          </>
        )}
      </Centered>
    </>
  );
};

Modal.propTypes = {
  closeModal: PropTypes.func,
  show: PropTypes.number,
  clientEdit: PropTypes.any,
};

export default Modal;
