import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const customStyles = (comp) => {
  return {
    container: (provided) => ({
      ...provided,
      width: '100%',
    }),
    control: (provided) => ({
      ...provided,
      borderRadius: '8px',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    menuPortal: (provided) => ({ ...provided, zIndex: 13 }),
    menu: (provided) => ({ ...provided, zIndex: 13 }),
    input: (provided) => ({
      ...provided,
      color: comp === 'select' ? 'transparent' : 'default',
    }),
  };
};

export const ButtonsRow = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 15%;
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 80px;
  height: 100%;
  margin-right: ${(props) => (props.left ? '8px' : '0')};
`;
