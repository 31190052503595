import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Label from 'components/commons/Texts/Label';
import BasicInput from 'components/commons/Inputs/BasicInput';
import { FirstRowDiv, InputContainer } from './styled';
import { avoidZeroFirstAmountDevices } from 'utils/functions/general';

const SecondRow = (props) => {
  return (
    <FirstRowDiv>
      <InputContainer>
        <Label value={<FormattedMessage id={'orders.input5'} />} />
        <BasicInput
          onChange={(e) =>
            props.handleChange(
              'amountDevices',
              avoidZeroFirstAmountDevices(e.target.value),
            )
          }
          value={props.amountDevices}
        />
      </InputContainer>
    </FirstRowDiv>
  );
};

SecondRow.propTypes = {
  handleChange: PropTypes.func,
  amountDevices: PropTypes.any,
};

export default SecondRow;
