import React, { useState, useEffect } from 'react';
import Table from 'components/commons/Table';
import PropTypes from 'prop-types';
import { columns } from './constants';
import { useDispatch } from 'react-redux';
import { getTableDevices } from 'store/Inventory/actions';
import { selectUser } from 'store/Login/selectors';
import { useSelector } from 'react-redux';
import { updateDeviceState } from 'store/Inventory/actions';
import { useIntl } from 'react-intl';
import { STATUS } from 'components/pages/Inventory/States/Inputs/FirstRow/constants';
import { DEVICES_TYPE as DT } from 'components/pages/Inventory/Modal/Inputs/constants';

const InventoryTable = (props) => {
  const dispatch = useDispatch();
  const [devices, setDevices] = useState(null);
  const user = useSelector(selectUser);
  const intl = useIntl();

  useEffect(() => {
    if (!props.openModal) {
      dispatch(getTableDevices(setDevices));
    }
  }, [props.openModal, props.flag]);

  const handleArray = (row) => {
    return {
      asset_id: row.asset_id,
      brand: row.brand,
      model: row.model,
      version: row.version,
      display_name: row.display_name,
      description: row.description,
      serial_number: row.serial_number,
      asset_type: row.asset_type,
      comercial_description: row.comercial_description,
      os: row.os,
      os_version: row.os_version,
      screen_size: row.screen_size,
      cpu_model: row.cpu_model,
      cpu_speed: row.cpu_speed,
      cpu_core_count: row.cpu_core_count,
      ram: row.ram,
      disk_space: row.disk_space,
      disk_type: row.disk_type,
      secondary_disk_space: row.secondary_disk_space,
      secondary_disk_type: row.secondary_disk_type,
      graphic_card: row.graphic_card,
      other_specs: row.other_specs,
      keyboard_language: row.keyboard_language,
      mdm: row.mdm,
      colour: row.colour,
      imei_number: row.imei_number,
      benchmark_name: row.benchmark_name,
      benchmark_result: row.benchmark_result,
      it_check_pending: row.it_check_pending,
      status: row.status,
      supplier_name: row.supplier_name,
      client_name: row.client_name,
      client_reserved_nif: row.client_reserved_nif,
      client_reserved_name: row.client_reserved_name,
    };
  };

  const handleItCheck = (row) => {
    dispatch(
      updateDeviceState(
        row.status,
        !row.it_check_pending,
        row.brand,
        row.serial_number,
        row.asset_type,
        null,
        props.setFlag,
        props.flag,
      ),
    );
  };

  const handleFilter = () => {
    let array = [];
    let secondArray = [];
    let client = props.client.length
      ? props.client.map((e) => {
          return e.value;
        })
      : props.clients.map((e) => {
          return e.name;
        });
    !props.client.length && client.push('');
    let state = props.state.length
      ? props.state.map((e) => {
          return e.value;
        })
      : STATUS.map((e) => {
          return e.value;
        });

    let assetType = props.assetType.length
      ? props.assetType.map((e) => {
          return e.value;
        })
      : DT.map((e) => {
          return e.value;
        });

    if (props.filter) {
      devices.forEach((row) => {
        if (
          row.display_name.toUpperCase().indexOf(props.filter.toUpperCase()) >
            -1 ||
          row.serial_number.toUpperCase().indexOf(props.filter.toUpperCase()) >
            -1 ||
          row.asset_type.toUpperCase().indexOf(props.filter.toUpperCase()) >
            -1 ||
          (row.supplier_name &&
            row.supplier_name
              .toUpperCase()
              .indexOf(props.filter.toUpperCase()) > -1) ||
          (row.client_name &&
            row.client_name.toUpperCase().indexOf(props.filter.toUpperCase()) >
              -1) ||
          (row.client_reserved_name &&
            row.client_reserved_name
              .toUpperCase()
              .indexOf(props.filter.toUpperCase()) > -1) ||
          (row.status &&
            intl
              .formatMessage({
                id: 'device.state' + row.status,
              })
              .toUpperCase()
              .indexOf(props.filter.toUpperCase()) > -1)
        ) {
          array.push(handleArray(row));
        }
      });
    }

    if (props.client.length || props.state.length || props.assetType.length) {
      (props.filter ? array : devices).forEach((row) => {
        if (
          (client.includes(row.client_name) ||
            client.includes(row.client_reserved_name)) &&
          state.includes(row.status) &&
          assetType.includes(row.asset_type)
        ) {
          secondArray.push(handleArray(row));
        }
      });
    }

    return props.client.length || props.state.length || props.assetType.length
      ? secondArray
      : array;
  };

  const handleEdit = (row) => {
    props.setItemEdit(handleArray(row));
    props.setOpenModal(2);
  };

  const handleState = (row) => {
    props.setItemEdit(handleArray(row));
    props.setOpenModal(3);
  };

  return (
    <Table
      columns={columns(handleEdit, user, handleItCheck, handleState)}
      data={
        props.filter ||
        props.client.length ||
        props.state.length ||
        props.assetType.length
          ? handleFilter()
          : devices
      }
    />
  );
};

InventoryTable.propTypes = {
  openModal: PropTypes.number,
  setOpenModal: PropTypes.func,
  setFilter: PropTypes.func,
  filter: PropTypes.string,
  setItemEdit: PropTypes.func,
  flag: PropTypes.bool,
  setFlag: PropTypes.func,
  client: PropTypes.array,
  state: PropTypes.array,
  assetType: PropTypes.array,
  clients: PropTypes.any,
};

export default InventoryTable;
