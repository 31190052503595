import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Details from './Details';
import Devices from './Devices';
import * as styled from './styled';

const Body = ({
  fulfillment,
  devices,
  openModal,
  setItemEdit,
  setOpenModal,
  redirectArray,
}) => {
  useEffect(() => {
    setItemEdit(fulfillment);
  }, [openModal]);

  return (
    <styled.Container>
      <Details fulfillment={fulfillment} />
      <Devices
        devices={devices}
        shipmentID={fulfillment.shipment_id}
        openModal={openModal}
        setOpenModal={setOpenModal}
        shipmentState={fulfillment.status}
        redirectArray={redirectArray}
      />
    </styled.Container>
  );
};

Body.propTypes = {
  fulfillment: PropTypes.object,
  devices: PropTypes.array,
  openModal: PropTypes.number,
  setItemEdit: PropTypes.func,
  setOpenModal: PropTypes.func,
  redirectArray: PropTypes.array,
};

export default Body;
