import React, { useState, useEffect } from 'react';
import Info from 'components/commons/Info';
import * as D from 'components/pages/App/constants';
import PropTypes from 'prop-types';
import { PERMISSIONS } from 'utils/constants';
import Body from './Body';
import { useDispatch } from 'react-redux';
import { getTableDevices } from 'store/Inventory/actions';

const InventoryInfo = (props) => {
  const dispatch = useDispatch();

  const asset = props.match.params.asset;
  const brand = props.match.params.brand;
  const serial = props.match.params.serial;
  const [device, setDevice] = useState([{}]);

  useEffect(() => {
    dispatch(getTableDevices(setDevice, asset, brand, serial));
  }, []);

  return (
    <Info
      redirectArray={[
        D.inventory_es + '/' + asset + '/' + brand + '/' + serial,
        D.inventory_en + '/' + asset + '/' + brand + '/' + serial,
        D.inventory_pt + '/' + asset + '/' + brand + '/' + serial,
      ]}
      permission={PERMISSIONS.inventory}
      moduleName="Inventario"
      subtitle="lateral_menu.inventory"
      title={device[0].display_name}
      setModuleOnClick={D.inventory_es}
    >
      <Body device={device[0]} />
    </Info>
  );
};

InventoryInfo.propTypes = {
  match: PropTypes.any,
};

export default InventoryInfo;
