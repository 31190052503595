import { checkStatus, parseJSON } from 'utils/request';
import config from 'config.js';
import { selectToken } from 'store/Login/selectors';
import { setLoading } from 'store/General/actions';

export const getContracts = (setContracts, nif) => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    const token = selectToken(getState());
    let requestURL = `${config.baseURL}/v1/contract?token=${token}`;
    nif ? (requestURL += `&nif=${nif}`) : '';
    try {
      const response = await fetch(requestURL, {
        method: 'GET',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
        },
      });
      const response_1 = await checkStatus(response);
      const response2 = await parseJSON(response_1);
      if (response2.status === '1') {
        setContracts(response2.result);
      }
      // eslint-disable-next-line no-empty
    } catch (error) {}
    dispatch(setLoading(false));
  };
};

export const getAnnexDevices = (setAnnexDevices, annex_id) => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    const token = selectToken(getState());
    const requestURL = `${config.baseURL}/v1/contract/annex/devices?token=${token}&annex_id=${annex_id}`;
    try {
      const response = await fetch(requestURL, {
        method: 'GET',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
        },
      });
      const response_1 = await checkStatus(response);
      const response2 = await parseJSON(response_1);
      if (response2.status === '1') {
        setAnnexDevices(response2.result);
      }
      // eslint-disable-next-line no-empty
    } catch (error) {}
    dispatch(setLoading(false));
  };
};

export const createContract = (
  setError,
  closeModal,
  setInserted,
  contractFile,
  contractId,
  nif,
  signatureDate,
  description,
  endDate,
  inserting,
) => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    setError('services.contract.status1');
    const token = selectToken(getState());
    const requestURL = `${config.baseURL}/v1/contract?token=${token}`;
    const data = new FormData();
    data.append('contract_file', contractFile);
    data.append('contract_id', contractId);
    data.append('nif', nif);
    data.append('signature_date', signatureDate);
    data.append('description', description);
    data.append('end_date', endDate);
    data.append('inserting', inserting);
    try {
      const response = await fetch(requestURL, {
        method: 'POST',
        credentials: 'include',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      });
      const response_1 = await checkStatus(response);
      const response2 = await parseJSON(response_1);
      if (response2.status === '1') {
        setInserted(true);
        setTimeout(closeModal, 1200);
      } else {
        setError('services.contract.status' + response2.status);
      }
    } catch (error) {
      setError('services.contract.status99');
    }
    dispatch(setLoading(false));
  };
};

export const createAnnex = (
  setError,
  closeModal,
  setInserted,
  annexFile,
  contractId,
  annexId,
  signatureDate,
  managedBy,
  amountDevices,
  inserting,
) => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    setError('services.annex.status1');
    const token = selectToken(getState());
    const requestURL = `${config.baseURL}/v1/contract/annex?token=${token}`;
    const data = new FormData();
    data.append('annex_file', annexFile);
    data.append('contract_id', contractId);
    data.append('annex_id', annexId);
    data.append('signature_date', signatureDate);
    data.append('managed_by', managedBy);
    data.append('amount_devices', amountDevices);
    data.append('inserting', inserting);
    try {
      const response = await fetch(requestURL, {
        method: 'POST',
        credentials: 'include',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      });
      const response_1 = await checkStatus(response);
      const response2 = await parseJSON(response_1);
      if (response2.status === '1') {
        setInserted(true);
        setTimeout(closeModal, 1200);
      } else {
        setError('services.annex.status' + response2.status);
      }
    } catch (error) {
      setError('services.annex.status99');
    }
    dispatch(setLoading(false));
  };
};

export const getDevicesToAnnex = (setDevicesToAnnex, clientNif) => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    const token = selectToken(getState());
    const requestURL = `${config.baseURL}/v1/contract/devices?token=${token}&client_nif=${clientNif}`;
    try {
      const response = await fetch(requestURL, {
        method: 'GET',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
        },
      });
      const response_1 = await checkStatus(response);
      const response2 = await parseJSON(response_1);
      if (response2.status === '1') {
        setDevicesToAnnex(response2.result);
      }
      // eslint-disable-next-line no-empty
    } catch (error) {}
    dispatch(setLoading(false));
  };
};

export const insertDevicesAnnex = (
  setError,
  setInserted,
  closeModal,
  annexId,
  infoDevices,
) => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    setError('services.device.status1');
    const token = selectToken(getState());
    const requestURL = `${config.baseURL}/v1/contract/devices?token=${token}&annex_id=${annexId}`;
    try {
      const response = await fetch(requestURL, {
        method: 'POST',
        body: JSON.stringify(infoDevices),
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'content-type': 'application/json',
        },
      });
      const response_1 = await checkStatus(response);
      const response2 = await parseJSON(response_1);
      if (response2.status === '1') {
        setInserted(true);
        setTimeout(closeModal, 1200);
      } else {
        setError('services.device.status' + response2.status);
      }
    } catch (error) {
      setError('services.device.status99');
    }
    dispatch(setLoading(false));
  };
};

export const editAnnexDevice = (
  setInserted,
  setError,
  closeModal,
  deviceId,
  guarantee,
  price,
  discount,
  shipDate,
  startRentingDate,
  period,
  endRentingDate,
  tracking,
  description,
) => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    setError('services.device.status1');
    const token = selectToken(getState());
    const requestURL = `${config.baseURL}/v1/contract/devices?token=${token}&device_id=${deviceId}`;
    try {
      const response = await fetch(requestURL, {
        method: 'PUT',
        body: JSON.stringify({
          guarantee: guarantee,
          price: price,
          discount: discount,
          ship_date: shipDate,
          start_renting_date: startRentingDate,
          period: period,
          end_date: endRentingDate,
          tracking_number: tracking,
          description: description,
        }),
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'content-type': 'application/json',
        },
      });
      const response_1 = await checkStatus(response);
      const response2 = await parseJSON(response_1);
      if (response2.status === '1') {
        setInserted(true);
        setTimeout(closeModal, 1200);
      } else {
        setError('services.device.status' + response2.status);
      }
    } catch (error) {
      setError('services.device.status99');
    }
    dispatch(setLoading(false));
  };
};
