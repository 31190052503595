import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Wrapper, Container, Error, Body } from './styled';
import FirstStage from './Stages/FirstStage';
import SecondStage from './Stages/SecondStage';

const Inputs = (props) => {
  return (
    <>
      <Wrapper>
        <Body>
          <Container>
            <FirstStage
              handleChange={props.handleChange}
              show={props.show}
              contractSignatureDate={props.contractSignatureDate}
              description={props.description}
              contractEndDate={props.contractEndDate}
            />
            <SecondStage
              handleChange={props.handleChange}
              show={props.show}
              annexSignatureDate={props.annexSignatureDate}
              amountDevices={props.amountDevices}
              managedBy={props.managedBy}
              employeeId={props.employeeId}
            />
          </Container>
          <Error id={props.error}>
            <FormattedMessage id={props.error} />
          </Error>
        </Body>
      </Wrapper>
    </>
  );
};

Inputs.propTypes = {
  error: PropTypes.string,
  handleChange: PropTypes.func,
  show: PropTypes.number,
  contractSignatureDate: PropTypes.string,
  contractEndDate: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  annexSignatureDate: PropTypes.string,
  amountDevices: PropTypes.any,
  managedBy: PropTypes.string,
  employeeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default Inputs;
