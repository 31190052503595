import React from 'react';
import PropTypes from 'prop-types';
import { Container, Tag } from './styled';
import { FormattedMessage } from 'react-intl';

const ShipmentTag = (props) => {
  return (
    <Container onClick={props.onClick}>
      <Tag status={props.status}>
        <FormattedMessage id={'shipments.status' + props.status} />
      </Tag>
    </Container>
  );
};

ShipmentTag.propTypes = {
  status: PropTypes.string,
  onClick: PropTypes.func,
};

export default ShipmentTag;
