import styled from 'styled-components';
import colors from 'styles/colors';
import { ReactComponent as info } from 'images/info_icon.svg';

export const Container = styled.div`
  margin-bottom: 20px;
  color: ${colors.gray2};
  line-height: 26px;
`;

export const ExtraRequests = styled.div`
  background-color: ${colors.yellow7};
  border: 2px solid ${colors.yellow6};
  border-radius: 8px;
  margin-top: 5px;
  padding: 12px 18px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
`;

export const Info = styled(info)`
  display: flex;
  height: 24px;
  width: 24px;
  fill: ${colors.yellow2};
`;
