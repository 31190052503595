import React from 'react';
import PropTypes from 'prop-types';
import FirstRow from './FirstRow';
import SecondRow from './SecondRow';
import ThirdRow from './ThirdRow';

const FirstStage = (props) => {
  if (props.stage !== 1) return null;

  return (
    <>
      <FirstRow
        handleChange={props.handleChange}
        clientName={props.clientName}
        nif={props.nif}
        address={props.address}
        door={props.door}
        floor={props.floor}
        show={props.show}
      />
      <SecondRow
        handleChange={props.handleChange}
        phone={props.phone}
        postalCode={props.postalCode}
        city={props.city}
        country={props.country}
      />
      <ThirdRow
        handleChange={props.handleChange}
        description={props.description}
      />
    </>
  );
};

FirstStage.propTypes = {
  handleChange: PropTypes.func,
  stage: PropTypes.any,
  clientName: PropTypes.string,
  nif: PropTypes.string,
  address: PropTypes.string,
  door: PropTypes.any,
  floor: PropTypes.any,
  phone: PropTypes.any,
  postalCode: PropTypes.any,
  city: PropTypes.string,
  country: PropTypes.string,
  description: PropTypes.string,
  show: PropTypes.number,
};

export default FirstStage;
