import produce from 'immer/dist/immer';
import { initialState } from './initialState';
import * as T from './types';

const general = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case T.SET_TABLE_OPEN:
        draft.open = !draft.open;
        break;
    }
  });

export default general;
