import styled from 'styled-components';
import colors from 'styles/colors';

export const Subtitle = styled.div`
  color: ${colors.gray3};
  font-size: 0.938rem;
  font-weight: 300;
  margin-bottom: 25px;
`;

export const InputAndError = styled.div`
  opacity: ${(props) => (props.sent ? 0 : 1)};
`;

export const Error = styled.div`
  margin-top: 5px;
`;

export const InputContainer = styled.div`
  input {
    width: 100%;
    padding: 15px 25px;
    box-sizing: border-box;
  }
`;

export const SubText = styled.div`
  color: blue;
  margin: 5px 0 0;
  color: ${(props) => (props.error ? colors.red2 : colors.gray3)};
  font-size: 0.8rem;
  font-weight: 500;
  ${(props) => props.cursor === 'pointer' && `cursor: pointer;`}
  opacity: ${(props) => (props.error || props.forget ? 100 : 0)};
`;
