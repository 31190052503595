import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import InfoRow from 'components/commons/Texts/InfoRow';
import { useDispatch } from 'react-redux';
import { getShipmentDevices } from 'store/Shipments/actions';

const Devices = (props) => {
  const dispatch = useDispatch();
  const [devices, setDevices] = useState([{}]);

  useEffect(() => {
    dispatch(getShipmentDevices(setDevices, props.shipmentId));
  }, [props.module]);

  if (props.module != 1) return null;
  return (
    <>
      {devices.map((device, index) => {
        return (
          <div key={index}>
            <InfoRow
              title={
                <FormattedMessage
                  id={'info.orders.device1'}
                  values={{ index: index + 1 }}
                />
              }
              value={device.comercial_description}
            />
            <InfoRow
              title={<FormattedMessage id={'info.orders.device2'} />}
              value={device.serial_number}
            />
          </div>
        );
      })}
    </>
  );
};

Devices.propTypes = {
  module: PropTypes.number,
  shipmentId: PropTypes.any,
};

export default Devices;
