import React from 'react';
import { FormattedMessage } from 'react-intl';
import { PERMISSIONS } from 'utils/constants';
import { Container, Check } from './styled';
import DevicesTag from 'components/commons/Tags/DevicesTag';
import TableIconsRow from 'components/commons/Icons/TableIconsRow';
import Tooltips from 'components/commons/Tooltips';
import history from 'utils/history';
import * as C from 'components/pages/App/constants';
import {
  Peripheral,
  Desktop,
  Laptop,
  Phone,
  Tablet,
  Monitor,
  IconContainer,
} from './styled';

const redirectUrl = (row) => {
  return (
    C.inventory_es +
    '/' +
    row.asset_type +
    '/' +
    row.brand +
    '/' +
    row.serial_number
  );
};

export const columns = (handleEdit, user, handleItCheck, handleState) => {
  return [
    {
      title: 'IT Check',
      field: 'it_check_pending',
      align: 'center',
      render: (row) =>
        row.asset_id && (
          <Container
            onClick={
              user.permissions[PERMISSIONS.inventory_abm] &&
              row.it_check_pending
                ? () => handleItCheck(row)
                : undefined
            }
            pending={row.it_check_pending}
          >
            <Check pending={row.it_check_pending ? 1 : 0} />
          </Container>
        ),
    },
    {
      title: <FormattedMessage id={'inventory.column3'} />,
      field: 'asset_type',
      align: 'center',
      render: (row) => {
        switch (row.asset_type) {
          case 'Peripheral':
            return (
              <IconContainer>
                <Peripheral />
              </IconContainer>
            );
          case 'Desktop':
            return (
              <IconContainer>
                <Desktop />
              </IconContainer>
            );
          case 'Laptop':
            return (
              <IconContainer>
                <Laptop />
              </IconContainer>
            );
          case 'Phone':
            return (
              <IconContainer>
                <Phone />
              </IconContainer>
            );
          case 'Tablet':
            return (
              <IconContainer>
                <Tablet />
              </IconContainer>
            );
          case 'Monitor':
            return (
              <IconContainer>
                <Monitor />
              </IconContainer>
            );
        }
      },
    },
    {
      title: <FormattedMessage id={'inventory.column1'} />,
      field: 'display_name',
      render: (row) =>
        row.asset_id && (
          <Tooltips title={row.display_name} desc={row.comercial_description} />
        ),
    },
    {
      title: <FormattedMessage id={'inventory.column2'} />,
      field: 'serial_number',
    },
    {
      title: <FormattedMessage id={'inventory.column7'} />,
      sorting: false,
      render: (row) =>
        row.asset_id && (
          <>
            {(row.ram ? row.ram + 'GB' : '-') +
              '/' +
              (row.disk_space ? row.disk_space + 'GB' : '-')}
          </>
        ),
    },
    {
      title: <FormattedMessage id={'inventory.column5'} />,
      field: 'status',
      align: 'center',
      render: (row) =>
        row.status && (
          <DevicesTag
            onClick={
              user.permissions[PERMISSIONS.inventory_abm]
                ? () => handleState(row)
                : null
            }
            status={row.status}
          />
        ),
    },
    {
      title: <FormattedMessage id={'inventory.column6'} />,
      field: 'client_name',
      render: (row) => (
        <>
          {row.client_reserved_name
            ? row.client_reserved_name
            : row.client_name}
        </>
      ),
    },
    {
      render: (row) => (
        <TableIconsRow
          onEdit={
            user.permissions[PERMISSIONS.inventory_abm]
              ? () => handleEdit(row)
              : undefined
          }
          onInfo={() => history.push(redirectUrl(row))}
          onAuxClick={() => window.open(redirectUrl(row))}
        />
      ),
    },
  ];
};
