import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  IconContainer,
  Add,
  Edit,
  Show,
  Info,
  Trash,
  Check,
  NewTab,
  AddAnnex,
} from './styled';

const TableIconsRow = (props) => {
  return (
    <Container>
      <IconContainer onClick={props.onCheck}>
        <Check />
      </IconContainer>
      <IconContainer onClick={props.onDelete}>
        <Trash />
      </IconContainer>
      {props.onAdd && (
        <IconContainer onClick={props.onAdd}>
          <Add />
        </IconContainer>
      )}
      <IconContainer onClick={props.onEdit}>
        <Edit />
      </IconContainer>
      {props.onAddPdf && (
        <IconContainer onClick={props.onAddPdf}>
          <AddAnnex />
        </IconContainer>
      )}
      {props.onInfo && (
        <IconContainer onClick={props.onInfo} onAuxClick={props.onAuxClick}>
          <Info />
        </IconContainer>
      )}
      {props.onNewTab && (
        <IconContainer onClick={props.onNewTab}>
          <NewTab />
        </IconContainer>
      )}
      {props.onShow && (
        <IconContainer onClick={props.onShow}>
          <Show />
        </IconContainer>
      )}
    </Container>
  );
};

TableIconsRow.propTypes = {
  onAdd: PropTypes.func,
  onDelete: PropTypes.func,
  onInfo: PropTypes.func,
  onEdit: PropTypes.func,
  onShow: PropTypes.func,
  onCheck: PropTypes.func,
  onAuxClick: PropTypes.func,
  onNewTab: PropTypes.func,
  onAddPdf: PropTypes.func,
};

export default TableIconsRow;
