import { checkStatus, parseJSON } from 'utils/request';
import config from 'config.js';
import * as T from './types';
import history from 'utils/history';
import { selectToken, selectUser, selectMail } from './selectors';
import * as D from 'components/pages/App/constants';
import { setLoading } from 'store/General/actions';
import { DEMO_USER } from 'utils/constants';

const loginSuccessAction = (user, userMail) => {
  return {
    type: T.LOGIN_SUCCESS,
    user,
    userMail,
  };
};

export const loginUser = (
  userMail,
  pass,
  setLoading,
  setErrorMessage,
  setErrorIsUser,
  setErrorIsPass,
) => {
  return async (dispatch) => {
    const requestURL = `${config.baseURL}/v1/auth?user_name=${userMail}&password=${pass}`;
    try {
      const response = await fetch(requestURL, {
        method: 'GET',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
        },
      });
      const response_1 = await checkStatus(response);
      const user = await parseJSON(response_1);
      if (user.status === '1') {
        dispatch(loginSuccessAction(user, userMail));
        !user.result.reset_password && history.replace(D.defaultRedirect);
      } else {
        switch (user.status) {
          case '2':
            setErrorIsUser(true);
            break;
          case '3':
            setErrorIsPass(true);
            break;
        }
        setErrorMessage('login.status' + user.status);
      }
    } catch (error) {
      setErrorMessage('login.status99');
    }
    setLoading(false);
  };
};

export const resetPass = (user, setSent, setErrorMessage, setLoading) => {
  return async () => {
    const requestURL = `${config.baseURL}/v1/auth/reset_password?user_name=${user}`;
    try {
      const response = await fetch(requestURL, {
        method: 'GET',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
        },
      });
      const response_1 = await checkStatus(response);
      const response2 = await parseJSON(response_1);
      response2.status === '1'
        ? setSent(true)
        : setErrorMessage('login.status' + response2.status);
    } catch (error) {
      setErrorMessage('login.status99');
    }
    setLoading(false);
  };
};

export const logout = () => {
  return {
    type: T.LOGOUT,
  };
};

const newPassword = (currentUser) => {
  return {
    type: T.NEW_PASSWORD,
    currentUser,
  };
};

export const newPass = (password, setUpdated, setErrorMessage, setLoading) => {
  return async (dispatch, getState) => {
    const token = selectToken(getState());
    const currentUser = selectUser(getState());
    const requestURL = `${config.baseURL}/v1/auth/new_password?token=${token}&password=${password}`;
    try {
      const response = await fetch(requestURL, {
        method: 'POST',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
        },
      });
      const response_1 = await checkStatus(response);
      const response2 = await parseJSON(response_1);
      if (response2.status === '1') {
        dispatch(newPassword(currentUser));
        setUpdated(true);
      } else {
        setErrorMessage('login.status' + response2.status);
      }
    } catch (error) {
      setErrorMessage('login.status99');
    }
    setLoading(false);
  };
};

export const lastConnection = () => {
  return async (dispatch, getState) => {
    const token = selectToken(getState());
    const requestURL = `${config.baseURL}/v1/auth/last_connection?token=${token}`;
    try {
      await fetch(requestURL, {
        method: 'POST',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
        },
      });
      // eslint-disable-next-line no-empty
    } catch {}
  };
};

export const getEmenduEmployees = (setEmployees) => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    const token = selectToken(getState());
    const requestURL = `${config.baseURL}/v1/auth/employee?token=${token}`;
    try {
      const response = await fetch(requestURL, {
        method: 'GET',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
        },
      });
      const response_1 = await checkStatus(response);
      const response2 = await parseJSON(response_1);
      if (response2.status === '1') {
        setEmployees(response2.result);
      }
      // eslint-disable-next-line no-empty
    } catch {}
    dispatch(setLoading(false));
  };
};

const clientViewSuccessAction = (user, demoUser, currentUser, currentMail) => {
  return {
    type: T.CLIENT_VIEW_SUCCESS,
    user,
    demoUser,
    currentUser,
    currentMail,
  };
};

export const clientViewEndAction = () => {
  return {
    type: T.CLIENT_VIEW_END,
  };
};

export const getTokenClientView = (nif, name) => {
  return async (dispatch, getState) => {
    const token = selectToken(getState());
    const currentUser = selectUser(getState());
    const currentMail = selectMail(getState());
    const requestURL = `${config.baseURL}/v1/auth/client_view?token=${token}&nif=${nif}&name=${name}`;
    try {
      const response = await fetch(requestURL, {
        method: 'GET',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
        },
      });
      const response_1 = await checkStatus(response);
      const response2 = await parseJSON(response_1);
      if (response2.status === '1') {
        dispatch(
          clientViewSuccessAction(
            response2.result,
            DEMO_USER,
            currentUser,
            currentMail,
          ),
        );
        history.push('/dashboard');
      }
      // eslint-disable-next-line no-empty
    } catch {}
    setLoading(false);
  };
};
