import styled from 'styled-components';
import colors from 'styles/colors';

export const Row = styled.div`
  display: flex;
  width: 100%;
  min-height: 40px;
  max-height: 40px;
  // align-items: center;
  background-color: ${(props) =>
    props.header ? colors.blue10 : props.i % 2 ? colors.gray12 : colors.white};
  overflow: hidden;
`;

export const RowContainer = styled.div`
  display: flex;
  margin: 0 26px;
  width: 100%;
  overflow: hidden;
`;

export const RowWrapper = styled.div`
  display: flex;
  // justify-content: space-between;
  align-items: center;
  width: 100%;
  font-weight: 400;
  font-size: 0.875rem;
  color: ${colors.gray1};
  overflow: hidden;
`;

export const LeftRow = styled.div`
  display: flex;
  align-items: center;
  width: 80%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const RightRow = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 20%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Text = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-right: 5px;
  width: ${(props) => props.width};
  font-weight: ${(props) => (props.bold ? '600' : '')};
  text-align: ${(props) => (props.center ? 'center' : 'left')};
`;
