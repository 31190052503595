import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Creatable from 'react-select/creatable';
import { FormattedMessage } from 'react-intl';
import Label from 'components/commons/Texts/Label';
import { removeFirstWhiteSpace } from 'utils/functions/general';
import {
  FifthRowDiv,
  InputContainer,
  customStyles,
  InputMiddleContainer,
  InputContainerMiddle,
} from './styled';
import { Input } from '..';

const FifthRow = (props) => {
  const selectRef = useRef();
  return (
    <FifthRowDiv>
      <InputMiddleContainer>
        <InputContainerMiddle>
          <Label value={<FormattedMessage id={'inventory.input18'} />} />
          <Creatable
            options={props.handleDuplicated(props.deviceInfo, 'cpu_model')}
            placeholder={''}
            styles={customStyles}
            components={{
              DropdownIndicator: () => null,
              Input,
            }}
            menuPortalTarget={document.body}
            menuPosition={'fixed'}
            onInputChange={(inputValue, { action }) =>
              props.onInputChange(
                'cpuModel',
                100,
                'string',
                removeFirstWhiteSpace(inputValue),
                {
                  action,
                },
              )
            }
            isDisabled={
              !props.searched ||
              !['Desktop', 'Laptop'].includes(props.deviceType)
            }
            onChange={(opt) => props.handleChange('cpuModel', opt.value)}
            value={
              !['Desktop', 'Laptop'].includes(props.deviceType)
                ? {
                    label: '',
                    name: '',
                  }
                : {
                    label: props.cpuModel,
                    name: props.cpuModel,
                  }
            }
            ref={selectRef}
            inputValue={props.cpuModel}
          />
        </InputContainerMiddle>
        <InputContainerMiddle>
          <Label value={<FormattedMessage id={'inventory.input19'} />} />
          <Creatable
            options={props.handleDuplicated(props.deviceInfo, 'cpu_speed')}
            placeholder={''}
            styles={customStyles}
            components={{
              DropdownIndicator: () => null,
              Input,
            }}
            menuPortalTarget={document.body}
            menuPosition={'fixed'}
            onInputChange={(inputValue, { action }) =>
              props.onInputChange('cpuSpeed', null, 'decimal', inputValue, {
                action,
              })
            }
            isDisabled={
              !props.searched ||
              !['Desktop', 'Laptop'].includes(props.deviceType)
            }
            onChange={(opt) => props.handleChange('cpuSpeed', opt.value)}
            value={
              !['Desktop', 'Laptop'].includes(props.deviceType)
                ? {
                    label: '',
                    name: '',
                  }
                : {
                    label: props.cpuSpeed,
                    name: props.cpuSpeed,
                  }
            }
            ref={selectRef}
            inputValue={props.cpuSpeed}
          />
        </InputContainerMiddle>
      </InputMiddleContainer>
      <InputMiddleContainer>
        <InputContainerMiddle>
          <Label value={<FormattedMessage id={'inventory.input20'} />} />
          <Creatable
            options={props.handleDuplicated(props.deviceInfo, 'cpu_core_count')}
            placeholder={''}
            styles={customStyles}
            components={{
              DropdownIndicator: () => null,
              Input,
            }}
            menuPortalTarget={document.body}
            menuPosition={'fixed'}
            onInputChange={(inputValue, { action }) =>
              props.onInputChange('cpuCoreCount', null, 'number', inputValue, {
                action,
              })
            }
            isDisabled={
              !props.searched ||
              !['Desktop', 'Laptop'].includes(props.deviceType)
            }
            onChange={(opt) => props.handleChange('cpuCoreCount', opt.value)}
            value={
              !['Desktop', 'Laptop'].includes(props.deviceType)
                ? {
                    label: '',
                    name: '',
                  }
                : {
                    label: props.cpuCoreCount,
                    name: props.cpuCoreCount,
                  }
            }
            ref={selectRef}
            inputValue={props.cpuCoreCount}
          />
        </InputContainerMiddle>
        <InputContainerMiddle>
          <Label value={<FormattedMessage id={'inventory.input21'} />} />
          <Creatable
            options={props.handleDuplicated(props.deviceInfo, 'colour')}
            placeholder={''}
            styles={customStyles}
            components={{
              Input,
              DropdownIndicator: () => null,
            }}
            menuPortalTarget={document.body}
            menuPosition={'fixed'}
            onInputChange={(inputValue, { action }) =>
              props.onInputChange(
                'colour',
                30,
                'string',
                removeFirstWhiteSpace(inputValue),
                {
                  action,
                },
              )
            }
            isDisabled={
              !props.searched ||
              ['Monitor', 'Peripheral'].includes(props.deviceType)
            }
            onChange={(opt) => props.handleChange('colour', opt.value)}
            value={{
              label: props.colour,
              name: props.colour,
            }}
            ref={selectRef}
            inputValue={props.colour}
          />
        </InputContainerMiddle>
      </InputMiddleContainer>
      <InputContainer>
        <Label value={<FormattedMessage id={'inventory.input22'} />} />
        <Creatable
          options={props.handleDuplicated(props.deviceInfo, 'mdm')}
          placeholder={''}
          styles={customStyles}
          components={{
            DropdownIndicator: () => null,
            Input,
          }}
          menuPortalTarget={document.body}
          menuPosition={'fixed'}
          onInputChange={(inputValue, { action }) =>
            props.onInputChange(
              'mdm',
              30,
              'string',
              removeFirstWhiteSpace(inputValue),
              {
                action,
              },
            )
          }
          isDisabled={
            !props.searched ||
            !['Desktop', 'Laptop', 'Phone', 'Tablet'].includes(props.deviceType)
          }
          onChange={(opt) => props.handleChange('mdm', opt.value)}
          value={
            !props.searched || !['Desktop', 'Laptop'].includes(props.deviceType)
              ? {
                  label: '',
                  name: '',
                }
              : {
                  label: props.mdm,
                  name: props.mdm,
                }
          }
          ref={selectRef}
          inputValue={props.mdm}
        />
      </InputContainer>
      <InputContainer>
        <Label value={<FormattedMessage id={'inventory.input23'} />} />
        <Creatable
          options={props.handleDuplicated(props.deviceInfo, 'graphic_card')}
          placeholder={''}
          styles={customStyles}
          components={{
            DropdownIndicator: () => null,
            Input,
          }}
          menuPortalTarget={document.body}
          menuPosition={'fixed'}
          onInputChange={(inputValue, { action }) =>
            props.onInputChange(
              'graphicCard',
              100,
              'string',
              removeFirstWhiteSpace(inputValue),
              {
                action,
              },
            )
          }
          isDisabled={
            !props.searched || !['Desktop', 'Laptop'].includes(props.deviceType)
          }
          onChange={(opt) => props.handleChange('graphicCard', opt.value)}
          value={
            !props.searched || !['Desktop', 'Laptop'].includes(props.deviceType)
              ? {
                  label: '',
                  name: '',
                }
              : {
                  label: props.graphicCard,
                  name: props.graphicCard,
                }
          }
          ref={selectRef}
          inputValue={props.graphicCard}
        />
      </InputContainer>
    </FifthRowDiv>
  );
};

FifthRow.propTypes = {
  handleChange: PropTypes.func,
  deviceType: PropTypes.string,
  searched: PropTypes.bool,
  cpuModel: PropTypes.string,
  cpuSpeed: PropTypes.any,
  cpuCoreCount: PropTypes.any,
  colour: PropTypes.string,
  mdm: PropTypes.string,
  graphicCard: PropTypes.string,
  deviceInfo: PropTypes.any,
  handleDuplicated: PropTypes.func,
  onInputChange: PropTypes.func,
};

export default FifthRow;
