import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import InfoRow from 'components/commons/Texts/InfoRow';
import { Text } from './styled';

const Contract = (props) => {
  if (props.module != 1 || !props.device || !props.device.annex_id) return null;

  return (
    <>
      <InfoRow
        title={<FormattedMessage id={'services.devices.key19'} />}
        value={props.device.annex_id}
      />
      <InfoRow
        title={<FormattedMessage id={'services.devices.key3'} />}
        value={props.device.device_price}
      />
      <InfoRow
        title={<FormattedMessage id={'services.devices.key5'} />}
        value={props.device.device_discount}
      />
      <InfoRow
        title={<FormattedMessage id={'services.devices.key4'} />}
        value={props.device.device_guarantee}
      />
      <InfoRow
        title={<FormattedMessage id={'services.devices.key6'} />}
        value={props.device.device_ship_date}
      />
      <InfoRow
        title={<FormattedMessage id={'services.devices.key8'} />}
        value={props.device.device_start_renting_date}
      />
      <InfoRow
        title={<FormattedMessage id={'services.devices.key22'} />}
        value={props.device.device_period}
      />
      <InfoRow
        title={<FormattedMessage id={'services.devices.key7'} />}
        value={props.device.device_tracking_number}
      />
      <InfoRow
        title={<FormattedMessage id={'services.devices.key13'} />}
        value={props.device.annex_signature_date}
      />
      <InfoRow
        title={<FormattedMessage id={'services.devices.key14'} />}
        value={props.device.annex_amount_devices}
      />
      <InfoRow
        title={<FormattedMessage id={'services.devices.key15'} />}
        value={
          <Text onClick={() => window.open(props.device.annex_url, '_blank')}>
            Link
          </Text>
        }
      />
      <InfoRow
        title={<FormattedMessage id={'services.devices.key20'} />}
        value={props.device.contract_id}
      />
      <InfoRow
        title={<FormattedMessage id={'services.devices.key16'} />}
        value={props.device.contract_signature_date}
      />
      <InfoRow
        title={<FormattedMessage id={'services.devices.key17'} />}
        value={
          <Text
            onClick={() => window.open(props.device.contract_url, '_blank')}
          >
            Link
          </Text>
        }
      />
    </>
  );
};

Contract.propTypes = {
  module: PropTypes.number,
  device: PropTypes.any,
};

export default Contract;
