import React, { useState, useEffect, useRef } from 'react';
import Landing from 'components/commons/Landing';
import * as C from 'components/pages/App/constants';
import { PERMISSIONS } from 'utils/constants';
import { Wrapper, IframeDiv } from './styled';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectMail } from 'store/Login/selectors';

const Iframe = (props) => {
  const id = props.match.params.id;
  const ref = useRef(null);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const mail = useSelector(selectMail);

  const handleResize = () => {
    setHeight(ref.current.clientHeight);
    setWidth(ref.current.clientWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    setHeight(ref.current.clientHeight);
    setWidth(ref.current.clientWidth);
  }, []);

  const handleSrc = () => {
    switch (id) {
      case '1':
        return (
          'https://docs.google.com/forms/d/e/1FAIpQLSfWNK_7k9OATMD78_OW-sMko61uut6gnKYGW9hIe_-hMq9aFA/viewform?embedded=true&usp=pp_url&entry.1397184774=' +
          mail
        );
      case '2':
        return (
          'https://docs.google.com/forms/d/e/1FAIpQLSeeB0Mf-cjLbRM9YBoccAbJSkMwFg9ZnrrLZcLwzrCxh3CGyg/viewform?embedded=true&usp=pp_url&entry.1989503069=' +
          mail
        );
      case '3':
        return (
          'https://docs.google.com/forms/d/e/1FAIpQLScoadyontFEzWjqWjuZkdLdv6bFKn6wz7hjegxUtSIaIaH1-g/viewform?embedded=true&usp=pp_url&entry.1831450042=' +
          mail
        );
    }
  };

  return (
    <>
      <Landing
        empty
        redirectArray={[C.form_es + id, C.form_en + id, C.form_pt + id]}
        permission={PERMISSIONS.dashboard}
        moduleName="Dashboard"
      >
        <Wrapper ref={ref}>
          <IframeDiv
            src={handleSrc()}
            width={width}
            height={height - 10}
            frameBorder={0}
          />
        </Wrapper>
      </Landing>
    </>
  );
};

Iframe.propTypes = {
  match: PropTypes.any,
};

export default Iframe;
