import styled from 'styled-components';
import colors from 'styles/colors';

export const Container = styled.div`
  display: flex;
  letter-spacing: 0.1px;
  font-size: 1rem;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const Key = styled.div`
  display: flex;
  font-weight: 400;
  color: ${colors.gray11};
  margin-right: 15px;
`;

export const Value = styled.div`
  display: flex;
  font-weight: 500;
  word-break: break-all;
`;

export const Right = styled.div`
  display: flex;
  width: 60%;
`;
