import React from 'react';
import PropTypes from 'prop-types';
import FirsStage from './FirstStage';

const Stages = (props) => {
  return (
    <>
      <FirsStage
        allDevices={props.allDevices}
        devicesToInsert={props.devicesToInsert}
        setDevicesToInsert={props.setDevicesToInsert}
        setInfoDevicesToInsert={props.setInfoDevicesToInsert}
        infoDevicesToInsert={props.infoDevicesToInsert}
        shipmentEdit={props.shipmentEdit}
      />
    </>
  );
};

Stages.propTypes = {
  allDevices: PropTypes.any,
  setDevicesToInsert: PropTypes.func,
  devicesToInsert: PropTypes.any,
  shipmentEdit: PropTypes.any,
  setInfoDevicesToInsert: PropTypes.func,
  infoDevicesToInsert: PropTypes.any,
};

export default Stages;
