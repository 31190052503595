import React from 'react';
import PropTypes from 'prop-types';
import { Container, Button, Chevron, IconContainer } from './styled';

const InfoButton = (props) => {
  if (!props.children) return null;
  return (
    <Container active={props.active}>
      <Button onClick={props.onClick}>
        <>{props.children}</>
        <>
          {props.active ? (
            <IconContainer>
              <Chevron />
            </IconContainer>
          ) : null}
        </>
      </Button>
    </Container>
  );
};

InfoButton.propTypes = {
  children: PropTypes.object,
  onClick: PropTypes.func,
  active: PropTypes.bool,
};

export default InfoButton;
