import React, { useState, useEffect } from 'react';
import Landing from 'components/commons/Landing';
import * as C from 'components/pages/App/constants';
import { PERMISSIONS } from 'utils/constants';
import Body from './Body';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { getAnnexDevices } from 'store/Services/actions';
import { FormattedMessage } from 'react-intl';
import Insert from './Insert';
import Modal from './Modal';

const ServicesAnnex = (props) => {
  const dispatch = useDispatch();
  const annex_id = props.match.params.id;
  const number = props.match.params.num;

  // 0: Close, 1: Add device, 2: Edit device
  const [openModal, setOpenModal] = useState(0);
  const [itemEdit, setItemEdit] = useState({});
  const [filter, setFilter] = useState('');
  const [annexDevices, setAnnexDevices] = useState('');

  useEffect(() => {
    !openModal && dispatch(getAnnexDevices(setAnnexDevices, annex_id));
  }, [openModal]);

  return (
    <>
      {![0, 2, 3].includes(openModal) && (
        <Insert
          show={openModal}
          closeModal={() => setOpenModal(0)}
          orderEdit={itemEdit}
          clientNif={annexDevices[0].client_nif}
        />
      )}
      {![0, 1, 2].includes(openModal) && (
        <Modal
          show={openModal}
          closeModal={() => setOpenModal(0)}
          itemEdit={itemEdit}
        />
      )}
      <Landing
        redirectArray={[
          C.services_annex_es + annex_id + '/' + number,
          C.services_annex_en + annex_id + '/' + number,
          C.services_annex_pt + annex_id + '/' + number,
        ]}
        permission={PERMISSIONS.services}
        moduleName="Servicios"
        titleId="lateral_menu.services"
        setOpenModal={setOpenModal}
        filter={filter}
        setFilter={setFilter}
        historic={
          annexDevices && [
            {
              subtitle: <FormattedMessage id={'lateral_menu.services'} />,
              click: C.services_es,
            },
            {
              subtitle: annexDevices[0].client_name,
              click: C.services_info_es + annexDevices[0].client_nif,
            },
            {
              subtitle: (
                <FormattedMessage
                  id={'services.annex_type' + annexDevices[0].description}
                  values={{ id: number }}
                />
              ),
            },
          ]
        }
      >
        <Body
          openModal={openModal}
          setItemEdit={setItemEdit}
          setOpenModal={setOpenModal}
          filter={filter}
          annexDevices={annexDevices}
          number={number}
        />
      </Landing>
    </>
  );
};

ServicesAnnex.propTypes = {
  match: PropTypes.any,
};

export default ServicesAnnex;
