import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getSuppliers } from 'store/Suppliers/actions';
import { BodyDiv } from './styled';
import { selectUser } from 'store/Login/selectors';
import Card from 'components/commons/Card';
import { PERMISSIONS as P } from 'utils/constants';
import * as D from 'components/pages/App/constants';
import history from 'utils/history';

const Body = (props) => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const [allSuppliers, setAllSuppliers] = useState([]);

  useEffect(() => {
    !props.openModal && dispatch(getSuppliers(setAllSuppliers));
  }, [props.openModal]);

  const handleArray = (row) => {
    return {
      name: row.name,
      mail: row.mail || '',
      phone_number: row.phone_number || '',
      discount: row.discount,
      id: row.id,
    };
  };

  const handleEdit = (supplier) => {
    props.setSupplierEdit(handleArray(supplier));
    props.setOpenModal(2);
  };

  const handleFilter = () => {
    let array = [];

    allSuppliers.forEach((row) => {
      if (row.name.toUpperCase().indexOf(props.filter.toUpperCase()) > -1) {
        array.push(handleArray(row));
      }
    });
    return array;
  };

  return (
    <BodyDiv>
      {(props.filter ? handleFilter() : allSuppliers).map((supplier) => {
        return (
          <Card
            key={supplier.id}
            name={supplier.name}
            state={'suppliers.subtitle'}
            onInfo={() => history.push(D.suppliers_info_es + supplier.id)}
            onAuxClick={() =>
              window.open(D.suppliers_info_es + supplier.id, '_blank')
            }
            onEdit={
              user.permissions[P.suppliers_abm] && (() => handleEdit(supplier))
            }
            blurred={props.blurred}
            blur
          />
        );
      })}
    </BodyDiv>
  );
};

Body.propTypes = {
  openModal: PropTypes.number,
  setSupplierEdit: PropTypes.func,
  setOpenModal: PropTypes.func,
  filter: PropTypes.string,
  blurred: PropTypes.bool,
};

export default Body;
