import React, { useState, useEffect } from 'react';
import InfoButton from 'components/commons/Buttons/InfoButton';
import { FormattedMessage } from 'react-intl';
import Info from './Info';
import Owner from './Owner';
import Contract from './Contract';
import PropTypes from 'prop-types';
import GenericButton from 'components/commons/Buttons/GenericButton';
import { selectUser } from 'store/Login/selectors';
import { useSelector } from 'react-redux';
import { PERMISSIONS } from 'utils/constants';
import { useDispatch } from 'react-redux';
import { insertOwnerDevice } from 'store/Dashboard/actions';
import {
  OptionsContainer,
  OptionsWrapper,
  Option,
  Information,
  InfoContainer,
  ButtonWrapper,
  ButtonContainer,
  RightContainer,
  ButtonSize,
  Error,
} from './styled';

const Body = (props) => {
  const [module, setModule] = useState(0);
  const user = useSelector(selectUser);
  const dispatch = useDispatch();

  const [owner, setOwner] = useState('');
  const [team, setTeam] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [zip, setZip] = useState('');
  const [mail, setMail] = useState('');
  const [phone, setPhone] = useState('');
  const [changes, setChanges] = useState(false);
  const [error, setError] = useState('owner.status1');

  const handleChange = (name, value) => {
    setChanges(true);
    switch (name) {
      case 'owner':
        setOwner(value);
        break;
      case 'team':
        setTeam(value);
        break;
      case 'address':
        setAddress(value);
        break;
      case 'city':
        setCity(value);
        break;
      case 'zip':
        setZip(value);
        break;
      case 'mail':
        setMail(value);
        break;
      case 'phone':
        setPhone(value);
        break;
    }
  };

  const setValues = () => {
    setChanges(false);
    setOwner(props.device.owner);
    setTeam(props.device.owner_team);
    setAddress(props.device.owner_address);
    setCity(props.device.owner_city);
    setZip(props.device.owner_zip);
    setMail(props.device.owner_email);
    setPhone(props.device.owner_phone);
  };

  useEffect(() => {
    setValues();
  }, [props.device]);

  return (
    <>
      <OptionsContainer>
        <OptionsWrapper>
          <Option>
            <InfoButton active={module === 0} onClick={() => setModule(0)}>
              <FormattedMessage id={'info.option1'} />
            </InfoButton>
          </Option>
          <Option>
            <InfoButton active={module === 1} onClick={() => setModule(1)}>
              <FormattedMessage id={'info.option5'} />
            </InfoButton>
          </Option>
          <Option>
            <InfoButton active={module === 2} onClick={() => setModule(2)}>
              <FormattedMessage id={'info.option6'} />
            </InfoButton>
          </Option>
        </OptionsWrapper>
      </OptionsContainer>
      <InfoContainer>
        <Information>
          <Info module={module} device={props.device} />
          <Contract module={module} device={props.device} />
          <Owner
            module={module}
            device={props.device}
            disabled={!user.permissions[PERMISSIONS.dashboard_abm]}
            handleChange={handleChange}
            owner={owner}
            team={team}
            address={address}
            city={city}
            zip={zip}
            mail={mail}
            phone={phone}
          />
        </Information>
        {module === 2 && user.permissions[PERMISSIONS.dashboard_abm] && (
          <ButtonWrapper>
            <ButtonContainer>
              <Error error={error}>
                <FormattedMessage id={error} />
              </Error>
              <RightContainer>
                <ButtonSize>
                  <GenericButton
                    theme="light"
                    title={<FormattedMessage id={'owner.left_button'} />}
                    onClick={() => setValues()}
                    disabled={!changes}
                  />
                </ButtonSize>
                <ButtonSize>
                  <GenericButton
                    theme="dark"
                    title={<FormattedMessage id={'owner.right_button'} />}
                    disabled={!changes}
                    onClick={() =>
                      dispatch(
                        insertOwnerDevice(
                          setError,
                          owner,
                          team,
                          address,
                          city,
                          zip,
                          mail,
                          phone,
                          setChanges,
                          props.id,
                        ),
                      )
                    }
                  />
                </ButtonSize>
              </RightContainer>
            </ButtonContainer>
          </ButtonWrapper>
        )}
      </InfoContainer>
    </>
  );
};

Body.propTypes = {
  device: PropTypes.any,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default Body;
