import React, { useState, useEffect } from 'react';
import Info from 'components/commons/Info';
import * as D from 'components/pages/App/constants';
import PropTypes from 'prop-types';
import { PERMISSIONS } from 'utils/constants';
import Body from './Body';
import { useDispatch } from 'react-redux';
import { getClients } from 'store/Clients/actions';

const ClientsInfo = (props) => {
  const dispatch = useDispatch();

  const nif = props.match.params.id;
  const [client, setClient] = useState([{}]);

  useEffect(() => {
    dispatch(getClients(setClient, nif));
  }, []);

  return (
    <Info
      redirectArray={[
        D.clients_info_es + nif,
        D.clients_info_en + nif,
        D.clients_info_pt + nif,
      ]}
      permission={PERMISSIONS.clients}
      moduleName="Clientes"
      subtitle="lateral_menu.clients"
      title={client[0].name}
      setModuleOnClick={D.clients_es}
    >
      <Body client={client[0]} />
    </Info>
  );
};

ClientsInfo.propTypes = {
  match: PropTypes.any,
};

export default ClientsInfo;
