import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import history from 'utils/history.js';
import login from 'store/Login/reducer';
import general from 'store/General/reducer';
import dashboard from 'store/Dashboard/reducer';

export default function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    login: login,
    general: general,
    dashboard: dashboard,
    router: connectRouter(history),
    ...injectedReducers,
  });

  return rootReducer;
}
