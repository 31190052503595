import React, { useState } from 'react';
import Modal from './Modal';
import States from './States';
import Insert from './Insert';
import Landing from 'components/commons/Landing';
import * as C from 'components/pages/App/constants';
import { PERMISSIONS } from 'utils/constants';
import ShipmentsTable from '../Shipments/Table';
import { TableContainer } from './styled';

const Shipments = () => {
  // 0: Close, 1: New, 2: Edit, 3: Status, 4: Insert
  const [openModal, setOpenModal] = useState(0);
  const [shipmentEdit, setShipmentEdit] = useState({});
  const [filter, setFilter] = useState('');

  return (
    <>
      {![0, 1, 2, 4].includes(openModal) && (
        <States
          show={openModal}
          closeModal={() => setOpenModal(0)}
          shipmentEdit={shipmentEdit}
        />
      )}

      {![0, 1, 2, 3].includes(openModal) && (
        <Insert
          show={openModal}
          closeModal={() => setOpenModal(0)}
          shipmentEdit={shipmentEdit}
        />
      )}

      {[1, 2].includes(openModal) && (
        <Modal
          show={openModal}
          closeModal={() => setOpenModal(0)}
          shipmentEdit={shipmentEdit}
        />
      )}

      <Landing
        redirectArray={[C.shipments_es, C.shipments_en, C.shipments_pt]}
        permission={PERMISSIONS.shipments}
        permissionAbm={PERMISSIONS.shipments_abm}
        moduleName="Envíos"
        titleId="lateral_menu.shipments"
        setOpenModal={setOpenModal}
        buttonTextId="shipments.add_shipment"
        filter={filter}
        setFilter={setFilter}
      >
        <TableContainer>
          <ShipmentsTable
            openModal={openModal}
            setOpenModal={setOpenModal}
            setShipmentEdit={setShipmentEdit}
            filter={filter}
            setFilter={setFilter}
          />
        </TableContainer>
      </Landing>
    </>
  );
};

export default Shipments;
