import styled from 'styled-components';
import colors from 'styles/colors';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 40px 40px 10px 40px;
  overflow: auto;
  &::-webkit-scrollbar {
    height: 1px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${colors.gray4};
    border-right: none;
    border-left: none;
    border-radius: 8px;
    border: 3px solid #f4f8fb;
  }
  &::-webkit-scrollbar-track-piece {
    background: transparent;
  }
`;

export const Title = styled.div`
  font-size: 2rem;
  font-weight: 600;
`;

export const SubtitleContainer = styled.div`
  display: flex;
  letter-spacing: -0.003em;
  font-weight: 500;
  font-size: 1rem;
  margin-bottom: 16px;
`;

export const PreviousSubtitle = styled.div`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export const ActualSubtitle = styled.div`
  color: ${colors.gray2};
  margin-left: 5px;
  cursor: default;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 100px);
  background-color: ${({ empty }) => (!empty ? colors.white : 'transparent')};
  border-radius: 8px;
`;

export const Box = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

export const Margin = styled.div`
  display: flex;
  width: 100%;
  margin: ${({ empty }) => (!empty ? '32px 24px' : 0)};
`;
