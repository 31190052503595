import React, { useState } from 'react';
import Landing from 'components/commons/Landing';
import * as C from 'components/pages/App/constants';
import { PERMISSIONS } from 'utils/constants';
import Body from 'components/pages/ServicesInfo/Body';

const Contracts = () => {
  const [filter, setFilter] = useState('');

  return (
    <>
      <Landing
        redirectArray={[C.contracts_es, C.contracts_en, C.contracts_pt]}
        permission={PERMISSIONS.contracts}
        moduleName="Contratos"
        titleId="lateral_menu.contracts"
        filter={filter}
        setFilter={setFilter}
      >
        <Body filter={filter} />
      </Landing>
    </>
  );
};

export default Contracts;
