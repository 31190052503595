import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Label from 'components/commons/Texts/Label';
import BasicInput from 'components/commons/Inputs/BasicInput';
import { SecondRowDiv, InputContainer } from './styled';
import Select from 'react-select';
import { customStyles } from 'components/pages/Orders/Modal/Inputs/FirstRow/styled';
import { CONTRACT_TYPES } from 'utils/constants';

const SecondRow = (props) => {
  return (
    <SecondRowDiv>
      <InputContainer>
        <Label value={<FormattedMessage id={'services.input2'} />} />
        <BasicInput
          onChange={(e) =>
            props.handleChange('contractSignatureDate', e.target.value)
          }
          value={props.contractSignatureDate}
          type="date"
        />
      </InputContainer>
      <InputContainer>
        <Label value={<FormattedMessage id={'services.input3'} />} />
        <Select
          options={CONTRACT_TYPES.map((item) => {
            return { value: item.id, label: item.name };
          })}
          placeholder={<FormattedMessage id={'selectBoxDefault'} />}
          styles={customStyles('select')}
          menuPortalTarget={document.body}
          menuPosition={'fixed'}
          onChange={(opt) => props.handleChange('description', opt.value)}
          hideSelectedOptions
          isSearchable={false}
          defaultValue={props.description}
          isDisabled={props.show === 2}
        />
      </InputContainer>
      <InputContainer>
        <Label value={<FormattedMessage id={'services.input4'} />} />
        <BasicInput
          onChange={(e) =>
            props.handleChange('contractEndDate', e.target.value)
          }
          value={props.contractEndDate}
          type="date"
          disabled={props.show === 1}
        />
      </InputContainer>
    </SecondRowDiv>
  );
};

SecondRow.propTypes = {
  handleChange: PropTypes.func,
  contractSignatureDate: PropTypes.string,
  contractEndDate: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  show: PropTypes.number,
};

export default SecondRow;
