import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import GenericButton from 'components/commons/Buttons/GenericButton';
import Check from 'images/check_no_background.png';
import Inputs from './Inputs';
import { getAssets, createDevice } from 'store/Inventory/actions';
import { removeSerialNumberDuplicated } from 'utils/functions/general';
import {
  Container,
  Centered,
  Wrapper,
  Title,
  TitleError,
  Body,
  LastRow,
  ConfirmButton,
  CancelButton,
  InsertedContainer,
  IconContainer,
  Icon,
} from './styled';

const Modal = (props) => {
  const [inserted, setInserted] = useState(false);
  const [error, setError] = useState('inventory.status1');
  const [deviceType, setDeviceType] = useState(
    props.show === 2 ? props.itemEdit.asset_type : '',
  );
  const [assets, setAssets] = useState('');
  const [brand, setBrand] = useState(
    props.show === 2 ? props.itemEdit.brand : '',
  );
  const [model, setModel] = useState(
    props.show === 2 ? props.itemEdit.model : '',
  );
  const [version, setVersion] = useState(
    props.show === 2 ? props.itemEdit.version : '',
  );
  const [searched, setSearched] = useState(props.show === 2);
  const [deviceInfo, setDeviceInfo] = useState('');
  const [displayName, setDisplayName] = useState(
    props.show === 2 ? props.itemEdit.display_name : '',
  );
  const [serialNumber, setSerialNumber] = useState(
    props.show === 2 ? props.itemEdit.serial_number : '',
  );
  const [os, setOs] = useState(props.show === 2 ? props.itemEdit.os : '');
  const [osVersion, setOsversion] = useState(
    props.show === 2 ? props.itemEdit.os_version : '',
  );
  const [comercialDescription, setComercialDescription] = useState('');
  const [otherSpecs, setOtherSpecs] = useState(
    props.show === 2 ? props.itemEdit.other_specs : '',
  );
  const [imeiNumber, setImeiNumber] = useState(
    props.show === 2 ? props.itemEdit.imei_number : '',
  );
  const [screenSize, setScreenSize] = useState(
    props.show === 2 ? props.itemEdit.screen_size : '',
  );
  const [ram, setRam] = useState(props.show === 2 ? props.itemEdit.ram : '');
  const [diskType, setDiskType] = useState(
    props.show === 2 ? props.itemEdit.disk_type : '',
  );
  const [diskSpace, setDiskSpace] = useState(
    props.show === 2 ? props.itemEdit.disk_space : '',
  );
  const [secondDiskType, setSecondDiskType] = useState(
    props.show === 2 ? props.itemEdit.secondary_disk_type : '',
  );
  const [secondDiskSpace, setsecondDiskSpace] = useState(
    props.show === 2 ? props.itemEdit.secondary_disk_space : '',
  );
  const [cpuModel, setCpuModel] = useState(
    props.show === 2 ? props.itemEdit.cpu_model : '',
  );
  const [cpuSpeed, setCpuSpeed] = useState(
    props.show === 2 ? props.itemEdit.cpu_speed : '',
  );
  const [cpuCoreCount, setCpuCoreCount] = useState(
    props.show === 2 ? props.itemEdit.cpu_core_count : '',
  );
  const [colour, setColour] = useState(
    props.show === 2 ? props.itemEdit.colour : '',
  );
  const [mdm, setMdm] = useState(props.show === 2 ? props.itemEdit.mdm : '');
  const [graphicCard, setGraphicCard] = useState(
    props.show === 2 ? props.itemEdit.graphic_card : '',
  );
  const [keyLang, setKeyLang] = useState(
    props.show === 2 ? props.itemEdit.keyboard_language : '',
  );
  const [benchmark, setBenchmark] = useState(
    props.show === 2 ? props.itemEdit.benchmark_name : '',
  );
  const [result, setResult] = useState(
    props.show === 2 ? props.itemEdit.benchmark_result : '',
  );
  const [description, setDescription] = useState(
    props.show === 2 ? props.itemEdit.description : '',
  );

  const dispatch = useDispatch();

  const createComercial = (spec) => {
    if (spec.length === 1 && spec[0]) {
      return spec[0] + '/';
    }
    if (spec.length > 2) {
      if (spec[0] && spec[2] && spec[3] && spec[5]) {
        return (
          spec[0] +
          spec[1] +
          ' ' +
          spec[2] +
          ' + ' +
          spec[3] +
          spec[4] +
          ' ' +
          spec[5] +
          '/'
        );
      } else if (spec[0] && spec[2]) {
        return spec[0] + spec[1] + ' ' + spec[2] + '/';
      }
    } else if (spec.length > 1 && spec[0]) {
      return spec[0] + spec[1] + '/';
    }
    return '';
  };

  useEffect(() => {
    let comercial = '';
    let vars = [];

    switch (deviceType) {
      case 'Desktop':
      case 'Laptop':
        vars = [
          [brand],
          [model],
          [version],
          [screenSize, '"'],
          [cpuModel],
          [ram, 'GB'],
          [diskSpace, 'GB', diskType, secondDiskSpace, 'GB', secondDiskType],
          [keyLang],
          [otherSpecs],
        ];
        break;
      case 'Phone':
      case 'Tablet':
        vars = [
          [brand],
          [model],
          [version],
          [diskSpace, 'GB'],
          [screenSize, '"'],
          [colour],
          [otherSpecs],
        ];
        break;
      case 'Monitor':
        vars = [[brand], [model], [version], [screenSize, '"']];
        break;
      case 'Peripheral':
        vars = [[brand], [model], [version], [otherSpecs], [keyLang]];
        break;
    }

    for (var i of vars) comercial += createComercial(i);

    setComercialDescription(comercial.substring(0, comercial.length - 1));
  }, [
    brand,
    model,
    version,
    screenSize,
    cpuModel,
    ram,
    diskSpace,
    diskType,
    secondDiskType,
    secondDiskSpace,
    colour,
    keyLang,
    otherSpecs,
  ]);

  const handleChange = (name, value) => {
    switch (name) {
      case 'deviceType':
        setDeviceType(value);
        dispatch(getAssets(value, setAssets, setError));
        break;
      case 'brand':
        setBrand(value);
        break;
      case 'model':
        setModel(value);
        break;
      case 'version':
        setVersion(value);
        break;
      case 'displayName':
        setDisplayName(value);
        break;
      case 'serialNumber':
        setSerialNumber(value);
        break;
      case 'os':
        setOs(value);
        break;
      case 'osVersion':
        setOsversion(value);
        break;
      case 'comercialDescription':
        setComercialDescription(value);
        break;
      case 'otherSpecs':
        setOtherSpecs(value);
        break;
      case 'imeiNumber':
        setImeiNumber(value);
        break;
      case 'screenSize':
        setScreenSize(value);
        break;
      case 'ram':
        setRam(value);
        break;
      case 'diskType':
        setDiskType(value);
        break;
      case 'diskSpace':
        setDiskSpace(value);
        break;
      case 'secondDiskType':
        setSecondDiskType(value);
        break;
      case 'secondDiskSpace':
        setsecondDiskSpace(value);
        break;
      case 'cpuModel':
        setCpuModel(value);
        break;
      case 'cpuSpeed':
        setCpuSpeed(value);
        break;
      case 'cpuCoreCount':
        setCpuCoreCount(value);
        break;
      case 'colour':
        setColour(value);
        break;
      case 'mdm':
        setMdm(value);
        break;
      case 'graphicCard':
        setGraphicCard(value);
        break;
      case 'keyLang':
        setKeyLang(value);
        break;
      case 'benchmark':
        setBenchmark(value);
        break;
      case 'result':
        setResult(value);
        break;
      case 'description':
        setDescription(value);
        break;
    }
  };

  const restartValues = () => {
    setError('inventory.status1');
    setModel('');
    setVersion('');
    setSearched(false);
    setDeviceInfo('');
    setDisplayName('');
    setSerialNumber('');
    setOs('');
    setOsversion('');
    setComercialDescription('');
    setOtherSpecs('');
    setImeiNumber('');
    setScreenSize('');
    setRam('');
    setDiskType('');
    setDiskSpace('');
    setSecondDiskType('');
    setsecondDiskSpace('');
    setCpuModel('');
    setCpuSpeed('');
    setCpuCoreCount('');
    setColour('');
    setMdm('');
    setGraphicCard('');
    setKeyLang('');
    setBenchmark('');
    setResult('');
    setDescription('');
  };

  useEffect(() => {
    function handleEscapeKey(event) {
      if (event.code === 'Escape') {
        props.closeModal();
      }
    }

    document.addEventListener('keydown', handleEscapeKey);
    return () => document.removeEventListener('keydown', handleEscapeKey);
  }, []);

  const toInsertTotal = serialNumber.split(',').length;
  const toInsertOk =
    removeSerialNumberDuplicated(serialNumber).split(',').length;
  const toInsertLengthError = toInsertTotal - toInsertOk;

  return (
    <>
      <Container />
      <Centered inserted={inserted}>
        {inserted ? (
          <InsertedContainer>
            <IconContainer>
              <Icon src={Check} alt={'icon'} />
            </IconContainer>
            <Title>
              {props.show === 2 ? (
                <FormattedMessage id={'inventory.inserted3'} />
              ) : (
                <div>
                  <div>
                    <FormattedMessage
                      id={
                        toInsertOk === 1
                          ? 'inventory.insertedOneOk'
                          : 'inventory.insertedMoreOk'
                      }
                      values={{ amount: toInsertOk }}
                    />
                  </div>
                  {toInsertLengthError > 0 && (
                    <TitleError>
                      <FormattedMessage
                        id={
                          toInsertLengthError === 1
                            ? 'inventory.insertedOneError'
                            : 'inventory.insertedMoreError'
                        }
                        values={{ amount: toInsertLengthError }}
                      />
                    </TitleError>
                  )}
                </div>
              )}
            </Title>
          </InsertedContainer>
        ) : (
          <Wrapper>
            <Title>
              <FormattedMessage
                id={props.show === 2 ? 'inventory.title2' : 'inventory.title'}
              />
            </Title>
            <Body>
              <Inputs
                error={error}
                handleChange={handleChange}
                deviceType={deviceType}
                assets={assets}
                brand={brand}
                model={model}
                version={version}
                setDeviceInfo={setDeviceInfo}
                deviceInfo={deviceInfo}
                searched={searched}
                setSearched={setSearched}
                setError={setError}
                displayName={displayName}
                os={os}
                osVersion={osVersion}
                comercialDescription={comercialDescription}
                otherSpecs={otherSpecs}
                imeiNumber={imeiNumber}
                screenSize={screenSize}
                ram={ram}
                diskType={diskType}
                diskSpace={diskSpace}
                secondDiskType={secondDiskType}
                secondDiskSpace={secondDiskSpace}
                cpuModel={cpuModel}
                cpuSpeed={cpuSpeed}
                cpuCoreCount={cpuCoreCount}
                colour={colour}
                mdm={mdm}
                graphicCard={graphicCard}
                keyLang={keyLang}
                benchmark={benchmark}
                result={result}
                description={description}
                restartValues={restartValues}
                serialNumber={serialNumber}
                show={props.show}
              />
              <LastRow>
                <CancelButton>
                  <GenericButton
                    theme="light"
                    title={<FormattedMessage id={'inventory.cancel'} />}
                    onClick={props.closeModal}
                  />
                </CancelButton>
                <ConfirmButton>
                  <GenericButton
                    theme="dark"
                    title={
                      <FormattedMessage
                        id={
                          props.show === 2
                            ? 'inventory.edit'
                            : 'inventory.create'
                        }
                      />
                    }
                    onClick={() =>
                      dispatch(
                        createDevice(
                          deviceType,
                          brand,
                          model,
                          version,
                          displayName,
                          removeSerialNumberDuplicated(serialNumber),
                          os,
                          osVersion,
                          comercialDescription,
                          otherSpecs,
                          imeiNumber,
                          screenSize,
                          ram,
                          diskType,
                          diskSpace,
                          secondDiskType,
                          secondDiskSpace,
                          cpuModel,
                          cpuSpeed,
                          cpuCoreCount,
                          colour,
                          mdm,
                          graphicCard,
                          keyLang,
                          benchmark,
                          result,
                          description,
                          setInserted,
                          setError,
                          props.closeModal,
                          !(props.show === 2),
                        ),
                      )
                    }
                    disabled={
                      !deviceType ||
                      !brand ||
                      !model ||
                      !version ||
                      !displayName ||
                      !serialNumber ||
                      serialNumber.charAt(serialNumber.length - 1) === ','
                    }
                  />
                </ConfirmButton>
              </LastRow>
            </Body>
          </Wrapper>
        )}
      </Centered>
    </>
  );
};

Modal.propTypes = {
  closeModal: PropTypes.func,
  show: PropTypes.number,
  itemEdit: PropTypes.any,
};

export default Modal;
