import styled from 'styled-components';
import colors from 'styles/colors';
import FormControlLabel from '@mui/material/FormControlLabel';

export const Label = styled(FormControlLabel)`
  && {
    width: 100%;
    color: ${colors.gray2};
    background-color: ${colors.gray12};
    border: 2px solid
      ${({ isChecked }) => (isChecked ? colors.green7 : colors.gray5)};
    margin: 0 0 10px;
    display: flex;
    border-radius: 8px;
    padding: 20px;
    box-sizing: border-box;
    font-size: 14px;
    line-height: 24px;
    cursor: pointer;

    .MuiFormControlLabel-label {
      margin-left: 15px;
    }

    h3 {
      margin: 0 0 5px 0;
      font-size: 18px;
      font-weight: 600;
      color: ${colors.blue5};
    }

    span {
      font-family: Poppins;
    }

    div {
      font-weight: 300;
    }
  }
`;
