import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import GenericButton from 'components/commons/Buttons/GenericButton';
import Check from 'images/check_no_background.png';
import Inputs from './Inputs';
import { createContract, createAnnex } from 'store/Services/actions';
import {
  Container,
  Centered,
  Wrapper,
  Title,
  Body,
  LastRow,
  ConfirmButton,
  CancelButton,
  InsertedContainer,
  IconContainer,
  Icon,
} from './styled';

const Modal = (props) => {
  const [inserted, setInserted] = useState(false);
  const [error, setError] = useState('services.contract.status1');
  const [contractFile, setContractFile] = useState('');
  const [contractId, setContractId] = useState(
    props.show === 2 ? props.itemEdit.contract_id : '',
  );
  const [contractSignatureDate, setContractSignatureDate] = useState(
    props.show === 2 ? props.itemEdit.signature_date : '',
  );
  const [contractEndDate, setContractEndDate] = useState(
    props.show === 2 ? props.itemEdit.end_date : '',
  );
  const [description, setDescription] = useState(
    props.show === 2
      ? [
          {
            value: props.itemEdit.description,
            label: (
              <FormattedMessage
                id={'contract_type' + props.itemEdit.description}
              />
            ),
          },
        ]
      : '',
  );
  const [annexId, setAnnexId] = useState(
    props.show === 4 ? props.itemEdit.annex_id : '',
  );
  const [annexSignatureDate, setAnnexSignatureDate] = useState(
    props.show === 4 ? props.itemEdit.signature_date : '',
  );
  const [amountDevices, setAmountDevices] = useState(
    props.show === 4 ? props.itemEdit.amount_devices : '',
  );
  const [managedBy, setManagedBy] = useState(
    props.show === 4 ? props.itemEdit.managed_by : '',
  );
  const [employeeId, setEmployeeId] = useState(
    props.show === 4 ? props.itemEdit.employee_id : '',
  );

  const dispatch = useDispatch();

  const handleChange = (name, value) => {
    switch (name) {
      case 'inserted':
        setInserted(value);
        break;
      case 'error':
        setError(value);
        break;
      case 'contractFile':
        setContractFile(value);
        break;
      case 'contractId':
        setContractId(value);
        break;
      case 'contractSignatureDate':
        setContractSignatureDate(value);
        break;
      case 'contractEndDate':
        setContractEndDate(value);
        break;
      case 'description':
        setDescription(value);
        break;
      case 'annexId':
        setAnnexId(value);
        break;
      case 'annexSignatureDate':
        setAnnexSignatureDate(value);
        break;
      case 'amountDevices':
        setAmountDevices(value);
        break;
      case 'managedBy':
        setManagedBy(value);
        break;
      case 'employeeId':
        setEmployeeId(value);
        break;
    }
  };

  const submit = () => {
    if (props.show === 1 || props.show === 2) {
      dispatch(
        createContract(
          setError,
          props.closeModal,
          setInserted,
          contractFile,
          contractId,
          props.nif,
          contractSignatureDate,
          props.show === 2 ? description[0].value : description,
          contractEndDate,
          props.show === 1,
        ),
      );
    }
    if (props.show === 3 || props.show === 4) {
      dispatch(
        createAnnex(
          setError,
          props.closeModal,
          setInserted,
          contractFile,
          props.show === 3 ? props.itemEdit.contract_id : '',
          annexId,
          annexSignatureDate,
          employeeId,
          amountDevices,
          props.show === 3,
        ),
      );
    }
  };

  const handleDisabled = () => {
    if (props.show === 1) {
      if (!contractFile || !contractSignatureDate || !description) {
        return true;
      }
    }
    if (props.show === 2) {
      if (!contractSignatureDate) {
        return true;
      }
    }
    if (props.show === 3) {
      if (
        !contractFile ||
        !annexSignatureDate ||
        !employeeId ||
        !amountDevices ||
        amountDevices < 1
      ) {
        return true;
      }
    }
    if (props.show === 4) {
      if (
        !annexSignatureDate ||
        !employeeId ||
        !amountDevices ||
        amountDevices < 1
      ) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    function handleEscapeKey(event) {
      if (event.code === 'Escape') {
        props.closeModal();
      }
    }

    document.addEventListener('keydown', handleEscapeKey);
    return () => document.removeEventListener('keydown', handleEscapeKey);
  }, []);

  return (
    <>
      <Container />
      <Centered inserted={inserted}>
        {inserted ? (
          <InsertedContainer>
            <IconContainer>
              <Icon src={Check} alt={'icon'} />
            </IconContainer>
            <Title>
              <FormattedMessage id={'services.inserted' + props.show} />
            </Title>
          </InsertedContainer>
        ) : (
          <Wrapper>
            <Title>
              <FormattedMessage id={'services.title' + props.show} />
            </Title>
            <Body>
              <Inputs
                error={error}
                handleChange={handleChange}
                show={props.show}
                contractSignatureDate={contractSignatureDate}
                contractEndDate={contractEndDate}
                description={description}
                annexId={annexId}
                annexSignatureDate={annexSignatureDate}
                amountDevices={amountDevices}
                managedBy={managedBy}
                employeeId={employeeId}
              />
              <LastRow>
                <CancelButton>
                  <GenericButton
                    theme="light"
                    title={<FormattedMessage id={'services.left_button'} />}
                    onClick={props.closeModal}
                  />
                </CancelButton>
                <ConfirmButton>
                  <GenericButton
                    theme="dark"
                    title={
                      <FormattedMessage
                        id={'services.right_button' + props.show}
                      />
                    }
                    onClick={() => submit()}
                    disabled={handleDisabled()}
                  />
                </ConfirmButton>
              </LastRow>
            </Body>
          </Wrapper>
        )}
      </Centered>
    </>
  );
};

Modal.propTypes = {
  closeModal: PropTypes.func,
  show: PropTypes.number,
  itemEdit: PropTypes.any,
  nif: PropTypes.string,
};

export default Modal;
