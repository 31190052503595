import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import InputRow from 'components/commons/Texts/InputRow';

const Owner = (props) => {
  if (props.module != 2 || !props.device) return null;

  return (
    <>
      <InputRow
        title={<FormattedMessage id={'owner.key1'} />}
        value={props.owner}
        disabled={
          props.disabled ||
          (props.device.device_status !== '999' &&
            props.device.actual_device_status !== '007')
        }
        onChange={(e) => props.handleChange('owner', e.target.value)}
        maxLength={70}
      />
      <InputRow
        title={<FormattedMessage id={'owner.key2'} />}
        value={props.team}
        disabled={
          props.disabled ||
          (props.device.device_status !== '999' &&
            props.device.actual_device_status !== '007')
        }
        onChange={(e) => props.handleChange('team', e.target.value)}
        maxLength={50}
      />
      <InputRow
        title={<FormattedMessage id={'owner.key3'} />}
        value={props.address}
        disabled={
          props.disabled ||
          (props.device.device_status !== '999' &&
            props.device.actual_device_status !== '007')
        }
        onChange={(e) => props.handleChange('address', e.target.value)}
        maxLength={70}
      />
      <InputRow
        title={<FormattedMessage id={'owner.key4'} />}
        value={props.city}
        disabled={
          props.disabled ||
          (props.device.device_status !== '999' &&
            props.device.actual_device_status !== '007')
        }
        onChange={(e) => props.handleChange('city', e.target.value)}
        maxLength={50}
      />
      <InputRow
        title={<FormattedMessage id={'owner.key5'} />}
        value={props.zip}
        disabled={
          props.disabled ||
          (props.device.device_status !== '999' &&
            props.device.actual_device_status !== '007')
        }
        onChange={(e) => props.handleChange('zip', e.target.value)}
        maxLength={10}
      />
      <InputRow
        title={<FormattedMessage id={'owner.key6'} />}
        value={props.mail}
        disabled={
          props.disabled ||
          (props.device.device_status !== '999' &&
            props.device.actual_device_status !== '007')
        }
        onChange={(e) => props.handleChange('mail', e.target.value)}
        maxLength={40}
      />
      <InputRow
        title={<FormattedMessage id={'owner.key7'} />}
        value={props.phone}
        disabled={
          props.disabled ||
          (props.device.device_status !== '999' &&
            props.device.actual_device_status !== '007')
        }
        onChange={(e) => props.handleChange('phone', e.target.value)}
        maxLength={20}
      />
    </>
  );
};

Owner.propTypes = {
  module: PropTypes.number,
  device: PropTypes.any,
  disabled: PropTypes.bool,
  handleChange: PropTypes.func,
  owner: PropTypes.string,
  team: PropTypes.string,
  address: PropTypes.string,
  city: PropTypes.string,
  zip: PropTypes.string,
  mail: PropTypes.string,
  phone: PropTypes.string,
};

export default Owner;
