import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import DataTable from 'components/commons/Table/DataTable';
import { COLUMNS } from './constants';
import {
  CountContainer,
  YCheck,
  GCheck,
  CountRight,
  Title,
  Subtitle,
} from './styled';

const FirsStage = (props) => {
  if (props.stage != 1) return null;
  return (
    <>
      <DataTable
        title={<FormattedMessage id={'services.device.subtitle1'} />}
        data={props.allDevices}
        columns={COLUMNS}
        onSelect={props.setDevicesToInsert}
        selectable={
          !(
            props.orderEdit.amount_devices ===
            props.devicesToInsert.length + props.orderEdit.amount_in_use
          )
        }
        itemsSelected={props.devicesToInsert}
        sortOrder={{
          name: 'status',
          direction: 'desc',
        }}
      />
      <CountContainer>
        {props.orderEdit.amount_devices ===
        props.devicesToInsert.length + props.orderEdit.amount_in_use ? (
          <GCheck />
        ) : (
          <YCheck />
        )}
        <CountRight>
          <Title>
            <FormattedMessage
              id={'services.device.subtitle3'}
              values={{ amount: props.orderEdit.amount_devices }}
            />
          </Title>
          <Subtitle>
            <FormattedMessage
              id={'orders.subtitle6'}
              values={{
                amount:
                  props.orderEdit.amount_devices -
                  (props.devicesToInsert.length +
                    props.orderEdit.amount_in_use),
              }}
            />
          </Subtitle>
        </CountRight>
      </CountContainer>
    </>
  );
};

FirsStage.propTypes = {
  stage: PropTypes.number,
  allDevices: PropTypes.any,
  setDevicesToInsert: PropTypes.func,
  devicesToInsert: PropTypes.any,
  orderEdit: PropTypes.any,
};

export default FirsStage;
