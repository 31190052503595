import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Label from 'components/commons/Texts/Label';
import { FirstRowDiv, InputContainer } from './styled';
import InputFile from 'components/commons/Inputs/InputFile';

const FifthRow = (props) => {
  return (
    <FirstRowDiv>
      <InputContainer>
        <Label value={<FormattedMessage id={'shipments.input20'} />} />
        <InputFile
          onChange={(e) =>
            props.handleChange('mailLabelUrl', e ? e.target.files[0] : '')
          }
          handleError={props.setError}
        />
      </InputContainer>
    </FirstRowDiv>
  );
};

FifthRow.propTypes = {
  handleChange: PropTypes.func,
  setError: PropTypes.func,
};

export default FifthRow;
