import React, { useEffect } from 'react';
import MaterialTable from 'material-table';
import Proptypes from 'prop-types';
import './custom-react-table.css'; // important: this line must be placed after react-table css import
import {
  CustomStyle,
  CustomStyleBody,
  borderRadius,
  Components,
  Icons,
  Localization,
} from './styled';

const Table = (props) => {
  useEffect(() => {
    handleEmptyRows();
  }, [props.data]);

  const handleEmptyRows = () => {
    var rows = document.querySelectorAll(
      'tbody tr.MuiTableRow-root:not([index]):nth-child(even)',
    );

    rows.forEach((row, index) => {
      if (index != rows.length - 1) {
        for (let i = 0; i < props.columns.length; i++) {
          var x = row.insertCell(0);
          x.innerHTML = '';
        }
        row.setAttribute('index', '2');
      }
    });
  };

  return (
    <MaterialTable
      columns={borderRadius(props.columns)}
      data={props.data && props.data.length > 0 ? props.data : [{}]}
      options={CustomStyle}
      style={CustomStyleBody}
      components={Components}
      icons={Icons}
      localization={Localization()}
      onChangePage={handleEmptyRows}
    />
  );
};

Table.propTypes = {
  data: Proptypes.any,
  columns: Proptypes.any,
};

export default Table;
