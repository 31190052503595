import React from 'react';
import { FormattedMessage } from 'react-intl';
import * as D from 'components/pages/App/constants';
import { PERMISSIONS as P } from 'utils/constants';
import {
  Stats,
  Clients,
  Suppliers,
  Orders,
  Services,
  Shipments,
  Fulfillments,
  Dashboard,
  Devices,
} from './styled';

export const BUTTONS = [
  {
    title: <FormattedMessage id={'lateral_menu.dashboard'} />,
    push: D.dashboard,
    active: 'Dashboard',
    icon: <Dashboard />,
    permission: P.dashboard,
  },
  {
    title: <FormattedMessage id={'lateral_menu.clients'} />,
    push: D.clients_es,
    active: 'Clientes',
    icon: <Clients />,
    permission: P.clients,
  },
  {
    title: <FormattedMessage id={'lateral_menu.inventory'} />,
    push: D.inventory_es,
    active: 'Inventario',
    icon: <Stats />,
    permission: P.inventory,
  },
  {
    title: <FormattedMessage id={'lateral_menu.devices'} />,
    push: D.devices_es,
    active: 'Dispositivos',
    icon: <Devices />,
    permission: P.dashboard,
  },
  {
    title: <FormattedMessage id={'lateral_menu.orders'} />,
    push: D.orders_es,
    active: 'Ordenes',
    icon: <Orders />,
    permission: P.orders,
  },
  {
    title: <FormattedMessage id={'lateral_menu.suppliers'} />,
    push: D.suppliers_es,
    active: 'Proveedores',
    icon: <Suppliers />,
    permission: P.suppliers,
  },
  {
    title: <FormattedMessage id={'lateral_menu.services'} />,
    push: D.services_es,
    active: 'Servicios',
    icon: <Services />,
    permission: P.services,
  },
  {
    title: <FormattedMessage id={'lateral_menu.shipments'} />,
    push: D.shipments_es,
    active: 'Envíos',
    icon: <Shipments />,
    permission: P.shipments,
  },
  {
    title: <FormattedMessage id={'lateral_menu.fulfillments'} />,
    push: D.fulfillments_es,
    active: 'Salidas',
    icon: <Fulfillments />,
    permission: P.fulfillments,
  },
  {
    title: <FormattedMessage id={'lateral_menu.contracts'} />,
    push: D.contracts_es,
    active: 'Contratos',
    icon: <Services />,
    permission: P.contracts,
  },
];
