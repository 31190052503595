import React, { useState, useEffect } from 'react';
import Landing from 'components/commons/Landing';
import * as C from 'components/pages/App/constants';
import { PERMISSIONS } from 'utils/constants';
import { FormattedMessage } from 'react-intl';
import * as styled from './styled';

import { useDispatch } from 'react-redux';
import { getClientDevices } from 'store/Dashboard/actions';
import Table from './Table';

const Devices = () => {
  const dispatch = useDispatch();
  const [clientDevices, setClientDevices] = useState([]);

  useEffect(() => {
    dispatch(getClientDevices(setClientDevices));
  }, []);

  const handleAmount = () => {
    let amount = {
      Desktop: 0,
      Laptop: 0,
      Tablet: 0,
      Phone: 0,
      Monitor: 0,
      Peripheral: 0,
      999: 0,
      998: 0,
      997: 0,
    };
    clientDevices.forEach((row) => {
      if (row.device_status === '999') {
        amount[row.asset_type] = amount[row.asset_type] + 1;
      }
      amount[row.device_status] = amount[row.device_status] + 1;
    });
    return amount;
  };

  return (
    <Landing
      empty
      redirectArray={[C.devices_es, C.devices_en, C.devices_pt]}
      permission={PERMISSIONS.dashboard}
      moduleName="Dispositivos"
      titleId="lateral_menu.devices"
    >
      <styled.Title>
        <FormattedMessage id="lateral_menu.devices" />
      </styled.Title>
      <Table clientDevices={clientDevices} amount={handleAmount()} />
    </Landing>
  );
};

export default Devices;
