import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import * as styled from './styled';

const Details = ({ fulfillment }) => {
  const {
    receiver_name,
    phone_number,
    receiver_mail,
    street,
    number,
    stair,
    floor,
    door,
    city,
    zip_code,
    country,
    company_name,
    extra_requests,
  } = fulfillment;
  const intl = useIntl();

  return (
    <styled.Container>
      <div>
        <div>{`${receiver_name} (${phone_number}, ${receiver_mail})`}</div>
        <div>
          {`
            ${street} ${number && number},
            ${
              stair
                ? ` ${intl.formatMessage({
                    id: 'shipments.input19',
                  })}: ${stair}, `
                : ''
            }
            ${
              floor
                ? ` ${intl.formatMessage({
                    id: 'shipments.input9',
                  })}: ${floor}, `
                : ''
            }
            ${
              door
                ? `${intl.formatMessage({
                    id: 'shipments.input10',
                  })}: ${door}, `
                : ''
            }
            ${city}, ${zip_code}, ${country}
          `}
        </div>
        <div>{company_name}</div>

        {extra_requests && (
          <styled.ExtraRequests>
            <styled.Info />
            {extra_requests}
          </styled.ExtraRequests>
        )}
      </div>
    </styled.Container>
  );
};

Details.propTypes = {
  fulfillment: PropTypes.object,
};

export default Details;
