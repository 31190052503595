import styled from 'styled-components';
import colors from 'styles/colors';

import { ReactComponent as devices } from 'images/devices.svg';
import { ReactComponent as calendar } from 'images/calendar.svg';
import { ReactComponent as newShipmentIcon } from 'images/new-shipment.svg';
import { ReactComponent as fulfillmentIcon } from 'images/fulfillment_inprogress.svg';
import qrIcon from 'images/qr.svg';
import infoIcon from 'images/info_icon.svg';

export const ItemWrap = styled.div`
  position: relative;
  background-color: ${colors.gray12};
  border: 1px solid ${colors.gray5};
  margin: 10px 0 0;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 5px;
  cursor: pointer;
  color: ${colors.gray1};
  font-weight: 600;
  font-size: 12px;
`;

export const Heading = styled.h3`
  color: ${colors.blue2};
  margin: 0;
  font-weight: 600;
  font-size: 14px;
`;

export const Company = styled.div`
  font-weight: 300;
  color: ${colors.gray9};
  text-transform: uppercase;
`;

export const Data = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 15px;
`;

export const Details = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  gap: 5px;
`;

export const Devices = styled(devices)`
  width: 16px;
  fill: ${colors.gray8};
`;

export const Calendar = styled(calendar)`
  width: 16px;
  fill: ${colors.gray8};
`;

export const NewShipment = styled(newShipmentIcon)`
  position: absolute;
  right: 20px;
  top: 35%;
  width: 36px;
  height: 36px;
  fill: ${colors.orange2};
`;

export const InProgress = styled(fulfillmentIcon)`
  position: absolute;
  right: 20px;
  top: 35%;
  width: 36px;
  height: 36px;
  fill: ${({ full }) => (full === 'true' ? colors.green5 : colors.gray8)};
`;

export const QR = styled.div`
  position: absolute;
  z-index: 2;
  right: 20px;
  top: 35%;
  width: 36px;
  height: 36px;
  background-repeat: no-repeat;
  background-image: ${({ status }) =>
    status === '006' ? `url(${qrIcon})` : ''};
  opacity: ${({ mail_label }) => (mail_label ? '1' : '0.25')};
  cursor: ${({ mail_label }) => (mail_label ? 'pointer' : 'default')};
`;

export const Info = styled(QR)`
  && {
    right: 80px;
    opacity: 1;
    background-image: ${`url(${infoIcon})`};
    cursor: pointer;
  }
`;
