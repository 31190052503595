export const DISK_SPACE_TYPE = [
  {
    value: 'SSD',
    label: 'SSD',
  },
  {
    value: 'HDD',
    label: 'HDD',
  },
];
