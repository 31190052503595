import React, { useState, useEffect } from 'react';
import Info from 'components/commons/Info';
import * as D from 'components/pages/App/constants';
import PropTypes from 'prop-types';
import { PERMISSIONS } from 'utils/constants';
import Body from 'components/pages/ServicesDevices/Body';
import { useDispatch } from 'react-redux';
import { getAnnexDevices } from 'store/Services/actions';
import { FormattedMessage } from 'react-intl';

const ContractsDevices = (props) => {
  const dispatch = useDispatch();

  const annexId = props.match.params.id;
  const number = props.match.params.num;
  const [devices, setDevices] = useState([]);

  useEffect(() => {
    dispatch(getAnnexDevices(setDevices, annexId));
  }, []);

  return (
    <Info
      redirectArray={[
        D.contracts_devices_es + annexId + '/' + number,
        D.contracts_devices_en + annexId + '/' + number,
        D.contracts_devices_pt + annexId + '/' + number,
      ]}
      permission={PERMISSIONS.contracts}
      moduleName="Contratos"
      subtitle="services.device.title4"
      values={{ id: number }}
      title={
        <FormattedMessage
          id={'services.device.title3'}
          values={{ id: number }}
        />
      }
      setModuleOnClick={D.contracts_annex_es + annexId + '/' + number}
    >
      <Body devices={devices.length ? devices[0].devices : []} clientView />
    </Info>
  );
};

ContractsDevices.propTypes = {
  match: PropTypes.any,
};

export default ContractsDevices;
