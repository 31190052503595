import React, { useState } from 'react';
import PropTypes from 'prop-types';
import GenericButton from 'components/commons/Buttons/GenericButton';
import { updateFulfillmentState } from 'store/Fulfillments/actions';
import { redirect } from 'utils/functions/redirect_lang';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import Inputs from './Inputs';
import * as styled from './styled';

const Modal = ({ closeModal, itemEdit, redirectArray }) => {
  const { shipment_id: shipmentId } = itemEdit;
  const status = '006';

  const dispatch = useDispatch();
  const [error, setError] = useState('fulfillments.required');
  const [overallWeight, setOverallWeight] = useState('');
  const [amountPackages, setAmountPackages] = useState('');

  const handleChange = (name, value) => {
    switch (name) {
      case 'overallWeight':
        setOverallWeight(value);
        break;
      case 'amountPackages':
        setAmountPackages(value);
        break;
    }
  };

  const validateFields = () => {
    if (!overallWeight) {
      setError('fulfillments.status2');
      return false;
    }
    if (!amountPackages) {
      setError('fulfillments.status1');
      return false;
    }
    return true;
  };

  const submit = () => {
    if (validateFields()) {
      dispatch(
        updateFulfillmentState(
          closeModal,
          shipmentId,
          status,
          amountPackages,
          parseFloat(overallWeight.replace(/,/g, '.')).toFixed(3),
        ),
      );
      redirect(redirectArray);
    }
  };

  return (
    <>
      <styled.Container />
      <styled.Centered>
        <styled.Wrapper>
          <styled.Body>
            <styled.Title>
              <FormattedMessage id={'fulfillments.title.readyToShip'} />
            </styled.Title>
            <Inputs
              error={error}
              handleChange={handleChange}
              overallWeight={overallWeight}
              amountPackages={amountPackages}
            />
            <styled.LastRow>
              <styled.CancelButton>
                <GenericButton
                  theme="light"
                  title={<FormattedMessage id={'fulfillments.btn.cancel'} />}
                  onClick={closeModal}
                />
              </styled.CancelButton>
              <styled.ConfirmButton>
                <GenericButton
                  theme="dark"
                  title={<FormattedMessage id={'fulfillments.btn.confirm'} />}
                  onClick={submit}
                  disabled={!overallWeight || !amountPackages}
                />
              </styled.ConfirmButton>
            </styled.LastRow>
          </styled.Body>
        </styled.Wrapper>
      </styled.Centered>
    </>
  );
};

Modal.propTypes = {
  closeModal: PropTypes.func,
  itemEdit: PropTypes.object,
  redirectArray: PropTypes.array,
};

export default Modal;
