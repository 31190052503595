import React from 'react';
import PropTypes from 'prop-types';
import { Container, TableContainer } from './styled';
import MUIDataTable from 'mui-datatables';
import { Options } from './constants';
import './custom-datatable.css'; // important: this line must be placed after datatable css import

const DataTable = (props) => {
  return (
    <>
      <Container dashboard={props.dashboard}>
        <TableContainer>
          <MUIDataTable
            title={props.title}
            data={props.data}
            columns={props.columns}
            options={Options(
              props.data,
              props.onSelect,
              props.selectable,
              props.itemsSelected,
              props.nonSearch,
              props.height,
              props.sortOrder,
              props.draggableColumns,
              props.selectableRows,
              props.searchText,
              props.onSearchChange,
              props.count,
            )}
          />
        </TableContainer>
      </Container>
    </>
  );
};

DataTable.propTypes = {
  data: PropTypes.any,
  columns: PropTypes.any,
  title: PropTypes.object,
  onSelect: PropTypes.func,
  selectable: PropTypes.bool,
  itemsSelected: PropTypes.any,
  nonSearch: PropTypes.bool,
  height: PropTypes.string,
  sortOrder: PropTypes.object,
  draggableColumns: PropTypes.object,
  selectableRows: PropTypes.string,
  dashboard: PropTypes.bool,
  searchText: PropTypes.string,
  onSearchChange: PropTypes.func,
  count: PropTypes.any,
};

export default DataTable;
