import styled from 'styled-components';
import colors from 'styles/colors';

export const BodyDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    height: 1px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${colors.gray4};
    border-right: none;
    border-left: none;
    border-radius: 8px;
    border: 3px solid #f4f8fb;
  }
  &::-webkit-scrollbar-track-piece {
    background: transparent;
    margin-bottom: 13px;
  }
  mask-image: linear-gradient(0deg, rgba(244, 248, 250, 0) 0%, #f4f8fa 10%);
`;
