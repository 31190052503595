import styled from 'styled-components';

export const FifthRowDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const InputContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  width: 24%;
`;

export const InputContainerMiddle = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  width: 47.7%;
`;

export const InputMiddleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
  width: 24%;
`;

export const customStyles = {
  container: (provided) => ({
    ...provided,
    width: '100%',
  }),
  control: (provided) => ({
    ...provided,
    minHeight: '100%',
    height: '48px',
    borderRadius: '8px',
  }),

  indicatorSeparator: () => ({
    display: 'none',
  }),
  menuPortal: (provided) => ({ ...provided, zIndex: 13 }),
  menu: (provided) => ({ ...provided, zIndex: 13 }),
};
