import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import FirstRow from './FirstRow';
import SecondRow from './SecondRow';
import ThirdRow from './ThirdRow';
import FourthRow from './FourthRow';
import FifthRow from './FifthRow';
import SixthRow from './SixthRow';
import { Wrapper, Container, Error, ErrorContainer } from './styled';
import { components } from 'react-select';

export const Input = (props) => (
  <components.Input {...props} isHidden={false} />
);

const Inputs = (props) => {
  const handleDuplicated = (array, obj, filterType) => {
    if (!array) return undefined;
    const duplicated = [];
    const newArray = [];

    switch (filterType) {
      case 'model':
        array.forEach((op) => {
          if (!duplicated.includes(op[obj]) && op.brand === props.brand) {
            duplicated.push(op[obj]);
            newArray.push({ label: op[obj], value: op[obj] });
          }
        });
        break;
      case 'version':
        array.forEach((op) => {
          if (
            !duplicated.includes(op[obj]) &&
            op.brand === props.brand &&
            op.model === props.model
          ) {
            duplicated.push(op[obj]);
            newArray.push({ label: op[obj], value: op[obj] });
          }
        });
        break;
      default:
        array.forEach((op) => {
          if (op[obj] && !duplicated.includes(op[obj])) {
            duplicated.push(op[obj]);
            newArray.push({ label: op[obj], value: op[obj] });
          }
        });
        break;
    }
    return newArray.length > 0 && newArray[0].value === ''
      ? undefined
      : newArray;
  };

  const onInputChange = (op, len, validation, inputValue, { action }) => {
    if (action === 'input-change') {
      props.handleChange(op, inputValue.value);
    }
    switch (validation) {
      case 'string':
        return inputValue.length <= len
          ? inputValue
          : inputValue.substr(0, len);
      case 'decimal':
        return inputValue.replace(/[^\d.]/g, '');
      case 'number':
        return inputValue.replace(/\D/g, '');
    }
  };

  return (
    <>
      <Wrapper>
        <Container>
          <FirstRow
            handleChange={props.handleChange}
            deviceType={props.deviceType}
            assets={props.assets}
            brand={props.brand}
            model={props.model}
            version={props.version}
            handleDuplicated={handleDuplicated}
            setDeviceInfo={props.setDeviceInfo}
            setSearched={props.setSearched}
            setError={props.setError}
            restartValues={props.restartValues}
            onInputChange={onInputChange}
            show={props.show}
          />
          <SecondRow
            handleChange={props.handleChange}
            deviceType={props.deviceType}
            searched={props.searched}
            deviceInfo={props.deviceInfo}
            handleDuplicated={handleDuplicated}
            displayName={props.displayName}
            os={props.os}
            osVersion={props.osVersion}
            serialNumber={props.serialNumber}
            onInputChange={onInputChange}
            show={props.show}
          />
          <ThirdRow
            handleChange={props.handleChange}
            deviceType={props.deviceType}
            searched={props.searched}
            deviceInfo={props.deviceInfo}
            handleDuplicated={handleDuplicated}
            comercialDescription={props.comercialDescription}
            otherSpecs={props.otherSpecs}
            onInputChange={onInputChange}
          />
          <FourthRow
            handleChange={props.handleChange}
            deviceType={props.deviceType}
            searched={props.searched}
            deviceInfo={props.deviceInfo}
            handleDuplicated={handleDuplicated}
            imeiNumber={props.imeiNumber}
            screenSize={props.screenSize}
            ram={props.ram}
            diskType={props.diskType}
            diskSpace={props.diskSpace}
            secondDiskType={props.secondDiskType}
            secondDiskSpace={props.secondDiskSpace}
            onInputChange={onInputChange}
          />
          <FifthRow
            handleChange={props.handleChange}
            deviceType={props.deviceType}
            searched={props.searched}
            deviceInfo={props.deviceInfo}
            handleDuplicated={handleDuplicated}
            cpuModel={props.cpuModel}
            cpuSpeed={props.cpuSpeed}
            cpuCoreCount={props.cpuCoreCount}
            colour={props.colour}
            mdm={props.mdm}
            graphicCard={props.graphicCard}
            onInputChange={onInputChange}
          />
          <SixthRow
            handleChange={props.handleChange}
            deviceType={props.deviceType}
            searched={props.searched}
            deviceInfo={props.deviceInfo}
            handleDuplicated={handleDuplicated}
            keyLang={props.keyLang}
            benchmark={props.benchmark}
            result={props.result}
            description={props.description}
            onInputChange={onInputChange}
          />
        </Container>
      </Wrapper>
      <ErrorContainer>
        <Error id={props.error}>
          <FormattedMessage id={props.error} />
        </Error>
      </ErrorContainer>
    </>
  );
};

Inputs.propTypes = {
  error: PropTypes.string,
  handleChange: PropTypes.func,
  deviceType: PropTypes.string,
  assets: PropTypes.any,
  brand: PropTypes.string,
  model: PropTypes.string,
  version: PropTypes.string,
  setDeviceInfo: PropTypes.func,
  deviceInfo: PropTypes.any,
  searched: PropTypes.bool,
  setSearched: PropTypes.func,
  setError: PropTypes.func,
  displayName: PropTypes.string,
  os: PropTypes.string,
  osVersion: PropTypes.string,
  comercialDescription: PropTypes.string,
  otherSpecs: PropTypes.string,
  imeiNumber: PropTypes.any,
  screenSize: PropTypes.any,
  ram: PropTypes.any,
  diskType: PropTypes.string,
  diskSpace: PropTypes.any,
  secondDiskType: PropTypes.string,
  secondDiskSpace: PropTypes.any,
  cpuModel: PropTypes.string,
  cpuSpeed: PropTypes.any,
  cpuCoreCount: PropTypes.any,
  colour: PropTypes.string,
  mdm: PropTypes.string,
  graphicCard: PropTypes.string,
  keyLang: PropTypes.string,
  benchmark: PropTypes.string,
  result: PropTypes.any,
  description: PropTypes.string,
  restartValues: PropTypes.func,
  serialNumber: PropTypes.any,
  show: PropTypes.number,
};

export default Inputs;
