import { get } from 'lodash';

export const selectMail = (state) => get(state, 'login.userMail', '');
export const selectUser = (state) => get(state, 'login.currentUser', '');
export const selectToken = (state) => get(state, 'login.currentUser.token', '');
export const selectResetPassword = (state) =>
  get(state, 'login.currentUser.reset_password', '');
export const selectMailCopy = (state) => get(state, 'login.userMailCopy', '');
export const selectUserCopy = (state) =>
  get(state, 'login.currentUserCopy', '');
