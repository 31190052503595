import React from 'react';
import PropTypes from 'prop-types';
import TableIconsRow from 'components/commons/Icons/TableIconsRow/index.js';
import { FormattedMessage } from 'react-intl';
import OrderTag from 'components/commons/Tags/OrdersTag';
import {
  Row,
  RowWrapper,
  RowContainer,
  LeftRow,
  RightRow,
  Text,
} from 'components/commons/Accordion/Generic/styled.js';

const AccordionChild = (props) => {
  let width = '';
  width = props.nif ? '20%' : '33%';
  return (
    <>
      {props.childs && props.childs.length > 0 && (
        <Row header>
          <RowContainer>
            <RowWrapper>
              <LeftRow>
                <Text bold width={width}>
                  <FormattedMessage id={'accordion1.header1'} />
                </Text>
                <Text bold width={width}>
                  <FormattedMessage id={'accordion1.header2'} />
                </Text>
                <Text bold width={width}>
                  <FormattedMessage
                    id={'accordion1.header' + (props.nif ? '6' : '3')}
                  />
                </Text>
                {props.nif && (
                  <>
                    <Text bold width={width}>
                      <FormattedMessage id={'accordion1.header4'} />
                    </Text>
                    <Text center bold width={width}>
                      <FormattedMessage id={'accordion1.header5'} />
                    </Text>
                  </>
                )}
              </LeftRow>
            </RowWrapper>
          </RowContainer>
        </Row>
      )}

      {props.childs &&
        props.childs.map((index, i) => {
          return (
            <Row key={i} i={i}>
              <RowContainer>
                <RowWrapper>
                  <LeftRow>
                    <Text width={width}>
                      <FormattedMessage
                        id={'services.annex_type' + props.status}
                        values={{ id: i + 1 }}
                      />
                    </Text>
                    <Text width={width}>{index.signature_date}</Text>
                    {props.nif ? (
                      <Text width={width}>
                        {index.amount_devices_inserted}/{index.amount_devices}
                      </Text>
                    ) : (
                      <Text width={width}>
                        <FormattedMessage
                          id={
                            index.amount_devices === 1
                              ? 'services.amount_device'
                              : 'services.amount_devices'
                          }
                          values={{ id: index.amount_devices }}
                        />
                      </Text>
                    )}
                    {props.nif && <Text width={width}>{index.managed_by}</Text>}
                    {props.nif && (
                      <Text center width={width}>
                        <OrderTag status={index.annex_devices_status} />
                      </Text>
                    )}
                  </LeftRow>
                  <RightRow>
                    <TableIconsRow
                      onEdit={
                        props.onEditChild
                          ? () => props.onEditChild(index)
                          : null
                      }
                      onInfo={() =>
                        props.onInfoChild(index.annex_id, false, i + 1)
                      }
                      onAuxClick={() =>
                        props.onInfoChild(index.annex_id, true, i + 1)
                      }
                    />
                  </RightRow>
                </RowWrapper>
              </RowContainer>
            </Row>
          );
        })}
    </>
  );
};

AccordionChild.propTypes = {
  childs: PropTypes.any,
  onEditChild: PropTypes.func,
  onInfoChild: PropTypes.func,
  status: PropTypes.string,
  nif: PropTypes.string,
};

export default AccordionChild;
