import React from 'react';
import PropTypes from 'prop-types';
import { Input } from './styled';

const BasicInput = (props) => {
  return (
    <Input
      disabled={props.disabled}
      maxLength={props.maxLength}
      onChange={props.onChange}
      value={props.value}
      type={props.type ? props.type : 'text'}
      min={props.min ? props.min : undefined}
    />
  );
};

BasicInput.propTypes = {
  disabled: PropTypes.bool,
  maxLength: PropTypes.number,
  onChange: PropTypes.func,
  value: PropTypes.any,
  type: PropTypes.string,
  min: PropTypes.number,
};

export default BasicInput;
