import React from 'react';
import PropTypes from 'prop-types';
import { Top, Bottom } from './styled';
import StockList from './StockList';
import DonutChart from './DonutChart';
import Options from './Options';
import Table from './Table';

const Body = (props) => {
  return (
    <>
      <Top isOpen={props.isOpen}>
        <StockList amount={props.amount} />
        <DonutChart amount={props.amount} />
        <Options />
      </Top>
      <Bottom isOpen={props.isOpen}>
        <Table
          clientDevices={props.clientDevices}
          isOpen={props.isOpen}
          handleChange={props.handleChange}
        />
      </Bottom>
    </>
  );
};

Body.propTypes = {
  clientDevices: PropTypes.any,
  amount: PropTypes.object,
  isOpen: PropTypes.bool,
  handleChange: PropTypes.func,
};

export default Body;
