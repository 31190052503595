import React, { useState } from 'react';
import InfoButton from 'components/commons/Buttons/InfoButton';
import { FormattedMessage } from 'react-intl';
import Info from './Info';
import PropTypes from 'prop-types';
import {
  OptionsContainer,
  OptionsWrapper,
  Option,
  Information,
} from './styled';

const Body = (props) => {
  const [module, setModule] = useState(0);

  return (
    <>
      <OptionsContainer>
        <OptionsWrapper>
          {props.devices &&
            props.devices.map((item, index) => {
              return (
                <Option key={index}>
                  <InfoButton
                    active={module === index}
                    onClick={() => setModule(index)}
                  >
                    <FormattedMessage
                      id={'services.device.count'}
                      values={{ id: index + 1 }}
                    />
                  </InfoButton>
                </Option>
              );
            })}
        </OptionsWrapper>
      </OptionsContainer>
      <Information>
        {props.devices.length ? (
          <Info info={props.devices[module]} clientView={props.clientView} />
        ) : (
          ''
        )}
      </Information>
    </>
  );
};

Body.propTypes = {
  devices: PropTypes.any,
  clientView: PropTypes.bool,
};

export default Body;
