import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { ButtonWrapper, Left, Right, Title, Subtitle } from './styled';

const Item = (props) => {
  if (!props.title || !props.subtitle) return null;
  return (
    <ButtonWrapper onClick={props.onClick} rent={props.rent}>
      <Left>{props.icon}</Left>
      <Right>
        <Title rent={props.rent}>
          <FormattedMessage id={props.title} />
        </Title>
        <Subtitle rent={props.rent}>
          <FormattedMessage id={props.subtitle} />
        </Subtitle>
      </Right>
    </ButtonWrapper>
  );
};

Item.propTypes = {
  icon: PropTypes.any,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  rent: PropTypes.bool,
  onClick: PropTypes.func,
};

export default Item;
