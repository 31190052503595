import { createGlobalStyle } from 'styled-components';
import Poppins100 from './fonts/Poppins_100.otf';
import Poppins300 from './fonts/Poppins_300.otf';
import Poppins400 from './fonts/Poppins_400.otf';
import Poppins500 from './fonts/Poppins_500.otf';
import Poppins600 from './fonts/Poppins_600.otf';
import Poppins700 from './fonts/Poppins_700.otf';
import Poppins900 from './fonts/Poppins_900.otf';
import colors from './styles/colors';

const GlobalStyle = createGlobalStyle`

  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 100;
    src: url(${Poppins100}) format("opentype");
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    src: url(${Poppins300}) format("opentype");
    font-display: swap;
  }

  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    src: url(${Poppins400}) format("opentype");
    font-display: swap;
  }

  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    src: url(${Poppins500}) format("opentype");
    font-display: swap;
  }

  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    src: url(${Poppins600}) format("opentype");
    font-display: swap;
  }

  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    src: url(${Poppins700}) format("opentype");
    font-display: swap;
  }

  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 900;
    src: url(${Poppins900}) format("opentype");
    font-display: swap;
  }

  html,
  body {
    height: 100vh;
    color: ${colors.blue2};
    overflow: hidden;
    margin: 0;
    background-color: ${colors.blue4};
  }

  body {
    font-family: Poppins;
  }

  body.fontLoaded {
    font-family: Poppins;
  }

  #app {
    height: 100%;
    width: 100%;
  }
  
  #root {
    display: flex;
  }

  p,
  label {
    line-height: 1.5em;
  }
  
`;

export default GlobalStyle;
