import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import InfoRow from 'components/commons/Texts/InfoRow';
import { Text } from './styled';

/* import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox'; */

const Info = (props) => {
  if (props.module != 0) return null;
  return (
    <>
      <InfoRow
        title={<FormattedMessage id={'info.shipments.client1'} />}
        value={props.shipment.company_name}
      />
      <InfoRow
        title={<FormattedMessage id={'info.shipments.client2'} />}
        value={props.shipment.company_nif}
      />
      <InfoRow
        title={<FormattedMessage id={'info.shipments.client3'} />}
        value={props.shipment.receiver_name}
      />
      <InfoRow
        title={<FormattedMessage id={'info.shipments.key6'} />}
        value={props.shipment.receiver_mail}
      />
      <InfoRow
        title={<FormattedMessage id={'info.shipments.client10'} />}
        value={props.shipment.phone_number}
      />
      <InfoRow
        title={<FormattedMessage id={'info.shipments.client4'} />}
        value={props.shipment.street}
      />
      <InfoRow
        title={<FormattedMessage id={'info.shipments.client11'} />}
        value={props.shipment.number}
      />
      <InfoRow
        title={<FormattedMessage id={'info.shipments.client5'} />}
        value={props.shipment.floor}
      />
      <InfoRow
        title={<FormattedMessage id={'info.shipments.client12'} />}
        value={props.shipment.stair}
      />
      <InfoRow
        title={<FormattedMessage id={'info.shipments.client6'} />}
        value={props.shipment.door}
      />
      <InfoRow
        title={<FormattedMessage id={'info.shipments.client9'} />}
        value={props.shipment.zip_code}
      />
      <InfoRow
        title={<FormattedMessage id={'info.shipments.client7'} />}
        value={props.shipment.city}
      />
      <InfoRow
        title={<FormattedMessage id={'info.shipments.client8'} />}
        value={props.shipment.country}
      />
      <InfoRow
        title={<FormattedMessage id={'info.shipments.key1'} />}
        value={props.shipment.shipment_id}
      />
      <InfoRow
        title={<FormattedMessage id={'info.shipments.key7'} />}
        value={props.shipment.amount_devices}
      />
      <InfoRow
        title={<FormattedMessage id={'info.shipments.key8'} />}
        value={props.shipment.amount_ready_devices}
      />
      <InfoRow
        title={<FormattedMessage id={'info.shipments.key13'} />}
        value={props.shipment.amount_ready_it_devices}
      />
      <InfoRow
        title={<FormattedMessage id={'info.shipments.key2'} />}
        value={props.shipment.estimated_date}
      />
      <InfoRow
        title={<FormattedMessage id={'info.shipments.key3'} />}
        value={props.shipment.actual_date}
      />
      <InfoRow
        title={<FormattedMessage id={'info.shipments.key4'} />}
        value={props.shipment.extra_requests}
      />
      <InfoRow
        title={<FormattedMessage id={'info.shipments.key5'} />}
        value={
          props.shipment.tracking_link && (
            <Text
              onClick={() =>
                window.open(props.shipment.tracking_link, '_blank')
              }
            >
              Link
            </Text>
          )
        }
      />
      <InfoRow
        title={<FormattedMessage id={'info.shipments.key10'} />}
        value={
          props.shipment.mail_label_url && (
            <Text
              onClick={() =>
                window.open(props.shipment.mail_label_url, '_blank')
              }
            >
              Link
            </Text>
          )
        }
      />
      <InfoRow
        title={<FormattedMessage id={'info.shipments.key11'} />}
        value={props.shipment.amount_packages}
      />
      <InfoRow
        title={<FormattedMessage id={'info.shipments.key12'} />}
        value={`${props.shipment.overall_weight}kg`}
      />
      <InfoRow
        title={<FormattedMessage id={'info.shipments.key9'} />}
        value={
          <FormattedMessage id={'shipments.status' + props.shipment.status} />
        }
      />
    </>
  );
};

Info.propTypes = {
  module: PropTypes.number,
  shipment: PropTypes.any,
};

export default Info;
