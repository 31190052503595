import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Label from 'components/commons/Texts/Label';
import BasicInput from 'components/commons/Inputs/BasicInput';
import { FirstRowDiv, InputContainer, customStyles } from './styled';
import Select from 'react-select';
import { avoidZeroFirstAmountDevices } from 'utils/functions/general';

const FirstRow = (props) => {
  return (
    <FirstRowDiv>
      <InputContainer>
        <Label value={<FormattedMessage id={'shipments.input1'} />} />
        <Select
          options={props.allCompanies.map((item) => {
            return { value: item.nif, label: item.name };
          })}
          placeholder={<FormattedMessage id={'selectBoxDefault'} />}
          styles={customStyles('select')}
          menuPortalTarget={document.body}
          menuPosition={'fixed'}
          onChange={(opt) => props.handleChange('company', opt.value)}
          hideSelectedOptions
          defaultValue={props.defaultCompany}
          isDisabled={props.isEditing}
        />
      </InputContainer>
      <InputContainer>
        <Label value={<FormattedMessage id={'shipments.input3'} />} />
        <BasicInput
          onChange={(e) => props.handleChange('shippingDate', e.target.value)}
          value={props.shippingDate}
          type="date"
          disabled={props.isEditing}
        />
      </InputContainer>
      <InputContainer>
        <Label value={<FormattedMessage id={'shipments.input4'} />} />
        <BasicInput
          onChange={(e) => props.handleChange('actualDate', e.target.value)}
          value={props.actualDate}
          type="date"
          disabled={props.isCreating}
        />
      </InputContainer>
      <InputContainer>
        <Label value={<FormattedMessage id={'shipments.input5'} />} />
        <BasicInput
          onChange={(e) =>
            props.handleChange(
              'amountDevices',
              avoidZeroFirstAmountDevices(e.target.value),
            )
          }
          value={props.amountDevices}
        />
      </InputContainer>
    </FirstRowDiv>
  );
};

FirstRow.propTypes = {
  amountDevices: PropTypes.any,
  handleChange: PropTypes.func,
  company: PropTypes.any,
  shippingDate: PropTypes.string,
  actualDate: PropTypes.string,
  allCompanies: PropTypes.array,
  isEditing: PropTypes.bool,
  isCreating: PropTypes.bool,
  defaultCompany: PropTypes.array,
};

export default FirstRow;
