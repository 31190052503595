import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@mui/material/Checkbox';
import * as styled from './styled';
import { useDispatch } from 'react-redux';
import { checkFulfillment } from 'store/Fulfillments/actions';

const Input = ({ device, shipmentID, totalChecked, setTotalChecked }) => {
  const isItemChecked = !!device.it_check;

  const [checked, setChecked] = useState(isItemChecked);
  const dispatch = useDispatch();

  const handleChange = () => {
    setChecked(!checked);
    setTotalChecked(totalChecked + 1);
    dispatch(checkFulfillment(shipmentID, device.serial_number, null));
  };

  useEffect(() => {
    setChecked(isItemChecked);
  }, [device]);

  return (
    <div>
      <styled.Label
        control={
          <Checkbox
            sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
            onChange={handleChange}
            checked={checked}
            disabled={checked}
          />
        }
        label={
          <div>
            <h3>{device.display_name}</h3>
            <div>{device.comercial_description}</div>
            <div>{device.serial_number}</div>
            <div>{device.asset_type}</div>
          </div>
        }
      />
    </div>
  );
};

Input.propTypes = {
  device: PropTypes.object,
  totalChecked: PropTypes.number,
  shipmentID: PropTypes.number,
  setTotalChecked: PropTypes.func,
};

export default Input;
