import styled from 'styled-components';
import colors from 'styles/colors';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;
  margin-bottom: 16px;
  &::-webkit-scrollbar {
    height: 10px;
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${colors.gray4};
    border-right: none;
    border-left: none;
    border-radius: 8px;
    border: 3px solid #f4f8fb;
  }
`;

export const Row = styled.div`
  display: flex;
  margin-bottom: 24px;
  justify-content: space-between;
  width: 100%;
  min-height: 75px;
`;

export const BigItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 48%;
  height: 100%;
`;

export const SmallItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 16%;
`;

export const Title = styled.div`
  display: flex;
  margin-bottom: 8px;
  color: ${colors.gray1};
  letter-spacing: 0.5px;
  font-size: 0.813rem;
  font-weight: 500;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const Serial = styled.div`
  display: flex;
  align-items: center;
  margin-left: 5px;
  font-size: 0.625rem;
`;
