import React from 'react';
import {
  Wrapper,
  Container,
  ButtonWrapper,
  IconDiv,
  Incident,
  Feedback,
} from './styled';
import Item from './Item';
import { form_es } from 'components/pages/App/constants';

const Options = () => {
  return (
    <Wrapper>
      <Container>
        <ButtonWrapper>
          <Item
            rent
            title="rent.title"
            subtitle="rent.subtitle"
            icon={<IconDiv dark>+</IconDiv>}
            onClick={() => window.open(form_es + '1')}
          />
        </ButtonWrapper>
        <ButtonWrapper>
          <Item
            title="incident.title"
            subtitle="incident.subtitle"
            icon={
              <IconDiv>
                <Incident />
              </IconDiv>
            }
            onClick={() => window.open(form_es + '2')}
          />
        </ButtonWrapper>
        <ButtonWrapper>
          <Item
            title="feedback.title"
            subtitle="feedback.subtitle"
            icon={
              <IconDiv>
                <Feedback />
              </IconDiv>
            }
            onClick={() => window.open(form_es + '3')}
          />
        </ButtonWrapper>
      </Container>
    </Wrapper>
  );
};

export default Options;
