import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import InfoRow from 'components/commons/Texts/InfoRow';
import { Text } from './styled';

const Contract = (props) => {
  if (
    props.module != 2 ||
    !props.device ||
    !props.device.annex_id ||
    props.device.annex_end_date
  )
    return null;

  return (
    <>
      <InfoRow
        title={<FormattedMessage id={'services.devices.key19'} />}
        value={props.device.annex_id}
      />
      <InfoRow
        title={<FormattedMessage id={'inventory.column6'} />}
        value={props.device.client_name}
      />
      <InfoRow
        title={<FormattedMessage id={'services.devices.key3'} />}
        value={props.device.annex_price}
      />
      <InfoRow
        title={<FormattedMessage id={'services.devices.key5'} />}
        value={props.device.annex_discount}
      />
      <InfoRow
        title={<FormattedMessage id={'services.devices.key4'} />}
        value={props.device.annex_guarantee}
      />
      <InfoRow
        title={<FormattedMessage id={'services.devices.key6'} />}
        value={props.device.annex_ship_date}
      />
      <InfoRow
        title={<FormattedMessage id={'services.devices.key8'} />}
        value={props.device.annex_start_date}
      />
      <InfoRow
        title={<FormattedMessage id={'services.devices.key9'} />}
        value={props.device.annex_period}
      />
      <InfoRow
        title={<FormattedMessage id={'services.devices.key7'} />}
        value={props.device.tacking_number}
      />
      <InfoRow
        title={<FormattedMessage id={'services.devices.key13'} />}
        value={props.device.annex_signature_date}
      />
      <InfoRow
        title={<FormattedMessage id={'services.devices.key14'} />}
        value={props.device.annex_amount_devices}
      />
      <InfoRow
        title={<FormattedMessage id={'services.devices.key15'} />}
        value={
          <Text onClick={() => window.open(props.device.annex_url, '_blank')}>
            Link
          </Text>
        }
      />
      <InfoRow
        title={<FormattedMessage id={'services.devices.key20'} />}
        value={props.device.contract_id}
      />
      <InfoRow
        title={<FormattedMessage id={'services.devices.key16'} />}
        value={props.device.contract_signature_date}
      />
      <InfoRow
        title={<FormattedMessage id={'services.devices.key17'} />}
        value={
          <Text
            onClick={() => window.open(props.device.contract_url, '_blank')}
          >
            Link
          </Text>
        }
      />
      <InfoRow
        title={<FormattedMessage id={'services.devices.key18'} />}
        value={
          <FormattedMessage
            id={'services.contract_type' + props.device.contract_description}
            values={{ id: props.device.contract_id }}
          />
        }
      />
    </>
  );
};

Contract.propTypes = {
  module: PropTypes.number,
  device: PropTypes.any,
};

export default Contract;
