import React from 'react';
import PropTypes from 'prop-types';
import { Container, Tag } from './styled';
import { FormattedMessage } from 'react-intl';

const DevicesTag = (props) => {
  return (
    <Container onClick={props.onClick}>
      <Tag status={props.status}>
        <FormattedMessage id={'device.state' + props.status} />
      </Tag>
    </Container>
  );
};

DevicesTag.propTypes = {
  status: PropTypes.string,
  onClick: PropTypes.func,
};

export default DevicesTag;
