import React from 'react';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled as st } from '@mui/material/styles';

export const CustomBox = styled(Box)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const Paragraph = styled.p`
  margin: 3px 0 0;
  font-size: 12px;
`;

export const CustomTooltip = st(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#072536',
    fontSize: 12,
    lineHeight: 1,
    padding: '10px 15px',
    maxWidth: 'none',
  },
  [`& .${tooltipClasses.test}`]: {
    backgroundColor: '#fff000',
  },
}));
