import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Label from 'components/commons/Texts/Label';
import BasicInput from 'components/commons/Inputs/BasicInput';
import { removeFirstWhiteSpace } from 'utils/functions/general';
import {
  FirstRowDiv,
  InputContainer,
  InputContainerMiddle,
  MiddleInputContainer,
} from './styled';

const SecondRow = (props) => {
  return (
    <FirstRowDiv>
      <InputContainer>
        <Label value={<FormattedMessage id={'shipments.input7'} />} />
        <BasicInput
          onChange={(e) =>
            props.handleChange(
              'receiverName',
              removeFirstWhiteSpace(e.target.value),
            )
          }
          maxLength={40}
          value={props.receiverName}
          disabled={props.show === 2}
        />
      </InputContainer>
      <InputContainer>
        <Label value={<FormattedMessage id={'shipments.input8'} />} />
        <BasicInput
          onChange={(e) =>
            props.handleChange('street', removeFirstWhiteSpace(e.target.value))
          }
          maxLength={50}
          value={props.street}
          disabled={props.show === 2}
        />
      </InputContainer>
      <InputContainerMiddle>
        <MiddleInputContainer>
          <Label value={<FormattedMessage id={'shipments.input18'} />} />
          <BasicInput
            onChange={(e) =>
              props.handleChange(
                'number',
                removeFirstWhiteSpace(e.target.value),
              )
            }
            maxLength={5}
            value={props.number}
          />
        </MiddleInputContainer>
        <MiddleInputContainer>
          <Label value={<FormattedMessage id={'shipments.input9'} />} />
          <BasicInput
            onChange={(e) =>
              props.handleChange('floor', removeFirstWhiteSpace(e.target.value))
            }
            maxLength={5}
            value={props.floor}
          />
        </MiddleInputContainer>
      </InputContainerMiddle>
      <InputContainerMiddle>
        <MiddleInputContainer>
          <Label value={<FormattedMessage id={'shipments.input19'} />} />
          <BasicInput
            onChange={(e) =>
              props.handleChange('stair', removeFirstWhiteSpace(e.target.value))
            }
            maxLength={5}
            value={props.stair}
          />
        </MiddleInputContainer>
        <MiddleInputContainer>
          <Label value={<FormattedMessage id={'shipments.input10'} />} />
          <BasicInput
            onChange={(e) =>
              props.handleChange('door', removeFirstWhiteSpace(e.target.value))
            }
            maxLength={5}
            value={props.door}
          />
        </MiddleInputContainer>
      </InputContainerMiddle>
    </FirstRowDiv>
  );
};

SecondRow.propTypes = {
  door: PropTypes.string,
  floor: PropTypes.string,
  handleChange: PropTypes.func,
  number: PropTypes.string,
  receiverName: PropTypes.string,
  show: PropTypes.string,
  stair: PropTypes.string,
  street: PropTypes.string,
};

export default SecondRow;
