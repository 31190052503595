import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import * as styled from '../styled';
import Item from '../Item';

const InProgress = ({ fulfillments, setItemEdit }) => {
  const items = fulfillments.filter(
    (item) => item.status === '004' || item.status === '005',
  );

  // Order by STATUS and SHIPMENT ID
  items.sort((a, b) => {
    if (a.status === b.status) {
      return a.shipment_id < b.shipment_id ? -1 : 1;
    } else {
      return a.status < b.status ? -1 : 1;
    }
  });

  return (
    <styled.BoxWrap>
      <styled.Heading>
        <FormattedMessage id={'fulfillments.title1'} />
      </styled.Heading>
      {items.map((item) => (
        <Item key={item.shipment_id} item={item} setItemEdit={setItemEdit} />
      ))}
    </styled.BoxWrap>
  );
};

InProgress.propTypes = {
  fulfillments: PropTypes.array,
  setItemEdit: PropTypes.func,
};

export default InProgress;
