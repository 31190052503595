import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Label from 'components/commons/Texts/Label';
import BasicInput from 'components/commons/Inputs/BasicInput';
import { FirstRowDiv, InputContainer } from './styled';

const FirstRow = (props) => {
  return (
    <FirstRowDiv>
      <InputContainer>
        <Label value={<FormattedMessage id={'services.device.input1'} />} />
        <BasicInput
          min={0}
          type="number"
          value={props.price}
          onChange={(value) => props.handleChange('price', value.target.value)}
        />
      </InputContainer>
      <InputContainer>
        <Label value={<FormattedMessage id={'services.device.input2'} />} />
        <BasicInput
          min={0}
          type="number"
          value={props.guarantee}
          onChange={(value) =>
            props.handleChange('guarantee', value.target.value)
          }
        />
      </InputContainer>
      <InputContainer>
        <Label value={<FormattedMessage id={'services.device.input3'} />} />
        <BasicInput
          onChange={(e) => props.handleChange('discount', e.target.value)}
          value={props.discount}
          type="number"
        />
      </InputContainer>
    </FirstRowDiv>
  );
};

FirstRow.propTypes = {
  handleChange: PropTypes.func,
  guarantee: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  discount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default FirstRow;
