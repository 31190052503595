import styled from 'styled-components';
import colors from 'styles/colors';

const handleBackground = (status) => {
  switch (status) {
    case '999':
      return '#4AB088';
    case '998':
      return '#F1B64F';
    case '997':
      return '#DDDDDD';
  }
};

export const Container = styled.div`
  width: 50px;
  height: 20px;
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
`;

export const Tag = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 16px;
  width: 16px;
  padding: 0;
  border-radius: 25px;
  color: ${colors.white};
  background-color: ${({ status }) => handleBackground(status)};

  div {
    height: 16px;
    width: 16px;
    border-radius: 25px;
    overflow: hidden;
    text-indent: -9999px;
  }
`;
