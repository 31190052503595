import { checkStatus, parseJSON } from 'utils/request';
import config from 'config.js';
import { selectToken } from 'store/Login/selectors';
import { setLoading } from 'store/General/actions';

export const createSupplier = (
  setError,
  name,
  mail,
  phone,
  discount,
  closeModal,
  inserting,
  setInserted,
) => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    setError('suppliers.status1');
    const token = selectToken(getState());
    const requestURL = `${config.baseURL}/v1/supplier?token=${token}`;
    try {
      const response = await fetch(requestURL, {
        method: 'POST',
        body: JSON.stringify({
          name: name,
          mail: mail,
          phone_number: phone,
          discount: discount,
          inserting: inserting,
        }),
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'content-type': 'application/json',
        },
      });
      const response_1 = await checkStatus(response);
      const response2 = await parseJSON(response_1);
      if (response2.status === '1') {
        setInserted(true);
        setTimeout(closeModal, 1200);
      } else {
        setError('suppliers.status' + response2.status);
      }
    } catch (error) {
      setError('suppliers.status99');
    }
    dispatch(setLoading(false));
  };
};

export const getSuppliers = (setSuppliers, setError, supplierId) => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    const token = selectToken(getState());
    const requestURL = `${
      config.baseURL
    }/v1/supplier?token=${token}&supplier_id=${supplierId || ''}`;
    try {
      const response = await fetch(requestURL, {
        method: 'GET',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
        },
      });
      const response_1 = await checkStatus(response);
      const response2 = await parseJSON(response_1);
      if (response2.status === '1') {
        setSuppliers(response2.result);
      }
    } catch (error) {
      setError('suppliers.status99');
    }
    dispatch(setLoading(false));
  };
};
