import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import TableIconsRow from 'components/commons/Icons/TableIconsRow/index.js';
import { Container, Wrapper, Left, Title, Subtitle } from './styled';

const Card = (props) => {
  return (
    <Container>
      <Wrapper>
        <Left>
          <Title blurred={props.blurred} blur={props.blur}>
            {props.name}
          </Title>
          <Subtitle>
            <FormattedMessage id={props.state} />
          </Subtitle>
        </Left>
        <TableIconsRow
          onAdd={props.onAdd}
          onDelete={props.onDelete}
          onInfo={props.onInfo}
          onEdit={props.onEdit}
          onShow={props.onShow}
          onAuxClick={props.onAuxClick}
        />
      </Wrapper>
    </Container>
  );
};

Card.propTypes = {
  name: PropTypes.string,
  state: PropTypes.string,
  onAdd: PropTypes.func,
  onDelete: PropTypes.func,
  onInfo: PropTypes.func,
  onEdit: PropTypes.func,
  onShow: PropTypes.func,
  onAuxClick: PropTypes.func,
  blurred: PropTypes.bool,
  blur: PropTypes.bool,
};

export default Card;
