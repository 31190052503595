import styled from 'styled-components';
import colors from 'styles/colors';

export const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 50px;
  color: ${(props) => (props.theme === 'dark' ? colors.white : colors.blue5)};
  background-color: ${(props) =>
    props.theme === 'dark' ? colors.blue5 : null};
  border: ${(props) =>
    props.theme === 'light' ? '1px solid' + colors.blue5 : null};
  text-align: center;
  font-size: ${(props) => (props.size === 'small' ? '0.813rem' : '1rem')};
  transition: all 0.4s;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  z-index: 1;

  //In case Disabled override
  background-color: ${(props) =>
    props.disabled && props.theme === 'dark' ? colors.gray4 : null};
  color: ${(props) =>
    props.disabled && props.theme === 'light' ? colors.gray4 : null};
  border: ${(props) =>
    props.disabled && props.theme === 'light'
      ? '1px solid' + colors.gray4
      : null};

  &:hover {
    transform: ${(props) => (props.disabled ? null : 'translateY(-3px)')};
    box-shadow: ${(props) =>
      props.disabled ? null : '0 10px 20px rgba(0, 0, 0, 0.2)'};
  }
`;
