import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Label from 'components/commons/Texts/Label';
import BasicInput from 'components/commons/Inputs/BasicInput';
import { removeFirstWhiteSpace } from 'utils/functions/general';
import { FirstRowDiv, InputContainer } from './styled';

const ThirdRow = (props) => {
  return (
    <FirstRowDiv>
      <InputContainer>
        <Label value={<FormattedMessage id={'shipments.input12'} />} />
        <BasicInput
          onChange={(e) =>
            props.handleChange('city', removeFirstWhiteSpace(e.target.value))
          }
          maxLength={50}
          value={props.city}
          disabled={props.show === 2}
        />
      </InputContainer>
      <InputContainer>
        <Label value={<FormattedMessage id={'shipments.input13'} />} />
        <BasicInput
          onChange={(e) =>
            props.handleChange('country', removeFirstWhiteSpace(e.target.value))
          }
          maxLength={20}
          value={props.country}
          disabled={props.show === 2}
        />
      </InputContainer>
      <InputContainer>
        <Label value={<FormattedMessage id={'shipments.input11'} />} />
        <BasicInput
          onChange={(e) =>
            props.handleChange('zipCode', removeFirstWhiteSpace(e.target.value))
          }
          maxLength={10}
          value={props.zipCode}
          disabled={props.show === 2}
        />
      </InputContainer>
      <InputContainer>
        <Label value={<FormattedMessage id={'shipments.input14'} />} />
        <BasicInput
          onChange={(e) =>
            props.handleChange(
              'phoneNumber',
              removeFirstWhiteSpace(e.target.value),
            )
          }
          maxLength={20}
          value={props.phoneNumber}
        />
      </InputContainer>
    </FirstRowDiv>
  );
};

ThirdRow.propTypes = {
  city: PropTypes.string,
  country: PropTypes.string,
  handleChange: PropTypes.func,
  zipCode: PropTypes.string,
  phoneNumber: PropTypes.string,
  show: PropTypes.string,
};

export default ThirdRow;
