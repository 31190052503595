import React from 'react';
import { FormattedMessage } from 'react-intl';
export const STATUS = [
  {
    value: '001',
    label: <FormattedMessage id={'device.state001'} />,
  },
  {
    value: '002',
    label: <FormattedMessage id={'device.state002'} />,
  },
  {
    value: '010',
    label: <FormattedMessage id={'device.state010'} />,
  },
];
