import React from 'react';
import PropTypes from 'prop-types';
import { Input } from './styled';

const GenericInput = (props) => {
  return (
    <Input
      disabled={props.disabled}
      value={props.value}
      onChange={props.onChange}
      error={props.error}
      type={props.type}
      name={props.name}
      placeholder={props.placeholder}
      onKeyDown={props.onKeyDown}
    />
  );
};

GenericInput.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  type: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  onKeyDown: PropTypes.func,
};

export default GenericInput;
