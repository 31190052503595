import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Label from 'components/commons/Texts/Label';
import BasicInput from 'components/commons/Inputs/BasicInput';
import { FirstRowDiv, InputContainer } from './styled';
import MultipleInput from 'components/commons/Inputs/MultipleInput';
import { removeFirstWhiteSpace } from 'utils/functions/general';

const FirstRow = (props) => {
  return (
    <FirstRowDiv>
      <InputContainer>
        <Label value={<FormattedMessage id={'suppliers.input1'} />} />
        <BasicInput
          onChange={(e) =>
            props.handleChange('name', removeFirstWhiteSpace(e.target.value))
          }
          maxLength={70}
          value={props.name}
          disabled={props.isEditing}
        />
      </InputContainer>
      <InputContainer>
        <Label value={<FormattedMessage id={'suppliers.input2'} />} />
        <MultipleInput items={props.mail} handleChange={props.setMail} />
      </InputContainer>
      <InputContainer>
        <Label value={<FormattedMessage id={'suppliers.input3'} />} />
        <MultipleInput
          items={props.phone}
          handleChange={props.setPhone}
          isNotMail
          type="number"
        />
      </InputContainer>
      <InputContainer>
        <Label value={<FormattedMessage id={'suppliers.input4'} />} />
        <BasicInput
          onChange={(e) => props.handleChange('discount', e.target.value)}
          value={props.discount}
          type="number"
        />
      </InputContainer>
    </FirstRowDiv>
  );
};

FirstRow.propTypes = {
  handleChange: PropTypes.func,
  name: PropTypes.string,
  phone: PropTypes.any,
  mail: PropTypes.any,
  discount: PropTypes.any,
  setMail: PropTypes.func,
  setPhone: PropTypes.func,
  isEditing: PropTypes.bool,
};

export default FirstRow;
