export const COLUMNS = [
  {
    name: 'display_name',
    label: 'Display Name',
  },
  {
    name: 'asset_type',
    label: 'Asset',
  },
  {
    name: 'serial_number',
    label: 'Serial Number',
  },
  {
    name: 'comercial_description',
    label: 'Comercial Description',
  },
];
