import React, { useEffect } from 'react';
import MainLayout from 'components/commons/MainLayout';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { selectUser } from 'store/Login/selectors';
import { setModule } from 'store/General/actions';
import { redirect } from 'utils/functions/redirect_lang';
import * as D from 'components/pages/App/constants';
import { FormattedMessage } from 'react-intl';
import history from 'utils/history';
import {
  Wrapper,
  Title,
  Container,
  SubtitleContainer,
  PreviousSubtitle,
  ActualSubtitle,
  Box,
  Margin,
} from './styled';

const Info = (props) => {
  const user = useSelector(selectUser);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user.permissions[props.permission]) {
      dispatch(setModule(props.moduleName));
      redirect(props.redirectArray);
    } else {
      history.replace(D.defaultRedirectNoP);
    }
  }, []);

  return (
    <>
      <MainLayout>
        <Wrapper>
          <Title>{props.title}</Title>
          <SubtitleContainer>
            <PreviousSubtitle
              onClick={() => history.push(props.setModuleOnClick)}
            >
              <FormattedMessage id={props.subtitle} values={props.values} />
            </PreviousSubtitle>
            <ActualSubtitle>
              {'>'} {props.title}
            </ActualSubtitle>
          </SubtitleContainer>
          <Container empty={props.empty}>
            <Box>
              <Margin empty={props.empty}>{props.children}</Margin>
            </Box>
          </Container>
        </Wrapper>
      </MainLayout>
    </>
  );
};

Info.propTypes = {
  match: PropTypes.any,
  permission: PropTypes.string,
  moduleName: PropTypes.string,
  redirectArray: PropTypes.array,
  title: PropTypes.any,
  subtitle: PropTypes.string,
  setModuleOnClick: PropTypes.string,
  children: PropTypes.node,
  values: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  empty: PropTypes.bool,
};

export default Info;
