import React from 'react';
import { Container, ImageContainer, Image, ButtonContainer } from './styled';
import GenericButton from 'components/commons/Buttons/GenericButton';
import { FormattedMessage } from 'react-intl';
import Nelson from 'images/Nelson.png';
import history from 'utils/history';

const NotFound = () => {
  return (
    <Container>
      <FormattedMessage id="notFound" />
      <ImageContainer>
        <Image src={Nelson} />
      </ImageContainer>
      <ButtonContainer>
        <GenericButton
          title={'Home'}
          theme="dark"
          onClick={() => history.replace('')}
        />
      </ButtonContainer>
    </Container>
  );
};

export default NotFound;
