import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { getClients } from 'store/Clients/actions';
import { BodyDiv } from './styled';
import Card from 'components/commons/Card';
import * as D from 'components/pages/App/constants';
import history from 'utils/history';

const Body = (props) => {
  const dispatch = useDispatch();

  const [allClients, setAllClients] = useState([]);

  useEffect(() => {
    dispatch(getClients(setAllClients));
  }, []);

  const handleArray = (row) => {
    return {
      name: row.name,
      nif: row.nif,
    };
  };

  const handleFilter = () => {
    let array = [];

    allClients.forEach((row) => {
      if (row.name.toUpperCase().indexOf(props.filter.toUpperCase()) > -1) {
        array.push(handleArray(row));
      }
    });
    return array;
  };

  return (
    <BodyDiv>
      {(props.filter ? handleFilter() : allClients).map((client, index) => {
        return (
          <Card
            key={index}
            name={client.name}
            state={'suppliers.subtitle'}
            onInfo={() => history.push(D.services_info_es + client.nif)}
            onAuxClick={() =>
              window.open(D.services_info_es + client.nif, '_blank')
            }
          />
        );
      })}
    </BodyDiv>
  );
};

Body.propTypes = {
  filter: PropTypes.string,
};

export default Body;
