import React, { useState, useEffect } from 'react';
import Table from 'components/commons/Table';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { getOrders } from 'store/Orders/actions';
import * as C from './constants';
import { stringToInputDate } from 'utils/functions/DateUtils';
import { selectUser } from 'store/Login/selectors';
import { useSelector } from 'react-redux';

const OrdersTable = (props) => {
  const dispatch = useDispatch();
  const [orders, setOrders] = useState(null);
  const user = useSelector(selectUser);

  useEffect(() => {
    if (!props.openModal) {
      setOrders(null);
      dispatch(getOrders(setOrders));
      props.setFilter('');
    }
  }, [props.openModal]);

  const handleFilter = () => {
    if (props.filter) {
      let array = [];

      orders.forEach((row) => {
        if (
          row.supplier_order.toUpperCase().indexOf(props.filter.toUpperCase()) >
            -1 ||
          row.supplier.toUpperCase().indexOf(props.filter.toUpperCase()) > -1
        ) {
          array.push({
            amount_devices: row.amount_devices,
            devices: row.devices,
            discount: row.discount,
            ending_date: row.ending_date,
            inserted_on: row.inserted_on,
            order_number: row.order_number,
            supplier_order: row.supplier_order,
            start_date: row.start_date,
            supplier: row.supplier,
            supplier_id: row.supplier_id,
            state: row.state,
          });
        }
      });
      return array;
    }
  };

  const handleEdit = (order) => {
    props.setOrderEdit({
      amount_devices: order.amount_devices,
      devices: order.devices,
      ending_date: stringToInputDate(order.ending_date),
      supplier_order: order.supplier_order,
      start_date: stringToInputDate(order.start_date),
      supplier: order.supplier,
      supplier_id: order.supplier_id,
    });
    props.setOpenModal(2);
  };

  const handleDelete = (order) => {
    props.setOrderEdit(order.order_number);
    props.setOpenModal(3);
  };

  const handleAdd = (order) => {
    props.setOrderEdit({
      supplier_order: order.supplier_order,
      order_number: order.order_number,
      discount: order.discount,
      devices: order.devices,
      amount_devices: order.amount_devices,
    });
    props.setOpenModal(4);
  };

  if (!orders) return null;
  return (
    <Table
      columns={C.columns(handleEdit, user, handleDelete, handleAdd)}
      data={props.filter ? handleFilter() : orders}
    />
  );
};

OrdersTable.propTypes = {
  openModal: PropTypes.number,
  setOrderEdit: PropTypes.func,
  setOpenModal: PropTypes.func,
  filter: PropTypes.string,
  setFilter: PropTypes.func,
};

export default OrdersTable;
