import styled from 'styled-components';
import colors from 'styles/colors';

export const Wrapper = styled.div`
  background-color: ${colors.white};
  border-radius: 8px;
  display: flex;
  width: 48%;
  height: 100%;
  overflow: hidden;

  @media (max-width: 1024px) {
    margin-bottom: 25px;
    display: block;
    width: 100%;
    height: auto;
    overflow: initial;
  }
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 12px 16px;
  height: 90%;

  @media (max-width: 1024px) {
    display: block;
    box-sizing: border-box;
    padding: 12px 16px;
    margin: 0;
    height: auto;
  }
`;

export const Title = styled.div`
  display: flex;
  color: ${colors.blue2};
  font-weight: 600;
  font-size: 1rem;
  padding: 0 4px 8px 4px;
  margin-bottom: 18px;
  white-space: nowrap;
  border-bottom: 1px solid ${colors.gray5};
`;

export const Body = styled.div`
  display: flex;
  width: 100%;
  height: calc(100% - 52px);
`;

export const BodyWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  margin: 0 16px;
`;

export const LeftContainer = styled.div`
  display: flex;
  width: 40%;
  align-items: center;
`;

export const LeftWrapper = styled.div`
  display: flex;
  width: 80%;
  height: 80%;
  align-items: center;
  margin-bottom: 20px;
`;

export const RightContainer = styled.div`
  display: flex;
  width: 60%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export const RightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 8px;
  border-bottom: 1px solid ${colors.gray5};
  margin-bottom: 18px;
`;

export const RowLeft = styled.div`
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: 500;
  color: ${colors.gray3};
  margin-left: 10px;
`;

export const Icon = styled.div`
  display: flex;
  height: 13px;
  width: 13px;
  border-radius: 4px;
  background-color: ${(props) => props.color};
  margin-right: 8px;
`;

export const RowRight = styled.div`
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: 500;
  color: ${colors.blue2};
  margin-right: 10px;
`;
