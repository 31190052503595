import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { selectUser, selectUserCopy } from 'store/Login/selectors';
import { selectLoading, selectModule } from 'store/General/selectors';
import { BUTTONS } from './constants';
import { useDispatch, useSelector } from 'react-redux';
import { lastConnection } from 'store/Login/actions';
import LateralmenuButton from 'components/commons/Buttons/LateralMenuButton';
import history from 'utils/history';
import Loading from 'components/commons/Loading';
import Menu from '../Menu';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import DefaultAvatar from 'images/default_avatar.png';
import { clientViewEndAction } from 'store/Login/actions';
import { clients_es } from 'components/pages/App/constants';
import {
  Wrapper,
  TopContainer,
  UserContainer,
  UserInfo,
  Picture,
  Name,
  Body,
  LeftContainer,
  EmenduContainer,
  EmenduText,
  EmenduLogo,
  ButtonsContainer,
  Logout,
  IconContainer,
  BackArrow,
} from './styled';

const MainLayout = (props) => {
  const user = useSelector(selectUser);
  const loading = useSelector(selectLoading);
  const module = useSelector(selectModule);
  const userCopy = useSelector(selectUserCopy);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(lastConnection());
  }, []);

  const backToAdminView = () => {
    dispatch(clientViewEndAction());
    history.push(clients_es);
  };

  return (
    <Wrapper>
      {loading && <Loading />}
      <TopContainer>
        <IconContainer>
          {userCopy && <BackArrow onClick={() => backToAdminView()} />}
        </IconContainer>
        <UserContainer>
          <UserInfo>
            <Picture
              src={
                user.profile_picture.includes('default_avatar.png')
                  ? DefaultAvatar
                  : user.profile_picture
              }
              alt={'icon'}
            />
            <Name>{user.company}</Name>
            <Menu>
              <MenuItem onClick={() => history.push('/logout')}>
                <ListItemIcon>
                  <Logout />
                </ListItemIcon>
                Logout
              </MenuItem>
            </Menu>
          </UserInfo>
        </UserContainer>
      </TopContainer>
      <Body>
        <LeftContainer>
          <ButtonsContainer>
            {BUTTONS.map((b) => (
              <LateralmenuButton
                key={b.active}
                active={module === b.active}
                title={b.title}
                onClick={() => history.push(b.push)}
                show={user.permissions[b.permission]}
              >
                {React.cloneElement(b.icon, {
                  active: (module === b.active).toString(),
                })}
              </LateralmenuButton>
            ))}
          </ButtonsContainer>
          <EmenduContainer>
            <EmenduText>POWERED BY</EmenduText>
            <EmenduLogo />
          </EmenduContainer>
        </LeftContainer>
        {props.children}
      </Body>
    </Wrapper>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node,
};

export default MainLayout;
