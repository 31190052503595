import { checkStatus, parseJSON } from 'utils/request';
import config from 'config.js';
import { selectToken } from 'store/Login/selectors';
import { setLoading } from 'store/General/actions';

export const createOrder = (
  setError,
  supplier,
  orderNumber,
  startDate,
  endingDate,
  setInserted,
  inserting,
  closeModal,
  amountDevices,
) => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    setError('orders.status1');
    const token = selectToken(getState());
    const requestURL = `${config.baseURL}/v1/order?token=${token}`;
    try {
      const response = await fetch(requestURL, {
        method: 'POST',
        body: JSON.stringify({
          supplier_id: supplier,
          start_renting_date: startDate,
          supplier_order_number: orderNumber,
          amount_devices: amountDevices,
          end_renting_date: endingDate,
          inserting: inserting,
        }),
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'content-type': 'application/json',
        },
      });
      const response_1 = await checkStatus(response);
      const response2 = await parseJSON(response_1);
      if (response2.status === '1') {
        setInserted(true);
        setTimeout(closeModal, 1200);
      } else {
        setError('orders.status' + response2.status);
      }
    } catch (error) {
      setError('orders.status99');
    }
    dispatch(setLoading(false));
  };
};

export const getOrders = (setOrders, orderId) => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    const token = selectToken(getState());
    const requestURL = `${config.baseURL}/v1/order?token=${token}&order_id=${
      orderId || ''
    }`;
    try {
      const response = await fetch(requestURL, {
        method: 'GET',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
        },
      });
      const response_1 = await checkStatus(response);
      const response2 = await parseJSON(response_1);
      if (response2.status === '1') {
        setOrders(response2.result);
      }
      // eslint-disable-next-line no-empty
    } catch (error) {}
    dispatch(setLoading(false));
  };
};

export const getOrdersDevices = (setDevices, orderId) => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    const token = selectToken(getState());
    const requestURL = `${config.baseURL}/v1/order/devices?token=${token}&order_id=${orderId}`;
    try {
      const response = await fetch(requestURL, {
        method: 'GET',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
        },
      });
      const response_1 = await checkStatus(response);
      const response2 = await parseJSON(response_1);
      if (response2.status === '1') {
        setDevices(response2.result);
      }
      // eslint-disable-next-line no-empty
    } catch (error) {}
    dispatch(setLoading(false));
  };
};

export const deleteOrder = (orderId, setInserted, closeModal) => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    const token = selectToken(getState());
    const requestURL = `${config.baseURL}/v1/order?token=${token}&order_id=${orderId}`;
    try {
      const response = await fetch(requestURL, {
        method: 'DELETE',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
        },
      });
      const response_1 = await checkStatus(response);
      const response2 = await parseJSON(response_1);
      if (response2.status === '1') {
        setInserted(true);
        setTimeout(closeModal, 1200);
      }
      // eslint-disable-next-line no-empty
    } catch (error) {}
    dispatch(setLoading(false));
  };
};

export const getDevicesForOrders = (setAllDevices) => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    const token = selectToken(getState());
    const requestURL = `${config.baseURL}/v1/product/orders/all?token=${token}`;
    try {
      const response = await fetch(requestURL, {
        method: 'GET',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
        },
      });
      const response_1 = await checkStatus(response);
      const response2 = await parseJSON(response_1);
      if (response2.status === '1') {
        setAllDevices(response2.result);
      }
      // eslint-disable-next-line no-empty
    } catch (error) {}
    dispatch(setLoading(false));
  };
};

export const insertOrderDevices = (
  setError,
  setInserted,
  closeModal,
  orderEdit,
  infoDevices,
) => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    setError('orders.status1');
    const token = selectToken(getState());
    const requestURL = `${config.baseURL}/v1/order/product?token=${token}&inserting=true&order_id=${orderEdit.order_number}&discount=${orderEdit.discount}`;
    try {
      const response = await fetch(requestURL, {
        method: 'POST',
        body: JSON.stringify(infoDevices),
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'content-type': 'application/json',
        },
      });
      const response_1 = await checkStatus(response);
      const response2 = await parseJSON(response_1);
      if (response2.status === '1') {
        setInserted(true);
        setTimeout(closeModal, 1200);
      } else {
        setError('orders.status' + response2.status);
      }
    } catch (error) {
      setError('orders.status99');
    }
    dispatch(setLoading(false));
  };
};
