import styled from 'styled-components';
import colors from 'styles/colors';
import { ReactComponent as AddIcon } from 'images/add_user.svg';
import { ReactComponent as TrashIcon } from 'images/trash.svg';
import { ReactComponent as InfoIcon } from 'images/info.svg';
import { ReactComponent as EditIcon } from 'images/edit.svg';
import { ReactComponent as Checklist } from 'images/checklist.svg';
import { ReactComponent as addAnnex } from 'images/add_annex.svg';
import { ReactComponent as newTab } from 'images/new_tab.svg';
import { ReactComponent as ShowIcon } from 'images/view.svg';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 4px;
  cursor: ${(props) =>
    props.onClick || props.onAuxClick ? 'pointer' : 'default'};
  opacity: ${(props) => (props.onClick || props.onAuxClick ? '1' : '0')};
`;

export const Add = styled(AddIcon)`
  display: flex;
  width: 25px;
  height: 20px;
  fill: ${colors.gray2};
`;

export const Trash = styled(TrashIcon)`
  display: flex;
  width: 25px;
  height: 20px;
  fill: ${colors.gray2};
`;

export const Info = styled(InfoIcon)`
  display: flex;
  height: 20px;
  width: 25px;
  fill: ${colors.gray2};
`;

export const AddAnnex = styled(addAnnex)`
  display: flex;
  height: 20px;
  width: 25px;
  fill: ${colors.gray2};
`;

export const NewTab = styled(newTab)`
  display: flex;
  height: 20px;
  width: 25px;
  path {
    fill: ${colors.gray2};
  }
`;

export const Edit = styled(EditIcon)`
  display: flex;
  height: 20px;
  width: 25px;
  fill: ${colors.gray2};
`;

export const Show = styled(ShowIcon)`
  display: flex;
  height: 20px;
  width: 25px;
  fill: ${colors.gray2};
`;

export const Check = styled(Checklist)`
  display: flex;
  width: 25px;
  height: 20px;
  fill: ${colors.gray2};
`;
