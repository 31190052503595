import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

export const Options = (
  data,
  onSelect,
  selectable,
  itemsSelected,
  nonSearch,
  height,
  sortOrder,
  draggableColumns,
  selectableRows,
  searchText,
  onSearchChange,
  count,
) => {
  const intl = useIntl();
  return {
    sortOrder: sortOrder && sortOrder,
    draggableColumns: draggableColumns ? { enabled: true } : { enabled: false },
    viewColumns: false,
    download: false,
    print: false,
    tableBodyHeight: height ? height : '290px',
    filter: false,
    count: count ? parseInt(count) : 0,
    responsive: 'standard',
    selectableRowsHeader: false,
    selectableRows: selectableRows ? selectableRows : 'multiple',
    customToolbarSelect: () => {},
    selectToolbarPlacement: 'none',
    rowsSelected: itemsSelected,
    search: !nonSearch,
    searchText: searchText && searchText,
    onSearchChange: (searchText) =>
      onSearchChange ? onSearchChange(searchText) : null,
    isRowSelectable: (item) => {
      if (itemsSelected && itemsSelected.includes(item)) {
        return true;
      } else {
        return selectable;
      }
    },
    rowHover: false,
    onRowSelectionChange: (a, b, items) => {
      onSelect(items);
    },
    rowsPerPage: 1000000000000000,
    customFooter: () => {},
    textLabels: {
      toolbar: {
        search: <FormattedMessage id={'table.localization.search'} />,
      },
      body: {
        toolTip: <FormattedMessage id={'table.localization.sort'} />,
        noMatch: <FormattedMessage id={'table.localization.noMatch'} />,
      },
      pagination: {
        next: intl.formatMessage({ id: 'table.NextPage' }),
        rowsPerPage: <FormattedMessage id={'table.localization.rows'} />,
        previous: intl.formatMessage({ id: 'table.PreviousPage' }),
        displayRows: intl.formatMessage({ id: 'table.localization.of' }),
      },
    },
  };
};
