export default {
  gray1: '#444444',
  gray2: '#666666',
  gray3: '#888888',
  gray4: '#CCCCCC',
  gray5: '#EEEEEE',
  gray6: '#C4C4C4',
  gray7: '#F2F2F2',
  gray8: '#BBBBBB',
  gray9: '#AEAEAE',
  gray10: '#F3F3F3',
  gray11: '#525252',
  gray12: '#FAFAFA',
  gray13: '#DDDDDD',
  white: '#FFFFFF',
  black: '#000000',
  blue1: '#004268',
  blue2: '#072536',
  blue3: '#A6D0E8',
  blue4: '#F4F8FB',
  blue5: '#0E374E',
  blue6: '#ECF0FF',
  blue7: '#D8E0FF',
  blue8: '#DEEBFF',
  blue9: '#EFF4FF',
  blue10: '#F4F7FF',
  blue11: '#476F8C',
  green1: '#5DB993',
  green2: '#AEEDD3',
  green3: '#CBF6EC',
  green4: '#EFFEFA',
  green5: '#028474',
  green6: '#00BCA4',
  green7: '#9ADBD3',
  green8: '#D6F4F0',
  green9: '#33B780',
  green10: '#4AB088',
  orange1: '#D56202',
  orange2: '#FA8E04',
  orange3: '#FFBC67',
  orange4: '#FFE0C3',
  yellow1: '#F9A824',
  yellow2: '#FFC000',
  yellow3: '#FDD735',
  yellow4: '#FFF59D',
  yellow5: '#F1B64F',
  yellow6: '#FFEB97',
  yellow7: '#FFF5CD',
  red1: '#E12B19',
  red2: '#FA503F',
  red3: '#FB8478',
  red4: '#FFE3E0',
  red5: '#F4EBE9',
  red6: '#CF6B64',
};
