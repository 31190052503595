import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Label from 'components/commons/Texts/Label';
import BasicInput from 'components/commons/Inputs/BasicInput';
import { SecondRowDiv, InputContainer } from './styled';
import { removeFirstWhiteSpace } from 'utils/functions/general';

const SecondRow = (props) => {
  return (
    <SecondRowDiv>
      <InputContainer>
        <Label value={<FormattedMessage id={'clients.input6'} />} />
        <BasicInput
          onChange={(e) =>
            props.handleChange('phone', removeFirstWhiteSpace(e.target.value))
          }
          maxLength={20}
          value={props.phone}
        />
      </InputContainer>
      <InputContainer>
        <Label value={<FormattedMessage id={'clients.input7'} />} />
        <BasicInput
          onChange={(e) =>
            props.handleChange(
              'postalCode',
              removeFirstWhiteSpace(e.target.value),
            )
          }
          maxLength={10}
          value={props.postalCode}
        />
      </InputContainer>
      <InputContainer>
        <Label value={<FormattedMessage id={'clients.input8'} />} />
        <BasicInput
          onChange={(e) =>
            props.handleChange('city', removeFirstWhiteSpace(e.target.value))
          }
          maxLength={50}
          value={props.city}
        />
      </InputContainer>
      <InputContainer>
        <Label value={<FormattedMessage id={'clients.input9'} />} />
        <BasicInput
          onChange={(e) =>
            props.handleChange('country', removeFirstWhiteSpace(e.target.value))
          }
          maxLength={20}
          value={props.country}
        />
      </InputContainer>
    </SecondRowDiv>
  );
};

SecondRow.propTypes = {
  handleChange: PropTypes.func,
  phone: PropTypes.string,
  postalCode: PropTypes.string,
  city: PropTypes.string,
  country: PropTypes.string,
};

export default SecondRow;
