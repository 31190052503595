import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Label from 'components/commons/Texts/Label';
import BasicInput from 'components/commons/Inputs/BasicInput';
import * as styled from './styled';
import { avoidZeroFirstAmountDevices } from 'utils/functions/general';

const FirstRow = (props) => {
  return (
    <styled.FirstRowDiv>
      <styled.InputContainer>
        <Label
          value={<FormattedMessage id={'fulfillments.quantityOfPackages'} />}
        />
        <BasicInput
          onChange={(e) =>
            props.handleChange(
              'amountPackages',
              avoidZeroFirstAmountDevices(e.target.value),
            )
          }
          value={props.amountPackages}
          maxLength={50}
        />
      </styled.InputContainer>
      <styled.InputContainer>
        <Label value={<FormattedMessage id={'fulfillments.totalWeight'} />} />
        <BasicInput
          onChange={(e) => props.handleChange('overallWeight', e.target.value)}
          value={props.overallWeight}
          maxLength={50}
        />
      </styled.InputContainer>
    </styled.FirstRowDiv>
  );
};

FirstRow.propTypes = {
  handleChange: PropTypes.func,
  overallWeight: PropTypes.string,
  amountPackages: PropTypes.string,
};

export default FirstRow;
