import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Wrapper, Container, Error, Body } from './styled';
import FirstRow from './FirstRow';
import SecondRow from './SecondRow';
import ThirdRow from './ThirdRow';
import FourthRow from './FourthRow';
import FifthRow from './FifthRow';

const Inputs = (props) => {
  return (
    <>
      <Wrapper>
        <Body show={props.show}>
          <Container>
            <FirstRow
              allCompanies={props.allCompanies}
              amountDevices={props.amountDevices}
              company={props.company}
              defaultCompany={props.defaultCompany}
              handleChange={props.handleChange}
              isCreating={props.show === 1}
              isEditing={props.show === 2}
              actualDate={props.actualDate}
              shippingDate={props.shippingDate}
            />
            <SecondRow
              door={props.door}
              floor={props.floor}
              handleChange={props.handleChange}
              number={props.number}
              receiverName={props.receiverName}
              stair={props.stair}
              street={props.street}
            />
            <ThirdRow
              city={props.city}
              country={props.country}
              handleChange={props.handleChange}
              phoneNumber={props.phoneNumber}
              zipCode={props.zipCode}
            />
            <FourthRow
              trackingLink={props.trackingLink}
              mailLabelUrl={props.mailLabelUrl}
              extraRequests={props.extraRequests}
              handleChange={props.handleChange}
              receiverMail={props.receiverMail}
            />
            <FifthRow
              handleChange={props.handleChange}
              setError={props.setError}
            />
          </Container>
          <Error id={props.error}>
            <FormattedMessage id={props.error} />
          </Error>
        </Body>
      </Wrapper>
    </>
  );
};

Inputs.propTypes = {
  allCompanies: PropTypes.array,
  amountDevices: PropTypes.any,
  city: PropTypes.string,
  company: PropTypes.any,
  country: PropTypes.string,
  defaultCompany: PropTypes.array,
  door: PropTypes.string,
  error: PropTypes.string,
  extraRequests: PropTypes.string,
  floor: PropTypes.string,
  handleChange: PropTypes.func,
  mailLabelUrl: PropTypes.string,
  number: PropTypes.string,
  phoneNumber: PropTypes.string,
  actualDate: PropTypes.string,
  receiverMail: PropTypes.string,
  receiverName: PropTypes.string,
  shipmentId: PropTypes.string,
  shippingDate: PropTypes.string,
  show: PropTypes.number,
  stair: PropTypes.string,
  street: PropTypes.string,
  trackingLink: PropTypes.string,
  zipCode: PropTypes.string,
  setError: PropTypes.func,
};

export default Inputs;
