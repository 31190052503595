import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import DataTable from 'components/commons/Table/DataTable';
import { COLUMNS } from './constants';
// import { useSelector, useDispatch } from 'react-redux';
// import { selectDashboardFilter } from 'store/General/selectors';
// import { setDashboardFilter } from 'store/General/actions';
import GenericButton from 'components/commons/Buttons/GenericButton';
import * as D from 'components/pages/App/constants';
import history from 'utils/history';
import * as styled from './styled';

const Table = (props) => {
  // const dispatch = useDispatch();
  // const dashboardFilter = useSelector(selectDashboardFilter);

  const [height, setHeight] = useState(0);
  const ref = useRef(null);

  const handleResize = () => {
    setHeight(ref.current.clientHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [props.isOpen]);

  /* const handleFilter = (value) => {
    dispatch(setDashboardFilter(value));
  }; */

  return (
    <styled.Wrapper>
      <styled.Container>
        <styled.Title>
          <FormattedMessage id={'table.latestDevices'} />
          <div>
            <GenericButton
              theme="light"
              title={<FormattedMessage id={'table.viewAll'} />}
              onClick={() => history.push(D.devices_es)}
              size="small"
            />
          </div>
        </styled.Title>
        <styled.Body>
          <styled.BodyWrapper ref={ref}>
            <DataTable
              data={props.clientDevices}
              columns={COLUMNS}
              height={height - 1 + 'px'}
              sortOrder={{
                name: 'device_start_renting_date',
                direction: 'desc',
              }}
              draggableColumns
              selectableRows="none"
              dashboard
              count="5"
              // onSearchChange={handleFilter}
              // searchText={dashboardFilter}
              nonSearch
            />
          </styled.BodyWrapper>
        </styled.Body>
      </styled.Container>
    </styled.Wrapper>
  );
};

Table.propTypes = {
  clientDevices: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  isOpen: PropTypes.bool,
  handleChange: PropTypes.func,
};

export default Table;
