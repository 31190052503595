import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Label from 'components/commons/Texts/Label';
import BasicInput from 'components/commons/Inputs/BasicInput';
import { FirstRowDiv, InputContainer } from './styled';

const FourthRow = (props) => {
  return (
    <FirstRowDiv>
      <InputContainer>
        <Label value={<FormattedMessage id={'services.device.input9'} />} />
        <BasicInput
          maxLength={500}
          value={props.description}
          onChange={(value) =>
            props.handleChange('description', value.target.value)
          }
        />
      </InputContainer>
    </FirstRowDiv>
  );
};

FourthRow.propTypes = {
  handleChange: PropTypes.func,
  description: PropTypes.string,
};

export default FourthRow;
