import React, { useState } from 'react';
import InfoButton from 'components/commons/Buttons/InfoButton';
import { FormattedMessage } from 'react-intl';
import Info from './Info';
import Devices from './Devices';
import PropTypes from 'prop-types';
import {
  OptionsContainer,
  OptionsWrapper,
  Option,
  Information,
} from './styled';

const Body = (props) => {
  const [module, setModule] = useState(0);

  return (
    <>
      <OptionsContainer>
        <OptionsWrapper>
          <Option>
            <InfoButton active={module === 0} onClick={() => setModule(0)}>
              <FormattedMessage id={'info.option1'} />
            </InfoButton>
          </Option>
          <Option>
            <InfoButton active={module === 1} onClick={() => setModule(1)}>
              <FormattedMessage id={'info.option2'} />
            </InfoButton>
          </Option>
        </OptionsWrapper>
      </OptionsContainer>
      <Information>
        <Info module={module} order={props.order} />
        <Devices module={module} orderId={props.order.order_number} />
      </Information>
    </>
  );
};

Body.propTypes = {
  order: PropTypes.any,
};

export default Body;
