import styled from 'styled-components';
import colors from 'styles/colors';

export const OptionsContainer = styled.div`
  display: flex;
  height: 100%;
  width: 25%;
  border-right: 1px solid ${colors.gray5};
  max-width: 270px;
  min-width: 190px;
  overflow: hidden;
`;

export const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  margin: 0 24px 0px 0;
`;

export const Option = styled.div`
  display: flex;
  height: 54px;
  width: 100%;
  margin-bottom: 8px;
`;

export const Information = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 15px 0 0 40px;
  overflow: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    height: 10px;
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${colors.gray4};
    border-right: none;
    border-left: none;
    border-radius: 8px;
    border: 3px solid #f4f8fb;
  }
  &::-webkit-scrollbar-track-piece {
    background: transparent;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  height: 100%;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: 30px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin: 0 0 0 40px;
  align-items: center;
`;

export const RightContainer = styled.div`
  display: flex;
  width: 60%;
  @media (max-width: 1030px) {
    width: auto;
  }
`;

export const ButtonSize = styled.div`
  display: flex;
  width: 30%;
  min-width: 170px;
  margin-right: 16px;
  height: 40px;
`;

export const Error = styled.div`
  display: flex;
  color: ${(props) =>
    props.error === 'owner.status100' ? colors.green6 : colors.red2};
  font-size: 0.813rem;
  font-weight: 500;
  opacity: ${(props) => (props.error === 'owner.status1' ? 0 : 1)};
`;
