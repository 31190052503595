import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import InfoRow from 'components/commons/Texts/InfoRow';

const Order = (props) => {
  if (props.module != 1 || !props.device || !props.device.supplier_order_number)
    return null;

  return (
    <>
      <InfoRow
        title={<FormattedMessage id={'info.orders.key1'} />}
        value={props.device.supplier_name}
      />
      <InfoRow
        title={<FormattedMessage id={'info.orders.key2'} />}
        value={props.device.supplier_order_number}
      />
      <InfoRow
        title={<FormattedMessage id={'info.orders.key4'} />}
        value={props.device.order_start_date}
      />
      <InfoRow
        title={<FormattedMessage id={'info.orders.key5'} />}
        value={props.device.order_period}
      />
      <InfoRow
        title={<FormattedMessage id={'info.orders.key6'} />}
        value={props.device.order_amount_devices}
      />
      <InfoRow
        title={<FormattedMessage id={'info.orders.key8'} />}
        value={props.device.order_price}
      />
      <InfoRow
        title={<FormattedMessage id={'info.orders.key9'} />}
        value={props.device.order_discount}
      />
      <InfoRow
        title={<FormattedMessage id={'info.orders.key10'} />}
        value={props.device.order_guarantee}
      />
    </>
  );
};

Order.propTypes = {
  module: PropTypes.number,
  device: PropTypes.any,
};

export default Order;
