import styled from 'styled-components';
import colors from 'styles/colors';
import Box from '@mui/material/Box';

export const BoxWrap = styled(Box)`
  && {
    background-color: ${colors.white};
    height: 100%;
    padding: 15px 15px;
    box-sizing: border-box;
  }
`;

export const Heading = styled.h2`
  color: ${colors.blue2};
  margin: 0;
  padding-bottom: 5px;
  font-weight: 600;
  text-align: center;
  font-size: 16px;
`;
