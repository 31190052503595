import styled from 'styled-components';
import colors from 'styles/colors';

export const Subtitle = styled.div`
  color: ${colors.gray3};
  font-size: 0.938rem;
  font-weight: 300;
  opacity: ${(props) => (props.updated ? 0 : 1)};
`;

export const InputAndError = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  opacity: ${(props) => (props.updated ? 0 : 1)};
`;

export const Error = styled.div`
  display: flex;
  justify-content: space-between;
  width: 98%;
  margin: 4px 0 12px 0;
`;

export const InputContainer = styled.div`
  height: 80px;
  width: 100%;
`;

export const SubText = styled.div`
  color: blue;
  margin: 0;
  color: ${(props) => (props.error ? colors.red2 : colors.gray3)};
  font-size: 0.8rem;
  font-weight: 500;
  ${(props) => props.cursor === 'pointer' && `cursor: pointer;`}
  opacity: ${(props) => (props.error || props.forget ? 100 : 0)};
`;

export const InputsContainer = styled.div`
  margin: 3% 0 0 0;
`;
