import styled from 'styled-components';
import colors from 'styles/colors';

const handleBackground = (status) => {
  switch (status) {
    case '001':
      return colors.orange4;
    case '002':
      return colors.green8;
    case '003':
      return colors.gray5;
    case '004':
      return colors.red5;
    case '005':
      return colors.green8;
    case '006':
      return colors.orange4;
    case '007':
      return colors.green8;
  }
};

const handleColor = (status) => {
  switch (status) {
    case '001':
      return colors.orange2;
    case '002':
      return colors.green5;
    case '003':
      return colors.gray2;
    case '004':
      return colors.red6;
    case '005':
      return colors.green5;
    case '006':
      return colors.orange2;
    case '007':
      return colors.green5;
  }
};

export const Container = styled.div`
  display: flex;
  height: 20px;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const Tag = styled.div`
  align-items: center;
  background-color: ${({ status }) => handleBackground(status)};
  border-radius: 6px;
  color: ${({ status }) => handleColor(status)};
  cursor: ${(props) => (props.status === '003' ? 'pointer' : 'default')};
  display: flex;
  font-size: 0.75rem;
  font-weight: 500;
  height: 100%;
  justify-content: center;
  letter-spacing: -0.4px;
  min-width: 120px;
  padding: 2px 10px;
`;
