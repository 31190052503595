import styled from 'styled-components';
import { ReactComponent as Yellow } from 'images/checkmark_yellow.svg';
import { ReactComponent as Green } from 'images/checkmark_green.svg';
import colors from 'styles/colors';

export const CountContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const GCheck = styled(Green)`
  height: 70px;
  width: 70px;
  circle.st0 {
    fill: white;
  }
  circle.st1 {
    fill: #00bca4;
  }
  path.st1 {
    fill: #00bca4;
  }
  path.st2 {
    fill: white;
  }
`;

export const YCheck = styled(Yellow)`
  height: 70px;
  width: 70px;
  circle {
    fill: white;
  }
  path.st1 {
    fill: #f9a824;
  }
  path.st2 {
    fill: white;
  }
`;

export const CountRight = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  justify-content: center;
`;

export const Title = styled.div`
  color: ${colors.gray1};
  font-weight: 500;
  font-size: 1.25rem;
`;

export const Subtitle = styled.div`
  color: ${colors.gray2};
  font-weight: 400;
  font-size: 0.875rem;
`;
