import { checkStatus, parseJSON } from 'utils/request';
import config from 'config.js';
import { selectToken } from 'store/Login/selectors';
import { setLoading } from 'store/General/actions';

export const getCompanies = (setAllCompanies, nif, nonLoading) => {
  return async (dispatch, getState) => {
    !nonLoading && dispatch(setLoading(true));
    const token = selectToken(getState());
    const requestURL = `${config.baseURL}/v1/auth/company?token=${token}&nif=${
      nif || ''
    }`;
    try {
      const response = await fetch(requestURL, {
        method: 'GET',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
        },
      });
      const response_1 = await checkStatus(response);
      const response2 = await parseJSON(response_1);
      if (response2.status === '1') {
        setAllCompanies(response2.result);
      }
      // eslint-disable-next-line no-empty
    } catch {}
    !nonLoading && dispatch(setLoading(false));
  };
};

export const getShipments = (setShipments, shipmentId) => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    const token = selectToken(getState());
    const requestURL = `${
      config.baseURL
    }/v1/shipment?token=${token}&shipment_id=${shipmentId || ''}`;
    try {
      const response = await fetch(requestURL, {
        method: 'GET',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
        },
      });
      const response_1 = await checkStatus(response);
      const response2 = await parseJSON(response_1);
      if (response2.status === '1') {
        setShipments(response2.result);
      }
      // eslint-disable-next-line no-empty
    } catch (error) {}
    dispatch(setLoading(false));
  };
};

export const createShipment = (
  setError,
  company,
  street,
  number,
  floor,
  door,
  stair,
  city,
  zipCode,
  country,
  receiverName,
  receiverMail,
  phoneNumber,
  extraRequests,
  amountDevices,
  shippingDate,
  trackingLink,
  shipmentId,
  actualDate,
  mailLabelUrl,
  setInserted,
  inserting,
  closeModal,
) => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    setError('shipments.status1');
    const token = selectToken(getState());
    const requestURL = `${config.baseURL}/v1/shipment?token=${token}`;
    const data = new FormData();
    data.append('amount_devices', amountDevices);
    data.append('city', city);
    data.append('company_nif', company);
    data.append('country', country);
    data.append('door', door);
    data.append('estimated_date', shippingDate);
    data.append('extra_requests', extraRequests);
    data.append('floor', floor);
    data.append('inserting', inserting);
    data.append('shipment_file', mailLabelUrl);
    data.append('number', number);
    data.append('phone_number', phoneNumber);
    data.append('actual_date', actualDate);
    data.append('receiver_mail', receiverMail);
    data.append('receiver_name', receiverName);
    data.append('stair', stair);
    data.append('street', street);
    data.append('tracking_link', trackingLink);
    data.append('zip_code', zipCode);
    data.append('shipment_id', shipmentId);
    try {
      const response = await fetch(requestURL, {
        method: 'POST',
        body: data,
        credentials: 'include',
        headers: {
          Accept: 'application/json',
        },
      });
      const response_1 = await checkStatus(response);
      const response2 = await parseJSON(response_1);
      if (response2.status === '1') {
        setInserted(true);
        setTimeout(closeModal, 1200);
      } else {
        setError('shipments.status' + response2.status);
      }
    } catch (error) {
      setError('shipments.status99');
    }
    dispatch(setLoading(false));
  };
};

export const updateShipmentState = (closeModal, shipmentId, shipmentStatus) => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    const token = selectToken(getState());
    const requestURL = `${config.baseURL}/v1/shipment/state?token=${token}`;
    try {
      const response = await fetch(requestURL, {
        method: 'POST',
        body: JSON.stringify({
          shipment_id: shipmentId,
          status: shipmentStatus,
        }),
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'content-type': 'application/json',
        },
      });
      const response_1 = await checkStatus(response);
      const response2 = await parseJSON(response_1);
      if (response2.status === '1') {
        // setFlag && setFlag(!flag);
        closeModal();
      }
      // eslint-disable-next-line no-empty
    } catch {}
    dispatch(setLoading(false));
  };
};

export const getDevicesForShipments = (setAllDevices, companyNif) => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    const token = selectToken(getState());
    const requestURL = `${config.baseURL}/v1/shipment/devices?token=${token}&company_nif=${companyNif}`;
    try {
      const response = await fetch(requestURL, {
        method: 'GET',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
        },
      });
      const response_1 = await checkStatus(response);
      const response2 = await parseJSON(response_1);
      if (response2.status === '1') {
        setAllDevices(response2.result);
      }
      // eslint-disable-next-line no-empty
    } catch (error) {}
    dispatch(setLoading(false));
  };
};

export const insertShipmentDevices = (
  setError,
  setInserted,
  closeModal,
  shipmentEdit,
  infoDevices,
) => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    setError('shipments.status1');
    const token = selectToken(getState());
    const requestURL = `${config.baseURL}/v1/shipment/devices?token=${token}&shipment_id=${shipmentEdit.shipment_id}`;
    try {
      const response = await fetch(requestURL, {
        method: 'POST',
        body: JSON.stringify(infoDevices),
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'content-type': 'application/json',
        },
      });
      const response_1 = await checkStatus(response);
      const response2 = await parseJSON(response_1);
      if (response2.status === '1') {
        setInserted(true);
        setTimeout(closeModal, 1200);
      } else {
        setError('shipments.insert.status' + response2.status);
      }
    } catch (error) {
      setError('shipments.insert.status99');
    }
    dispatch(setLoading(false));
  };
};

export const getShipmentDevices = (setDevices, shipmentId, nonLoading) => {
  return async (dispatch, getState) => {
    !nonLoading && dispatch(setLoading(true));
    const token = selectToken(getState());
    const requestURL = `${config.baseURL}/v1/shipment/devices/info?token=${token}&shipment_id=${shipmentId}`;
    try {
      const response = await fetch(requestURL, {
        method: 'GET',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
        },
      });
      const response_1 = await checkStatus(response);
      const response2 = await parseJSON(response_1);
      if (response2.status === '1') {
        setDevices(response2.result);
      }
      // eslint-disable-next-line no-empty
    } catch {}
    !nonLoading && dispatch(setLoading(false));
  };
};
