import styled, { keyframes } from 'styled-components';
import colors from 'styles/colors';

const dark = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(7, 7, 7, 0.8);
  text-align: center;
  animation: 0.3s ${dark};
  z-index: 2;
`;

export const Centered = styled.div`
  position: absolute;
  z-index: 3;
  width: 100%;
  height: 100%;
  padding: 15px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  padding: 32px 40px;
  border-radius: 8px;
  width: 100%;
  background-color: ${colors.white};
  box-sizing: border-box;

  @media (min-width: 768px) {
    max-width: 620px;
  }
`;

export const Wrapper = styled.div``;

export const FirstRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 15px;
`;

export const Title = styled.div`
  color: ${colors.gray1};
  font-weight: 500;
  font-size: 1.5rem;
  letter-spacing: 0.2px;
  line-height: 1.85rem;
`;

export const Check = styled.img`
  width: 25px;
  height: 25px;
  margin: 0 5px;
`;

export const CrossIcon = styled.img`
  width: 16px;
  height: 16px;
  cursor: pointer;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  height: 50px;
  display: inline-block;

  @media (min-width: 768px) {
    width: 40%;
    margin: 0 0 0 auto;
  }
`;

export const LastRow = styled.div`
  width: 100%;
  display: flex;
  margin-top: 10px;

  @media (min-width: 768px) {
  }
`;

export const Spinner = styled.div`
  display: inline-block;
  width: 40px;
  height: 40px;
  &:after {
    content: ' ';
    display: block;
    width: 16px;
    height: 16px;
    margin: 8px;
    border-radius: 50%;
    border: 4px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
