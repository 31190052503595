import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Label from 'components/commons/Texts/Label';
import BasicInput from 'components/commons/Inputs/BasicInput';
import { removeFirstWhiteSpace } from 'utils/functions/general';
import { FirstRowDiv, InputContainer } from './styled';

const FourthRow = (props) => {
  return (
    <FirstRowDiv>
      <InputContainer>
        <Label value={<FormattedMessage id={'shipments.input17'} />} />
        <BasicInput
          onChange={(e) =>
            props.handleChange(
              'receiverMail',
              removeFirstWhiteSpace(e.target.value),
            )
          }
          maxLength={40}
          value={props.receiverMail}
          disabled={props.show === 2}
        />
      </InputContainer>
      <InputContainer>
        <Label value={<FormattedMessage id={'shipments.input16'} />} />
        <BasicInput
          onChange={(e) =>
            props.handleChange(
              'trackingLink',
              removeFirstWhiteSpace(e.target.value),
            )
          }
          maxLength={255}
          value={props.trackingLink}
          disabled={props.show === 2}
        />
      </InputContainer>
      <InputContainer>
        <Label value={<FormattedMessage id={'shipments.input15'} />} />
        <BasicInput
          onChange={(e) =>
            props.handleChange(
              'extraRequests',
              removeFirstWhiteSpace(e.target.value),
            )
          }
          maxLength={300}
          value={props.extraRequests}
          disabled={props.show === 2}
        />
      </InputContainer>
    </FirstRowDiv>
  );
};

FourthRow.propTypes = {
  extraRequests: PropTypes.string,
  handleChange: PropTypes.func,
  mailLabelUrl: PropTypes.string,
  show: PropTypes.string,
  trackingLink: PropTypes.string,
  receiverMail: PropTypes.string,
};

export default FourthRow;
