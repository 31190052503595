import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';

import { useDispatch } from 'react-redux';
import { getFulfillments } from 'store/Fulfillments/actions';

import InProgress from './InProgress';
import Ready from './Ready';

const FulfillmentsTable = ({ setOpenModal, setItemEdit, flag }) => {
  const dispatch = useDispatch();
  const [fulfillments, setFulfillments] = useState([]);

  useEffect(() => {
    dispatch(getFulfillments(setFulfillments));
  }, [flag]);

  return (
    <>
      <Grid container rowSpacing={1} columnSpacing={2} sx={{ height: '100%' }}>
        <Grid item xs={12} sm={6}>
          <InProgress fulfillments={fulfillments} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Ready
            fulfillments={fulfillments}
            setOpenModal={setOpenModal}
            setItemEdit={setItemEdit}
          />
        </Grid>
      </Grid>
    </>
  );
};

FulfillmentsTable.propTypes = {
  setOpenModal: PropTypes.func,
  setItemEdit: PropTypes.func,
  flag: PropTypes.bool,
};

export default FulfillmentsTable;
