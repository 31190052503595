import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import InfoRow from 'components/commons/Texts/InfoRow';
import { useDispatch } from 'react-redux';
import { getClientsUsers } from 'store/Clients/actions';

const Devices = (props) => {
  const dispatch = useDispatch();

  const [users, setUsers] = useState([{}]);

  useEffect(() => {
    dispatch(getClientsUsers(setUsers, props.client));
  }, [props.module]);

  if (props.module != 1) return null;
  return (
    <>
      {users.map((user, index) => {
        return (
          <div key={index}>
            <InfoRow
              title={
                <FormattedMessage
                  id={'info.clients.users1'}
                  values={{ index: index + 1 }}
                />
              }
              value={user.user_name}
            />
            <InfoRow
              title={<FormattedMessage id={'info.clients.users2'} />}
              value={user.registered_on}
            />
          </div>
        );
      })}
    </>
  );
};

Devices.propTypes = {
  module: PropTypes.number,
  client: PropTypes.any,
};

export default Devices;
