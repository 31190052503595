import { locale } from 'index';
import history from 'utils/history';

export const redirect = (redirectArray) => {
  if (locale.includes('en')) {
    history.replace(redirectArray[1]);
  } else if (locale.includes('pt')) {
    history.replace(redirectArray[2]);
  } else {
    history.replace(redirectArray[0]);
  }
};
