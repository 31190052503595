import styled from 'styled-components';
import colors from 'styles/colors';

export const OptionsContainer = styled.div`
  display: flex;
  height: 100%;
  width: 25%;
  border-right: 1px solid ${colors.gray5};
  max-width: 270px;
  min-width: 190px;
  overflow-x: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
    height: 10px;
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${colors.gray4};
    border-right: none;
    border-left: none;
    border-radius: 8px;
    border: 3px solid #f4f8fb;
  }
  &::-webkit-scrollbar-track-piece {
    background: transparent;
  }
`;

export const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  margin: 0 24px 0px 0;
`;

export const Option = styled.div`
  display: flex;
  height: 54px;
  width: 100%;
  margin-bottom: 8px;
`;

export const Information = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 100%;
  margin: 15px 0 0 40px;
  overflow: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    height: 10px;
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${colors.gray4};
    border-right: none;
    border-left: none;
    border-radius: 8px;
    border: 3px solid #f4f8fb;
  }
  &::-webkit-scrollbar-track-piece {
    background: transparent;
  }
`;
