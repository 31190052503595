import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Label from 'components/commons/Texts/Label';
import BasicInput from 'components/commons/Inputs/BasicInput';
import { removeFirstWhiteSpace } from 'utils/functions/general';
import {
  FirstRowDiv,
  InputContainer,
  InputContainerMiddle,
  MiddleInputContainer,
} from './styled';

const FirstRow = (props) => {
  return (
    <FirstRowDiv>
      <InputContainer>
        <Label value={<FormattedMessage id={'clients.input1'} />} />
        <BasicInput
          onChange={(e) =>
            props.handleChange(
              'clientName',
              removeFirstWhiteSpace(e.target.value),
            )
          }
          maxLength={50}
          value={props.clientName}
          disabled={props.show === 2}
        />
      </InputContainer>
      <InputContainer>
        <Label value={<FormattedMessage id={'clients.input2'} />} />
        <BasicInput
          onChange={(e) =>
            props.handleChange('nif', removeFirstWhiteSpace(e.target.value))
          }
          maxLength={15}
          value={props.nif}
          disabled={props.show === 2}
        />
      </InputContainer>
      <InputContainer>
        <Label value={<FormattedMessage id={'clients.input3'} />} />
        <BasicInput
          onChange={(e) =>
            props.handleChange('address', removeFirstWhiteSpace(e.target.value))
          }
          maxLength={50}
          value={props.address}
        />
      </InputContainer>
      <InputContainerMiddle>
        <MiddleInputContainer>
          <Label value={<FormattedMessage id={'clients.input4'} />} />
          <BasicInput
            onChange={(e) =>
              props.handleChange('door', removeFirstWhiteSpace(e.target.value))
            }
            maxLength={5}
            value={props.door}
          />
        </MiddleInputContainer>
        <MiddleInputContainer>
          <Label value={<FormattedMessage id={'clients.input5'} />} />
          <BasicInput
            onChange={(e) =>
              props.handleChange('floor', removeFirstWhiteSpace(e.target.value))
            }
            maxLength={5}
            value={props.floor}
          />
        </MiddleInputContainer>
      </InputContainerMiddle>
    </FirstRowDiv>
  );
};

FirstRow.propTypes = {
  handleChange: PropTypes.func,
  clientName: PropTypes.string,
  nif: PropTypes.string,
  address: PropTypes.string,
  door: PropTypes.any,
  floor: PropTypes.any,
  show: PropTypes.number,
};

export default FirstRow;
