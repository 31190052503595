import { checkStatus, parseJSON } from 'utils/request';
import config from 'config.js';
import { selectToken } from 'store/Login/selectors';
import { setLoading } from 'store/General/actions';
import * as T from './types';

export const setTableOpen = () => {
  return {
    type: T.SET_TABLE_OPEN,
  };
};

export const getClientDevices = (setClientDevices, idAnnexProduct) => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    const token = selectToken(getState());
    const requestURL = `${
      config.baseURL
    }/v1/product/client?token=${token}&annex_x_product_id=${
      idAnnexProduct || ''
    }`;
    try {
      const response = await fetch(requestURL, {
        method: 'GET',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
        },
      });
      const response_1 = await checkStatus(response);
      const response2 = await parseJSON(response_1);
      if (response2.status === '1') {
        setClientDevices(response2.result);
      }
      // eslint-disable-next-line no-empty
    } catch {}
    dispatch(setLoading(false));
  };
};

export const insertOwnerDevice = (
  setError,
  owner,
  team,
  address,
  city,
  zip,
  mail,
  phone,
  setChanges,
  id,
) => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    setError('owner.status1');
    const token = selectToken(getState());
    const requestURL = `${config.baseURL}/v1/contract/owner?token=${token}`;
    try {
      const response = await fetch(requestURL, {
        method: 'POST',
        body: JSON.stringify({
          annex_x_product_id: id,
          owner: owner,
          team: team,
          email: mail,
          phone_number: phone,
          address: address,
          city: city,
          zip_code: zip,
        }),
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'content-type': 'application/json',
        },
      });
      const response_1 = await checkStatus(response);
      const response2 = await parseJSON(response_1);
      if (response2.status === '1') {
        setError('owner.status100');
        setChanges(false);
      } else {
        setError('owner.status' + response2.status);
      }
    } catch (error) {
      setError('owner.status99');
    }
    dispatch(setLoading(false));
  };
};
