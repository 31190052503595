import styled from 'styled-components';
import colors from 'styles/colors';
import { ReactComponent as Search } from 'images/search_modal.svg';

export const FirstRowDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const InputContainer = styled.div`
  display: flex;
  height: 100%;
  width: 24%;
  flex-direction: column;
`;

export const LastInputContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: space-between;
`;

export const LastInput = styled.div`
  display: flex;
  height: 100%;
  width: 75%;
`;

export const IconContainer = styled.div`
  display: flex;
  height: 100%;
  width: 20%;
  justify-content: center;
  align-items: center;
  background-color: ${colors.gray10};
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  transition: all 0.4s;
  cursor: ${(props) => (props.disabled ? 'auto' : 'pointer')};
  &:hover {
    transform: ${(props) => (props.disabled ? 'none' : 'translateY(-2px)')};
    box-shadow: ${(props) =>
      props.disabled ? '' : '0 2px 5px rgba(0, 0, 0, 0.2);'};
  }
`;

export const Icon = styled(Search)`
  width: 17px;
  fill: ${(props) => (props.disabled ? colors.gray6 : colors.blue2)};
`;

export const customStyles = (comp) => {
  return {
    container: (provided) => ({
      ...provided,
      width: '100%',
    }),
    control: (provided) => ({
      ...provided,
      minHeight: '100%',
      height: '48px',
      borderRadius: '8px',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    menuPortal: (provided) => ({ ...provided, zIndex: 13 }),
    menu: (provided) => ({ ...provided, zIndex: 13 }),
    input: (provided) => ({
      ...provided,
      color: comp === 'select' ? 'transparent' : 'default',
    }),
  };
};

export const Empty = styled.div`
  display: flex;
  width: 100%;
`;
