import styled from 'styled-components';

export const ConfirmButton = styled.div`
  display: flex;
  width: 17%;
  height: 45px;
  min-width: 200px;
  margin: 0 0 0 auto;
  padding: 30px 0;
`;
