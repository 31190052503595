export const login = '/';

export const inventory_es = '/inventario';
export const inventory_en = '/inventory';
export const inventory_pt = '/inventario';

export const clients_es = '/clientes';
export const clients_en = '/clients';
export const clients_pt = '/clientes';
export const clients_info_es = '/clientes/info/';
export const clients_info_en = '/clients/info/';
export const clients_info_pt = '/clientes/info/';

export const suppliers_es = '/proveedores';
export const suppliers_en = '/suppliers';
export const suppliers_pt = '/provedores';
export const suppliers_info_es = '/proveedores/info/';
export const suppliers_info_en = '/suppliers/info/';
export const suppliers_info_pt = '/provedores/info/';

export const orders_es = '/ordenes';
export const orders_en = '/orders';
export const orders_pt = '/ordens';
export const orders_info_es = '/ordenes/info/';
export const orders_info_en = '/orders/info/';
export const orders_info_pt = '/ordens/info/';

export const services_es = '/servicios';
export const services_en = '/services';
export const services_pt = '/servicos';
export const services_info_es = '/servicios/info/';
export const services_info_en = '/services/info/';
export const services_info_pt = '/servicos/info/';
export const services_annex_es = '/servicios/annex/';
export const services_annex_en = '/services/annex/';
export const services_annex_pt = '/servicos/annex/';
export const services_devices_es = '/servicios/dispositivos/';
export const services_devices_en = '/services/devices/';
export const services_devices_pt = '/servicos/dispositivos/';

export const contracts_es = '/contratos';
export const contracts_en = '/contracts';
export const contracts_pt = '/contratos';
export const contracts_annex_es = '/contratos/anexo/';
export const contracts_annex_en = '/contracts/annex/';
export const contracts_annex_pt = '/contratos/anexo/';
export const contracts_devices_es = '/contratos/dispositivos/';
export const contracts_devices_en = '/contracts/devices/';
export const contracts_devices_pt = '/contratos/dispositivos/';

export const device_es = '/dispositivo/';
export const device_en = '/device/';
export const device_pt = '/dispositivo/';

export const devices_es = '/dispositivos';
export const devices_en = '/devices';
export const devices_pt = '/dispositivos';

export const shipments_es = '/envios';
export const shipments_en = '/shipments';
export const shipments_pt = '/envios';
export const shipments_info_es = '/envios/info/';
export const shipments_info_en = '/shipments/info/';
export const shipments_info_pt = '/envios/info/';

export const fulfillments_es = '/salidas';
export const fulfillments_en = '/fulfillments';
export const fulfillments_pt = '/saidas';
export const fulfillments_info_es = '/salidas/info/';
export const fulfillments_info_en = '/fulfillments/info/';
export const fulfillments_info_pt = '/saidas/info/';

export const form_es = '/formulario/';
export const form_en = '/form/';
export const form_pt = '/formato/';

export const dashboard = '/dashboard';

export const logout = '/logout';

export const defaultRedirect = '/inventario';
export const defaultRedirectNoP = '/dashboard';
