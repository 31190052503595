import React, { useState } from 'react';
import Modal from './Modal';
import Landing from 'components/commons/Landing';
import * as C from 'components/pages/App/constants';
import { PERMISSIONS } from 'utils/constants';
import Body from './Body';

const Clients = () => {
  // 0: Close, 1: Insert, 2: Edit, 3: Add User
  const [openModal, setOpenModal] = useState(0);
  const [clientEdit, setClientEdit] = useState({});
  const [filter, setFilter] = useState('');
  const [blurred, setBlurred] = useState(false);

  return (
    <>
      {openModal !== 0 && (
        <Modal
          show={openModal}
          closeModal={() => setOpenModal(0)}
          clientEdit={clientEdit}
        />
      )}
      <Landing
        redirectArray={[C.clients_es, C.clients_en, C.clients_pt]}
        permission={PERMISSIONS.clients}
        permissionAbm={PERMISSIONS.clients_abm}
        moduleName="Clientes"
        titleId="lateral_menu.clients"
        setOpenModal={setOpenModal}
        buttonTextId="clients.add_client"
        filter={filter}
        setFilter={setFilter}
        buttonBlur={blurred ? 'clients.hide_clients' : 'clients.show_clients'}
        setBlurred={setBlurred}
        blurred={blurred}
      >
        <Body
          openModal={openModal}
          setClientEdit={setClientEdit}
          setOpenModal={setOpenModal}
          filter={filter}
          setFilter={setFilter}
          blurred={blurred}
        />
      </Landing>
    </>
  );
};

export default Clients;
