import styled from 'styled-components';

export const FirstRowDiv = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  max-height: 75px;
  min-height: 75px;
  padding-bottom: 20px;
`;

export const InputContainer = styled.div`
  display: flex;
  height: 100%;
  width: 32.5%;
  flex-direction: column;
`;

export const customStyles = (comp) => {
  return {
    container: (provided) => ({
      ...provided,
      width: '100%',
    }),
    control: (provided) => ({
      ...provided,
      minHeight: '100%',
      height: '48px',
      borderRadius: '8px',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    menuPortal: (provided) => ({ ...provided, zIndex: 13 }),
    menu: (provided) => ({ ...provided, zIndex: 13 }),
    input: (provided) => ({
      ...provided,
      color: comp === 'select' ? 'transparent' : 'default',
    }),
  };
};
