import React, { useState, useEffect } from 'react';
import Info from 'components/commons/Info';
import * as D from 'components/pages/App/constants';
import PropTypes from 'prop-types';
import { PERMISSIONS } from 'utils/constants';
import Body from './Body';
import { useDispatch } from 'react-redux';
import { getClientDevices } from 'store/Dashboard/actions';

const DashboardInfo = (props) => {
  const dispatch = useDispatch();

  const id = props.match.params.id;
  const [device, setDevice] = useState([{}]);

  useEffect(() => {
    dispatch(getClientDevices(setDevice, id));
  }, []);

  return (
    <Info
      redirectArray={[D.device_es + id, D.device_en + id, D.device_pt + id]}
      permission={PERMISSIONS.dashboard}
      moduleName="Dispositivos"
      subtitle="lateral_menu.devices"
      title={device[0].display_name}
      setModuleOnClick={D.devices_es}
    >
      <Body device={device[0]} id={id} />
    </Info>
  );
};

DashboardInfo.propTypes = {
  match: PropTypes.any,
};

export default DashboardInfo;
