import React from 'react';
import GlobalStyle from './styled';
import App from './components/pages/App';
import { Provider } from 'react-redux';
import store from './utils/store';
import { createRoot } from 'react-dom/client';
import { IntlProvider } from 'react-intl';
import Spanish from 'lang/es.json';
import English from 'lang/en.json';
import Portuguese from 'lang/pt.json';

const root = createRoot(document.getElementById('root'));
export const locale = navigator.language;
let lang;

if (locale.includes('en')) {
  lang = English;
} else if (locale.includes('pt')) {
  lang = Portuguese;
} else {
  lang = Spanish;
}

root.render(
  <IntlProvider locale={locale} messages={lang}>
    <Provider store={store}>
      <App />
      <GlobalStyle />
    </Provider>
  </IntlProvider>,
);
