import React from 'react';
import PropTypes from 'prop-types';
import GenericInput from 'components/commons/Inputs/GenericInput';
import InputLabel from 'components/commons/Texts/InputLabel';
import { Container } from './styled';

const InputWithLabel = (props) => {
  return (
    <Container>
      <InputLabel value={props.title} size={props.size} />
      <GenericInput
        disabled={props.disabled}
        value={props.value}
        onChange={props.onChange}
        error={props.error}
        type={props.type}
        name={props.name}
        placeholder={props.placeholder}
        onKeyDown={props.onKeyDown}
      />
    </Container>
  );
};

InputWithLabel.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  title: PropTypes.any,
  error: PropTypes.bool,
  type: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  size: PropTypes.string,
  onKeyDown: PropTypes.func,
};

export default InputWithLabel;
