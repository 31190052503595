import styled from 'styled-components';
import colors from 'styles/colors';

export const ButtonContainer = styled.div`
  width: 98%;
  height: 55px;
  display: flex;
  align-items: center;
  background-color: ${(props) => (props.active ? colors.blue4 : null)};
  cursor: pointer;
  border-left: ${(props) =>
    props.active ? '5px solid' + colors.blue2 : '5px solid transparent'};
  color: ${(props) => (props.active ? colors.blue2 : colors.gray8)};
  &:hover {
    color: ${(props) => (props.active ? null : colors.gray2)};
    transition: all 0.4s;
    svg {
      path {
        fill: ${(props) => (props.active ? null : colors.gray2)};
        transition: all 0.4s;
      }
    }
  }
`;

export const IconContainer = styled.div`
  display: flex;
  width: 25%;
  justify-content: center;
`;

export const Title = styled.div`
  display: flex;
  width: 75%;
  font-size: 1.125rem;
  font-weight: 500;

  @media (max-width: 1024px) {
    font-size: 1rem;
  }
`;
