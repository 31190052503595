import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import GenericButton from 'components/commons/Buttons/GenericButton';
import Check from 'images/check_no_background.png';
import {
  getDevicesForShipments,
  insertShipmentDevices,
} from 'store/Shipments/actions';
import Stages from './Stages';
import {
  Container,
  Centered,
  Wrapper,
  Title,
  Body,
  LastRow,
  ConfirmButton,
  CancelButton,
  InsertedContainer,
  IconContainer,
  Icon,
  Subtitle,
  StageContainer,
  Error,
} from './styled';

const Insert = (props) => {
  const dispatch = useDispatch();
  const [inserted, setInserted] = useState(false);
  const [allDevices, setAllDevices] = useState([]);
  const [devicesToInsert, setDevicesToInsert] = useState([]);
  const [infoDevicesToInsert, setInfoDevicesToInsert] = useState([]);
  const [error, setError] = useState('shipments.status1');

  useEffect(() => {
    function handleEscapeKey(event) {
      if (event.code === 'Escape') {
        props.closeModal();
      }
    }

    props.show === 4 &&
      dispatch(
        getDevicesForShipments(setAllDevices, props.shipmentEdit.company_nif),
      );

    document.addEventListener('keydown', handleEscapeKey);
    return () => document.removeEventListener('keydown', handleEscapeKey);
  }, []);

  return (
    <>
      <Container />
      <Centered inserted={inserted}>
        {inserted ? (
          <InsertedContainer>
            <IconContainer>
              <Icon src={Check} alt={'icon'} />
            </IconContainer>
            <Title>
              <FormattedMessage id={'shipments.confirm' + props.show} />
            </Title>
          </InsertedContainer>
        ) : (
          <>
            <Wrapper>
              <Title>
                <FormattedMessage
                  id={'shipments.title' + props.show}
                  values={{
                    shipment_id: props.shipmentEdit.shipment_id,
                  }}
                />
              </Title>
              <Body>
                <Subtitle>
                  <FormattedMessage id={'shipments.subtitle7'} />
                </Subtitle>
                <StageContainer>
                  <Stages
                    allDevices={allDevices}
                    setDevicesToInsert={setDevicesToInsert}
                    devicesToInsert={devicesToInsert}
                    shipmentEdit={props.shipmentEdit}
                    setInfoDevicesToInsert={setInfoDevicesToInsert}
                    infoDevicesToInsert={infoDevicesToInsert}
                  />
                  <Error id={error}>
                    <FormattedMessage id={error} />
                  </Error>
                </StageContainer>
                <LastRow>
                  <CancelButton>
                    <GenericButton
                      theme="light"
                      title={<FormattedMessage id={'shipments.cancel'} />}
                      onClick={props.closeModal}
                    />
                  </CancelButton>
                  <ConfirmButton>
                    <GenericButton
                      theme="dark"
                      title={<FormattedMessage id={'shipments.button3'} />}
                      onClick={() =>
                        dispatch(
                          insertShipmentDevices(
                            setError,
                            setInserted,
                            props.closeModal,
                            props.shipmentEdit,
                            infoDevicesToInsert,
                          ),
                        )
                      }
                      disabled={!devicesToInsert.length}
                    />
                  </ConfirmButton>
                </LastRow>
              </Body>
            </Wrapper>
          </>
        )}
      </Centered>
    </>
  );
};

Insert.propTypes = {
  closeModal: PropTypes.func,
  show: PropTypes.number,
  shipmentEdit: PropTypes.any,
};

export default Insert;
