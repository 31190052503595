import React from 'react';
import PropTypes from 'prop-types';
import { Label } from './styled';

const InputLabel = (props) => {
  return <Label size={props.size}> {props.value} </Label>;
};

InputLabel.propTypes = {
  value: PropTypes.any,
  size: PropTypes.string,
};

export default InputLabel;
