import styled from 'styled-components';
import colors from 'styles/colors';
import { ReactComponent as CheckTable } from 'images/check_table.svg';
import { ReactComponent as EditIcon } from 'images/edit.svg';
import { ReactComponent as laptop } from 'images/Laptop.svg';
import { ReactComponent as desktop } from 'images/Desktop.svg';
import { ReactComponent as phone } from 'images/Phone.svg';
import { ReactComponent as tablet } from 'images/Tablet.svg';
import { ReactComponent as monitor } from 'images/Monitor.svg';
import { ReactComponent as peripheral } from 'images/Peripheral.svg';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${(props) => (props.pending ? 'pointer' : 'default')};
`;

export const Check = styled(CheckTable)`
  display: flex;
  width: 25px;
  height: 20px;
  fill: ${(props) => (props.pending ? colors.gray4 : colors.green6)};
`;

export const Edit = styled(EditIcon)`
  display: flex;
  width: 25px;
  height: 20px;
`;

export const Laptop = styled(laptop)`
  display: flex;
  width: 25px;
  height: 20px;
  fill: ${colors.gray3};
`;

export const Desktop = styled(desktop)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 25px;
  height: 20px;
  fill: ${colors.gray3};
`;

export const Phone = styled(phone)`
  display: flex;
  width: 25px;
  height: 20px;
  fill: ${colors.gray3};
`;

export const Tablet = styled(tablet)`
  display: flex;
  width: 25px;
  height: 20px;
  fill: ${colors.gray3};
`;

export const Monitor = styled(monitor)`
  display: flex;
  width: 25px;
  height: 20px;
  fill: ${colors.gray3};
`;

export const Peripheral = styled(peripheral)`
  display: flex;
  width: 25px;
  height: 20px;
  fill: ${colors.gray3};
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
