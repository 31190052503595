import styled from 'styled-components';
import colors from 'styles/colors';

export const Input = styled.input`
  display: flex;
  height: 100%;
  border-radius: 8px;
  border-color: ${(props) =>
    props.disabled ? 'hsl(0, 0%, 90%);' : 'hsl(0, 0%, 80%)'};
  border-style: solid;
  border-width: 1px;
  outline: 0 !important;
  cursor: default;
  background-color: ${(props) =>
    props.disabled ? 'hsl(0, 0%, 95%);' : 'hsl(0, 0%, 100%)'};
  padding: 2px 8px;
  color: hsl(0, 0%, 20%);
  color: ${(props) => (props.disabled ? colors.gray3 : 'hsl(0, 0%, 20%)')};
  font: inherit;
  text-overflow: ellipsis;
  &:focus {
    border-color: #2684ff;
    box-shadow: 0 0 0 1px #2684ff;
  }
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;
