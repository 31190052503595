import React, { useState } from 'react';
import Landing from 'components/commons/Landing';
import * as C from 'components/pages/App/constants';
import { PERMISSIONS } from 'utils/constants';
import Body from './Body';

const Services = () => {
  const [filter, setFilter] = useState('');

  return (
    <>
      <Landing
        redirectArray={[C.services_es, C.services_en, C.services_pt]}
        permission={PERMISSIONS.services}
        permissionAbm={PERMISSIONS.services_abm}
        moduleName="Servicios"
        titleId="lateral_menu.services"
        filter={filter}
        setFilter={setFilter}
      >
        <Body filter={filter} setFilter={setFilter} />
      </Landing>
    </>
  );
};

export default Services;
