import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Label from 'components/commons/Texts/Label';
import BasicInput from 'components/commons/Inputs/BasicInput';
import { SecondRowDiv, InputContainer } from './styled';
import { EMENDU_NIF } from 'utils/constants';
import { removeFirstWhiteSpace } from 'utils/functions/general';

const SecondRow = (props) => {
  return (
    <SecondRowDiv>
      <InputContainer>
        <Label value={<FormattedMessage id={'clients.input15'} />} />
        <BasicInput
          onChange={(e) =>
            props.handleChange('role', removeFirstWhiteSpace(e.target.value))
          }
          maxLength={40}
          value={props.role}
          disabled={props.nif != EMENDU_NIF}
        />
      </InputContainer>
    </SecondRowDiv>
  );
};

SecondRow.propTypes = {
  handleChange: PropTypes.func,
  role: PropTypes.string,
  nif: PropTypes.string,
};

export default SecondRow;
