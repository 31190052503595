import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Label from 'components/commons/Texts/Label';
import { FirstRowDiv, InputContainer } from './styled';
import Select from 'react-select';
import { customStyles } from 'components/pages/Inventory/Modal/Inputs/FirstRow/styled';
import { STATUS } from './constants';

const FirstRow = (props) => {
  return (
    <FirstRowDiv>
      <InputContainer middle={props.status === '002'}>
        <Label value={<FormattedMessage id={'orders.input9'} />} />
        <Select
          onChange={(value) => props.setStatus(value.value)}
          options={STATUS}
          placeholder={''}
          styles={customStyles('select')}
          menuPortalTarget={document.body}
          menuPosition={'fixed'}
          hideSelectedOptions
          isSearchable={false}
          defaultValue={{
            value: props.status,
            label: <FormattedMessage id={'device.state' + props.status} />,
          }}
        />
      </InputContainer>
      {props.status === '002' && (
        <InputContainer middle={props.status === '002'}>
          <Label value={<FormattedMessage id={'orders.input10'} />} />
          <Select
            onChange={(value) => props.setNif(value.value)}
            options={
              props.clients &&
              props.clients.map((item) => {
                return { value: item.nif, label: item.name };
              })
            }
            placeholder={''}
            styles={customStyles('select')}
            menuPortalTarget={document.body}
            menuPosition={'fixed'}
            hideSelectedOptions
            defaultValue={{
              value: props.nif,
              label: props.itemEdit.client_reserved_name,
            }}
          />
        </InputContainer>
      )}
    </FirstRowDiv>
  );
};

FirstRow.propTypes = {
  status: PropTypes.string,
  setStatus: PropTypes.func,
  nif: PropTypes.string,
  setNif: PropTypes.func,
  itemEdit: PropTypes.any,
  clients: PropTypes.any,
};

export default FirstRow;
