import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import GenericButton from 'components/commons/Buttons/GenericButton';
import { useDispatch } from 'react-redux';
import { updateFulfillmentState } from 'store/Fulfillments/actions';
import * as styled from './styled';

const States = ({ itemEdit, closeModal, flag, setFlag }) => {
  const dispatch = useDispatch();
  const status = '007';

  const submit = () => {
    dispatch(
      updateFulfillmentState(
        closeModal,
        itemEdit.shipment_id,
        status,
        null,
        null,
        setFlag,
        flag,
      ),
    );
  };

  return (
    <>
      <styled.Container />
      <styled.Centered>
        <styled.Wrapper>
          <styled.Title>
            <FormattedMessage
              id="fulfillments.sent"
              values={{
                shipmentId: itemEdit.shipment_id,
              }}
            />
          </styled.Title>
          <styled.Body>
            <styled.LastRow>
              <styled.CancelButton>
                <GenericButton
                  theme="light"
                  title={<FormattedMessage id={'fulfillments.btn.no'} />}
                  onClick={closeModal}
                />
              </styled.CancelButton>
              <styled.ConfirmButton>
                <GenericButton
                  theme="dark"
                  title={<FormattedMessage id={'fulfillments.btn.yes'} />}
                  onClick={submit}
                />
              </styled.ConfirmButton>
            </styled.LastRow>
          </styled.Body>
        </styled.Wrapper>
      </styled.Centered>
    </>
  );
};

States.propTypes = {
  closeModal: PropTypes.func,
  itemEdit: PropTypes.object,
  flag: PropTypes.bool,
  setFlag: PropTypes.func,
};

export default States;
