import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { DEVICES_TYPE as DT } from '../constants';
import Select from 'react-select';
import Creatable from 'react-select/creatable';
import { FormattedMessage } from 'react-intl';
import Label from 'components/commons/Texts/Label';
import { useDispatch } from 'react-redux';
import { getDevice } from 'store/Inventory/actions';
import { Input } from '..';
import { removeFirstWhiteSpace } from 'utils/functions/general';
import {
  FirstRowDiv,
  InputContainer,
  customStyles,
  LastInputContainer,
  LastInput,
  IconContainer,
  Icon,
  Empty,
} from './styled';

const FirstRow = (props) => {
  const dispatch = useDispatch();
  const selectRef = useRef();

  const clean = (opt, value) => {
    props.restartValues();
    props.handleChange(opt, value);
    opt === 'deviceType' && props.handleChange('brand', '');
  };

  const handleEnter = (event) => {
    if (event.key === 'Enter' && props.version) getDeviceInfo();
  };

  const getDeviceInfo = () => {
    dispatch(
      getDevice(
        props.setSearched,
        props.setDeviceInfo,
        props.setError,
        props.deviceType,
        props.brand,
        props.model,
        props.version,
        props.handleChange,
      ),
    );
  };

  return (
    <FirstRowDiv>
      <InputContainer>
        <Label value={<FormattedMessage id={'inventory.input1'} />} />
        <Select
          options={DT}
          placeholder={<FormattedMessage id={'selectBoxDefault'} />}
          styles={customStyles('select')}
          menuPortalTarget={document.body}
          menuPosition={'fixed'}
          onChange={(opt) => clean('deviceType', opt.value)}
          hideSelectedOptions
          isSearchable={false}
          defaultValue={[
            {
              value: props.deviceType,
              label: props.deviceType,
            },
          ]}
        />
      </InputContainer>
      <InputContainer>
        <Label value={<FormattedMessage id={'inventory.input2'} />} />
        <Creatable
          options={props.handleDuplicated(props.assets, 'brand')}
          placeholder={''}
          styles={customStyles()}
          components={{
            Input,
            DropdownIndicator: () => null,
          }}
          menuPortalTarget={document.body}
          menuPosition={'fixed'}
          onInputChange={(inputValue, { action }) =>
            props.onInputChange(
              'brand',
              50,
              'string',
              removeFirstWhiteSpace(inputValue),
              {
                action,
              },
            )
          }
          isDisabled={!props.deviceType}
          onChange={(opt) => clean('brand', opt.value)}
          value={{
            label: props.brand,
            name: props.brand,
          }}
          hideSelectedOptions
          ref={selectRef}
          inputValue={props.brand}
          defaultValue={[
            {
              value: props.brand,
              label: props.brand,
            },
          ]}
        />
      </InputContainer>
      <InputContainer>
        <Label value={<FormattedMessage id={'inventory.input3'} />} />
        <Creatable
          options={props.handleDuplicated(props.assets, 'model', 'model')}
          placeholder={''}
          styles={customStyles()}
          components={{
            Input,
            DropdownIndicator: () => null,
          }}
          menuPortalTarget={document.body}
          menuPosition={'fixed'}
          onInputChange={(inputValue, { action }) =>
            props.onInputChange(
              'model',
              50,
              'string',
              removeFirstWhiteSpace(inputValue),
              {
                action,
              },
            )
          }
          isDisabled={!props.brand}
          onChange={(opt) => props.handleChange('model', opt.value)}
          value={{
            label: props.model,
            name: props.model,
          }}
          ref={selectRef}
          inputValue={props.model}
        />
      </InputContainer>
      <InputContainer>
        <Label value={<FormattedMessage id={'inventory.input4'} />} />
        <LastInputContainer>
          <LastInput>
            <Empty onKeyDown={handleEnter}>
              <Creatable
                options={props.handleDuplicated(
                  props.assets,
                  'version',
                  'version',
                )}
                placeholder={''}
                styles={customStyles()}
                components={{
                  DropdownIndicator: () => null,
                  Input,
                }}
                menuPortalTarget={document.body}
                menuPosition={'fixed'}
                onInputChange={(inputValue, { action }) =>
                  props.onInputChange(
                    'version',
                    50,
                    'string',
                    removeFirstWhiteSpace(inputValue),
                    {
                      action,
                    },
                  )
                }
                isDisabled={!props.model}
                onChange={(opt) => props.handleChange('version', opt.value)}
                value={{
                  label: props.version,
                  name: props.version,
                }}
                ref={selectRef}
                inputValue={props.version}
              />
            </Empty>
          </LastInput>
          <IconContainer
            disabled={!props.version}
            onClick={props.version ? () => getDeviceInfo() : null}
          >
            <Icon disabled={!props.version} />
          </IconContainer>
        </LastInputContainer>
      </InputContainer>
    </FirstRowDiv>
  );
};

FirstRow.propTypes = {
  handleChange: PropTypes.func,
  deviceType: PropTypes.string,
  assets: PropTypes.any,
  brand: PropTypes.string,
  handleDuplicated: PropTypes.func,
  model: PropTypes.string,
  version: PropTypes.string,
  setSearched: PropTypes.func,
  setDeviceInfo: PropTypes.func,
  setError: PropTypes.func,
  restartValues: PropTypes.func,
  onInputChange: PropTypes.func,
  show: PropTypes.number,
};

export default FirstRow;
