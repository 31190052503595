import React from 'react';
import {
  Container,
  Wrapper,
  Image,
  Title,
  SubTitle,
  InputContainer,
  Subtitle,
  InputAndSubtitle,
  SubText,
  ResetPassword,
  ButtonContainer,
  Spinner,
} from './styled';
import InputWithLabel from 'components/commons/Inputs/InputWithLabel';
import GenericButton from 'components/commons/Buttons/GenericButton';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

const LoginView = (props) => {
  const handleChange = (event) => {
    props.onChange(event.target.name, event.target.value);
  };

  const handleEnter = (event) => {
    if (event.key === 'Enter') props.onSubmit();
  };

  return (
    <Container>
      <Wrapper>
        <Image />
        <Title>
          <FormattedMessage id="login.title" />
        </Title>
        <SubTitle>
          <FormattedMessage
            id="login.subtitle"
            values={{
              mailTo: (
                <a href="mailto:customer@emendu.com">customer@emendu.com</a>
              ),
            }}
          />
        </SubTitle>
        <InputAndSubtitle>
          <InputContainer>
            <InputWithLabel
              title={<FormattedMessage id="login.input1" />}
              type="text"
              name="email"
              value={props.usernameValue}
              onChange={handleChange}
              error={props.errorIsUser}
            />
          </InputContainer>
          <Subtitle>
            <SubText
              error={
                props.errorMessage !== 'login.status1' && props.errorIsUser
              }
            >
              {
                <FormattedMessage
                  id={props.errorIsUser ? props.errorMessage : 'login.status1'}
                />
              }
            </SubText>
          </Subtitle>
        </InputAndSubtitle>
        <InputAndSubtitle>
          <InputContainer>
            <InputWithLabel
              title={<FormattedMessage id="login.input2" />}
              type="password"
              name="password"
              value={props.passwordValue}
              onChange={handleChange}
              error={props.errorIsPass}
              onKeyDown={handleEnter}
            />
          </InputContainer>
          <Subtitle>
            <SubText
              error={
                props.errorMessage !== 'login.status1' && !props.errorIsUser
              }
            >
              {
                <FormattedMessage
                  id={!props.errorIsUser ? props.errorMessage : 'login.status1'}
                />
              }
            </SubText>
            <ResetPassword
              forget
              cursor="pointer"
              onClick={() => props.setForgetPassword(true)}
            >
              {<FormattedMessage id="login.forget" />}
            </ResetPassword>
          </Subtitle>
        </InputAndSubtitle>
        <ButtonContainer>
          <GenericButton
            theme={'dark'}
            title={
              props.loading ? (
                <Spinner />
              ) : (
                <FormattedMessage id="login.button" />
              )
            }
            onClick={props.onSubmit}
          />
        </ButtonContainer>
      </Wrapper>
    </Container>
  );
};

LoginView.propTypes = {
  usernameValue: PropTypes.string,
  passwordValue: PropTypes.string,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onKeyDown: PropTypes.func,
  loading: PropTypes.bool,
  errorMessage: PropTypes.string,
  errorIsUser: PropTypes.bool,
  errorIsPass: PropTypes.bool,
  setForgetPassword: PropTypes.func,
};

export default LoginView;
