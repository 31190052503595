import { checkStatus, parseJSON } from 'utils/request';
import config from 'config.js';
import { selectToken } from 'store/Login/selectors';
import { setLoading } from 'store/General/actions';

export const createCompany = (
  handleChange,
  nif,
  description,
  clientName,
  street,
  floor,
  door,
  city,
  postalCode,
  country,
  phone,
  email,
  password,
  name,
  lastName,
  role,
  closeModal,
  inserting,
) => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    handleChange('error', 'clients.status1');
    const token = selectToken(getState());
    const requestURL = `${config.baseURL}/v1/auth/company?token=${token}`;
    try {
      const response = await fetch(requestURL, {
        method: 'POST',
        body: JSON.stringify({
          nif: nif,
          description: description,
          name: clientName,
          street: street,
          floor: floor,
          door: door,
          city: city,
          zip_code: postalCode,
          country: country,
          phone_number: phone,
          inserting: inserting,
        }),
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'content-type': 'application/json',
        },
      });
      const response_1 = await checkStatus(response);
      const response2 = await parseJSON(response_1);
      if (response2.status === '1') {
        if (inserting) {
          dispatch(
            createUser(
              handleChange,
              email,
              password,
              nif,
              name,
              lastName,
              role,
              true,
              closeModal,
            ),
          );
        } else {
          handleChange('inserted', true);
          setTimeout(closeModal, 1200);
        }
      } else {
        handleChange('error', 'clients.status' + response2.status);
        handleChange('stage', 1);
      }
    } catch (error) {
      handleChange('error', 'clients.status99');
      handleChange('stage', 1);
    }
    dispatch(setLoading(false));
  };
};

export const createUser = (
  handleChange,
  email,
  password,
  nif,
  name,
  lastName,
  role,
  isNewClient,
  closeModal,
) => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    handleChange('error', 'clients.status1');
    const token = selectToken(getState());
    const requestURL = `${config.baseURL}/v1/auth/user?token=${token}&user_name=${email}&password=${password}&nif=${nif}&first_name=${name}&last_name=${lastName}&role=${role}`;
    try {
      const response = await fetch(requestURL, {
        method: 'POST',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
        },
      });
      const response_1 = await checkStatus(response);
      const response2 = await parseJSON(response_1);
      if (response2.status === '1') {
        handleChange('inserted', true);
        setTimeout(closeModal, 1200);
      } else {
        handleChange('error', 'clients.status' + response2.status);
        if (isNewClient) dispatch(deleteClient(nif));
      }
    } catch (error) {
      handleChange('error', 'clients.status99');
      if (isNewClient) dispatch(deleteClient(nif));
    }
    dispatch(setLoading(false));
  };
};

export const deleteClient = (nif) => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    const token = selectToken(getState());
    const requestURL = `${config.baseURL}/v1/auth/company?token=${token}&nif=${nif}`;
    try {
      await fetch(requestURL, {
        method: 'DELETE',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
        },
      });

      // eslint-disable-next-line no-empty
    } catch (error) {}
  };
};

export const getClients = (setAllClients, nif, nonLoading) => {
  return async (dispatch, getState) => {
    !nonLoading && dispatch(setLoading(true));
    const token = selectToken(getState());
    const requestURL = `${config.baseURL}/v1/auth/company?token=${token}&nif=${
      nif || ''
    }`;
    try {
      const response = await fetch(requestURL, {
        method: 'GET',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
        },
      });
      const response_1 = await checkStatus(response);
      const response2 = await parseJSON(response_1);
      if (response2.status === '1') {
        setAllClients(response2.result);
      }
      // eslint-disable-next-line no-empty
    } catch {}
    !nonLoading && dispatch(setLoading(false));
  };
};

export const getClientsUsers = (setUsers, nif) => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    const token = selectToken(getState());
    const requestURL = `${config.baseURL}/v1/auth/company/user?token=${token}&nif=${nif}`;
    try {
      const response = await fetch(requestURL, {
        method: 'GET',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
        },
      });
      const response_1 = await checkStatus(response);
      const response2 = await parseJSON(response_1);
      if (response2.status === '1') {
        setUsers(response2.result);
      }
      // eslint-disable-next-line no-empty
    } catch {}
    dispatch(setLoading(false));
  };
};
