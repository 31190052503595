import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import * as styled from '../styled';
import Item from '../Item';

const Ready = ({ fulfillments, setOpenModal, setItemEdit }) => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    setItems(fulfillments.filter((item) => item.status === '006'));
  }, [fulfillments]);

  return (
    <styled.BoxWrap>
      <styled.Heading>
        <FormattedMessage id={'fulfillments.title2'} />
      </styled.Heading>
      {items.map((item) => (
        <Item
          key={item.shipment_id}
          item={item}
          setOpenModal={setOpenModal}
          setItemEdit={setItemEdit}
        />
      ))}
    </styled.BoxWrap>
  );
};

Ready.propTypes = {
  fulfillments: PropTypes.array,
  setOpenModal: PropTypes.func,
  setItemEdit: PropTypes.func,
};

export default Ready;
