import styled from 'styled-components';
import colors from 'styles/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background: ${colors.blue4};
  border-radius: 8px;
  border: 1px dashed ${colors.blue11};
  justify-content: center;
  align-items: center;
  font-size: 0.875rem;
  color: ${colors.gray3};
`;

export const Input = styled.input`
  color: ${colors.blue2};
  font-weight: 600;
  cursor: pointer;
  &::-webkit-file-upload-button {
    font-family: 'Poppins';
    background: ${colors.white};
    border: 3px solid white;
    font-weight: 600;
    color: ${colors.blue2};
    cursor: pointer;
  }
`;
