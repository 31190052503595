import React from 'react';
import PropTypes from 'prop-types';
import { Container, Input } from './styled';
import { FormattedMessage } from 'react-intl';

const InputFile = (props) => {
  const check_size = (e) => {
    if (e.target.files[0].size > 5242880) {
      props.handleError('services.contract.status9');
      e.target.value = '';
      props.onChange('');
    } else if (e.target.files[0].type !== 'application/pdf') {
      props.handleError('services.contract.status7');
      e.target.value = '';
      props.onChange('');
    } else {
      props.handleError('services.contract.status1');
      props.onChange(e);
    }
  };

  return (
    <Container>
      <Input type="file" accept=".pdf" onChange={(e) => check_size(e)} />
      <FormattedMessage id={'services.subtitle1'} />
    </Container>
  );
};

InputFile.propTypes = {
  onChange: PropTypes.func,
  handleError: PropTypes.func,
};

export default InputFile;
