import React from 'react';
import { FormattedMessage } from 'react-intl';
import OrderTag from 'components/commons/Tags/OrdersTag';
import { stringToDate } from 'utils/functions/DateUtils';
import TableIconsRow from 'components/commons/Icons/TableIconsRow';
import history from 'utils/history';
import * as D from 'components/pages/App/constants';

import { PERMISSIONS } from 'utils/constants';

export const columns = (handleEdit, user, handleDelete, handleAdd) => {
  return [
    {
      title: <FormattedMessage id={'orders.header1'} />,
      field: 'supplier_order',
      // align: 'center',
    },
    {
      title: <FormattedMessage id={'orders.header2'} />,
      field: 'supplier',
      // align: 'center',
    },
    {
      title: <FormattedMessage id={'orders.header4'} />,
      field: 'start_date',
      // align: 'center',
      customSort: (a, b) => {
        const firstDate = stringToDate(a.start_date);
        const secondDate = stringToDate(b.start_date);
        return firstDate - secondDate;
      },
    },
    {
      title: <FormattedMessage id={'orders.header5'} />,
      field: 'ending_date',
      // align: 'center',
      customSort: (a, b) => {
        const firstDate = stringToDate(a.ending_date);
        const secondDate = stringToDate(b.ending_date);
        return firstDate - secondDate;
      },
    },
    {
      title: <FormattedMessage id={'orders.header3'} />,
      field: 'devices',
      align: 'center',
      render: (row) =>
        row.devices && (
          <>
            {row.amount_devices}/{row.devices}
          </>
        ),
    },
    {
      title: <FormattedMessage id={'orders.header6'} />,
      field: 'state',
      align: 'center',
      render: (row) => row.supplier && <OrderTag status={row.state} />,
    },
    {
      // align: 'center',
      render: (row) =>
        row.supplier_order && (
          <TableIconsRow
            onCheck={
              !['001', '004'].includes(row.state) ||
              !user.permissions[PERMISSIONS.orders_abm]
                ? undefined
                : () => handleAdd(row)
            }
            onDelete={
              row.state === '003' || !user.permissions[PERMISSIONS.orders_abm]
                ? undefined
                : () => handleDelete(row)
            }
            onEdit={
              row.state === '003' || !user.permissions[PERMISSIONS.orders_abm]
                ? undefined
                : () => handleEdit(row)
            }
            onInfo={() => history.push(D.orders_info_es + row.order_number)}
            onAuxClick={() =>
              window.open(D.orders_info_es + row.order_number, '_blank')
            }
          />
        ),
    },
  ];
};
