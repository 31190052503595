import React, { useState, useEffect } from 'react';
import States from './States';
import Landing from 'components/commons/Landing';
import * as C from 'components/pages/App/constants';
import { PERMISSIONS } from 'utils/constants';

import Box from '@mui/material/Box';

import FulfillmentsTable from './Table';

const Fulfillments = () => {
  // 0: Close, 1: Confirm, 2: State
  const [openModal, setOpenModal] = useState(0);
  const [itemEdit, setItemEdit] = useState({});
  const [flag, setFlag] = useState(false);

  useEffect(() => {
    setFlag(!flag);
  }, []);

  return (
    <>
      {![0, 1].includes(openModal) && (
        <States
          show={openModal}
          closeModal={() => setOpenModal(0)}
          itemEdit={itemEdit}
          flag={flag}
          setFlag={setFlag}
        />
      )}

      <Landing
        empty
        redirectArray={[
          C.fulfillments_es,
          C.fulfillments_en,
          C.fulfillments_pt,
        ]}
        permission={PERMISSIONS.fulfillments}
        permissionAbm={PERMISSIONS.fulfillments_abm}
        moduleName="Salidas"
        setOpenModal={setOpenModal}
      >
        <Box sx={{ width: '100%', height: '100%' }}>
          <FulfillmentsTable
            openModal={openModal}
            setOpenModal={setOpenModal}
            setItemEdit={setItemEdit}
            setFlag={setFlag}
            flag={flag}
          />
        </Box>
      </Landing>
    </>
  );
};

export default Fulfillments;
