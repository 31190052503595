import styled from 'styled-components';

export const FirstRowDiv = styled.div``;

export const InputContainer = styled.div`
  display: flex;
  width: 100%;
  height: 75px;
  margin-bottom: 15px;
  flex-direction: column;
`;
