import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getClients } from 'store/Clients/actions';
import { BodyDiv } from './styled';
import { selectUser } from 'store/Login/selectors';
import Card from 'components/commons/Card';
import { PERMISSIONS as P } from 'utils/constants';
import * as D from 'components/pages/App/constants';
import history from 'utils/history';
import { getTokenClientView } from 'store/Login/actions';

const Body = (props) => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);

  const [allClients, setAllClients] = useState([]);

  useEffect(() => {
    !props.openModal && dispatch(getClients(setAllClients));
  }, [props.openModal]);

  const handleArray = (row) => {
    return {
      city: row.city,
      country: row.country,
      description: row.description || '',
      door: row.door || '',
      floor: row.floor || '',
      name: row.name,
      nif: row.nif,
      phone_number: row.phone_number,
      street: row.street,
      zip_code: row.phone_number,
    };
  };

  const handleEdit = (client, value) => {
    props.setClientEdit(handleArray(client));
    props.setOpenModal(value);
  };

  const handleFilter = () => {
    let array = [];

    allClients.forEach((row) => {
      if (row.name.toUpperCase().indexOf(props.filter.toUpperCase()) > -1) {
        array.push(handleArray(row));
      }
    });
    return array;
  };

  return (
    <BodyDiv>
      {(props.filter ? handleFilter() : allClients).map((client, index) => {
        return (
          <Card
            key={index}
            name={client.name}
            state={'suppliers.subtitle'}
            onAdd={
              user.permissions[P.clients_abm] && (() => handleEdit(client, 3))
            }
            onInfo={() => history.push(D.clients_info_es + client.nif)}
            onEdit={
              user.permissions[P.clients_abm] && (() => handleEdit(client, 2))
            }
            onAuxClick={() =>
              window.open(D.clients_info_es + client.nif, '_blank')
            }
            onShow={
              user.permissions[P.client_view] &&
              (() => dispatch(getTokenClientView(client.nif, client.name)))
            }
            blurred={props.blurred}
            blur
          />
        );
      })}
    </BodyDiv>
  );
};

Body.propTypes = {
  openModal: PropTypes.number,
  setClientEdit: PropTypes.func,
  setOpenModal: PropTypes.func,
  filter: PropTypes.string,
  blurred: PropTypes.bool,
};

export default Body;
