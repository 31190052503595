import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoginView from './components/LoginView';
import * as C from './constants';
import { loginUser } from 'store/Login/actions';
import { selectResetPassword, selectUser } from 'store/Login/selectors';
import ForgetPassword from './components/ForgetPassword';
import ResetPassword from './components/ResetPassword';
import history from 'utils/history';
import * as D from 'components/pages/App/constants';

const Login = () => {
  const [user, setUser] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('login.status1');
  const [errorIsUser, setErrorIsUser] = useState(false);
  const [errorIsPass, setErrorIsPass] = useState(false);
  const [forgetPassword, setForgetPassword] = useState(false);
  const [passUpdated, setPassUpdated] = useState(false);

  const resetPassword = useSelector(selectResetPassword);
  const currentUser = useSelector(selectUser);

  const dispatch = useDispatch();

  useEffect(() => {
    if (currentUser && !resetPassword) {
      history.replace(D.defaultRedirect);
    }
  }, []);

  const handleChange = (name, value) => {
    switch (name) {
      case 'email':
        setUser(value);
        break;
      case 'password':
        setPassword(value);
        break;
    }
  };

  const submitForm = () => {
    if (validateFields(user, password)) {
      setLoading(true);
      dispatch(
        loginUser(
          user,
          password,
          setLoading,
          setErrorMessage,
          setErrorIsUser,
          setErrorIsPass,
        ),
      );
    }
  };

  const validateFields = () => {
    cleanError();

    if (!user || !C.EMAIL_PATTERN.test(user)) {
      setErrorMessage('login.status2');
      setErrorIsUser(true);
      return false;
    }

    if (!password || password.length < 8) {
      setErrorMessage('login.status3');
      setErrorIsPass(true);
      return false;
    }

    return true;
  };

  const cleanError = () => {
    setErrorMessage('login.status1');
    setErrorIsUser(false);
    setErrorIsPass(false);
  };

  return (
    <>
      {(resetPassword || passUpdated) && (
        <ResetPassword
          setPassUpdated={setPassUpdated}
          passUpdated={passUpdated}
        />
      )}
      {forgetPassword && (
        <ForgetPassword setForgetPassword={setForgetPassword} />
      )}
      <LoginView
        usernameValue={user}
        passwordValue={password}
        onChange={handleChange}
        loading={loading}
        errorMessage={errorMessage}
        errorIsUser={errorIsUser}
        errorIsPass={errorIsPass}
        onSubmit={submitForm}
        setForgetPassword={setForgetPassword}
      />
    </>
  );
};

export default Login;
