import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import GenericButton from 'components/commons/Buttons/GenericButton';
import Check from 'images/check_no_background.png';
import { getDevicesToAnnex, insertDevicesAnnex } from 'store/Services/actions';
import Stages from './Stages';
import {
  Container,
  Centered,
  Wrapper,
  Title,
  Body,
  LastRow,
  ConfirmButton,
  CancelButton,
  InsertedContainer,
  IconContainer,
  Icon,
  Subtitle,
  StageContainer,
  Progress,
  ProgressBar,
  Error,
} from './styled';

const Insert = (props) => {
  const dispatch = useDispatch();
  const [inserted, setInserted] = useState(false);
  const [allDevices, setAllDevices] = useState([]);
  const [stage, setStage] = useState(1);
  const [devicesToInsert, setDevicesToInsert] = useState([]);
  const [infoDevicesToInsert, setInfoDevicesToInsert] = useState([]);
  const [error, setError] = useState('services.device.status1');

  useEffect(() => {
    function handleEscapeKey(event) {
      if (event.code === 'Escape') {
        props.closeModal();
      }
    }

    props.show === 1 &&
      dispatch(getDevicesToAnnex(setAllDevices, props.clientNif));

    document.addEventListener('keydown', handleEscapeKey);
    return () => document.removeEventListener('keydown', handleEscapeKey);
  }, []);

  return (
    <>
      <Container />
      <Centered inserted={inserted}>
        {inserted ? (
          <InsertedContainer>
            <IconContainer>
              <Icon src={Check} alt={'icon'} />
            </IconContainer>
            <Title>
              <FormattedMessage id={'services.device.inserted' + props.show} />
            </Title>
          </InsertedContainer>
        ) : (
          <>
            <Progress>
              <ProgressBar stage={stage} />
            </Progress>
            <Wrapper>
              <Title>
                <FormattedMessage id={'services.device.title1'} />
              </Title>
              <Body>
                <Subtitle stage={stage}>
                  <FormattedMessage id={'services.device.subtitle2'} />
                </Subtitle>
                <StageContainer>
                  <Stages
                    stage={stage}
                    allDevices={allDevices}
                    setDevicesToInsert={setDevicesToInsert}
                    devicesToInsert={devicesToInsert}
                    orderEdit={props.orderEdit}
                    setInfoDevicesToInsert={setInfoDevicesToInsert}
                    infoDevicesToInsert={infoDevicesToInsert}
                  />
                  <Error id={error} stage={stage}>
                    <FormattedMessage id={error} />
                  </Error>
                </StageContainer>
                <LastRow>
                  <CancelButton>
                    <GenericButton
                      theme="light"
                      title={
                        <FormattedMessage
                          id={stage === 1 ? 'orders.cancel' : 'orders.button5'}
                        />
                      }
                      onClick={
                        stage === 1 ? props.closeModal : () => setStage(1)
                      }
                    />
                  </CancelButton>
                  <ConfirmButton>
                    <GenericButton
                      theme="dark"
                      title={
                        <FormattedMessage
                          id={stage === 1 ? 'orders.button4' : 'orders.button3'}
                        />
                      }
                      onClick={
                        stage === 1
                          ? () => setStage(2)
                          : () =>
                              dispatch(
                                insertDevicesAnnex(
                                  setError,
                                  setInserted,
                                  props.closeModal,
                                  props.orderEdit.annex_id,
                                  infoDevicesToInsert,
                                ),
                              )
                      }
                      disabled={stage === 1 && !devicesToInsert.length}
                    />
                  </ConfirmButton>
                </LastRow>
              </Body>
            </Wrapper>
          </>
        )}
      </Centered>
    </>
  );
};

Insert.propTypes = {
  closeModal: PropTypes.func,
  show: PropTypes.number,
  orderEdit: PropTypes.any,
  clientNif: PropTypes.string,
};

export default Insert;
