export const data = (amount) => {
  return {
    datasets: [
      {
        data: [amount['999'], amount['998'], amount['997']],
        backgroundColor: ['#4AB088', '#F1B64F', '#DDDDDD'],
      },
    ],
  };
};

export const options = () => {
  return {
    maintainAspectRatio: false,
  };
};
