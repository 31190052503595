import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import DataTable from 'components/commons/Table/DataTable';
import { COLUMNS } from './constants';
import { useSelector, useDispatch } from 'react-redux';
import { selectDashboardFilter } from 'store/General/selectors';
import { setDashboardFilter } from 'store/General/actions';
import * as styled from './styled';

const Table = (props) => {
  const dispatch = useDispatch();
  const dashboardFilter = useSelector(selectDashboardFilter);

  const [height, setHeight] = useState(0);
  const ref = useRef(null);

  const handleResize = () => {
    setHeight(ref.current.clientHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleFilter = (value) => {
    dispatch(setDashboardFilter(value));
  };

  return (
    <styled.Wrapper>
      <styled.Container>
        <styled.Body>
          <styled.BodyWrapper ref={ref}>
            <DataTable
              data={props.clientDevices}
              columns={COLUMNS}
              height={height - 66 + 'px'}
              sortOrder={{
                name: 'device_start_renting_date',
                direction: 'desc',
              }}
              draggableColumns
              selectableRows="none"
              // count="5"
              onSearchChange={handleFilter}
              searchText={dashboardFilter}
            />
          </styled.BodyWrapper>
        </styled.Body>
      </styled.Container>
    </styled.Wrapper>
  );
};

Table.propTypes = {
  clientDevices: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default Table;
