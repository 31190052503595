import styled from 'styled-components';
import colors from 'styles/colors';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: 1024px) {
    display: block;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      height: 10px;
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${colors.gray4};
      border-right: none;
      border-left: none;
      border-radius: 8px;
      border: 3px solid #f4f8fb;
    }
    &::-webkit-scrollbar-track-piece {
      background: transparent;
    }
  }
`;
