import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import GenericButton from 'components/commons/Buttons/GenericButton';
import { Wrapper, customStyles, ButtonsRow, ButtonContainer } from './styled';
import { FormattedMessage } from 'react-intl';
import { DEVICES_TYPE as DT } from 'components/pages/Inventory/Modal/Inputs/constants';
import { STATUS } from 'components/pages/Inventory/States/Inputs/FirstRow/constants';

const Filters = (props) => {
  const clean = () => {
    props.handleChange('client', []);
    props.handleChange('assetType', []);
    props.handleChange('state', []);
  };

  return (
    <Wrapper>
      <Select
        options={
          props.clients &&
          props.clients.map((item) => {
            return { value: item.name, label: item.name };
          })
        }
        placeholder={<FormattedMessage id={'inventory.column6'} />}
        styles={customStyles('select')}
        menuPortalTarget={document.body}
        menuPosition={'fixed'}
        hideSelectedOptions
        isMulti
        onChange={(item) => {
          props.handleChange('client', item);
        }}
        value={props.client}
      />
      <Select
        options={DT}
        placeholder={<FormattedMessage id={'inventory.column3'} />}
        styles={customStyles('select')}
        menuPortalTarget={document.body}
        menuPosition={'fixed'}
        hideSelectedOptions
        isMulti
        onChange={(item) => {
          props.handleChange('assetType', item);
        }}
        value={props.assetType}
      />
      <Select
        options={STATUS}
        placeholder={<FormattedMessage id={'inventory.column5'} />}
        styles={customStyles('select')}
        menuPortalTarget={document.body}
        menuPosition={'fixed'}
        hideSelectedOptions
        isMulti
        onChange={(item) => {
          props.handleChange('state', item);
        }}
        value={props.state}
      />
      <ButtonsRow>
        <ButtonContainer>
          <GenericButton
            theme="light"
            title="Limpiar"
            size="small"
            onClick={() => clean()}
          />
        </ButtonContainer>
        {/* 
        <ButtonContainer>
          <GenericButton
            theme="dark"
            title="Aplicar"
            size="small"
            onClick={() => props.setFilterFlag(!props.filterFlag)}
          />
        </ButtonContainer>
        */}
      </ButtonsRow>
    </Wrapper>
  );
};

Filters.propTypes = {
  clients: PropTypes.any,
  client: PropTypes.array,
  state: PropTypes.array,
  assetType: PropTypes.array,
  handleChange: PropTypes.func,
};

export default Filters;
